import { useEffect, useState } from "react";
import "./DigitalMethodBet.scss";
import classNames from "classnames";

interface methodI {
  id: number;
  title: string;
  type: string;
  isActive: boolean;
}

interface digitalI {
  id: number;
  unit: string;
  title: string;
  listNums: Array<any>;
  listTypeBet: Array<methodI>;
  isShow: boolean;
}

const methodChoiceList: methodI[] = [
  {
    id: 1,
    title: "Tất cả",
    type: "all",
    isActive: false,
  },
  {
    id: 2,
    title: "Tài",
    type: "big",
    isActive: false,
  },
  {
    id: 3,
    title: "Xỉu",
    type: "small",
    isActive: false,
  },
  {
    id: 4,
    title: "Chẵn",
    type: "even",
    isActive: false,
  },
  {
    id: 5,
    title: "Lẻ",
    type: "odd",
    isActive: false,
  },
];

const listNumberDigital: digitalI[] = [
  {
    id: 4,
    unit: "thousand",
    title: "Nghìn",
    listNums: [],
    listTypeBet: [],
    isShow: false,
  },
  {
    id: 3,
    unit: "hundred",
    title: "Trăm",
    listNums: [],
    listTypeBet: [],
    isShow: false,
  },
  {
    id: 2,
    unit: "dozen",
    title: "Chục",
    listNums: [],
    listTypeBet: [],
    isShow: false,
  },
  {
    id: 1,
    unit: "unit",
    title: "Đơn vị",
    listNums: [],
    listTypeBet: [],
    isShow: true,
  },
];

function DigitalMethodBet(props: any) {
  const { typeBet, getCodes, statusBet } = props;
  const [listArrayDigital, setListArrayDigital] =
    useState<digitalI[]>(listNumberDigital);

  useEffect(() => {
    const listNumber = Array.from({ length: 10 }, (_, index) => ({
      number: index,
      isActive: false,
    }));
    const listArrayDigitalConvert = listArrayDigital.map((item: digitalI) => {
      item.listNums = listNumber;
      item.listTypeBet = methodChoiceList;
      if (typeBet === "DAU") {
        item.isShow = item.id === 2;
      } else if (typeBet === "DUOI") {
        item.isShow = item.id === 1;
      } else if (
        typeBet === "3CANGDAU" ||
        typeBet === "3CANGDAUDUOI" ||
        typeBet === "3CANGDACBIET" ||
        typeBet === "LO3SO" ||
        typeBet === "3CANGDAUDUOIMB" ||
        typeBet === "3CANGDAUMB"
      ) {
        item.isShow = item.id !== 4;
      } else if (typeBet === "LO4SO" || typeBet === "4CANGDACBIET") {
        item.isShow = true;
      } else {
        item.isShow = item.id === 1 || item.id === 2;
      }
      return item;
    });
    setListArrayDigital(listArrayDigitalConvert);
  }, [typeBet]);

  useEffect(() => {
    const newListArrayTicket = listArrayDigital.map((item: digitalI) => {
      const listArrNumberSelect = item?.listNums.filter(
        (num: any) => num.isActive
      );
      return convertNumberSelect(listArrNumberSelect);
    });
    convertTicket(newListArrayTicket.filter((item: any) => item !== undefined));
  }, [listArrayDigital, typeBet]);

  useEffect(() => {
    if (!statusBet) {
      handleClearDigital();
    }
  }, [statusBet]);

  const convertTicket = (array: any) => {
    let ticket;
    if (typeBet === "LO4SO" || typeBet === "4CANGDACBIET") {
      ticket = array.length === 4 ? array.join("|") : "";
    } else if (
      typeBet === "LO3SO" ||
      typeBet === "3CANGDAU" ||
      typeBet === "3CANGDACBIET" ||
      typeBet === "3CANGDAUDUOI" ||
      typeBet === "3CANGDAUDUOIMB" ||
      typeBet === "3CANGDAUMB"
    ) {
      ticket = array.length === 3 ? array.slice(0, 3).join("|") : "";
    } else if (typeBet === "DAU" || typeBet === "DUOI") {
      ticket = array.length === 1 ? array.slice(0, 1).join("|") : "";
    } else {
      ticket = array.length === 2 ? array.slice(0, 2).join("|") : "";
    }
    const checkTicketEmpty = ticket
      .split("|")
      .some((tic: string) => tic === "");
    const listArray = ticket.split("|").map((num: string) => {
      const numSplit = num?.length > 1 ? sumNumbers(num) : num?.length;
      return numSplit;
    });
    let lengthTicket: number = 1;
    listArray.forEach((item: number) => {
      lengthTicket *= item;
    });
    if (!checkTicketEmpty) {
      getCodes(ticket, lengthTicket);
    } else {
      getCodes("", 0);
    }
  };

  const handleClearDigital = () => {
    const clearArrayList = listArrayDigital.map((item: digitalI) => {
      item.listNums = item.listNums.map((num: methodI) => {
        num.isActive = false;
        return num;
      });
      item.listTypeBet = item.listTypeBet.map((num: any) => {
        num.isActive = false;
        return num;
      });
      return item;
    });
    setListArrayDigital(clearArrayList);
  };

  const convertNumberSelect = (listChoice: any) => {
    if (listChoice?.length > 0) {
      const newListChoice = listChoice.map((item: any) => item.number);
      const ticket = newListChoice.join(",");
      return ticket;
    }
  };

  // Hàm chọn số đơn
  const handleChoiceNumber = (unit: string, num: number) => {
    const newListArrayDigital = listArrayDigital.map((item: any) =>
      item.unit === unit
        ? {
            ...item,
            listNums: item.listNums.map((child: any) =>
              child.number === num
                ? { ...child, isActive: !child.isActive }
                : child
            ),
          }
        : item
    );
    setListArrayDigital(newListArrayDigital);
  };

  // Hàm chọn số theo loại
  const handlePickChoiceNumber = (type: string, unit: string) => {
    const newArrayActiveNumber = listArrayDigital.map((parent: digitalI) => {
      if (parent.unit === unit) {
        const newListMethod = parent.listTypeBet.map((item: methodI) => {
          return {
            ...item,
            isActive: type === item.type ? !item.isActive : false,
          };
        });
        const checkTypeChoice: any = newListMethod.find(
          (item: any) => item.type === type
        );
        const newListNums = parent.listNums.map((child: any) => {
          const checkBig = child.number >= 5 && child.number <= 9;
          const checkSmall = child.number >= 0 && child.number <= 4;
          const checkEven = child.number % 2 === 0;
          const checkOdd = child.number % 2 !== 0;
          return {
            ...child,
            isActive:
              type === "all"
                ? checkTypeChoice.isActive
                : type === "big"
                ? checkBig && checkTypeChoice.isActive
                : type === "small"
                ? checkSmall && checkTypeChoice.isActive
                : type === "even"
                ? checkEven && checkTypeChoice.isActive
                : type === "odd"
                ? checkOdd && checkTypeChoice.isActive
                : false,
          };
        });
        parent.listTypeBet = newListMethod;
        parent.listNums = newListNums;
      }
      return parent;
    });
    setListArrayDigital(newArrayActiveNumber);
  };

  // Hàm tính tổng của các số từ chuỗi, bỏ qua dấu &
  const sumNumbers = (input: string) => {
    const numbersArray = input.split(",");
    return numbersArray.length;
  };

  return (
    <div className="digital-bet">
      {listArrayDigital.length > 0 &&
        listArrayDigital.map(
          (digit, index) =>
            digit?.isShow === true && (
              <div
                className={`list-number-wrap flex items-center ${
                  index === 0 ? "" : "mt-2"
                } overflow-hidden`}
                key={index}
              >
                <p
                  className={classNames(
                    "title-type min-w-20 text-center text-black dark:text-white px-2.5 py-2 border-l-4 border-primary bg-[linear-gradient(90deg,rgba(255,0,0,0.29)_0%,rgba(255,0,0,0)_100%)] text-base mr-2 bg-cover"
                  )}
                >
                  {digit?.title}
                </p>
                <div className="list-number flex flex-1 items-center justify-between mx-4">
                  {digit?.listNums.length > 0 &&
                    digit?.listNums.map((num: any, index: number) => (
                      <button
                        type="button"
                        className={classNames(
                          "border-none w-9 h-9 flex items-center outline-none justify-center transition-all px-2 py-2 rounded-full bg-white dark:bg-bgPrimary text-text-secondary dark:text-white/40 cursor-pointer hover:shadow-custom1 hover:bg-ballActive dark:hover:bg-ballActive hover:text-white dark:hover:text-white duration-200",
                          {
                            "!bg-ballActive !shadow-custom1 !text-white !opacity-100":
                              num?.isActive,
                          }
                        )}
                        key={index}
                        onClick={() =>
                          handleChoiceNumber(digit?.unit, num?.number)
                        }
                      >
                        <span className="font-semibold text-sm">
                          {num?.number}
                        </span>
                      </button>
                    ))}
                </div>
                <div className="list-bet-choice flex items-center justify-between">
                  {digit?.listTypeBet.length > 0 &&
                    digit?.listTypeBet.map((item: methodI, index: number) => (
                      <button
                        className={classNames(
                          "btn-choice flex flex-col text-text-secondary dark:text-white/40 items-center transition-all justify-center text-xs font-medium cursor-pointer hover:text-black dark:hover:text-white ml-3",
                          {
                            "text-black dark:!text-white": item.isActive,
                            "!ml-0": index === 0,
                          }
                        )}
                        type="button"
                        key={index}
                        onClick={() =>
                          handlePickChoiceNumber(item.type, digit.unit)
                        }
                      >
                        {item.title}
                        <div
                          className={`w-5 h-[3px] rounded ${
                            item.isActive ? "bg-primary" : "bg-transparent"
                          }`}
                        ></div>
                      </button>
                    ))}
                </div>
              </div>
            )
        )}
    </div>
  );
}

export default DigitalMethodBet;
