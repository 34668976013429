import React, { useEffect, useState } from "react";

const ToggleMode = ({
  size = "medium",
  onToggle,
  checked,
}: {
  size: "small" | "medium" | "large";
  onToggle: any;
  checked?: boolean;
}) => {
  const [isToggle, setIsToggle] = useState<boolean>(false);

  useEffect(() => {
    setIsToggle(checked ? checked : false);
  }, [checked]);

  const handleToggle = () => {
    setIsToggle(!isToggle);
    onToggle(!isToggle);
  };

  const sizeClasses = {
    small: "w-7 h-[18px]",
    medium: "w-10 h-[22px]",
    large: "w-20 h-[42px]",
  };

  return (
    <div
      className={`flex p-[2px] items-center ${
        sizeClasses[size]
      } rounded-full transition-all duration-300 ${
        isToggle ? "bg-primary" : "bg-[#6D6D6D]"
      } cursor-pointer`}
      onClick={handleToggle}
    >
      <div
        className={`w-1/2 h-full bg-white ${
          !isToggle ? "translate-x-0" : "translate-x-full"
        } rounded-full transform transition-all duration-300 shadow-[0px_1px_2px_0px_#1018280F,0px_1px_3px_0px_#1018281A]`}
      ></div>
    </div>
  );
};

export default ToggleMode;
