import { setShowToast } from "features/commonSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function useToast() {
  const { isMobile } = useSelector((state: any) => state.layout);
  const dispatch = useDispatch();
  const [toastQueue, setToastQueue] = useState<any>([]);

  useEffect(() => {
    // Hiển thị toast đầu tiên khi toastQueue thay đổi
    if (isMobile && toastQueue.length > 0) {
      displayNextToast();
    }
  }, [isMobile, toastQueue]);

  const toastify = (
    message: string,
    type: "success" | "warning" | "error" | "info" | "default"
  ) => {
    if (window.innerWidth < 769) {
      setToastQueue((prevQueue: any) => [...prevQueue, { message, type }]);
      if (toastQueue.length === 0) {
        displayNextToast();
      }
    } else {
      toast(message, { type: type });
    }
  };

  const displayNextToast = () => {
    if (toastQueue.length > 0) {
      const nextToast = toastQueue[0];
      dispatch(
        setShowToast({
          show: true,
          message: nextToast.message,
          type: nextToast.type,
        })
      );
      // Sau khi hiển thị toast, loại bỏ nó khỏi hàng đợi
      setToastQueue((prevQueue: any) => prevQueue.slice(1));
    }
  };

  return toastify;
}

export default useToast;
