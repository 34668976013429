import { createSlice } from "@reduxjs/toolkit";

export const seoSlice = createSlice({
  name: "seo",
  initialState: {
    configSeo: {
      title: "Trợ lý xổ số hàng đầu Việt Nam",
      description: "Hệ thống xổ số trực tiếp số 1 Việt Nam - Trợ lý xổ số",
      keywords: `xổ số, kết quả xổ số, miền bắc, miền trung, miền nam, kết quả xổ số,xổ số kiến thiết, kqxs, xskt,trợ lý xổ số, keo nha cai, xo so hom nay, két quả xổ số, tỷ lệ keo nha cai, ty le keo, tỷ lể kèo, lottery assistant, lottery`,
      image: `${window.origin}/images/logo/logo.png`,
      url: `${window.origin}`,
    },
  },
  reducers: {
    setConfigSeo(state, action) {
      state.configSeo = action.payload;
    },
  },
});

const { actions, reducer } = seoSlice;

export const { setConfigSeo } = actions;
export default reducer;
