import React from "react";
import SidebarChatAI from "./components/SidebarChatAI";
import MainPageAI from "./components/MainPageAI";
import useToast from "hooks/useToast";
import { useSelector } from "react-redux";
import classNames from "classnames";

const AIChatPage = () => {
  const toastify = useToast();

  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  const handleChangePage = (e: any) => {
    e.preventDefault();
    toastify("Comming soon!", "warning");
    return;
  };

  return (
    <div className="flex w-full h-full max-w-[1440px]">
      {!isMobile && (
        <div className="w-[260px] mr-5">
          <SidebarChatAI />
        </div>
      )}
      <div
        className={classNames("flex-1 rounded-[10px] overflow-hidden", {
          "!rounded-none": isMobile,
        })}
      >
        <MainPageAI changePage={handleChangePage} />
      </div>
    </div>
  );
};

export default AIChatPage;
