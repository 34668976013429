import classNames from "classnames";
import { useSelector } from "react-redux";

const SectionContact = () => {
  const { isMobile, mode } = useSelector((state: any) => state.layout);
  return (
    <div
      className={classNames(
        "w-full flex items-center justify-center pt-20 pb-[150px] px-5",
        { "!pb-[60px]": isMobile }
      )}
    >
      <div className="flex flex-col">
        <div className="flex flex-col items-center justify-center mb-10">
          <h3
            className={classNames(
              "text-[40px] font-semibold text-linear-primary text-center mb-3",
              { "text-linear-primary-dark": mode === 0 }
            )}
          >
            Hãy là một phần trong chúng tôi
          </h3>
          <h2
            className={classNames(
              "text-linear-primary font-semibold text-center text-[32px]",
              { "text-linear-primary-dark": mode === 0 }
            )}
          >
            Liên hệ để quảng cáo
          </h2>
        </div>
        <div className="flex items-center justify-center rounded-lg overflow-hidden">
          <iframe
            width="640px"
            height="480px"
            src="https://forms.office.com/r/xmKmtrUCZ4?embed=true"
            style={{ border: "none", maxWidth: "100%", maxHeight: "100vh" }}
            allowFullScreen
          >
            {" "}
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default SectionContact;
