import lotteryApi from "apis/lotteryApi";
import classNames from "classnames";
import BottomSheet from "components/BottomSheet/BottomSheet";
import DateTimePicker from "components/DatePickerFilter/components/DateTimePicker/DateTimePicker";
import Modal from "components/Modal/Modal";
import TabSegmentComponent from "components/TabSegmentComponent/TabSegmentComponent";
import {
  checkWatingLottery,
  listIdLotteryByRegion,
  prizesMB,
  prizesMTMN,
} from "constant";
import { setLoading } from "features/commonSlice";
import { listTypeI } from "interfaces/common.interface";
import moment from "moment";
import MenuResult from "partials/MenuResult/MenuResult";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getCurrentDateTime } from "utils/date";
import {
  checkDateBigAvailabilityToday,
  checkDateChoiceToday,
  formatDateBasic,
  formatDateTimeBasic,
} from "utils/formateDate";
import { icons } from "utils/icons";
import { formatStringWinnumberToResultData } from "utils/lotteryFormat";
import ListNumberStatitic from "./components/ListNumberStatitic";
import TableDauDuoiComponent from "./components/TableDauDuoiComponent";
import TableLotteryMBComponent from "./components/TableLotteryMBComponent";
import TableLotteryXSTTComponent from "./components/TableLotteryXSTTComponent";
import WaitingForLotteryComponent from "./components/WaitingForLotteryComponent";
import { Helmet } from "react-helmet";
import { setConfigSeo } from "features/seoSlice";

const listLotteryRegion = [
  {
    id: 1,
    desc: "Miền Bắc",
    name: "mb",
  },
  {
    id: 2,
    desc: "Miền Trung",
    name: "mt",
  },
  {
    id: 3,
    desc: "Miền Nam",
    name: "mn",
  },
];

const listSliceNumber = [
  {
    id: 1,
    desc: "Bình thường",
    name: "normal",
  },
  {
    id: 2,
    desc: "2 Số",
    name: "2so",
  },
  {
    id: 3,
    desc: "3 Số",
    name: "3so",
  },
];

let countObjEvent: any = {};

const TodayResultPage = () => {
  const pathname = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const popoverRef = useRef<any>();

  const { isMobile, mode } = useSelector((state: any) => state.layout);
  const { listGames } = useSelector((state: any) => state.lottery);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [dateChoice, setDateChoice] = useState<any>(new Date());
  const [lotteryResultSelected, setLotteryResultSelected] = useState<string>(
    "Chọn đài muốn xem kết quả"
  );
  const [showMenuResultLottery, setShowMenuResultLottery] =
    useState<boolean>(false);

  const [listDataLotteryAPI, setListDataLotteryAPI] = useState<any>([]);
  const [listDataLotteryYesterdayAPI, setListDataLotteryYesterdayAPI] =
    useState<any>([]);
  const [dataHoverDauDuoi, setDataHoverDauDuoi] = useState<any>();
  const [dataSelectDauDuoi, setDataSelectDauDuoi] = useState<any>();
  const [listDataHoverDauDuoi, setListDataHoverDauDuoi] = useState<any>();
  const [listDataSelectDauDuoi, setListDataSelectDauDuoi] = useState<any>();
  const [numberSelected, setNumberSelected] = useState<number | null>(null);
  const [numberHovered, setNumberHovered] = useState<number | null>(null);

  const [regionLotteryActive, setRegionLotteryActive] = useState<string>("");

  const [listDataMainLotteryTableNew, setListDataMainLotteryTableNew] =
    useState<any>([]);

  const [listDataLotteryAnyTab, setListDataLotteryAnyTab] = useState<any>({
    mb: [],
    mt: [],
    mn: [],
  });
  const [prevListDataLotteryAnyTab, setPrevListDataLotteryAnyTab] =
    useState<any>({
      mb: [],
      mt: [],
      mn: [],
    });
  const [listDataLotteryAnyTabYesterday, setListDataLotteryAnyTabYesterday] =
    useState<any>({
      mb: [],
      mt: [],
      mn: [],
    });

  const [changedPrizesInfo, setChangedPrizesInfo] = useState<any>({
    mb: [],
    mt: [],
    mn: [],
  });

  const [waitingOpen, setWaitingOpen] = useState<any>({
    mb: false,
    mt: false,
    mn: false,
  });
  const [listDataResultEventLottery, setListDataResultEventLottery] =
    useState<any>([]);

  const [listLotteryNameByRegion, setListLotteryNameByRegion] = useState<any>(
    []
  );

  const { configSeo } = useSelector((state: any) => state?.seo);
  const [typeSliceNumber, setTypeSliceNumber] = useState<string>("normal");

  useEffect(() => {
    const parts = pathname.pathname.split("/");
    const strSlice = parts[2] || "";
    if (strSlice?.length > 0) {
      let tabs =
        strSlice === "truc-tiep-mien-bac"
          ? "mb"
          : strSlice === "truc-tiep-mien-trung"
          ? "mt"
          : "mn";
      setRegionLotteryActive(tabs);
      handleCheckShowTableData(tabs);
      // handleChangeTab({ name: tabs });
    }
  }, [pathname]);

  useEffect(() => {
    // check xem co dang đợi quay khong
    handleCheckShowTableData(regionLotteryActive);
    // setListDataMainLotteryTableNew(dataLotteryByRegion[regionLotteryActive]);
    handleSeoContent(regionLotteryActive);
    return () => {
      setDataHoverDauDuoi(null);
      setDataSelectDauDuoi(null);
      setListDataHoverDauDuoi([]);
      setListDataSelectDauDuoi([]);
    };
  }, [regionLotteryActive, waitingOpen]);

  useEffect(() => {
    try {
      //lấy giá trị cũ trước khi update lại
      const arrayOld = listDataLotteryAnyTab;
      setPrevListDataLotteryAnyTab(arrayOld);
      let dataNew = listDataLotteryAnyTab;
      // Cập nhật lại giá trị mới khi có data thay đổi
      listDataResultEventLottery?.forEach(
        (eventItem: { [x: string]: any; gameid?: any }) => {
          const regionName = findRegionByGameId(eventItem?.gameid);

          if (regionName && eventItem?.gameid) {
            setListDataLotteryAnyTab((prevListData: any) => {
              const updatedList = {
                ...prevListData,
                [regionName]: prevListData[regionName]?.map((item: any) =>
                  item.gameid === eventItem.gameid
                    ? { ...item, ...eventItem }
                    : item
                ),
              };
              return updatedList;
            });
          }
        }
      );

      checkLotteryResultMatching(listDataResultEventLottery, arrayOld, dataNew);
    } catch (error) {
    } finally {
    }
  }, [listDataResultEventLottery]);

  // handle chang mouse
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !isMobile
      ) {
        setShowPopover(false);
      }
    };

    const handleOnScroll = () => {
      if (showPopover && !isMobile) setShowPopover(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleOnScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [isMobile]);

  useEffect(() => {
    handleGetListLotteryData(dateChoice);
  }, []);
  // handle chang mouse end

  useEffect(() => {
    handleCheckShowTableData(regionLotteryActive);
    // setListDataMainLotteryTableNew(listDataLotteryAnyTab[regionLotteryActive]);
  }, [
    listDataLotteryAnyTab,
    listDataLotteryAnyTabYesterday,
    regionLotteryActive,
  ]);

  useEffect(() => {
    // check nếu ko phải ngày hôm nay thì ko gọi event
    if (!checkDateChoiceToday(dateChoice)) {
      return;
    }
    const eventSource = new EventSource(
      `${process.env.REACT_APP_BASE_API}/api/v1/lottery/public/schedules/winnumber/stream`
    );
    eventSource.onmessage = (event) => {
      if (event.data && event.data !== "keep alive") {
        const result = JSON.parse(event.data);
        setTimeout(() => {
          setListDataResultEventLottery((prevData: any) => {
            const index = prevData.findIndex(
              (item: any) => item.gameid === result?.gameid
            );
            if (index !== -1) {
              const newData = [...prevData];
              newData[index] = result;
              return newData;
            } else {
              const newData = [...prevData, result];
              return newData;
            }
          });
        }, 1000);
      }
    };
    eventSource.onerror = (error) => {
      console.log(error);
    };
    return () => {
      eventSource.close();
    };
  }, [dateChoice]);
  // Kiểm tra giá trị mới và giá trị cũ mới nhận được xem có khác gì nhau không
  const checkLotteryResultMatching = (
    listDataResultEventLottery: any,
    prevData: any,
    newData: any
  ) => {
    try {
      const dataIndexChanges = listDataResultEventLottery
        ?.map((eventItem: { [x: string]: any; gameid?: any }) => {
          const regionName = findRegionByGameId(eventItem?.gameid);
          if (!regionName) return null;
          const correspondingItem = prevData[regionName].find(
            (item: { gameid: any }) => item?.gameid === eventItem?.gameid
          );
          if (!correspondingItem) return null;
          const changedIndices: { [key: string]: number[] } = {};
          // Sắp xếp các key theo thứ tự stt
          const sortedKeys: any = Object?.keys(eventItem)
            ?.filter(
              (key) => key !== "id" && key !== "gameid" && key !== "issue"
            )
            ?.sort(
              (a, b) =>
                findPrizeSttByDataKey(regionName, a) -
                findPrizeSttByDataKey(regionName, b)
            );
          Object?.keys(sortedKeys)?.forEach((key) => {
            if (
              sortedKeys[key] !== "id" &&
              sortedKeys[key] !== "gameid" &&
              sortedKeys[key] !== "issue"
            ) {
              const oldValue = correspondingItem[sortedKeys[key]];
              const newValue = eventItem[sortedKeys[key]];

              if (
                !correspondingItem[
                  sortedKeys[Object?.keys(sortedKeys).length - 1]
                ] &&
                typeof oldValue === "string" &&
                typeof newValue === "string"
              ) {
                let oldValueArray = oldValue?.split(",");
                const newValueArray = newValue?.split(",");

                // Nếu oldValueArray chỉ chứa một chuỗi rỗng, đặt lại nó dựa trên sortedKeys[key]
                const length = findPrizeLengthByDataKey(
                  regionName,
                  sortedKeys[key]
                );
                const dataLength = Array(length)?.fill("");

                oldValueArray = dataLength?.map(
                  (_, index) => oldValueArray[index] || ""
                );
                const indices: number[] = [];
                oldValueArray?.forEach((value, index) => {
                  if (
                    value === "" &&
                    (newValueArray[index] === undefined ||
                      newValueArray[index] === "")
                  ) {
                    return;
                  }
                  if (value !== newValueArray[index]) {
                    indices?.push(index);
                  }
                });
                if (indices?.length > 0) {
                  changedIndices[sortedKeys[key]] = indices;
                } else {
                  if (sortedKeys[key] === "giaitam") {
                    if (newValue && oldValue) {
                    }
                  }
                  changedIndices[sortedKeys[key]] = [];
                }
              } else if (
                !correspondingItem[
                  sortedKeys[Object?.keys(sortedKeys).length - 1]
                ] &&
                newValue &&
                oldValue !== newValue
              ) {
                changedIndices[sortedKeys[key]] = [0];
              } else {
                changedIndices[sortedKeys[key]] = [];
              }
            }
          });
          if (String(regionName) !== "mb") {
            // Kiểm tra điều kiện cho giaitam và giaibay
            // Nếu có giải 7 mới show KQ giải 8 ra -> tránh random trùng KQ mà chưa quay xong
            if (changedIndices.giaitam?.length === 0) {
              const giatricu7 = correspondingItem["giaibay"];
              const giatricu8 = correspondingItem["giaitam"];
              const giatrimoi8 = eventItem["giaitam"];

              const giatrimoi7 = eventItem["giaibay"];

              if (!giatrimoi7) {
                changedIndices.giaitam = [0];
              }
            }
          }

          let dataConvert =
            regionName === "mb" ? sortByStt(prizesMB) : sortByStt(prizesMTMN);
          // Đặt lại giá trị cho giải cuối cùng có dữ liệu
          const result = filterChangedIndices(
            dataConvert,
            changedIndices,
            eventItem
          );
          return {
            ...eventItem,
            ...result,
            // ...changedIndices,
            region: regionName,
          };
        })
        .filter(Boolean);

      // update lại 'data vị trí thay đổi' đúng với đài đã thay đổi
      const updatedChangedPrizesInfo = listIdLotteryByRegion?.reduce(
        (acc, region) => {
          const regionDataChanges = dataIndexChanges?.filter(
            (change: { region: string }) => change?.region === region?.name
          );
          return {
            ...acc,
            [region?.name]: regionDataChanges,
          };
        },
        {}
      );
      setChangedPrizesInfo(updatedChangedPrizesInfo);
    } catch (error) {
      console.error("Error comparing data:", error);
    }
  };

  const handleGetListLotteryData = async (date: string) => {
    dispatch(setLoading(true));
    try {
      const yesterday = moment(date).subtract(1, "days").format("YYYY-MM-DD");

      const { data } = await lotteryApi.getListSchedulesByDay({
        todate: moment(date).format("YYYY-MM-DD"),
        fromdate: moment(yesterday).format("YYYY-MM-DD"),
      });

      const dataRes = data.data;
      if (data?.success && dataRes) {
        const dataSearch = [
          ...dataRes[1]?.schedules,
          // {
          //   id: 3006918,
          //   gameid: 300,
          //   issue: "2024-07-16",
          //   basetime: "2024-07-16T18:14:00",
          //   starttime: "2024-07-14T18:14:00",
          //   endtime: "2024-07-16T18:14:00",
          //   sysopentime: "2024-07-16T18:35:00",
          //   opentime: "2024-07-16T18:35:00",
          //   status: 2,
          //   winnumber: "",
          //   sale: 1,
          //   remain: 1,
          //   openstep: 1,
          //   remark: null,
          // },
        ];
        // check theo thời gian để active bảng
        let tabs = "mb";
        if (!regionLotteryActive || regionLotteryActive === "") {
          const parts = pathname.pathname.split("/");
          const strSlice = parts[2] || "";
          if (strSlice?.length > 0) {
            tabs =
              strSlice === "truc-tiep-mien-bac"
                ? "mb"
                : strSlice === "truc-tiep-mien-trung"
                ? "mt"
                : "mn";
            setRegionLotteryActive(tabs);
          } else {
            const checkTab = checkMatchingTimeTabActive();
            const changetabs = listLotteryRegion.filter(
              (item: any) => String(item?.name) === String(checkTab)
            );
            if (changetabs?.length > 0) {
              tabs = changetabs[0]?.name;
              setRegionLotteryActive(tabs);
            }
          }
        } else {
          tabs = regionLotteryActive;
        }
        // check theo thời gian để active bảng end
        let dataList: any = {
          mb: [],
          mt: [],
          mn: [],
        };

        // Lưu giá trị khi tìm kiểm tra thành dạng show tablse
        dataSearch?.forEach((item: any) => {
          const regionName = findRegionByGameId(item?.gameid);
          if (regionName) {
            const convertedData = handleConvertListLotteryAPI(
              dataSearch,
              String(regionName)
            );
            dataList[regionName] = [...convertedData];
          }
        });
        setListDataLotteryAnyTab(dataList);
        let dataListYesterday: any = {
          mb: [],
          mt: [],
          mn: [],
        };

        dataRes[0]?.schedules?.forEach((item: any) => {
          const dataWinnumber = formatStringWinnumberToResultData(
            item.gameid,
            item.winnumber
          );
          const regionName = findRegionByGameId(item?.gameid);
          if (regionName) {
            const convertedData = handleConvertListLotteryAPI(
              dataRes[0]?.schedules,
              String(regionName)
            );
            dataListYesterday[regionName] = [...convertedData];
          }
        });
        setListDataLotteryAnyTabYesterday(dataListYesterday);

        setListDataLotteryAPI(dataSearch);
        // Lưu giá trị KQ XS của ngày hôm qua, nếu thời gian chọn là ngày hôm nay
        if (checkWaitingStatus() && checkDateChoiceToday(date)) {
          setListDataLotteryYesterdayAPI(dataRes[0]?.schedules);
        }
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Format lại giá trị nhận được từ API
  const handleConvertListLotteryAPI = (
    listData: any,
    regionActive: string,
    yesterday?: boolean
  ) => {
    try {
      if (listData) {
        const region = listIdLotteryByRegion.find(
          (item: any) => String(item?.name) === String(regionActive)
        );
        const listRegionLotteryIds = region ? region?.listIdLotterys : [];
        const filterData = listData?.filter((lottery: any) =>
          listRegionLotteryIds?.includes(lottery.gameid)
        );
        const datas = handleConvertResult(filterData);

        return datas;

        setListDataLotteryAnyTab({
          ...listDataLotteryAnyTab,
          [regionActive]: datas,
        });
      }
    } catch (error) {}
  };

  // Kiểm tra xem tới giờ quay chưa
  const checkWaitingStatus = () => {
    // miền Nam: Thời gian quay 16h14
    // miền Trung: Thời gian quay 17h12 -> 17h28
    // miền Bắc: Thời gian quay 18h00
    const currentTimeVN = new Date(getCurrentDateTime());
    const currentHour = currentTimeVN.getHours();
    const currentMinute = currentTimeVN.getMinutes();
    // Kiểm tra miền Nam
    const isWaitingMN =
      currentHour < 16 || (currentHour === 16 && currentMinute < 10);
    // Kiểm tra miền Trung
    const isWaitingMT =
      currentHour < 17 || (currentHour === 17 && currentMinute < 12);
    // Kiểm tra miền Bắc
    const isWaitingMB =
      currentHour < 18 || (currentHour === 18 && currentMinute < 0);

    setWaitingOpen({
      mb: isWaitingMB,
      mt: isWaitingMT,
      mn: isWaitingMN,
    });

    return isWaitingMB || isWaitingMT || isWaitingMN;
  };

  const handleChangeTab = (tab: any) => {
    setRegionLotteryActive(tab?.name);
    setNumberSelected(null);

    navigate(
      tab?.name === "mb"
        ? "/ket-qua/truc-tiep-mien-bac"
        : tab?.name === "mt"
        ? "/ket-qua/truc-tiep-mien-trung"
        : "/ket-qua/truc-tiep-mien-nam"
    );

    // Check để hiển thị bảng data
    if (checkDateChoiceToday(dateChoice) && waitingOpen[tab?.name]) {
      setListDataMainLotteryTableNew(listDataLotteryAnyTabYesterday[tab?.name]);
    } else {
      setListDataMainLotteryTableNew(listDataLotteryAnyTab[tab?.name]);
    }
    // setListDataMainLotteryTableNew(listDataLotteryAnyTab[tab?.name]);
  };
  const handleSeoContent = (type: string) => {
    try {
      const parts = pathname.pathname.split("/");
      const strSlice = parts[2] || "";
      if (strSlice?.length > 0) {
        const nameLottery = checkWatingLottery?.find(
          (item: any) => String(item?.type) === String(type)
        );
        if (nameLottery) {
          dispatch(
            setConfigSeo({
              ...configSeo,
              title:
                nameLottery?.name.toString() +
                " - Trợ lý xổ số hàng đầu Việt Nam",
            })
          );
        }
      } else {
        dispatch(
          setConfigSeo({
            ...configSeo,
            title:
              "Xổ Số Trực Tiếp: Kết quả xổ số tường thuật từng giải - Trợ lý xổ số hàng đầu Việt Nam",
          })
        );
      }
    } catch (error) {}
  };

  const checkMatchingTimeTabActive = (): string | null => {
    try {
      // Miền Nam: Bắt đầu lúc 16h10 -> 16h35 || thời gian quay 16h14
      // Miền Trung: 17h10 -> 17h35 || thời gian quay 17h12
      // Miền Bắc: 18h15 -> 18h35 || thời gian quay 18h00

      const currentTimeVN = new Date(getCurrentDateTime());
      const currentHour = currentTimeVN.getHours();
      const currentMinute = currentTimeVN.getMinutes();

      if (currentHour < 16 || (currentHour === 16 && currentMinute < 40)) {
        // Trước 16h40 thì return "mn"
        return "mn";
      } else if (
        (currentHour === 16 && currentMinute >= 40) ||
        (currentHour === 17 && currentMinute <= 40)
      ) {
        // Từ 16h40 đến 17h40 thì return "mt"
        return "mt";
      } else if (
        currentHour > 17 ||
        (currentHour === 17 && currentMinute > 40)
      ) {
        // Sau 17h40 thì return "mb"
        return "mb";
      }
      return "mb";
    } catch (error) {
      return "mb";
    }
  };

  const handleGetNumberSelected = (number: number) => {
    setNumberSelected(number);
  };

  const findRegionByGameId = (gameid: number) => {
    for (const region of listIdLotteryByRegion) {
      if (region?.listIdLotterys?.includes(gameid)) {
        return region?.name;
      }
    }
    return null;
  };

  // Hàm để tìm length dựa trên dataKey
  const findPrizeLengthByDataKey = (
    regionName: string,
    dataKey: string
  ): number => {
    if (String(regionName) === "mb") {
      const prize = prizesMB.find((prize) => prize?.dataKey === dataKey);
      return prize ? prize?.subPrizes : 1;
    } else {
      const prize = prizesMTMN.find((prize) => prize?.dataKey === dataKey);
      return prize ? prize?.subPrizes : 1;
    }
  };

  function sortByStt(array: any[]) {
    return array?.sort(
      (a: { stt: number }, b: { stt: number }) => a?.stt - b?.stt
    );
  }

  const filterChangedIndices = (
    dataConvert: any[],
    changedIndices: { [x: string]: any },
    eventItem: any
  ) => {
    try {
      let previousHasData = false;
      const filteredIndices: any = {};
      let lastPrizeWithData = null;

      dataConvert?.forEach((prize: { dataKey: any }) => {
        const key = prize?.dataKey;
        const hasData = changedIndices[key]?.length > 0;

        if (previousHasData && !hasData) {
          filteredIndices[key] = changedIndices[key];
        } else {
          filteredIndices[key] = [];
        }

        if (hasData) {
          previousHasData = true;
          lastPrizeWithData = key;
        } else {
          previousHasData = false;
        }
      });

      // Đặt lại giá trị cho giải cuối cùng có dữ liệu
      if (lastPrizeWithData) {
        filteredIndices[lastPrizeWithData] = changedIndices[lastPrizeWithData];
      }

      // Kiểm tra và đặt lại giá trị cho vị trí cuối cùng của giải đó
      if (lastPrizeWithData && changedIndices[lastPrizeWithData]?.length > 0) {
        const lastPosition = Math.max(...changedIndices[lastPrizeWithData]);

        filteredIndices[lastPrizeWithData] = [lastPosition];
      }
      return { ...eventItem, ...filteredIndices };
    } catch (error) {}
  };
  // Hàm để tìm stt dựa trên dataKey
  const findPrizeSttByDataKey = (
    regionName: string,
    dataKey: string
  ): number => {
    if (String(regionName) === "mb") {
      const prize = prizesMB?.find((prize) => prize?.dataKey === dataKey);
      return prize ? prize?.stt : Infinity;
    } else {
      const prize = prizesMTMN?.find((prize) => prize?.dataKey === dataKey);
      return prize ? prize?.stt : Infinity;
    }
  };

  // check xem co dang đợi quay khong
  const handleCheckShowTableData = (activeTab: string) => {
    try {
      if (checkDateChoiceToday(dateChoice)) {
        if (waitingOpen?.[activeTab]) {
          setListDataMainLotteryTableNew(
            listDataLotteryAnyTabYesterday[activeTab]
          );
        } else {
          setListDataMainLotteryTableNew(listDataLotteryAnyTab[activeTab]);
        }
      } else {
        setListDataMainLotteryTableNew(listDataLotteryAnyTab[activeTab]);
      }

      // setListDataMainLotteryTableNew(listDataLotteryAnyTab[activeTab]);
    } catch (error) {}
  };

  const handleConvertResult = (arr: any) => {
    try {
      if (arr?.length === 0) return [];
      const listResult = arr?.map((item: any) => {
        const dataWinnumber = formatStringWinnumberToResultData(
          item.gameid,
          item.winnumber
        );

        return {
          ...dataWinnumber,
          gameid: item.gameid,
          timeDay: item.sysopentime,
        };
      });
      return listResult;
    } catch (error) {
      return [];
    }
  };

  const handleGetDateTime = (date: string) => {
    if (checkDateBigAvailabilityToday(date)) {
      toast.warn("Vui lòng chọn ngày phù hợp!");
      return;
    }
    setShowPopover(false);
    setDateChoice(date);
    handleGetListLotteryData(date);
  };

  const handleGetDauDuoiNumber = (
    data: any,
    indexParent: any,
    type: string
  ) => {
    setDataHoverDauDuoi({
      data: data,
      indexParent: indexParent,
      type: type,
    });
  };

  return (
    <div
      className={`bg-lotteryPageLight dark:bg-lotteryPage bg-cover w-full ${
        isMobile ? "p-4" : "pt-10 p-5"
      } ${isMobile ? "" : "rounded-[20px]"}`}
    >
      <h1
        className={` ${
          mode ? "text-linear-primary" : "text-linear-primary-dark"
        } text-[40px] font-bold mx-auto mb-5 text-center`}
      >
        KẾT QUẢ XỔ SỐ KIẾN THIẾT
      </h1>

      {isMobile && (
        <div className="flex flex-col">
          <div
            className="px-3 py-2.5 rounded-[10px] bg-white dark:bg-bgPrimary flex items-center"
            onClick={() => setShowMenuResultLottery(true)}
          >
            <span className="text-black dark:text-white font-medium text-sm w-full">
              {lotteryResultSelected}
            </span>
            <img
              src={icons.arrowDown}
              className="w-5 h-5"
              alt={lotteryResultSelected}
            />
          </div>
        </div>
      )}
      <div
        className={`flex justify-between ${
          isMobile ? "flex-col" : "flex-row"
        } mb-5`}
      >
        <TabSegmentComponent
          listTabs={listLotteryRegion}
          handleChange={handleChangeTab}
          regionLotteryActive={regionLotteryActive}
        />
        <div className={`flex flex-col ${isMobile ? "mt-3" : ""}`}>
          <span className="text-xs font-semibold text-black dark:text-white mb-1">
            Thời gian
          </span>
          <div className="relative group">
            <div
              className="flex items-center py-2 px-3 h-9 bg-white dark:bg-bgPrimary rounded-[10px] cursor-pointer"
              onClick={() => setShowPopover(!showPopover)}
            >
              <img
                src={icons.calendar}
                className="w-5 h-5 mr-1.5"
                alt="Thời gian"
              />
              <span className="text-sm font-medium text-black dark:text-white w-full mr-1.5">
                {formatDateBasic(dateChoice)}
              </span>
              <img src={icons.arrowDown} className="w-5 h-5" alt="Thời gian" />
            </div>
            <div
              className={`rounded-[10px] shadow-custom2 absolute top-10 right-0 z-50 ${
                showPopover && !isMobile
                  ? "opacity-100 visible"
                  : "opacity-0 invisible"
              } transition-all duration-300 ease-in-out`}
              ref={popoverRef}
            >
              <DateTimePicker
                onClose={() => setShowPopover(false)}
                onSubmit={handleGetDateTime}
                valueDate={formatDateTimeBasic(dateChoice)}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {isMobile && regionLotteryActive !== "mb" && (
          <div className="mb-5">
            <TabSegmentComponent
              listTabs={listSegmentNameLottery}
              handleChange={handleChangeTabProvince}
            />
          </div>
        )} */}
      {waitingOpen[regionLotteryActive] && (
        <div className={`w-full ${regionLotteryActive !== "mb" && "mb-6"}`}>
          <WaitingForLotteryComponent name={regionLotteryActive} />
        </div>
      )}

      <div
        className={classNames(
          "p-2 pt-4 bg-[#003313]/30 dark:bg-[#046E2B]/30 rounded-2xl flex flex-col"
        )}
      >
        <div
          className={classNames("flex items-center justify-between mb-4", {})}
        >
          {regionLotteryActive === "mb" && !isMobile && (
            <ListNumberStatitic
              getNumber={(number: number) => setNumberSelected(number)}
              getNumberHover={(number: number) => setNumberHovered(number)}
              lengthTable={listDataMainLotteryTableNew?.length}
              dataSelect={listDataSelectDauDuoi}
              getListDauDuoiSelected={(data: any) =>
                setListDataSelectDauDuoi(data)
              }
            />
          )}
          <div
            className={classNames("w-full flex items-center justify-end", {})}
          >
            {listSliceNumber.map((slice: listTypeI, index: number) => (
              <div
                className={classNames("flex items-center cursor-pointer", {
                  "ml-5": index > 0,
                })}
                key={index}
                onClick={() => setTypeSliceNumber(slice.name)}
              >
                <div
                  className={classNames(
                    "border border-surface-tertiary dark:border-borderPrimary rounded-full w-4 h-4 mr-1.5",
                    { "!border-none": slice.name === typeSliceNumber }
                  )}
                >
                  {slice.name === typeSliceNumber && (
                    <img
                      src={require("assets/icons/check.png")}
                      className="w-full h-full"
                      alt={slice.desc}
                    />
                  )}
                </div>
                <span className="text-xs font-semibold text-black dark:text-white">
                  {slice.desc}
                </span>
              </div>
            ))}
          </div>
        </div>
        {listDataMainLotteryTableNew?.length > 0 ? (
          <Fragment>
            {regionLotteryActive === "mb" ? (
              <div
                className={classNames("flex justify-between", {
                  "flex-col": isMobile,
                })}
              >
                <div
                  className={classNames(
                    "w-2/3 h-fit flex bg-white dark:bg-black rounded-[10px] overflow-hidden border border-borderLightPrimary dark:border-borderPrimary mr-4",
                    { "!w-full mb-4": isMobile }
                  )}
                >
                  <TableLotteryMBComponent
                    data={listDataMainLotteryTableNew}
                    // isToday={isSameDate(regionLotteryActive)}
                    isToday={checkDateChoiceToday(dateChoice)}
                    listName={listLotteryNameByRegion}
                    region={regionLotteryActive}
                    typeSlice={typeSliceNumber}
                    changedPrizesInfo={changedPrizesInfo[regionLotteryActive]}
                    dataHoverDauDuoi={dataHoverDauDuoi}
                    dataSelectDauDuoi={dataSelectDauDuoi}
                    listDataHoverDauDuoi={listDataHoverDauDuoi}
                    listDataSelectDauDuoi={listDataSelectDauDuoi}
                  />
                </div>
                {isMobile && (
                  <div className="flex items-center justify-between mb-4">
                    <ListNumberStatitic
                      getNumber={(num: number) => setNumberSelected(num)}
                      getNumberHover={(number: number) =>
                        setNumberHovered(number)
                      }
                      dataSelect={listDataSelectDauDuoi}
                      lengthTable={listDataMainLotteryTableNew?.length}
                      getListDauDuoiSelected={(data: any) =>
                        setListDataSelectDauDuoi(data)
                      }
                    />
                  </div>
                )}
                <div
                  className={classNames(
                    "w-1/3 h-fit flex bg-white dark:bg-black rounded-[10px] overflow-hidden border border-borderLightPrimary dark:border-borderPrimary",
                    { "!w-full": isMobile }
                  )}
                >
                  <TableDauDuoiComponent
                    data={listDataMainLotteryTableNew}
                    changedPrizesInfo={changedPrizesInfo[regionLotteryActive]}
                    region={regionLotteryActive}
                    listName={listLotteryNameByRegion}
                    getDauDuoi={handleGetDauDuoiNumber}
                    numberSelected={numberSelected}
                    numberHovered={numberHovered}
                    listDauDuoiSelected={listDataSelectDauDuoi}
                    getDauDuoiSelected={(data: any) => {
                      setDataSelectDauDuoi(data);
                    }}
                    getDauDuoiHover={(data: any) =>
                      setListDataHoverDauDuoi(data)
                    }
                    getListDauDuoiSelected={(data: any) =>
                      setListDataSelectDauDuoi(data)
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  className={classNames(
                    "flex h-fit bg-white dark:bg-black rounded-[10px] overflow-hidden border border-borderLightPrimary dark:border-borderPrimary",
                    { "mb-4": isMobile }
                  )}
                >
                  <TableLotteryXSTTComponent
                    data={listDataMainLotteryTableNew}
                    // isToday={isSameDate(regionLotteryActive)}
                    isToday={checkDateChoiceToday(dateChoice)}
                    listName={listLotteryNameByRegion}
                    region={regionLotteryActive}
                    typeSlice={typeSliceNumber}
                    changedPrizesInfo={changedPrizesInfo[regionLotteryActive]}
                    dataHoverDauDuoi={dataHoverDauDuoi}
                    dataSelectDauDuoi={dataSelectDauDuoi}
                    listDataHoverDauDuoi={listDataHoverDauDuoi}
                    listDataSelectDauDuoi={listDataSelectDauDuoi}
                  />
                </div>

                {!isMobile ? (
                  <div className="my-5 px-2 flex items-center justify-between">
                    <ListNumberStatitic
                      getNumber={(number: number) => setNumberSelected(number)}
                      getNumberHover={(number: number) =>
                        setNumberHovered(number)
                      }
                      dataSelect={listDataSelectDauDuoi}
                      lengthTable={listDataMainLotteryTableNew?.length}
                      getListDauDuoiSelected={(data: any) =>
                        setListDataSelectDauDuoi(data)
                      }
                    />
                    <div
                      className={classNames(
                        "w-full flex items-center justify-end "
                      )}
                    >
                      {listSliceNumber.map(
                        (slice: listTypeI, index: number) => (
                          <div
                            className={classNames(
                              "flex items-center cursor-pointer",
                              {
                                "ml-5": index > 0,
                              }
                            )}
                            key={index}
                            onClick={() => setTypeSliceNumber(slice.name)}
                          >
                            <div
                              className={classNames(
                                "border border-borderLightPrimary dark:border-borderPrimary rounded-full w-4 h-4 mr-1.5",
                                {
                                  "!border-none":
                                    slice.name === typeSliceNumber,
                                }
                              )}
                            >
                              {slice.name === typeSliceNumber && (
                                <img
                                  src={require("assets/icons/check.png")}
                                  className="w-full h-full"
                                  alt={slice.desc}
                                />
                              )}
                            </div>
                            <span className="text-xs font-semibold text-white">
                              {slice.desc}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between mb-4">
                    <ListNumberStatitic
                      getNumber={(num: number) => setNumberSelected(num)}
                      getNumberHover={(number: number) =>
                        setNumberHovered(number)
                      }
                      dataSelect={listDataSelectDauDuoi}
                      lengthTable={listDataMainLotteryTableNew?.length}
                      getListDauDuoiSelected={(data: any) =>
                        setListDataSelectDauDuoi(data)
                      }
                    />
                  </div>
                )}
                <div className="flex h-fit bg-white dark:bg-black rounded-[10px] overflow-hidden border border-borderLightPrimary dark:border-borderPrimary">
                  <TableDauDuoiComponent
                    data={listDataMainLotteryTableNew}
                    changedPrizesInfo={changedPrizesInfo[regionLotteryActive]}
                    region={regionLotteryActive}
                    listName={listLotteryNameByRegion}
                    getDauDuoi={handleGetDauDuoiNumber}
                    numberSelected={numberSelected}
                    numberHovered={numberHovered}
                    listDauDuoiSelected={listDataSelectDauDuoi}
                    getDauDuoiSelected={(data: any) => {
                      setDataSelectDauDuoi(data);
                    }}
                    getDauDuoiHover={(data: any) =>
                      setListDataHoverDauDuoi(data)
                    }
                    getListDauDuoiSelected={(data: any) =>
                      setListDataSelectDauDuoi(data)
                    }
                  />
                </div>
              </>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <h3 className="dark:text-white text-black text-center">
              Đang chờ kết quả!
            </h3>
          </Fragment>
        )}
      </div>
      <Modal
        isShow={showPopover && isMobile}
        onClose={() => setShowPopover(false)}
      >
        <DateTimePicker
          onClose={() => setShowPopover(false)}
          onSubmit={handleGetDateTime}
          valueDate={formatDateTimeBasic(dateChoice)}
        />
      </Modal>
      <BottomSheet
        isShow={showMenuResultLottery}
        title="Danh sách đài"
        onClose={() => setShowMenuResultLottery(false)}
      >
        <MenuResult onClose={() => setShowMenuResultLottery(false)} />
      </BottomSheet>
    </div>
  );
};

export default TodayResultPage;
