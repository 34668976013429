import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getStartDateTime } from "../../../../utils/date";
import { formatDateTimeBasic } from "../../../../utils/formateDate";
import { icons } from "../../../../utils/icons";
import Modal from "../../../Modal/Modal";
import DateTimePicker from "../DateTimePicker/DateTimePicker";

const DateFilter = (props: any) => {
  const { width, onChange, value, type, reset } = props;

  const containerRef = useRef<any>();

  const { isMobile } = useSelector((state: any) => state.layout);

  const [dateChoice, setDateChoice] = useState<string>(getStartDateTime());
  const [showPopoverDatePicker, setShowPopoverDatePicker] =
    useState<boolean>(false);
  const [dropUp, setDropUp] = useState(false);

  useEffect(() => {
    if (reset) {
      setDateChoice(getStartDateTime());
    }
  }, [reset]);

  useEffect(() => {
    if (value) {
      setDateChoice(value);
    }
  }, [value]);

  useEffect(() => {
    if (showPopoverDatePicker) {
      const rect = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const shouldDropUp = rect.bottom + 200 > windowHeight; // 200 là chiều cao tối đa của dropdown
      setDropUp(shouldDropUp);
    }
  }, [showPopoverDatePicker]);

  useEffect(() => {
    if (!isMobile) {
      const handleClickOutside = (event: any) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target) &&
          !isMobile
        ) {
          setShowPopoverDatePicker(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isMobile]);

  const handleTogglePopoverFromDate = () => {
    setShowPopoverDatePicker(!showPopoverDatePicker);
  };

  const handleGetDateTime = (date: string) => {
    setShowPopoverDatePicker(false);
    setDateChoice(date);
    onChange(type, date);
  };

  return (
    <div
      className={`relative ${width ? "w-[" + width + "]" : "w-60"}`}
      ref={containerRef}
    >
      <div
        className="flex items-center py-2 px-3 h-9 bg-white dark:bg-bgPrimary border border-borderLightPrimary dark:border-borderPrimary rounded-[10px] cursor-pointer"
        onClick={handleTogglePopoverFromDate}
      >
        <img src={icons.calendar} className="w-5 h-5 mr-1.5" alt="Chọn ngày" />
        <span className="text-sm font-medium text-black dark:text-white  w-full mr-1.5">
          {formatDateTimeBasic(dateChoice)}
        </span>
        <img src={icons.arrowDown} className="w-5 h-5" alt="Chọn ngày" />
      </div>
      {!isMobile && (
        <div
          className={classNames(
            "absolute top-10 -z-50 opacity-0 transition-all duration-300 ease-in-out",
            {
              "!z-[99999] !opacity-100": showPopoverDatePicker,
              "!bottom-10": dropUp,
            }
          )}
        >
          <DateTimePicker
            onClose={() => setShowPopoverDatePicker(false)}
            onSubmit={handleGetDateTime}
            valueDate={formatDateTimeBasic(dateChoice)}
          />
        </div>
      )}
      {isMobile && (
        <Modal
          isShow={showPopoverDatePicker}
          onClose={() => setShowPopoverDatePicker(false)}
        >
          <DateTimePicker
            onClose={() => setShowPopoverDatePicker(false)}
            onSubmit={handleGetDateTime}
            valueDate={formatDateTimeBasic(dateChoice)}
          />
        </Modal>
      )}
    </div>
  );
};

export default DateFilter;
