import classNames from "classnames";
import { useEffect, useState } from "react";

function BetTypeMobileComponent(props: any) {
  const { listMethod, getType, methodActive } = props;
  const [betTypeList, setBetTypeList] = useState<any>([]);
  const [betMethodList, setBetMethodList] = useState<any>([]);

  useEffect(() => {
    if (listMethod.length > 0) {
      const groupData = listMethod.reduce((group: any, item: any) => {
        const { groupbet, ...rest } = item;
        if (!group[groupbet]) {
          group[groupbet] = {
            nameType: groupbet,
            methodList: [],
            isActive: methodActive
              ? methodActive?.groupbet === groupbet
              : groupbet === "Bao lô"
              ? true
              : false,
          };
        }
        group[groupbet].methodList.push({ ...rest, groupbet: groupbet });
        return group;
      }, {});
      const groupArray = Object.values(groupData);
      setBetTypeList(groupArray);
      const listMethodFilter: any = groupArray?.find(
        (item: any) => item.isActive === true
      );
      const methodsList = listMethodFilter?.methodList.map(
        (item: any, index: number) => {
          const isActive = methodActive
            ? methodActive?.checkflag === item.checkflag
            : index === 0
            ? true
            : false;
          return { isActive: isActive, ...item };
        }
      );
      setBetMethodList(methodsList);
      if (methodsList) {
        getType(methodsList[0]);
      }
    }
  }, [listMethod, methodActive]);

  // Hàm chọn loại cược
  const handleSelectBettype = (indexType: number) => {
    const newListBettype = betTypeList.map((item: any, index: number) => {
      if (index === indexType) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setBetTypeList(newListBettype);
    const listMethodFilter = betTypeList.find(
      (item: any, index: number) => index === indexType
    );
    const methodsList = listMethodFilter?.methodList.map(
      (item: any, index: number) => {
        const isActive = index === 0 ? true : false;
        return { isActive: isActive, ...item };
      }
    );
    setBetMethodList(methodsList);
    getType(methodsList[0]);
  };

  // Hàm chọn method cược
  const handleSelectMethod = (indexMethod: number, type: string) => {
    const newlistMethod = betMethodList.map((item: any, index: number) => {
      if (index === indexMethod) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setBetMethodList(newlistMethod);
    getType(type);
  };

  return (
    <div className="bet-type w-full">
      <div className="list-bet-type bg-success-50 dark:bg-success-1000 w-full flex items-center overflow-auto px-4">
        {betTypeList?.length > 0 &&
          betTypeList.map((type: any, index: number) => (
            <span
              className={classNames(
                "type-item text-primary whitespace-nowrap px-4 py-3 mr-2 text-sm border-b-2 border-transparent font-semibold cursor-pointer",
                {
                  "!border-success-500 !text-black dark:!text-white":
                    type.isActive,
                }
              )}
              onClick={() => handleSelectBettype(index)}
              key={index}
            >
              {type.nameType}
            </span>
          ))}
      </div>
      <div className="list-bet-method flex items-center flex-1 flex-wrap p-5 mb-4">
        {betMethodList?.length > 0 &&
          betMethodList.map((item: any, index: number) => (
            <span
              className={classNames(
                "method-item text-sm whitespace-nowrap px-3 py-2 rounded-md text-primary mr-3 mb-2 cursor-pointer",
                {
                  "!text-success-500 bg-success-500 bg-opacity-15":
                    item.isActive,
                }
              )}
              onClick={() => handleSelectMethod(index, item)}
              key={index}
            >
              {item.methodname}
            </span>
          ))}
      </div>
    </div>
  );
}

export default BetTypeMobileComponent;
