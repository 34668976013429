import { createSlice } from "@reduxjs/toolkit";

export const socketSlice = createSlice({
  name: "socket",
  initialState: {
    socketLoaded: false,
  },
  reducers: {
    updateStatusSocket(state, action) {
      state.socketLoaded = action.payload;
    },
  },
});

const { actions, reducer } = socketSlice;

export const { updateStatusSocket } = actions;
export default reducer;
