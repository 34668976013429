import classNames from "classnames";
import InputComponent from "components/InputComponent/InputComponent";
import useToast from "hooks/useToast";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  validateEmail,
  validateLength,
  validatePhone,
} from "utils/validateForm";

const FormContact = () => {
  const toastify = useToast();

  const [formData, setFormData] = useState({
    username: "",
    phone: "",
    email: "",
    note: "",
  });
  const [statusFilter, setStatusFilter] = useState<boolean>(false);
  const { isMobile } = useSelector((state: any) => state.layout);

  const updateFormData = (field: any, value: any, type?: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value !== "" ? value : null,
    }));
  };

  const handleClearFilter = () => {
    setFormData({
      username: "",
      phone: "",
      email: "",
      note: "",
    });
    setStatusFilter(true);
    setTimeout(() => {
      setStatusFilter(false);
    }, 500);
  };

  const handleSubmitFormContact = async () => {
    if (validateLength(formData.username)) {
      toastify("Độ dài tối thiểu là 5 ký tự!", "warning");
      return;
    }
    if (!validatePhone(formData.phone)) {
      toastify("Số điện thoại không hợp lệ", "warning");
      return;
    }
    if (!validateEmail(formData.email)) {
      toastify("Email không hợp lệ", "warning");
      return;
    }
  };

  return (
    <div className={classNames("p-10 w-full rounded-2xl bg-cardLottery bg-cover bg-left", { "!p-4": isMobile })}>
      <div
        className={classNames("flex items-center mb-2", {
          "flex-col": isMobile,
        })}
      >
        <div className="wrap-input w-full">
          <InputComponent
            handleChange={updateFormData}
            type={"username"}
            typeInput="text"
            label="Nhập tên của bạn..."
            title="Tên"
            reset={statusFilter}
            width="w-full"
            isMobile={isMobile}
          />
        </div>
        <div className={classNames("wrap-input w-full ml-2", { "!ml-0 mt-2": isMobile })}>
          <InputComponent
            handleChange={updateFormData}
            type={"phone"}
            typeInput="text"
            label="Nhập số điện thoại..."
            title="Số điện thoại"
            reset={statusFilter}
            width="w-full"
            isMobile={isMobile}
          />
        </div>
      </div>
      <div className="wrap-input w-full mb-2">
        <InputComponent
          handleChange={updateFormData}
          type={"email"}
          typeInput="email"
          label="Nhập Email..."
          title="Email"
          width="w-full"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>
      <div className="wrap-input w-full mb-5">
        <InputComponent
          handleChange={updateFormData}
          type={"note"}
          typeInput="textarea"
          label="Nhập mô tả..."
          title="Mô tả"
          width="w-full"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>
      <div className="flex items-center justify-end">
        <button
          className="text-sm items-center justify-center h-9 w-fit px-[30px] py-2.5 bg-success-500 hover:bg-success-600 rounded-[10px] text-white border border-transparent leading-none capitalize"
          onClick={handleSubmitFormContact}
        >
          Gửi
        </button>
      </div>
    </div>
  );
};

export default FormContact;
