import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import Modal from "components/Modal/Modal";
import ModalBetMobile from "components/ModalBet/ModalBetMobile";
import ModalCommon from "components/ModalCommon/ModalCommon";
import { addListTickets } from "features/lotterySlice";
import { addBalanceUser } from "features/userSlice";
import useToast from "hooks/useToast";
import { betLotteryI } from "interfaces/lottery.interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";
import { formatCodeBreakWord, handleGetBalanceUser } from "utils/lotteryFormat";

function TableTicketBetMobile(props: any) {
  const { currentIssue } = props;
  const dispatch = useDispatch();
  const toastify = useToast();
  const { listTickets } = useSelector((state: any) => state.lottery);
  const { balance } = useSelector((state: any) => state.user);

  const [listTicketsBet, setListTicketsBet] = useState<any[]>([]);
  const [showModalBet, setShowModalBet] = useState<boolean>(false);
  const [showModalCodes, setShowModalCodes] = useState<boolean>(false);
  const [codes, setCodes] = useState<string>("");
  const [totalBetMoney, setTotalBetMoney] = useState<number>(0);
  const [paramsStressTest, setParamsStress] = useState<any>();
  const [showModalCommon, setShowModalCommon] = useState<boolean>(false);

  useEffect(() => {
    setListTicketsBet(listTickets);
    let total = 0;
    listTickets.forEach((ticket: betLotteryI) => {
      total += ticket.amount;
    });
    setTotalBetMoney(total);
  }, [listTickets]);

  const handleRemoveTicket = (indexTicket: number) => {
    const newlistTickets = listTicketsBet.filter(
      (item: any, index: number) => index !== indexTicket
    );
    dispatch(addListTickets(newlistTickets));
  };

  const handleCloseModalBet = () => {
    setShowModalBet(false);
  };

  const handleSubmitBet = async () => {
    if (
      listTicketsBet.some(
        (item: betLotteryI) =>
          item.issue !== currentIssue?.issue && item.gameid !== 50
      )
    ) {
      setShowModalCommon(true);
    } else {
      handleConfirmSubmit();
    }
  };

  const handleConfirmSubmit = async () => {
    const newListTicket = listTickets.map((obj: any) => {
      const objKeys = Object.keys(obj);
      const newObj = objKeys.reduce((acc: any, key: any) => {
        if (key !== "methodName" && key !== "odd") {
          acc[key] = obj[key];
        }
        return acc;
      }, {});

      return { ...newObj, issue: currentIssue?.issue };
    });

    let totalAmountBet: number = 0;

    listTicketsBet.forEach((element) => {
      totalAmountBet += element.amount;
    });

    if (Number(totalAmountBet) > balance) {
      setShowModalBet(false);
      toastify("Số dư không đủ!", "warning");
      return;
    }

    try {
      const { data } = await lotteryApi.submitBetLottery(newListTicket);
      if (data.error_code === 0) {
        handleCloseModalBet();
        setShowModalCommon(false);
        toastify("Đặt vé thành công!", "success");
        dispatch(addListTickets([]));
        setTotalBetMoney(0);
        handleGetBalanceUser();
        return;
      }
    } catch (error: any) {
      setShowModalBet(false);
      console.log(error);
      // if (!error.msg) toastify(error.msg, "error");
    }
  };

  const handleShowModalCodes = (codes: string) => {
    setShowModalCodes(true);
    setCodes(codes);
  };

  const handleGetStressTest = (stressTest: any) => {
    setParamsStress(stressTest);
  };

  const handleCancelTicket = () => {
    setShowModalBet(false);
  };

  const handleShowModalBet = () => {
    setShowModalBet(true);
  };

  return (
    <div className="table-ticket-bet w-full h-full p-4 bg-white dark:bg-bgPrimary flex flex-col overflow-y-auto max-h-[calc(100%-48px)]">
      <div className="total-bet-wrap flex items-center justify-between px-3 py-2 text-sm font-medium rounded-[10px] border border-borderLightPrimary dark:border-white/10 bg-secondary/10 mb-2">
        <span className="text-black dark:text-white mr-1">Tổng điểm chơi:</span>
        <span className="text-warning-500">
          {totalBetMoney ? currencyFormat(totalBetMoney) : 0}
        </span>
      </div>
      <div className="wrap-ticket-bet">
        <div className="content-table-ticket">
          {listTicketsBet.length > 0 ? (
            listTicketsBet.map((item: any, index: number) => (
              <div
                className={`ticket-item flex flex-col bg-surface-primary dark:bg-bgLayout rounded-[10px] overflow-hidden ${
                  index > 0 ? "mt-2" : ""
                }`}
                key={index}
              >
                <div className="flex flex-col px-3 py-2">
                  <div className="flex justify-between mb-2 text-xs font-medium">
                    <div className="w-1/2 flex flex-col">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Kiểu chơi
                      </span>
                      <span className="text-black dark:text-white">
                        {item?.methodName}
                      </span>
                    </div>
                    <div className="w-1/2 flex flex-col">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Số chơi
                      </span>
                      <span
                        className="text-black dark:text-white text-xs text-wrap break-words font-semibold"
                        onClick={() => handleShowModalCodes(item.codes)}
                      >
                        {item?.codes}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between text-xs font-medium">
                    <div className="w-1/2 flex flex-col">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Tỉ lệ
                      </span>
                      <span className="text-base-error">1:{item?.odd}</span>
                    </div>
                    <div className="w-1/2 flex flex-col">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Cấp số nhân
                      </span>
                      <span className="text-black dark:text-white">
                        {item?.multiple} số
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between bg-surface-tertiary dark:bg-black py-2 px-4 text-xs font-medium">
                  <div className="w-5/12 flex flex-col mr-3">
                    <span className="text-text-secondary dark:text-white/40 mb-1">
                      Điểm chơi
                    </span>
                    <span className="text-black dark:text-white">
                      {item?.amount ? currencyFormat(item?.amount) : 0}
                    </span>
                  </div>
                  <div className="w-5/12 flex flex-col">
                    <span className="text-text-secondary dark:text-white/40 mb-1">
                      Điểm thắng/lần
                    </span>
                    <span className="text-primary">
                      {currencyFormat(item.odd * 1000 * item.multiple)}
                    </span>
                  </div>
                  <div className="w-1/12 flex items-center justify-center p-1 rounded-lg bg-secondary/10">
                    <img
                      src={require("assets/icons/trash.png")}
                      className="w-5 h-5 cursor-pointer"
                      alt="Xoá vé cược"
                      onClick={() => handleRemoveTicket(index)}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-data px-2.5 py-2.5 flex items-center justify-center border-borderLightPrimary dark:border-borderPrimary">
              <span className="text-xs font-semibold text-black dark:text-white">
                Chưa có vé nào
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex items-center justify-end mt-3 pb-2">
        {totalBetMoney > 0 && (
          <button
            type="button"
            className="btn-bet flex items-center justify-center bg-primary active:opacity-90 border border-transparent text-white px-3 py-2 rounded-[10px] text-sm font-semibold"
            onClick={handleShowModalBet}
          >
            <img src={icons.bolt} className="w-5 h-5 mr-1" alt="Chơi ngay" />
            Chơi ngay
          </button>
        )}
      </div>
      <Modal
        isShow={showModalBet}
        onClose={() => setShowModalBet(false)}
        width="300"
      >
        <ModalBetMobile
          issue={currentIssue}
          onClose={handleCloseModalBet}
          clearTicket={handleCancelTicket}
          typeModalBet="betList"
          handleSubmit={handleSubmitBet}
          getStressTest={handleGetStressTest}
        />
      </Modal>
      <Modal
        isShow={showModalCodes}
        onClose={() => setShowModalCodes(false)}
        width="380"
      >
        <ModalCommon
          title="Số chơi"
          content={formatCodeBreakWord(codes, 5)}
          handleClose={() => setShowModalCodes(false)}
        />
      </Modal>
      <Modal isShow={showModalCommon} onClose={() => setShowModalCommon(false)}>
        {showModalCommon && (
          <ModalCommon
            title="Thông báo"
            content="Có vé sai lượt xổ bạn có muốn đặt vé đó ở lượt hiện tại không?"
            handleClose={() => setShowModalCommon(false)}
            handleSubmit={handleConfirmSubmit}
          />
        )}
      </Modal>
    </div>
  );
}

export default TableTicketBetMobile;
