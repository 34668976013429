import {
  dataBetWinReportI,
  dataItemGameReportI,
} from "interfaces/data.interface";
import { useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { formatGetNameLottery } from "utils/lotteryFormat";

interface propsI {
  data: dataBetWinReportI[];
}

function DetailReportBet(props: propsI) {
  const { data } = props;
  const { listGames } = useSelector((state: any) => state.lottery);

  return (
    <div className="detail-report px-4 py-3 w-full h-[calc(100%-56px)] overflow-auto bg-white dark:bg-bgPrimary">
      <table className="table-auto w-full my-1 rounded-[10px] bg-transparent">
        <thead className="bg-surface-tertiary dark:bg-black rounded-tl-[10px] rounded-tr-[10px]">
          <tr className="grid grid-cols-3 items-center justify-center text-warning-500">
            <th className="col-span-1 text-sm text-center border-t border-b border-l border-borderLightPrimary dark:border-borderPrimary py-1 px-3">
              Trò chơi
            </th>
            <th className="col-span-1 text-sm text-center border border-r-0 border-borderLightPrimary dark:border-borderPrimary py-1 px-3">
              Chơi
            </th>
            <th className="col-span-1 text-sm text-center border border-l border-borderLightPrimary dark:border-borderPrimary py-1 px-3">
              Thắng
            </th>
          </tr>
        </thead>
        <tbody className="rounded-bl-[10px] rounded-br-[10px]">
          {data?.map((filteredItem: dataBetWinReportI, index: number) =>
            filteredItem.details.map(
              (detail: dataItemGameReportI, detailIndex: number) => (
                <tr
                  key={detailIndex}
                  className="grid grid-cols-3 bg-white dark:bg-bgPrimary items-center justify-center text-black dark:text-white"
                >
                  <td className="col-span-1 flex items-center justify-center text-sm text-center border-b border-l border-borderLightPrimary dark:border-borderPrimary py-1 px-3 ">
                    {formatGetNameLottery(detail.gameid, listGames)}
                  </td>
                  <td className="col-span-1 flex items-center justify-center text-sm text-center border-b  border-l border-borderLightPrimary dark:border-borderPrimary py-1 px-3 h-full">
                    {detail.bet ? currencyFormat(detail.bet) : 0}
                  </td>
                  <td className="col-span-1 flex items-center justify-center text-sm text-center border-b  border-l border-r border-borderLightPrimary dark:border-borderPrimary py-1 px-3 h-full">
                    {detail.win ? currencyFormat(detail.win) : 0}
                  </td>
                </tr>
              )
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default DetailReportBet;
