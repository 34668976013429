import { Tooltip } from "@mui/material";
import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalBet from "components/ModalBet/ModalBet";
import ModalCommon from "components/ModalCommon/ModalCommon";
import { addListTickets } from "features/lotterySlice";
import { addBalanceUser } from "features/userSlice";
import useToast from "hooks/useToast";
import { betLotteryI } from "interfaces/lottery.interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatBetContent,
  formatCodesEasyWatch,
  handleGetBalanceUser,
} from "utils/lotteryFormat";
import { currencyFormat } from "../../utils/currencyFormat";
import "./TableTicketBet.scss";
import { icons } from "utils/icons";

const listHeaderTitle = [
  "Lượt xổ",
  "Kiểu chơi",
  "Số chơi",
  "Tổng số chơi",
  "Cấp số nhân",
  "Điểm chơi",
  "Điểm thắng/lần",
  "",
];

const listWidths = [
  "w-2/12",
  "w-2/12",
  "w-2/12",
  "w-1/12",
  "w-1/12",
  "w-2/12",
  "w-2/12",
  "w-10",
];

function TableTicketBet(props: any) {
  const { getCheck, gameid } = props;
  const dispatch = useDispatch();
  const toastify = useToast();
  const { listTickets, currentIssue } = useSelector(
    (state: any) => state.lottery
  );
  const { balance } = useSelector((state: any) => state.user);

  const [listTicketsBet, setListTicketsBet] = useState<betLotteryI[]>([]);
  const [totalBetMoney, setTotalBetMoney] = useState<number>(0);
  const [showModalBet, setShowModalBet] = useState<boolean>(false);
  const [paramsStressTest, setParamsStress] = useState<any>();
  const [showModalCommon, setShowModalCommon] = useState<boolean>(false);

  useEffect(() => {
    setListTicketsBet(listTickets);
    let total = 0;
    listTickets.forEach((ticket: betLotteryI) => {
      total += ticket.amount;
    });
    setTotalBetMoney(total);
  }, [listTickets, currentIssue]);

  const handleRemoveTicket = (indexTicket: number) => {
    const newlistTickets = listTicketsBet.filter(
      (item: any, index: number) => index !== indexTicket
    );
    dispatch(addListTickets(newlistTickets));
  };

  const handleSubmitBet = async () => {
    if (
      listTicketsBet.some(
        (item: betLotteryI) =>
          item.issue !== currentIssue?.issue && item.gameid !== 50
      )
    ) {
      setShowModalCommon(true);
    } else {
      handleConfirmSubmit();
    }
  };

  const handleConfirmSubmit = async () => {
    const newListTicket = listTickets.map((obj: any) => {
      const objKeys = Object.keys(obj);
      const newObj = objKeys.reduce((acc: any, key: any) => {
        if (key !== "methodName" && key !== "odd") {
          acc[key] = obj[key];
        }
        return acc;
      }, {});

      return { ...newObj, issue: currentIssue?.issue };
    });

    let totalAmountBet: number = 0;

    listTicketsBet.forEach((element) => {
      totalAmountBet += element.amount;
    });

    if (Number(totalAmountBet) > balance) {
      setShowModalBet(false);
      toastify("Số dư không đủ!", "warning");
      return;
    }

    try {
      const { data } = await lotteryApi.submitBetLottery(newListTicket);
      if (data.error_code === 0) {
        handleCloseModalBet();
        setShowModalCommon(false);
        getCheck(true);
        toastify("Đặt vé thành công!", "success");
        dispatch(addListTickets([]));
        setTotalBetMoney(0);
        handleGetBalanceUser();
        return;
      }
    } catch (error: any) {
      // if (!error.msg) toastify(error.msg, "error");
      console.log(error);
    }
  };

  const handleGetStressTest = (stressTest: any) => {
    setParamsStress(stressTest);
  };

  const handleCancelTicket = () => {
    setShowModalBet(false);
  };

  const handleCloseModalBet = () => {
    setShowModalBet(false);
  };

  const submitBetNow = (type: string) => {
    setShowModalBet(true);
  };

  return (
    <>
      <div className="table-ticket rounded overflow-hidden">
        <div className="title-table-wrap flex items-center">
          <span className="title-table text-xl font-semibold text-black dark:text-white">
            Vé Chờ Thanh Toán{" "}
            {listTickets?.length > 0 && `(${listTickets?.length})`}
          </span>
        </div>
        <div className="wrap-ticket-bet mt-3 border border-borderLightPrimary dark:border-borderPrimary rounded-[10px] overflow-hidden">
          <div className="header-table-ticket flex bg-surface-tertiary dark:bg-black items-center py-2.5">
            {listHeaderTitle?.map((header: any, index: number) => (
              <div
                className={`${listWidths[index]} px-2.5 flex items-center justify-center`}
                key={index}
              >
                <span className="text-primary text-xs text-center font-semibold">
                  {header}
                </span>
              </div>
            ))}
          </div>
          <div className="content-table-ticket">
            {listTicketsBet.length > 0 ? (
              listTicketsBet.map((item: any, index: number) => (
                <div
                  className={`ticket-item flex items-center py-2.5 ${
                    index % 2 === 0
                      ? "bg-white dark:bg-bgPrimary"
                      : "bg-surface-tertiary dark:bg-black"
                  }`}
                  key={index}
                >
                  <div
                    className={`px-2.5 ${listWidths[0]} flex items-center justify-center`}
                  >
                    <span className="text-black dark:text-white text-xs font-semibold text-center">
                      {item.issue}
                    </span>
                  </div>
                  <div
                    className={`px-2.5 ${listWidths[1]} flex items-center justify-center`}
                  >
                    <span className="text-black dark:text-white text-xs font-semibold text-center">
                      {formatBetContent(item.betcontent)}
                    </span>
                  </div>
                  <div
                    className={`px-2.5 ${listWidths[2]} flex items-center justify-center`}
                  >
                    <Tooltip
                      title={formatCodesEasyWatch(item?.codes)}
                      placement="right"
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -8],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <span className="text-black dark:text-white text-xs font-semibold text-center truncate text-nowrap max-w-[90%]">
                        {formatCodesEasyWatch(item?.codes)}
                      </span>
                    </Tooltip>
                  </div>
                  <div
                    className={`px-2.5 ${listWidths[3]} flex items-center justify-center`}
                  >
                    <span className="text-black dark:text-white text-xs font-semibold text-center">
                      {item.betnumber} số
                    </span>
                  </div>
                  <div
                    className={`px-2.5 ${listWidths[4]} flex items-center justify-center`}
                  >
                    <span className="text-black dark:text-white text-xs font-semibold text-center">
                      {item.multiple} lần
                    </span>
                  </div>
                  <div
                    className={`px-2.5 ${listWidths[5]} flex items-center justify-center`}
                  >
                    <span className="text-black dark:text-white text-xs font-semibold text-center">
                      {currencyFormat(item?.amount)}
                    </span>
                  </div>
                  <div
                    className={`px-2.5 ${listWidths[6]} flex items-center justify-center`}
                  >
                    <span className="text-warning-500 text-xs font-semibold text-center">
                      {currencyFormat(item.odd * 1000 * item.multiple)}
                    </span>
                  </div>
                  <div
                    className={`px-2.5 ${listWidths[7]} flex items-center justify-center`}
                  >
                    <figure className="p-1 rounded-md bg-primary/10 hover:bg-primary/20">
                      <img
                        src={require("assets/icons/trash.png")}
                        className="w-5 h-5 cursor-pointer"
                        alt="Xoá vé cược"
                        onClick={() => handleRemoveTicket(index)}
                      />
                    </figure>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data px-2.5 py-2.5 flex items-center justify-center bg-white dark:bg-bgPrimary">
                <span className=" text-xs font-semibold text-black dark:text-white">
                  Chưa có vé nào
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="wrap-amount-ticket flex items-center justify-between mt-3 w-full">
          <div className="total-bet-wrap bg-secondary/10 py-1.5 px-3 border border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm">
            <span className="text-text-secondary dark:text-white mr-2">
              Tổng điểm chơi:
            </span>
            <span className="text-warning-500 font-semibold">
              {totalBetMoney ? currencyFormat(totalBetMoney) : 0}
            </span>
          </div>
          <div className="wrap-button">
            <button
              className={classNames(
                "btn-bet bg-primary flex items-center justify-center text-white hover:bg-primary/80 px-3 py-2.5 rounded-[10px] text-sm font-semibold",
                { disable: listTickets.length === 0 }
              )}
              type="button"
              onClick={() => submitBetNow("bet")}
            >
              <img src={icons.bolt} className="w-5 h-5 mr-1" alt="Chơi ngay" />
              Chơi ngay
            </button>
          </div>
        </div>
      </div>
      <Modal isShow={showModalBet} onClose={() => setShowModalBet(false)}>
        {showModalBet && (
          <ModalBet
            onClose={handleCloseModalBet}
            clearTicket={handleCancelTicket}
            gameid={gameid}
            typeModalBet="betList"
            issue={currentIssue}
            handleSubmit={handleSubmitBet}
            getStressTest={handleGetStressTest}
          />
        )}
      </Modal>
      <Modal isShow={showModalCommon} onClose={() => setShowModalCommon(false)}>
        {showModalCommon && (
          <ModalCommon
            title="Thông báo"
            content="Có vé sai lượt xổ bạn có muốn đặt vé đó ở lượt hiện tại không?"
            handleClose={() => setShowModalCommon(false)}
            handleSubmit={handleConfirmSubmit}
          />
        )}
      </Modal>
    </>
  );
}

export default TableTicketBet;
