import lotteryApi from "apis/lotteryApi";
import classNames from "classnames";
import BottomSheet from "components/BottomSheet/BottomSheet";
import BounceLoadingComponent from "components/BounceLoadingComponent/BounceLoadingComponent";
import { listIdLotteryByRegion } from "constant";
import { setLoading } from "features/commonSlice";
import { scheduleLotteryI } from "interfaces/lottery.interface";
import MenuResult from "partials/MenuResult/MenuResult";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getCurrentDateTime } from "utils/date";
import {
  compareDate,
  formatDateBasic,
  formatDayInWeeks,
} from "utils/formateDate";
import { icons } from "utils/icons";
import {
  convertNameLotteryToParam,
  formatGetNameLottery,
  formatStringWinnumberToResultData,
} from "utils/lotteryFormat";
import ResultHistoryDauDuoi from "./components/ResultHistoryDauDuoi";
import ResultHistoryTable from "./components/ResultHistoryTable";
import ButtonAction from "components/ButtonAction/ButtonAction";
import useToast from "hooks/useToast";
import { Helmet } from "react-helmet";
import { setConfigSeo } from "features/seoSlice";

const ResultHistoryPage = () => {
  const { idLottery } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastify = useToast();

  const { isMobile, mode } = useSelector((state: any) => state.layout);
  const { isAuth } = useSelector((state: any) => state.auth);
  const { listGames } = useSelector((state: any) => state.lottery);
  const { configSeo } = useSelector((state: any) => state?.seo);

  const [listHistoryResult, setListHistoryResult] = useState<any>([]);
  const [dataHoverDauDuoi, setDataHoverDauDuoi] = useState<any>();
  const [dataSelectDauDuoi, setDataSelectDauDuoi] = useState<any>();

  const [lotteryResultSelected, setLotteryResultSelected] = useState<string>(
    "Chọn đài muốn xem kết quả"
  );
  const [listTabsGamesSelected, setListTabGamesSelected] = useState<any[]>([]);
  const [openTime, setOpenTime] = useState<any>();
  const [regionById, setRegionById] = useState<string>("mb");
  const [gameIdActive, setGameIdActive] = useState<number | null>();
  const [showMenuResultLottery, setShowMenuResultLottery] =
    useState<boolean>(false);


  useEffect(() => {
    if (idLottery) {
      const gameid = listGames
        .filter((item: any) => [1, 2, 3].includes(item.type))
        .find(
          (lottery: any) =>
            convertNameLotteryToParam(lottery.desc) === idLottery
        )?.id;
      setGameIdActive(gameid);

      const existId = listIdLotteryByRegion?.find((item: any) =>
        item.listIdLotterys.includes(gameid)
      );
      if (existId) {
        setRegionById(existId.name);
      }
      if (gameid && listGames) {
        const foundItem = getElementById(gameid, listGames);
        if (foundItem) {
          if (listTabsGamesSelected?.length > 0) {
            setListTabGamesSelected((prevGame: any) => {
              const exixts = listTabsGamesSelected?.some(
                (game: any) => Number(game.id) === gameid
              );
              if (!exixts) {
                const updatedPrevGames = prevGame.map((game: any) => ({
                  ...game,
                  isSelected: false,
                }));
                const itemTab = {
                  ...foundItem,
                  isSelected: Number(foundItem?.id) === gameid,
                };
                return [...updatedPrevGames, itemTab];
              } else {
                return prevGame.map((game: any) =>
                  Number(game.id) === gameid
                    ? { ...game, isSelected: true }
                    : { ...game, isSelected: false }
                );
              }
            });
          } else {
            const newListTabGames = [
              {
                ...foundItem,
                isSelected: Number(foundItem?.id) === gameid,
              },
            ];
            setListTabGamesSelected(newListTabGames);
          }
        }
      }

      if (gameid) {
        handleGetListHistoryById(gameid);
        setLotteryResultSelected(
          formatGetNameLottery(Number(gameid), listGames)
        );
      }
    }
  }, [idLottery, listGames]);

  useEffect(()=>{
    dispatch(
      setConfigSeo({
        ...configSeo,
        title: `Kết quả xổ số ${lotteryResultSelected} - Trợ lý xổ số hàng đầu Việt Nam`,
      })
    );
  },[lotteryResultSelected])

  const getElementById = (idLottery: number, arrFind: any) => {
    let foundElement = arrFind?.find(
      (game: any) => Number(game.id) === idLottery
    );

    if (foundElement) {
      return foundElement;
    } else {
      return null;
    }
  };

  const handleGetListHistoryById = async (lotteryid: string) => {
    dispatch(setLoading(true));
    try {
      const { data } = await lotteryApi.getListSchedules({
        gameid: Number(lotteryid),
        numRecord: 10,
      });
      if (data.error_code === 0) {
        const dataRes = data.data;
        setOpenTime(dataRes[0]?.opentime);
        setListHistoryResult(dataRes);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetDauDuoiNumber = (
    e: any,
    data: any,
    type: string,
    index: number
  ) => {
    e.preventDefault();
    setDataHoverDauDuoi({ data: data, type: type, index: index });
  };

  const handleSelectDauDuoi = (
    e: any,
    data: any,
    type: string,
    index: number
  ) => {
    e.preventDefault();
    setDataSelectDauDuoi({ data: data, type: type, index: index });
  };

  const handleNavigateLottery = (data: any) => {
    navigate(`/ket-qua/${convertNameLotteryToParam(data?.desc)}`);
  };

  const deleteTabGames = (gameid: string) => {
    const newListGames = listTabsGamesSelected.filter(
      (game: any) => Number(game.id) !== Number(gameid)
    );
    if (listTabsGamesSelected.length > 1) {
      if (Number(gameid) === gameIdActive) {
        handleNavigateLottery(newListGames[newListGames?.length - 1]);
      }
      setListTabGamesSelected(newListGames);
    }
  };

  return (
    <div
      className={`${
        mode === 1 ? "bg-lotteryPage" : "bg-lotteryPageLight"
      } bg-cover w-full ${isMobile ? "p-4 bg-center" : "p-5 rounded-[20px]"} `}
    >
      <div className="flex items-center justify-between w-full mb-2">
        {listTabsGamesSelected?.length > 0 && (
          <div className="w-full flex flex-wrap">
            {listTabsGamesSelected.map((game: any, index: number) => (
              <div
                className={`bg-white dark:bg-bgPrimary
                        border ${
                          game.isSelected
                            ? "border-primary"
                            : "border-transparent"
                        } 
                        rounded-[28px] p-2 pl-2.5 text-sm font-medium flex items-center 
                        ${
                          game.isSelected
                            ? "text-black dark:text-white"
                            : "text-text-secondary dark:text-white/40"
                        } hover:text-black dark:hover:text-white cursor-pointer 
                        mr-2 mb-2 `}
                key={index}
              >
                <span onClick={() => handleNavigateLottery(game)}>
                  {game.desc}
                </span>
                <img
                  src={require("assets/icons/cancel.png")}
                  className="w-5 h-5 ml-1 cursor-pointer"
                  alt={game.desc}
                  onClick={() => deleteTabGames(game.id)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <h1
        className={`${
          mode === 0 ? " text-linear-primary-dark" : "text-linear-primary"
        } ${
          isMobile ? "text-[30px]" : "text-[40px]"
        } font-bold mx-auto mb-5 text-center uppercase`}
      >
        KẾT QUẢ XỔ SỐ {lotteryResultSelected}
      </h1>
      <div className="w-full flex flex-col items-center">
        {compareDate(openTime, getCurrentDateTime()) ? (
          listHistoryResult[0]?.winnumber?.length > 0 ? (
            <span className="text-primary text-base font-semibold text-center">
              Xổ số {lotteryResultSelected} hôm nay đã kết thúc
            </span>
          ) : (
            <div className="flex flex-col">
              <span className="text-text-secondary dark:text-white/40 text-base text-center font-semibold">
                {new Date(openTime).getTime() >
                new Date(getCurrentDateTime()).getTime()
                  ? `Đang chờ xổ số ${lotteryResultSelected} lúc
              ${
                regionById === "mb"
                  ? " 18h00"
                  : regionById === "mt"
                  ? " 17h12'"
                  : " 16h14'"
              }`
                  : `Đang trong thời gian xổ trực tiếp ${lotteryResultSelected}`}
              </span>
              <div className="mt-4">
                <BounceLoadingComponent length={8} size={3} color={"#525252"} />
              </div>
            </div>
          )
        ) : (
          <span className="text-text-secondary dark:text-white/40 text-base text-center font-semibold">
            Kỳ mở thưởng xổ số {lotteryResultSelected} tiếp theo ngày{" "}
            {formatDateBasic(openTime)} lúc
            {regionById === "mb"
              ? " 18h00"
              : regionById === "mt"
              ? " 17h12'"
              : " 16h14'"}
          </span>
        )}{" "}
      </div>
      {isMobile && (
        <div className="flex flex-col mt-4">
          <span className="text-xs font-semibold text-text-secondary dark:text-white/40 mb-2">
            Kết quả đài
          </span>
          <div
            className="px-3 py-2.5 rounded-[10px] bg-white dark:bg-bgPrimary flex items-center"
            onClick={() => setShowMenuResultLottery(true)}
          >
            <span className="text-black dark:text-white font-medium text-sm w-full">
              {lotteryResultSelected}
            </span>
            <img src={icons.arrowDown} className="w-5 h-5" alt={lotteryResultSelected} />
          </div>
        </div>
      )}
      <div
        className={classNames(
          "wrap-list flex flex-col mt-7 p-2 bg-[#003313]/30 dark:bg-[#046E2B]/30 rounded-2xl"
        )}
      >
        {listHistoryResult?.length > 0 ? (
          listHistoryResult.map((result: scheduleLotteryI, index: number) => (
            <div
              className={classNames(
                "flex flex-col rounded-[10px] overflow-hidden border border-borderLightPrimary dark:border-borderPrimary mb-3",
                {
                  "!mb-0": index === listHistoryResult?.length - 1,
                  "!hidden": !result.winnumber,
                }
              )}
              key={index}
            >
              <div
                className={classNames(
                  "header px-3 py-2 text-sm flex items-center justify-between bg-surface-tertiary dark:bg-bgPrimary border-b border-borderLightPrimary dark:border-borderPrimary",
                  { "flex-col": isMobile }
                )}
              >
                <h4
                  className={classNames("text-primary font-semibold", {
                    "mb-1": isMobile,
                  })}
                >
                  Kết quả xổ số {formatGetNameLottery(result.gameid, listGames)}
                </h4>
                <div className="flex items-center">
                  <ButtonAction
                    title="Chơi ngay"
                    onSubmit={() => {
                      if (!isAuth) {
                        toastify("Vui lòng đăng nhập!", "warning");
                        return;
                      }
                      navigate(
                        `/mua-so/${convertNameLotteryToParam(
                          formatGetNameLottery(result.gameid, listGames)
                        )}`
                      );
                    }}
                    type="secondary"
                  />
                  <span className="text-black dark:text-white font-medium ml-4">
                    {formatDayInWeeks(new Date(result.endtime).getDay())} Ngày:
                    {"  "}
                    {formatDateBasic(result.endtime)}
                  </span>
                </div>
              </div>
              <div className={classNames("flex h-auto bg-white dark:bg-black")}>
                <div className="w-2/3 h-auto border-r border-borderLightPrimary dark:border-borderPrimary">
                  <ResultHistoryTable
                    data={formatStringWinnumberToResultData(
                      result.gameid,
                      result.winnumber
                    )}
                    gameid={result.gameid}
                    dataHover={dataHoverDauDuoi}
                    dataSelect={dataSelectDauDuoi}
                    isHovered={
                      index === dataHoverDauDuoi?.index ||
                      index === dataSelectDauDuoi?.index
                    }
                  />
                </div>
                <div className="w-1/3 h-full">
                  <ResultHistoryDauDuoi
                    winnumber={formatStringWinnumberToResultData(
                      result.gameid,
                      result.winnumber
                    )}
                    index={index}
                    method="LO2SO"
                    getDauDuoi={handleGetDauDuoiNumber}
                    getDauDuoiSelect={handleSelectDauDuoi}
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <h2 className="text-base font-semibold text-black dark:text-white">
              Chưa có kết quả nào
            </h2>
          </div>
        )}
      </div>
      {isMobile && (
        <BottomSheet
          isShow={showMenuResultLottery}
          title="Danh sách đài"
          onClose={() => setShowMenuResultLottery(false)}
        >
          <MenuResult onClose={() => setShowMenuResultLottery(false)} />
        </BottomSheet>
      )}
    </div>
  );
};

export default ResultHistoryPage;
