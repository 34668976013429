import Popover from "@mui/material/Popover";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import { useEffect, useRef, useState } from "react";
import { icons } from "utils/icons";
import { formatGetTitleStatus } from "utils/lotteryFormat";

function SelectOptionComponent(props: any) {
  const {
    getStatus,
    type,
    listStatus,
    title,
    reset,
    isMobile,
    noAll,
    initData,
    fieldSelected,
  } = props;

  const selectedOptionRef = useRef<HTMLDivElement | null>(null);
  const selectedOptionContainerRef = useRef<HTMLDivElement | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [listStatusFilter, setListStatusFilter] = useState<any>([]);
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [dropUp, setDropUp] = useState<boolean>(false);
  const [isOpenSelect, setIsOpenSelect] = useState<boolean>(false);

  useEffect(() => {
    if (listStatus) {
      if (!noAll) {
        const listStatusNew = [
          {
            title: "Tất cả",
            desc: "Tất cả",
            key: "all",
            id: null,
          },
          ...(Array.isArray(listStatus) ? listStatus : []),
        ];
        setStatusFilter(listStatusNew[0]?.title);
        setListStatusFilter(listStatusNew);
      } else {
        setListStatusFilter(listStatus);
      }
    }
  }, []);

  useEffect(() => {
    if (initData === "all") {
      setStatusFilter("Tất cả");
      getStatus(type, null);
    } else {
      setStatusFilter(
        initData === undefined || initData < 0
          ? title
          : formatGetTitleStatus(initData, listStatus)
      );
      getStatus(type, initData);
    }
  }, [initData, listStatus]);

  useEffect(() => {
    if (reset) {
      if (initData || initData === 0) {
        if (initData === "all") {
          setStatusFilter("Tất cả");
          getStatus(type, null);
        } else {
          setStatusFilter(
            initData === undefined || initData < 0
              ? title
              : formatGetTitleStatus(initData, listStatus)
          );
          getStatus(type, initData);
        }
      } else {
        if (initData) {
          if (initData === "all" || initData === "all") {
            setStatusFilter("Tất cả");
          } else {
            const checks = listStatus.filter((item: any) => {
              return Number(item.id) === Number(initData);
            });
            setStatusFilter(
              (checks && checks[0] && fieldSelected
                ? checks[0][fieldSelected]
                : title) || title
            );
          }
        } else {
          setStatusFilter(initData === "all" ? "Tất cả" : title);
          getStatus(type, null);
        }
      }
    }
  }, [reset]);

  // Scroll đến giá trị đang checked
  useEffect(() => {
    if (!isOpenSelect) return;
    setTimeout(() => {
      if (selectedOptionRef?.current) {
        selectedOptionRef?.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 50);
  }, [isOpenSelect]);

  useEffect(() => {
    if (selectedOptionContainerRef.current && isOpenSelect) {
      const rect = selectedOptionContainerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const shouldDropUp = rect.bottom + 200 > windowHeight; // 200 là chiều cao tối đa của dropdown
      setDropUp(shouldDropUp);
    }
  }, [selectedOptionRef, isOpenSelect]);

  const handleShowPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (isMobile) {
      setIsOpenSelect(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoiceStatus = (status: any) => {
    const id = status.id
      ? status.id
      : status.id === 0
      ? "0"
      : status.idtype === 0
      ? "0"
      : status.id;
    setStatusFilter(
      status.title ? status.title : status.desc ? status.desc : status.name
    );

    handleClose();
    if (id === null) {
      getStatus(type, undefined);
    } else {
      getStatus(type, id ? id : initData);
    }
  };

  const open = Boolean(anchorEl);
  return (
    <div className="select-options w-full flex flex-col">
      <span className="text-xs text-text-secondary font-semibold text-start mb-1">
        {title}
      </span>
      <button
        type="button"
        onClick={handleShowPopover}
        className={classNames(
          "inline-flex items-center h-9 w-fit px-2.5 py-2 rounded-[10px] text-text-secondary dark:text-white border border-borderLightPrimary dark:border-borderPrimary leading-none bg-white dark:bg-bgPrimary",
          { "!w-full": isMobile }
        )}
      >
        <span
          className={classNames(
            "text-black dark:text-white text-sm text-start",
            {
              "w-full": isMobile,
            }
          )}
        >
          {statusFilter !== "" && statusFilter}
        </span>
        <svg
          viewBox="0 0 24 24"
          className="w-4 ml-1.5 text-gray-400 dark:text-gray-600"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </button>

      {isMobile ? (
        <Modal isShow={isOpenSelect} onClose={() => setIsOpenSelect(false)}>
          <div className="flex flex-col w-4/5 rounded-[10px] overflow-hidden">
            <div className="wrap-status-list w-full p-2 bg-white dark:bg-bgPrimary max-h-80 overflow-auto">
              {listStatusFilter?.length > 0 &&
                listStatusFilter.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="item-status px-3 py-2 text-sm cursor-pointer flex items-center justify-between text-black dark:text-white rounded "
                    onClick={() => handleChoiceStatus(item)}
                  >
                    {item.desc}
                    {statusFilter.includes(item.desc) && (
                      <img src={icons.check} className="w-5 h-5" alt={item.desc} />
                    )}
                  </div>
                ))}
            </div>
            <div className="p-2 border-t border-borderLightPrimary dark:border-borderPrimary w-full flex items-center justify-end bg-white dark:bg-bgPrimary">
              <button
                type="button"
                className="text-white text-sm px-3 py-2 flex items-center justify-center bg-primary active:bg-primary/80 rounded-[10px]"
                onClick={() => setIsOpenSelect(false)}
              >
                Đóng
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: dropUp ? "top" : "bottom",
            horizontal: "left",
          }}
        >
          <div
            className="wrap-status-list p-2 bg-white dark:bg-bgPrimary max-h-80 overflow-auto"
            ref={selectedOptionContainerRef}
          >
            {listStatusFilter?.length > 0 &&
              listStatusFilter.map((item: any, index: number) => (
                <div
                  key={index}
                  className={classNames(
                    "item-status flex items-center justify-between px-3 py-2 text-sm cursor-pointer hover:bg-primary hover:text-white text-black dark:text-white rounded ",
                    {
                      "!bg-primary !text-white": item.desc === statusFilter,
                    }
                  )}
                  onClick={() => handleChoiceStatus(item)}
                  ref={selectedOptionRef}
                >
                  {item.desc}
                </div>
              ))}
          </div>
        </Popover>
      )}
    </div>
  );
}

export default SelectOptionComponent;
