import classNames from "classnames";
import RandomNumberCount from "components/RandomNumberCount/RandomNumberCount";
import { prizesInitMTMN } from "constant";
import { useSelector } from "react-redux";
import {
  formatGeneratePrizeLottery,
  formatPrizeNumberByMethod,
} from "utils/lotteryFormat";

function TableFormatMTMNMobile(props: any) {
  const { method, data, currentIssue, issue, type, statusUpdate } = props;
  const { isMobile } = useSelector((state: any) => state.layout);

  const checkInvalidValue = (value: any) => {
    if ((!value || value === "" || value === null) && currentIssue === issue) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      className={classNames(
        "table-format bg-white dark:bg-black shadow-table rounded-[10px] overflow-hidden border border-borderLightPrimary dark:border-borderPrimary",
        { "!rounded-none": type === "modal" }
      )}
    >
      {prizesInitMTMN.map((prize: any, index: number) => (
        <div
          className={classNames("wrap-prize p-2 grid grid-cols-5", {
            "border-t border-borderLightPrimary dark:border-borderPrimary":
              index > 0,
          })}
          key={index}
        >
          <div className="title-prize flex items-center justify-center col-span-1">
            <span
              className={classNames(
                "text-sm text-black dark:text-white font-semibold text-center",
                { "!text-xs": isMobile }
              )}
            >
              {prize.title}
            </span>
          </div>
          <div className="flex-1 w-full flex items-center justify-center flex-wrap col-span-4">
            {Array.from({ length: prize.subPrizes || 1 }).map((_, subIndex) => (
              <div
                className={`${
                  ["giaitu"].includes(prize.dataKey) &&
                  (subIndex > 3
                    ? "mt-1 !w-1/3"
                    : [2, 3].includes(subIndex)
                    ? "mt-1 !w-1/2"
                    : "!w-1/2")
                } ${
                  ["giaisau"].includes(prize.dataKey)
                    ? "w-1/3"
                    : ["giaiba"].includes(prize.dataKey)
                    ? "w-1/2"
                    : "w-full"
                } flex items-center justify-center`}
                key={subIndex}
              >
                <div
                  className={classNames(
                    "number-prize w-full h-9 py-1.5 mx-0.5 px-3 rounded-[6px] bg-secondary/20 text-black dark:text-white flex items-center justify-center"
                  )}
                >
                  {checkInvalidValue(
                    formatGeneratePrizeLottery(
                      data?.[prize.dataKey],
                      subIndex + 1
                    )
                  ) ? (
                    statusUpdate ? (
                      <RandomNumberCount length={prize.length} />
                    ) : (
                      <span
                        className={classNames(
                          "text-sm font-semibold text-center"
                        )}
                        dangerouslySetInnerHTML={{
                          __html: formatPrizeNumberByMethod(
                            method,
                            index,
                            subIndex,
                            formatGeneratePrizeLottery(
                              data?.[prize.dataKey],
                              subIndex + 1
                            )
                          ),
                        }}
                      ></span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TableFormatMTMNMobile;
