import { createSlice } from "@reduxjs/toolkit";

export const lotterySlice = createSlice({
  name: "lottery",
  initialState: {
    listMenu: [],
    listMethod: [],
    gameId: null,
    listTickets: [] || null,
    currentIssue: null,
    listStatus: [],
    listStatusTransaction: [],
    listGames: [],
    listSchedules: [],
    listLotteryToday: [],
    statusUpdateSchedule: false,
    updateResult: false,
  },
  reducers: {
    listLotteryMenu(state, action) {
      state.listMenu = action.payload;
    },
    addListMethod(state, action) {
      state.listMethod = action.payload;
    },
    addGameId(state, action) {
      state.gameId = action.payload;
    },
    addListTickets(state, action) {
      state.listTickets = action.payload;
    },
    addCurrentIssue(state, action) {
      state.currentIssue = action.payload;
    },
    addListStatus(state, action) {
      state.listStatus = action.payload;
    },
    addListStatusTransaction(state, action) {
      state.listStatusTransaction = action.payload;
    },
    addListGames(state, action) {
      state.listGames = action.payload;
    },
    addListSchedules(state, action) {
      state.listSchedules = action.payload;
    },
    addListLotteryToday(state, action) {
      state.listLotteryToday = action.payload;
    },
    setStatusUpdateSchedule(state, action) {
      state.statusUpdateSchedule = action.payload;
    },
    setUpdateResult(state, action) {
      state.updateResult = action.payload;
    },
  },
});

const { actions, reducer } = lotterySlice;

export const {
  listLotteryMenu,
  addListMethod,
  addGameId,
  addListTickets,
  addCurrentIssue,
  addListStatus,
  addListStatusTransaction,
  addListGames,
  addListSchedules,
  setStatusUpdateSchedule,
  setUpdateResult,
  addListLotteryToday,
} = actions;
export default reducer;
