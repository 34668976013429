import axiosInstance from "./axiosInstance";
import { registerI, signinI } from "../interfaces/auth.interface";

const authApi = {
  signin(payload: signinI) {
    const url = "/api/v1/user/public/login";
    return axiosInstance.post(url, payload);
  },
  register(payload: registerI) {
    const url = `/api/v1/user/public/register`;
    return axiosInstance.post(url, payload);
  },
  logout() {
    const url = "/api/v1/user/logout";
    return axiosInstance.get(url);
  },
};

export default authApi;
