import { Tooltip } from "@mui/material";
import lotteryApi from "apis/lotteryApi";
import classNames from "classnames";
import BottomSheet from "components/BottomSheet/BottomSheet";
import FilterHistoryBet from "components/FilterComponent/FilterHistoryBet";
import Modal from "components/Modal/Modal";
import ModalDetailBet from "components/ModalDetailBet/ModalDetailBet";
import PaginationComponent from "components/PaginationComponent/PaginationComponent";
import { setLoading, setLoadingTopBar } from "features/commonSlice";
import useToast from "hooks/useToast";
import { recordLotteryI } from "interfaces/lottery.interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { currencyFormat } from "utils/currencyFormat";
import { getEndDateTime, getStartDateTime } from "utils/date";
import { formatDate, formatToIOString } from "utils/formateDate";
import { icons } from "utils/icons";
import {
  formatBetContent,
  formatCodesEasyWatch,
  formatGetNameLottery,
  formatGetTitleStatus,
} from "utils/lotteryFormat";
import HistoryTableBet from "./HistoryTableBet";

const listHeaderTitle = [
  "Mã vé chơi",
  "Lượt xổ",
  "Xổ số",
  "Kiểu chơi",
  "Thời gian chơi",
  "Thời gian kết thúc",
  "Số chơi",
  "Điểm chơi",
  "Điểm trúng",
  "Trạng thái",
  "",
];

const listColSpan = [
  "col-span-2",
  "col-span-2",
  "col-span-2",
  "col-span-2",
  "col-span-3",
  "col-span-3",
  "col-span-3",
  "col-span-2",
  "col-span-2",
  "col-span-2",
  "col-span-1",
];

function HistoryPage() {
  const toastify = useToast();
  const dispatch = useDispatch();

  const { listStatus, listGames } = useSelector((state: any) => state.lottery);
  const { isMobile } = useSelector((state: any) => state.layout);
  const [listGameRecord, setListGameRecord] = useState<recordLotteryI[]>([]);
  // const [paramSearch, setParamSearch] = useState<filterHistoryI>({});
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState<number>();
  const [showModalDetailBet, setShowModalDetailBet] = useState<boolean>(false);
  const [dataDetailBet, setDataDetailBet] = useState<any>({});
  const [statusFilter, setStatusFilter] = useState<boolean>(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false);
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const [formDataPagination, setFormDataPagination] = useState<any>();
  const [showFilterBottomSheet, setShowFilterBottomSheet] =
    useState<boolean>(false);
  const [formData, setFormData] = useState({
    offset: 0,
    fromdate: formatToIOString(new Date(getStartDateTime())),
    todate: formatToIOString(new Date(getEndDateTime())),
    status: null,
    id: null,
    gameid: null,
    timefilter: "bettime",
  });

  useEffect(() => {
    handleGetRecord(0, "search");
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      if (atBottom && Number(currentPage) < Number(totalPage)) {
        setDataAvailable(true);
        if (!loadingSkeleton)
          handleGetRecord(
            currentPage === 0 ? currentPage + 1 : currentPage,
            "pagi"
          );
      }
    }
  }, [atBottom, totalPage]);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const updateFormData = (field: any, value: any, type?: any) => {
    setFormData({
      ...formData,
      [field]: value !== "" ? value : null,
    });
    setFormDataPagination({
      ...formDataPagination,
      [field]: value !== "" ? value : null,
    });
  };

  const changeOffset = (newOffset: any) => {
    setFormDataPagination((prevParams: any) => ({
      ...prevParams,
      offset: newOffset,
    }));
    handleGetRecord(newOffset, "pagi");
  };

  // Hàm hiện modal chi tiết vé cược
  const handleShowDetailBet = (event: any, data: any) => {
    event.preventDefault();
    setShowModalDetailBet(true);
    setDataDetailBet(data);
  };

  const handleGetRecord = async (offset?: number | 0, typeCall?: string) => {
    setLoadingSkeleton(true);
    if (typeCall === "search") {
      dispatch(setLoading(true));
    }
    if (typeCall === "filter") {
      dispatch(setLoadingTopBar(true));
    }

    let payload =
      typeCall === "pagi"
        ? {
            ...formDataPagination,
            pageSize: isMobile ? 15 : 10,
            offset: offset,
          }
        : {
            ...formData,
            pageSize: isMobile ? 15 : 10,
            offset: typeCall === "filter" ? 0 : offset,
          };
    try {
      const { data } = await lotteryApi.getGameRecordCurrentUser(payload);
      if (data) {
        const dataRes = data.data.gameRecords.content;
        if (!isMobile || typeCall === "search" || typeCall === "filter") {
          setListGameRecord(dataRes);
        } else {
          setListGameRecord((prevList: any) => [...prevList, ...dataRes]);
        }
        setCurrentPage(Number(data.data.gameRecords.number) + 1);
        setTotalPage(data.data.gameRecords.totalPages);
      }
    } catch (error: any) {
      // if (error.error_code !== 403 && error.error_code !== 401) {
      //   if (!error.msg) toastify(error.msg, "error");
      // }
      console.log(error);
    } finally {
      setLoadingSkeleton(false);
      dispatch(setLoading(false));
      dispatch(setLoadingTopBar(false));
      if (typeCall === "filter") {
        setFormDataPagination(formData);
        setShowFilterBottomSheet(false);
      }
    }
  };

  const handleClearFilter = () => {
    setFormData({
      offset: 0,
      fromdate: formatToIOString(new Date(getStartDateTime())),
      todate: formatToIOString(new Date(getEndDateTime())),
      status: null,
      id: null,
      gameid: null,
      timefilter: "bettime",
    });
    setStatusFilter(true);

    setTimeout(() => {
      setStatusFilter(false);
    }, 500);
  };

  const handleUpdateListRecord = (id: any, typeUpdate: string) => {
    const listRecordUpdate = listGameRecord.map((item: recordLotteryI) => {
      item.status = typeUpdate === "cancel" && item.id === id ? 6 : item.status;
      return item;
    });
    setListGameRecord(listRecordUpdate);
  };

  return (
    <div className={classNames("w-full")}>
      <div
        className={classNames(
          "history-page flex w-full bg-surface-primary dark:bg-bgLayout",
          {
            "flex-col": isMobile,
          }
        )}
      >
        <div className={classNames("w-full rounded-md h-full p-4 pt-3 pb-0")}>
          <div className="table-history-container w-full rounded">
            <div className="title-table-wrap flex items-center justify-between">
              <h1 className="title-table text-xl font-semibold text-black dark:text-white">
                Lịch Sử Chơi
              </h1>
              {isMobile && (
                <button
                  className="bg-secondary/10 active:bg-secondary/20 rounded-[10px] p-2.5"
                  type="button"
                  onClick={() => setShowFilterBottomSheet(true)}
                >
                  <img src={icons.filter} className="w-5 h-5" alt="Lọc lịch sử" />
                </button>
              )}
            </div>

            {!isMobile && (
              <>
                <FilterHistoryBet
                  updateFormData={updateFormData}
                  statusFilter={statusFilter}
                  handleGetRecord={handleGetRecord}
                  handleClearFilter={handleClearFilter}
                />
                <div className="table-wrap mb-4 border border-borderLightPrimary dark:border-borderPrimary shadow-table rounded-[10px] overflow-hidden">
                  <div className="header-table">
                    <div className="header-table-item grid grid-cols-24 bg-surface-tertiary dark:bg-black py-3">
                      {listHeaderTitle?.map((header: string, index: number) => (
                        <div
                          className={`flex justify-center items-center ${listColSpan[index]}`}
                          key={index}
                        >
                          <span className="text-primary text-xs text-center text-wrap font-semibold">
                            {header}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="body-table">
                    {listGameRecord?.length > 0 ? (
                      listGameRecord?.map(
                        (item: recordLotteryI, index: number) => (
                          <div
                            className={classNames(
                              "hover:bg-gray-300 dark:hover:!bg-gray-800 grid grid-cols-24 py-3 bg-white dark:bg-bgPrimary border-t border-borderLightPrimary dark:border-borderPrimary",
                              {
                                "!bg-surface-tertiary dark:!bg-black":
                                  index % 2 !== 0,
                              }
                            )}
                            key={index}
                          >
                            <div
                              className={`flex justify-center items-center ${listColSpan[0]}`}
                            >
                              <span className="px-1.5  text-xs text-center text-wrap break-words w-4/5 text-black dark:text-white">
                                {item.id}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[1]}`}
                            >
                              <span className="px-1.5  text-xs text-center text-wrap text-black dark:text-white">
                                {item.issue}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[2]}`}
                            >
                              <span className="px-1.5 text-xs text-center  text-wrap text-black dark:text-white">
                                {formatGetNameLottery(item.gameid, listGames)}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[3]}`}
                            >
                              <span className="px-1.5  text-xs text-center text-wrap text-black dark:text-white">
                                {formatBetContent(item.betcontent)}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[4]}`}
                            >
                              <span className="px-1.5 whitespace-pre-line  text-xs text-center text-wrap text-black dark:text-white">
                                {formatDate(item.bettime)}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[5]}`}
                            >
                              <span className="px-1.5 whitespace-pre-line  text-xs text-center text-wrap text-black dark:text-white">
                                {item.finishtime && formatDate(item.finishtime)}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[6]} overflow-hidden`}
                            >
                              <Tooltip
                                title={formatCodesEasyWatch(item.codes)}
                                placement="right"
                                arrow
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -8],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              >
                                <span className="px-1.5  w-4/5 text-xs text-center truncate text-black dark:text-white">
                                  {formatCodesEasyWatch(item.codes)}
                                </span>
                              </Tooltip>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[7]}`}
                            >
                              <span className="w-[99%] px-1.5  text-xs text-center text-wrap break-words text-black dark:text-white">
                                {item.amount > 0
                                  ? currencyFormat(item.amount)
                                  : 0}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[8]}`}
                            >
                              <span className="w-[99%] px-1.5 text-center text-xs  text-wrap break-words text-black dark:text-white cursor-pointer">
                                {item.bonus > 0
                                  ? currencyFormat(item.bonus)
                                  : 0}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[9]}`}
                            >
                              <span
                                className={classNames(
                                  "px-1.5 text-center text-wrap break-words truncate text-xs text-warning-500",
                                  {
                                    "!text-success-500":
                                      formatGetTitleStatus(
                                        item.status,
                                        listStatus,
                                        "id"
                                      ) === 4,
                                    "!text-error-500":
                                      formatGetTitleStatus(
                                        item.status,
                                        listStatus,
                                        "idtype"
                                      ) === 3 ||
                                      formatGetTitleStatus(
                                        item.status,
                                        listStatus,
                                        "id"
                                      ) === 5 ||
                                      formatGetTitleStatus(
                                        item.status,
                                        listStatus,
                                        "id"
                                      ) === 6 ||
                                      formatGetTitleStatus(
                                        item.status,
                                        listStatus,
                                        "id"
                                      ) === 7,
                                  }
                                )}
                              >
                                {formatGetTitleStatus(
                                  item.status,
                                  listStatus,
                                  "title"
                                )}
                              </span>
                            </div>
                            <div
                              className={`flex justify-center items-center ${listColSpan[10]}`}
                            >
                              <img
                                onClick={(e: any) =>
                                  handleShowDetailBet(e, item)
                                }
                                src={icons.documentCheckPrimary}
                                className="w-5 h-5 cursor-pointer"
                                alt="Chi tiết vé cược"
                              />
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="no-data w-full px-1.5 py-2.5 flex items-center justify-center bg-white dark:bg-bgPrimary border-t border-borderLightPrimary dark:border-borderPrimary">
                        <span className="text-xs text-black dark:text-white">
                          Chưa tìm thấy lịch sử chơi nào
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {totalPage && totalPage > 1 ? (
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPage={totalPage}
                    changeOffset={changeOffset}
                  ></PaginationComponent>
                ) : (
                  <></>
                )}

                {showModalDetailBet && (
                  <Modal isShow={showModalDetailBet} noClickOutSide>
                    <ModalDetailBet
                      onClose={() => setShowModalDetailBet(false)}
                      data={dataDetailBet}
                      getListRecord={handleGetRecord}
                    />
                  </Modal>
                )}
              </>
            )}
          </div>
        </div>
        {isMobile && (
          <div>
            <HistoryTableBet
              data={listGameRecord}
              listStatus={listStatus}
              listGames={listGames}
              updateListRecord={handleUpdateListRecord}
              loading={loadingSkeleton}
            />
            {loadingSkeleton ? (
              <div className="w-full flex items-center justify-center my-4">
                <PuffLoader color="#36d7b7" size={36} />
              </div>
            ) : (
              dataAvailable && (
                <div className="w-full flex items-center justify-center my-4 bg-transparent">
                  <span className="text-sm text-white font-medium">
                    Đã tải hết dữ liệu
                  </span>
                </div>
              )
            )}
          </div>
        )}
      </div>
      <BottomSheet
        isShow={showFilterBottomSheet}
        onClose={() => setShowFilterBottomSheet(false)}
        title="Bộ lọc"
      >
        <FilterHistoryBet
          updateFormData={updateFormData}
          statusFilter={statusFilter}
          handleGetRecord={handleGetRecord}
          handleClearFilter={handleClearFilter}
        />
      </BottomSheet>
    </div>
  );
}

export default HistoryPage;
