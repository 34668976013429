import classNames from "classnames";
import { useSelector } from "react-redux";
import MenuLottery from "partials/MenuLottery/MenuLottery";
import { useNavigate } from "react-router";

function HomePage() {
  const navigate = useNavigate();

  const { isTablet, isMobile } = useSelector((state: any) => state.layout);

  return (
    <div className={classNames("w-full flex justify-center")}>
      <div
        className={classNames(
          "content-home w-full h-full flex flex-col items-center p-8",
          { "!p-0": isMobile || isTablet }
        )}
      >
        <div className="w-full p-2">
          <button
            type="button"
            onClick={() => navigate("/game/today-result")}
            className="w-full py-2.5 px-3 rounded-[10px] border border-primary text-primary text-xs font-semibold hover:text-primary/80 hover:border-primary/80 mb-4"
          >
            Tra cứu KQXS
          </button>
          <MenuLottery className="h-full !p-0" />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
