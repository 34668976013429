import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  handleFormatDauDuoi,
  handleFormatDuoiDau,
  handleGroupDauDuoi,
} from "utils/lotteryFormat";

const ResultHistoryDauDuoi = ({
  winnumber,
  method,
  getDauDuoi,
  index,
  getDauDuoiSelect,
}: any) => {
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);
  const [listDauduoi, setListDauduoi] = useState<any[]>([]);
  const [dataSelect, setDataSelect] = useState<any>();

  useEffect(() => {
    setListDauduoi(
      handleGroupDauDuoi(
        handleFormatDuoiDau(winnumber, method),
        handleFormatDauDuoi(winnumber, method)
      )
    );
  }, [winnumber, method]);

  const handleMouseEnter = (e: any, data: any, type: string) => {
    getDauDuoi(e, data, type, index);
  };

  const handleMouseLeave = (e: any) => {
    getDauDuoi(e, "", "", index);
  };

  const handleSelectDauDuoi = (e: any, data: any, type: string) => {
    const checkType =
      type === "dau"
        ? data.dau === dataSelect?.data?.dau && dataSelect?.type === "dau"
        : type === "duoi"
        ? data.duoi === dataSelect?.data?.duoi && dataSelect?.type === "duoi"
        : data.key === dataSelect?.data?.key && dataSelect?.type === "both";
    if (!checkType) {
      getDauDuoiSelect(e, data, type, index);
      setDataSelect({ data: data, type: type });
    } else {
      getDauDuoiSelect(e, "");
      setDataSelect(null);
    }
  };

  return (
    <div
      className={classNames(
        "table-dau-duoi border-borderLightPrimary dark:border-borderPrimary"
      )}
    >
      <div
        className={classNames(
          "wrap-prize h-8 text-primary text-sm font-semibold flex items-center",
          { "!text-xs": isMobile || isTablet }
        )}
      >
        <div className="title-prize h-full flex items-center justify-center w-2/5">
          <span className="text-center">Chục</span>
        </div>
        <div className="title-prize h-full flex items-center justify-center w-1/5 border-x border-borderLightPrimary dark:border-borderPrimary">
          <span className="text-center">Số</span>
        </div>
        <div className="number-prize h-full flex items-center justify-center w-2/5">
          <span className="text-center">Đơn vị</span>
        </div>
      </div>
      <div className={classNames("h-full flex-1", {})}>
        {listDauduoi?.length > 0 &&
          listDauduoi?.map((item: any, index: number) => (
            <div
              className={classNames(
                "wrap-prize flex items-center border-t border-borderLightPrimary dark:border-borderPrimary h-8 text-sm font-semibold",
                { "!text-xs": isMobile || isTablet }
              )}
              key={index}
            >
              <div
                className={classNames(
                  "number-prize h-full flex items-center hover:bg-gray-200 dark:hover:bg-gray-800 justify-center w-2/5 cursor-pointer",
                  {
                    "!bg-gray-200 dark:!bg-gray-800":
                      item.dau === dataSelect?.data?.dau &&
                      dataSelect?.type === "dau",
                  }
                )}
                onMouseEnter={(e) => handleMouseEnter(e, item, "dau")}
                onMouseLeave={handleMouseLeave}
                onClick={(e) => handleSelectDauDuoi(e, item, "dau")}
              >
                <span
                  className={`text-black dark:text-white  text-center text-wrap break-words w-[95%]`}
                >
                  {item.dau}
                </span>
              </div>
              <div
                className={classNames(
                  "title-prize h-full flex items-center justify-center border-x border-borderLightPrimary dark:border-borderPrimary hover:bg-gray-200 dark:hover:bg-gray-800 group w-1/5 cursor-pointer",
                  {
                    "!bg-gray-200 dark:!bg-gray-800":
                      item.key === dataSelect?.data?.key &&
                      dataSelect?.type === "both",
                  }
                )}
                onMouseEnter={(e) => handleMouseEnter(e, item, "both")}
                onMouseLeave={handleMouseLeave}
                onClick={(e) => handleSelectDauDuoi(e, item, "both")}
              >
                <span
                  className={`w-5 h-5 rounded-full ${
                    item.key === dataSelect?.data?.key &&
                    dataSelect?.type === "both"
                      ? "!bg-primary !text-white"
                      : "bg-surface-tertiary dark:bg-bgSecondary"
                  } group-hover:bg-primary group-hover:text-white flex items-center justify-center text-text-secondary dark:text-white text-center`}
                >
                  {item.key}
                </span>
              </div>
              <div
                className={classNames(
                  "number-prize h-full flex items-center justify-center hover:bg-gray-200 dark:hover:bg-gray-800 w-2/5 cursor-pointer",
                  {
                    "!bg-gray-200 dark:!bg-gray-800":
                      item.duoi === dataSelect?.data?.duoi &&
                      dataSelect?.type === "duoi",
                  }
                )}
                onMouseEnter={(e) => handleMouseEnter(e, item, "duoi")}
                onClick={(e) => handleSelectDauDuoi(e, item, "duoi")}
                onMouseLeave={handleMouseLeave}
              >
                <span
                  className={`text-black dark:text-white  text-center text-wrap break-words w-[95%]`}
                >
                  {item.duoi}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ResultHistoryDauDuoi;
