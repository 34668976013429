import InputComponent from "components/InputComponent/InputComponent";
import Modal from "components/Modal/Modal";
import useToast from "hooks/useToast";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoginForm from "./LoginForm";
import BottomSheet from "components/BottomSheet/BottomSheet";
import ListAgentReg from "components/ListAgentReg/ListAgentReg";
import { validateUsername } from "utils/validateForm";
import { icons } from "utils/icons";
import { copyToClipboard } from "utils/commonFormat";
import classNames from "classnames";
import TagComponent from "components/TagComponent/TagComponent";

function RegisterForm(props: any) {
  const {
    submitRegister,
    agentId,
    submitLogin,
    getAgentId,
    listTopAgent,
    loading,
  } = props;
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  const toastify = useToast();
  const [formData, setFormData] = useState<any>({
    loginname: "",
    password: "",
    password2: "",
  });
  const [showModalLogin, setShowModalLogin] = useState<boolean>(false);
  const [showBottomSheetAgent, setShowBottomSheetAgent] =
    useState<boolean>(false);

  const updateFormData = (field: any, value: any, type?: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [field]: value !== "" ? value : null,
    }));
    if (field === "agentid") {
      getAgentId(Number(value));
    }
  };

  const handleSubmit = () => {
    if (!formData.loginname) {
      toastify("Xin mời nhập tên đăng nhập", "warning");
      return;
    } else if (!validateUsername(formData.loginname)) {
      toastify("Tên đăng nhập không chứa ký tự đặc biệt!", "warning");
      return;
    } else if (!formData.password) {
      toastify("Xin mời nhập mật khẩu!", "warning");
      return;
    } else if (!formData.password2) {
      toastify("Xin mời nhập mật khẩu xác nhận!", "warning");
      return;
    } else if (formData.password !== formData.password2) {
      toastify("Mật khẩu và mật khẩu xác nhận không giống nhau!", "warning");
      return;
    } else {
      const payload = {
        loginname: formData.loginname.trim(),
        password: formData.password,
      };
      submitRegister(payload);
    }
  };

  const handleChoiceAgent = (agentid: number) => {
    getAgentId(agentid);
  };

  return (
    <>
      <form className="form bg-surface-tertiary dark:bg-black rounded-[20px] w-full">
        <div className="flex items-center justify-start px-5 py-[18px] bg-headerForm bg-center bg-cover">
          <h1 className="text-base font-semibold text-black dark:text-white">
            Đăng ký
          </h1>
        </div>
        {(isMobile || isTablet) && (
          <div className="px-4 py-2">
            <div className="flex flex-col">
              {listTopAgent?.slice(0, 2).map((agent: any, index: number) => (
                <div
                  className={classNames(
                    "bg-secondary/10 rounded-[10px] border border-transparent px-4 py-2 cursor-pointer",
                    {
                      "mt-2": index > 0,
                      "!border-primary shadow-[0px_0px_0px_4px_#22C55E33]":
                        agent.userid === Number(agentId),
                    }
                  )}
                  onClick={() => handleChoiceAgent(agent.userid)}
                  key={index}
                >
                  <div className="flex items-center justify-between">
                    <span
                      className={classNames(
                        "text-sm font-semibold text-black dark:text-white flex items-center"
                      )}
                    >
                      {agent.loginname}{" "}
                      <div className={`ml-2`}>
                        <TagComponent title={agent.tag} type="warning" />
                      </div>
                    </span>
                    <div className={classNames("flex items-center", {})}>
                      <span className="text-xs text-text-secondary dark:text-white/40 mr-1">
                        Mã đại lý:
                      </span>
                      <span
                        className={classNames(
                          "flex items-center text-xs text-black dark:text-white"
                        )}
                      >
                        {agent.userid}
                        <img
                          src={icons.copy}
                          className="w-4 h-4 ml-1 active transition-all duration-200 scale-100 active:scale-110"
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(agent.userid);
                          }}
                          alt="Sao chép"
                        />
                      </span>
                    </div>
                  </div>
                  {agent.bio && (
                    <div className="flex items mt-1.5">
                      <p className="text-sm text-black dark:text-white">
                        {agent.bio}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <button
              type="button"
              className="mt-2 flex items-center justify-center w-full px-3 py-2.5 rounded-[10px] bg-secondary/10 hover:opacity-80"
            >
              <span
                className="text-sm font-semibold text-primary cursor-pointer active:text-primary/80"
                onClick={() => setShowBottomSheetAgent(true)}
              >
                Danh sách các đại lý
              </span>
            </button>
          </div>
        )}
        <div className="flex flex-col py-4 px-10">
          <div className="mb-2">
            <InputComponent
              title="Tài khoản"
              label="Tên tài khoản..."
              type="loginname"
              typeInput="text"
              handleChange={updateFormData}
              width="w-full"
            />
          </div>
          <div className="mb-2">
            <InputComponent
              title="Mật khẩu"
              label="Mật khẩu..."
              type="password"
              typeInput="password"
              handleChange={updateFormData}
              width="w-full"
            />
          </div>
          <div className="mb-2">
            <InputComponent
              title="Mật khẩu xác nhận"
              label="Mật khẩu xác nhận..."
              type="password2"
              typeInput="password"
              handleChange={updateFormData}
              width="w-full"
            />
          </div>
          <div>
            <InputComponent
              title="Mã đại lý"
              label="Mã đại lý..."
              type="agentid"
              typeInput="text"
              initValue={agentId}
              handleChange={updateFormData}
              width="w-full"
            />
          </div>
        </div>
        <div className="pt-0 p-10">
          <div className="form-wrap-btn w-full">
            <button
              type="button"
              onClick={handleSubmit}
              className="bg-primary hover:bg-primary/80 w-full flex items-center py-2.5 px-4 rounded-[10px] justify-center text-white font-semibold"
            >
              Đăng ký
            </button>
          </div>
          <div className="flex items-center justify-center mt-3">
            <span className="text-sm text-black dark:text-white mr-1">
              Bạn đã có tài khoản?
            </span>
            <span
              className="text-sm font-semibold text-primary hover:text-primary/80 hover:underline cursor-pointer"
              onClick={() => setShowModalLogin(true)}
            >
              Đăng nhập ngay
            </span>
          </div>
        </div>
        <Modal isShow={showModalLogin} onClose={() => setShowModalLogin(false)}>
          <LoginForm
            submitLogin={submitLogin}
            onClose={() => setShowModalLogin(false)}
          />
        </Modal>
        {isMobile && (
          <BottomSheet
            isShow={showBottomSheetAgent}
            onClose={() => setShowBottomSheetAgent(false)}
            title="TOP CÁC ĐẠI LÝ UY TÍN"
            height="90%"
          >
            <ListAgentReg
              getAgentId={getAgentId}
              onClose={() => setShowBottomSheetAgent(false)}
              listTopAgent={listTopAgent}
              loading={loading}
              agentid={agentId}
            />
          </BottomSheet>
        )}
        {isTablet && (
          <Modal
            isShow={showBottomSheetAgent}
            onClose={() => setShowBottomSheetAgent(false)}
          >
            <ListAgentReg
              getAgentId={getAgentId}
              onClose={() => setShowBottomSheetAgent(false)}
              listTopAgent={listTopAgent}
              loading={loading}
              agentid={agentId}
            />
          </Modal>
        )}
      </form>
    </>
  );
}

export default RegisterForm;
