import { createSlice } from "@reduxjs/toolkit";

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    listChat: "",
  },
  reducers: {
    addListChat(state, action) {
      state.listChat = action.payload;
    },
  },
});

const { actions, reducer } = chatSlice;

export const { addListChat } = actions;
export default reducer;
