import { createSlice } from "@reduxjs/toolkit";

export const minigameSlice = createSlice({
  name: "minigames",
  initialState: {
    dataTXCB: null,
    showTXCB: false,
    alertMinigame: {
      isShow: false,
      msg: "",
    },
  },
  reducers: {
    socketDataTXCB(state, action) {
      state.dataTXCB = action.payload;
    },
    showGameTXCB(state, action) {
      state.showTXCB = action.payload;
    },
    showAlertMinigame(state, action) {
      state.alertMinigame = action.payload;
    },
  },
});

const { actions, reducer } = minigameSlice;

export const { socketDataTXCB, showGameTXCB, showAlertMinigame } = actions;
export default reducer;
