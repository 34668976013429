export const currencyFormat = (amount: number) => {
  // Chuyển đổi số thành chuỗi và thêm dấu phẩy phân cách hàng nghìn
  if (amount < 0) {
    const formattedAmount = Math.abs(Number(amount))
      .toFixed()
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `-${formattedAmount}`;
  } else if (amount > 0) {
    const formattedAmount = Number(amount)
      .toFixed()
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Thêm đơn vị tiền tệ (VND)
    return `${formattedAmount}`;
  } else {
    return 0;
  }
};

export const currencyFormatK = (amount: number) => {
  if (amount >= 1000000000) {
    return (
      currencyFormat(Math.floor(amount / 1000000000)) +
      "B" +
      (amount % 1000000000
        ? currencyFormat(Math.floor((amount % 1000000000) / 100000000))
        : "")
    );
  } else if (amount >= 1000000) {
    return (
      currencyFormat(Math.floor(amount / 1000000)) +
      "M" +
      (amount % 1000000
        ? currencyFormat(Math.floor((amount % 1000000) / 100000))
        : "")
    );
  } else if (amount >= 1000) {
    return (
      currencyFormat(Math.floor(amount / 1000)) +
      "K" +
      (amount % 1000 ? currencyFormat(Math.floor((amount % 1000) / 100)) : "")
    );
  } else {
    return amount.toString();
  }
};

export const formatCurrencyNumberWithDecimal = (value: string) => {
  if (value) {
    const cleanedValue = value.replace(/,/g, ""); // Remove existing commas
    const parts = cleanedValue.split(".");
    const integerPart = parts[0].replace(/\D/g, "");
    const decimalPart = parts[1] ? parts[1].replace(/\D/g, "").slice(0, 4) : "";

    let formattedValue = "";

    if (integerPart) {
      formattedValue = parseFloat(integerPart).toLocaleString("en-US");
    }

    if (decimalPart) {
      formattedValue += `.${decimalPart}`;
    }

    return formattedValue;
  }
};


export const convertMoneyTxt = (money: number) => {
  let moneyConvertStr = "";
  if (money < 1000) {
    moneyConvertStr = `${money}`;
  } else if (money >= 1000 && money < 1000000) {
    moneyConvertStr = `${Math.round((money / 1000) * 100) / 100}K`;
  } else if (money >= 1000000) {
    moneyConvertStr = `${Math.round((money / 1000000) * 100) / 100}M`;
  }
  return moneyConvertStr;
}

