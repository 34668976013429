import classNames from 'classnames'
import DatePickerFilter from 'components/DatePickerFilter/DatePickerFilter';
import React from 'react'
import { useSelector } from 'react-redux';
interface propsI {
    updateFormData: any;
    statusFilter: any;
    handleGetReportUser: any;
    handleClearFilter: any;
}
const FilterReport = (props: propsI) => {
    const { updateFormData, statusFilter, handleGetReportUser, handleClearFilter } =
        props;

    const { isMobile } = useSelector((state: any) => state.layout);
    return (
        <div className={classNames("flex w-full items-center mt-4 mb-2 flex-wrap", {
            "!my-0 p-4": isMobile,
        })}>
            <div className={classNames("mb-2", { "w-full": isMobile })}>
                <DatePickerFilter onChange={updateFormData} type={isMobile ? "vertical" : "horizontal"} width={isMobile ? "100%" : ""} reset={statusFilter} />
            </div>
            <div
                className={classNames("mt-3 flex items-center", {
                    "w-full !mr-0": isMobile,
                })}
            >
                <button
                    type="button"
                    onClick={() => handleGetReportUser(0, "filter")}
                    className={classNames(
                        "inline-flex text-sm items-center justify-center h-9 w-fit px-2.5 py-2 mr-3 bg-success-500 hover:bg-success-600 rounded-[10px] text-white text-white border border-transparent leading-none capitalize",
                        { "!w-1/2 !mr-1.5 !ml-0": isMobile }
                    )}
                >
                    Tìm kiếm
                </button>
                <button
                    type="button"
                    onClick={() => handleClearFilter()}
                    className={classNames(
                        "inline-flex text-sm justify-center items-center h-9 w-fit px-2.5 py-2 bg-transparent rounded-[10px] text-success-500 border border-success-500 hover:border-success-600 hover:text-success-600 leading-none capitalize",
                        { "!w-1/2 !ml-1.5 !mr-0": isMobile }
                    )}
                >
                    Xoá bộ lọc
                </button>
            </div>
        </div>
    )
}

export default FilterReport