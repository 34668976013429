import authApi from "apis/authApi";
import ButtonAction from "components/ButtonAction/ButtonAction";
import InputComponent from "components/InputComponent/InputComponent";
import { addRefreshToken, checkAuth, login } from "features/authSlice";
import { addLoginname } from "features/userSlice";
import useToast from "hooks/useToast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
function LoginForm(props: any) {
  const { submitLogin, onClose } = props;
  const navigate = useNavigate();
  const toastify = useToast();
  const dispatch = useDispatch();

  // const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    loginname: "",
    password: "",
  });
  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  const updateFormData = (field: any, value: any, type?: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [field]: value !== "" ? value : null,
    }));
  };

  const handlesubmit = async () => {
    if (!formData.loginname) {
      toastify("Xin mời nhập tên đăng nhập!", "warning");
      return;
    } else if (!formData.password) {
      toastify("Xin mời nhập mật khẩu!", "warning");
      return;
    } else {
      const params = {
        loginname: formData.loginname.trim(),
        password: formData.password,
      };
      try {
        const { data } = await authApi.signin(params);
        if (data.error_code === 0) {
          dispatch(addLoginname(params.loginname));
          dispatch(addRefreshToken(data.data.refeshtoken));
          dispatch(checkAuth(true));
          dispatch(login(data.data.token));
          toastify("Đăng nhập thành công!", "success");
          onClose();
          navigate("/ket-qua");
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoadingAction(true);
        setTimeout(() => {
          setLoadingAction(false);
        }, 2000);
      }
    }
  };

  const handleChangePage = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <form className="form bg-surface-tertiary dark:bg-black rounded-[20px] shadow-lg w-[360px] border border-borderPrimary">
        <div className="flex items-center justify-between px-5 py-[18px] bg-headerForm bg-center bg-cover">
          <h1 className="text-base font-semibold text-black dark:text-white">
            Đăng nhập
          </h1>
          <button
            type="button"
            className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
            onClick={onClose}
          >
            <img
              src={require("assets/icons/close.png")}
              className="w-5 h-5"
              alt="Đăng nhập"
            />
          </button>
        </div>

        <div className="flex flex-col p-10">
          <div className="mb-2">
            <InputComponent
              title="Tài khoản"
              label="Tên tài khoản..."
              type="loginname"
              typeInput="text"
              handleChange={updateFormData}
              width="w-full"
            />
          </div>
          <div className="mb-2">
            <InputComponent
              title="Mật khẩu"
              label="Mật khẩu..."
              type="password"
              typeInput="password"
              handleChange={updateFormData}
              width="w-full"
            />
          </div>
          {/* <label className="flex w-fit items-center mt-4 cursor-pointer">
            <input type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
            <span className="text-sm text-white ml-2 font-semibold">Nhớ tài khoản</span>
          </label> */}
        </div>
        <div className="form-group form-wrap-btn pt-0 p-10 w-full">
          <ButtonAction
            title="Đăng nhập"
            loading={loadingAction}
            onSubmit={handlesubmit}
            type="primary"
            className="!px-4 !py-2.5"
          />
          <div className="flex items-center justify-center mt-3">
            <span className="text-sm text-black dark:text-white mr-1">
              Bạn chưa có tài khoản?
            </span>
            <span
              className="text-sm font-semibold text-primary hover:text-primary/80 hover:underline cursor-pointer"
              onClick={() => handleChangePage("/dang-ky")}
            >
              Đăng ký ngay
            </span>
          </div>
        </div>
      </form>
    </>
  );
}

export default LoginForm;
