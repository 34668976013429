import classNames from "classnames";
import DatePickerFilter from "components/DatePickerFilter/DatePickerFilter";
import InputComponent from "components/InputComponent/InputComponent";
import SelectOptionComponent from "components/SelectOptionComponent/SelectOptionComponent";
import { useSelector } from "react-redux";
import { listTypeDate } from "../../interfaces/list-lottery";
import { useState } from "react";

interface propsI {
  updateFormData: any;
  statusFilter: any;
  handleGetRecord: any;
  handleClearFilter: any;
}

const FilterHistoryBet = (props: propsI) => {
  const { updateFormData, statusFilter, handleGetRecord, handleClearFilter } =
    props;

  const { isMobile } = useSelector((state: any) => state.layout);
  const { listStatus, listGames } = useSelector((state: any) => state.lottery);

  const [listGamesLotteryFilter, setListGamesLotteryFilter] = useState<any>(
    listGames.filter((item: any) => [0, 1, 2, 3].includes(item.type))
  );

  return (
    <div
      className={classNames("flex w-full items-center my-4 flex-wrap", {
        "!my-0 p-4 h-[calc(100%-53px)] overflow-auto": isMobile,
      })}
    >
      <div
        className={classNames("mb-2 mr-3", {
          "w-full !mr-0": isMobile,
        })}
      >
        <SelectOptionComponent
          getStatus={updateFormData}
          type={"timefilter"}
          listStatus={listTypeDate}
          title="Loại thời gian"
          reset={statusFilter}
          isMobile={isMobile}
          noAll
        />
      </div>
      <div className={classNames("mb-2", { "w-full": isMobile })}>
        <DatePickerFilter
          onChange={updateFormData}
          type={isMobile ? "vertical" : "horizontal"}
          width={isMobile ? "100%" : ""}
          reset={statusFilter}
        />
      </div>
      <div
        className={classNames("mb-2 mr-3", {
          "w-full !mr-0": isMobile,
        })}
      >
        <SelectOptionComponent
          getStatus={updateFormData}
          type={"status"}
          listStatus={listStatus}
          title="Trạng thái"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>
      <div
        className={classNames("mb-2 mr-3", {
          "w-full !mr-0": isMobile,
        })}
      >
        <SelectOptionComponent
          getStatus={updateFormData}
          type={"gameid"}
          listStatus={listGamesLotteryFilter}
          title="Đài chơi"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>
      <div
        className={classNames("mb-2 mr-3", {
          "w-full !mr-0": isMobile,
        })}
      >
        <InputComponent
          handleChange={updateFormData}
          type={"id"}
          typeInput="text"
          label="Nhập mã vé chơi"
          title="Mã vé chơi"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>
      <div
        className={classNames("mb-2 mr-3", {
          "w-full !mr-0": isMobile,
        })}
      >
        <InputComponent
          handleChange={updateFormData}
          type={"issue"}
          typeInput="text"
          label="Nhập lượt xổ"
          title="Lượt xổ"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>
      <div
        className={classNames("mt-3 flex items-center", {
          "w-full !mr-0": isMobile,
        })}
      >
        <button
          type="button"
          onClick={() => handleGetRecord(0, "filter")}
          className={classNames(
            "inline-flex text-sm items-center justify-center h-9 w-fit px-2.5 py-2 mr-3 bg-success-500 hover:bg-success-600 rounded-[10px] text-white border border-transparent leading-none capitalize",
            { "!w-1/2 !mr-1.5 !ml-0": isMobile }
          )}
        >
          Tìm kiếm
        </button>
        <button
          type="button"
          onClick={handleClearFilter}
          className={classNames(
            "inline-flex text-sm justify-center items-center h-9 w-fit px-2.5 py-2 bg-transparent rounded-[10px] text-success-500 border border-success-500 hover:border-success-600 hover:text-success-600 leading-none capitalize",
            { "!w-1/2 !ml-1.5 !mr-0": isMobile }
          )}
        >
          Xoá bộ lọc
        </button>
      </div>
    </div>
  );
};

export default FilterHistoryBet;
