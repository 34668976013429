import classNames from "classnames";
import DatePickerFilter from "components/DatePickerFilter/DatePickerFilter";
import InputComponent from "components/InputComponent/InputComponent";
import SelectOptionComponent from "components/SelectOptionComponent/SelectOptionComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
interface propsI {
  updateFormData: any;
  statusFilter: any;
  handleGetTransaction: any;
  handleClearFilter: any;
}
const FilterTransaction = (props: propsI) => {
  const {
    updateFormData,
    statusFilter,
    handleGetTransaction,
    handleClearFilter,
  } = props;

  const { listStatusTransaction } = useSelector((state: any) => state.lottery);

  const [listStatusFilter, setListStatusFilter] = useState<any>(
    listStatusTransaction.filter((item: any) =>
      [2, 3, 4, 16, 17, 18].includes(item.id)
    )
  );

  const { isMobile } = useSelector((state: any) => state.layout);

  return (
    <div
      className={classNames("flex w-full items-center my-4 flex-wrap", {
        "!my-0 p-4": isMobile,
      })}
    >
      <div className={classNames("", { "w-full mb-3": isMobile })}>
        <DatePickerFilter
          onChange={updateFormData}
          type={isMobile ? "vertical" : "horizontal"}
          width={isMobile ? "100%" : ""}
          reset={statusFilter}
        />
      </div>
      <div
        className={classNames("mr-3", {
          "w-full mb-3 !mr-0": isMobile,
        })}
      >
        <SelectOptionComponent
          getStatus={updateFormData}
          type={"transtype"}
          listStatus={listStatusFilter}
          title="Loại biến động"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>
      <div
        className={classNames("mr-3", {
          "w-full !mr-0": isMobile,
        })}
      >
        <InputComponent
          handleChange={updateFormData}
          type={"id"}
          typeInput="text"
          title="Mã biến động"
          label="Nhập mã biến động"
          reset={statusFilter}
          isMobile={isMobile}
        />
      </div>

      <div
        className={classNames("mt-5 flex items-center", {
          "w-full !mr-0": isMobile,
        })}
      >
        <button
          type="button"
          onClick={() => handleGetTransaction(0, "filter")}
          className={classNames(
            "inline-flex text-sm items-center justify-center h-9 w-fit px-2.5 py-2 mr-3 bg-success-500 hover:bg-success-600 rounded-[10px] text-white text-white border border-transparent leading-none capitalize",
            { "!w-1/2 !mr-1.5 !ml-0": isMobile }
          )}
        >
          Tìm kiếm
        </button>
        <button
          type="button"
          onClick={() => handleClearFilter()}
          className={classNames(
            "inline-flex text-sm justify-center items-center h-9 w-fit px-2.5 p-2 bg-transparent rounded-[10px] text-success-500 border border-success-500 hover:border-success-600 hover:text-success-600 leading-none capitalize",
            { "!w-1/2 !ml-1.5": isMobile }
          )}
        >
          Xoá bộ lọc
        </button>
      </div>
    </div>
  );
};

export default FilterTransaction;
