import ChatAILayout from "layout/ChatAILayout";
import CommonLayout from "layout/CommonLayout";
import GameLayout from "layout/GameLayout";
import HomeLayout from "layout/HomeLayout";
import LandingPageLayout from "layout/LandingPageLayout";
import ResultLayout from "layout/ResultLayout";
import AuthPage from "pages/AuthPage/AuthPage";
import ContactPage from "pages/ContactPage/ContactPage";
import HistoryPage from "pages/HistoryPage/HistoryPage";
import HomePage from "pages/HomePage/HomePage";
import InfoPage from "pages/InfoPage/InfoPage";
import NotificationPage from "pages/NotificationPage/NotificationPage";
import PlayBackPage from "pages/PlayBackPage/PlayBackPage";
import ReportAllBetPage from "pages/ReportAllBetPage/ReportAllBetPage";
import ResultHistoryPage from "pages/ResultHistoryPage/ResultHistoryPage";
import TodayResultPage from "pages/TodayResultPage/TodayResultPage";
import TransactionPage from "pages/TransactionPage/TransactionPage";

export const mainRoutes = [
  {
    path: "/ket-qua/*",
    component: ResultLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "/tai-khoan/*",
    component: HomeLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "/mua-so/*",
    component: GameLayout,
    isMain: true,
    isPublic: false,
  },
  {
    path: "/dang-ky",
    component: AuthPage,
    isMain: true,
    isPublic: true,
  },
  {
    path: "/tro-ly-xo-so",
    component: ChatAILayout,
    isMain: false,
    isPublic: false,
  },
  {
    path: "/ve-chung-toi",
    component: LandingPageLayout,
    isMain: false,
    isPublic: true,
  },
  {
    path: "/trang/*",
    component: CommonLayout,
    isMain: false,
    isPublic: true,
  },
];

export const resultRoutes = [
  {
    paths: [
      "/",
      "/truc-tiep-mien-bac",
      "/truc-tiep-mien-trung",
      "/truc-tiep-mien-nam",
    ],
    component: TodayResultPage,
    isMain: true,
    isPublic: true,
  },
  {
    path: "/:idLottery",
    component: ResultHistoryPage,
    isMain: true,
    isPublic: true,
  },
];

export const gameRoutes = [
  {
    path: "/trang-chu",
    component: HomePage,
    isMain: true,
    isPublic: true,
  },
  {
    path: "/:idLottery",
    component: PlayBackPage,
    isMain: false,
    isPublic: false,
  },
];

export const homeRoutes = [
  {
    path: "/thong-tin-ca-nhan",
    component: InfoPage,
    isMain: false,
    isPublic: false,
  },
  {
    path: "/lich-su",
    component: HistoryPage,
    isMain: false,
    isPublic: false,
  },
  {
    path: "/thong-ke",
    component: ReportAllBetPage,
    isMain: false,
    isPublic: false,
  },
  {
    path: "/thong-bao",
    component: NotificationPage,
    isMain: false,
    isPublic: false,
  },
  // {
  //   path: "/promosys",
  //   component: PromoSysPage,
  //   isMain: false,
  //   isPublic: false,
  // },
  {
    path: "/bien-dong-so-du",
    component: TransactionPage,
    isMain: false,
    isPublic: false,
  },
];

export const commonRoutes = [
  {
    path: "/lien-he-quang-cao",
    component: ContactPage,
    isMain: true,
    isPublic: true,
  },
];
