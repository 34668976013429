import { icons } from "utils/icons";

function ModalPromotion(props: any) {
  const { data, onClose } = props;

  return (
    <div className="promo-modal rounded-lg overflow-hidden bg-surface-primary dark:bg-surface-second max-w-[400px] shadow-[0px_4px_24px_0px_#00000033] mx-auto">
      <div className="header-promo w-full relative">
        {data?.imgurl && (
          <img src={data?.imgurl} className="w-full h-full" alt={data?.title} />
        )}
        <button
          type="button"
          className="bg-transparent border-none cursor-pointer absolute top-3 right-3"
          onClick={onClose}
        >
          <img src={icons.closeWhite} className="w-6 h-6" alt="Đóng" />
        </button>
      </div>
      <div className="content-promo px-4 py-2">
        <div className="wrap-content w-full h-full flex flex-col items-center">
          <span className="text-lg text-center text-success-500 font-semibold">
            {data?.title}
          </span>
          <div
            className="mt-1 w-full text-sm text-white max-h-[350px] overflow-auto no-scrollbar"
            dangerouslySetInnerHTML={{ __html: data?.content }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ModalPromotion;
