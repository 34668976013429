import { createTheme, ThemeProvider } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { paginationI } from "interfaces/common.interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  currencyFormat,
  formatCurrencyNumberWithDecimal,
} from "utils/currencyFormat";

const PaginationComponent = (props: paginationI) => {
  const { changeOffset, totalPage, currentPage } = props;
  // Hook get từ store
  const { mode } = useSelector((state: any) => state.layout);

  const [inputValue, setInputValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const theme = createTheme({
    palette: {
      mode: mode === 1 ? "dark" : "light", // Chọn chế độ Dark Mode
    },
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#6D6D6D", // Màu chữ cho các trang
            "&:hover": {
              background: "transparent",
              color: "#12B76A",
            },
            "&.Mui-selected": {
              border: "1px solid #12B76A",
              backgroundColor: "transparent", // Màu nền cho trang hiện tại
              color: "#12B76A", // Màu chữ cho trang hiện tại
              "&:hover": {
                backgroundColor: "transparent",
                border: "1px solid #039855",
                color: "#039855",
              },
            },
          },
          icon: {
            color: "yourIconColor", // Màu biểu tượng
          },
        },
      },
    },
  });

  useEffect(() => {
    if (currentPage) {
      setPage(currentPage);
    }
  }, [currentPage]);

  const pickOffset = (event: any) => {
    if (event.selected >= 0 && event.selected < totalPage)
      changeOffset(event.selected);
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    if (Number(value) > totalPage) {
      return;
    }
    const formatValue: any =
      value.length > 0 ? formatCurrencyNumberWithDecimal(value) : value;
    setInputValue(formatValue);
  };

  // Hàm onChange pagination
  const handleChangePagination = (ev: any, page: number) => {
    const currentPage = page - 1;
    setPage(page);
    changeOffset(currentPage);
  };

  const handleEnterKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    // Kiểm tra xem mã phím có phải là các phím số từ 1 đến 9 hoặc các phím điều hướng (nếu cần)
    const isNumberKey =
      (keyCode >= 48 && keyCode <= 57) || // Phím số từ 1 đến 9
      (keyCode >= 96 && keyCode <= 105) || // Phím số từ bàn phím số
      keyCode === 8 || // Phím Backspace
      keyCode === 9 || // Phím Tab
      keyCode === 37 || // Phím mũi tên sang trái
      keyCode === 39; // Phím mũi tên sang phải
    // Nếu không phải là phím số hoặc phím điều hướng, ngăn không cho sự kiện tiếp tục xảy ra
    if (!isNumberKey || ["e", "E", "+", "-", "."].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === "Enter" || event.key === "Return") {
      if (inputValue === "") {
        return;
      } else {
        pickOffset({ selected: Number(inputValue) - 1 });
        setPage(Number(inputValue));
      }
    }
  };

  return (
    <>
      {totalPage && totalPage > 1 ? (
        <div className="flex items-center justify-end gap-2 w-full">
          <ThemeProvider theme={theme}>
            <Pagination
              showFirstButton
              showLastButton
              count={totalPage}
              shape="rounded"
              page={page}
              onChange={(e, page: number) => handleChangePagination(e, page)}
            />
          </ThemeProvider>
          <span className="text-sm text-white mx-2">
            {currencyFormat(page) + "/" + currencyFormat(totalPage)}
          </span>
          <div className="flex items-center">
            <span className="text-sm text-primary mr-1">Tới: </span>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleEnterKeyPress}
              className="px-1 rounded py-2 text-white text-sm focus:outline-0 w-16 border border-borderPrimary bg-bgPrimary max-h-6"
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default PaginationComponent;
