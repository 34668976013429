import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Section1 = () => {
  const navigate = useNavigate();
  const { isMobile } = useSelector((state: any) => state.layout);
  const { isAuth } = useSelector((state: any) => state.auth);

  return (
    <section
      className={classNames(
        "flex flex-col items-center justify-center pb-7 pt-[120px] px-4"
      )}
    >
      <div className="flex flex-col mb-8">
        <h1 className="opacity-0 hidden">Khám phá và trải nghiệm Trợ Lý Xổ Số - Trang cập nhật kết quả hàng đầu tại Việt Nam</h1>
        <h3
          className={classNames(
            "font-semibold text-linear-primary text-center mb-2 text-5xl leading-[1.2]",
            { "!text-4xl": isMobile }
          )}
        >
          Khám phá và trải nghiệm
        </h3>
        <h2
          className={classNames(
            "font-semibold text-linear-primary text-center mb-2 text-5xl leading-[1.2]",
            { "!text-4xl": isMobile }
          )}
        >
          Trợ Lý Xổ Số - Trang cập nhật kết quả
        </h2>
        <h2
          className={classNames(
            "font-semibold text-linear-primary text-center text-5xl leading-[1.2]",
            { "!text-4xl": isMobile }
          )}
        >
          hàng đầu tại Việt Nam
        </h2>
      </div>
      <div className="w-full flex items-center justify-center mb-20">
        <p className="font-medium text-text-secondary text-2xl text-center">
          Mở cửa tương lai, đón nhận vận may
        </p>
      </div>
      {!isAuth && (
        <div className="flex items-center justify-center">
          <button
            className="bg-bgPrimary text-white rounded-[10px] py-3 px-11 hover:opacity-80 border-light-hover"
            type="button"
            onClick={() => navigate("/dang-ky")}
          >
            <h2 className="relative z-[3] w-full h-full">Đăng ký ngay</h2>
          </button>
        </div>
      )}
    </section>
  );
};

export default Section1;
