import authApi from "apis/authApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalCommon from "components/ModalCommon/ModalCommon";
import { headerPageMenus, listMenuPublic, listNav, listSocial } from "constant";
import { addRefreshToken, checkAuth, logout } from "features/authSlice";
import { addModalPromoTimes } from "features/commonSlice";
import { addThemeMode } from "features/layoutSlice";
import { addBalanceUser, addUserInfo } from "features/userSlice";
import useToast from "hooks/useToast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { handleRedirectLink } from "utils/commonFormat";
import { icons } from "utils/icons";

const MenuMobile = (props: any) => {
  const { onClose, isShow } = props;

  const dispatch = useDispatch();
  const toastify = useToast();
  const navigate = useNavigate();

  const { isAuth } = useSelector((state: any) => state.auth);
  const { userInfo } = useSelector((state: any) => state.user);
  const { mode } = useSelector((state: any) => state.layout);

  const [showModalConfirmLogout, setShowModalConfirmLogout] =
    useState<boolean>(false);
    const { configSeo } = useSelector((state: any) => state?.seo);

  const handleLogout = async () => {
    try {
      const { data } = await authApi.logout();
      if (data) {
        dispatch(logout());
        dispatch(checkAuth(false));
        dispatch(addUserInfo(null));
        dispatch(addBalanceUser(0));
        dispatch(addRefreshToken(null));
        dispatch(addModalPromoTimes(0));
        toastify("Đăng xuất thành công!", "success");
        setShowModalConfirmLogout(false);
        onClose();
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = (path: any, isPublic?: boolean) => {
    navigate(path);
    onClose();
  };

  const handleToggleMode = (e: any) => {
    if (mode === 0) {
      dispatch(addThemeMode(1));
      localStorage.setItem("darkMode", "true");
    } else {
      dispatch(addThemeMode(0));
      localStorage.setItem("darkMode", "false");
    }
  };

  return (
    <div
      className={classNames(
        "fixed top-0 left-0 w-full py-2 px-4 shadow-[0px_10px_20px_0px_#0000004D] bg-surface-tertiary dark:bg-bgPrimary flex flex-col -translate-y-[120%] transition-all duration-500",
        { "!translate-y-0": isShow }
      )}
    >
      <div className="flex items-center justify-between mb-3">
        {isAuth ? (
          <div className="flex items-center text-sm ">
            <span className="text-black dark:text-white font-semibold">
              {userInfo?.loginname}
            </span>
            <span className="text-text-secondary dark:text-white/40 font-medium ml-2">
              ID: {userInfo?.id}
            </span>
          </div>
        ) : (
          <img
            src={require("assets/images/icon-logo.png")}
            className="w-10 h-10"
            alt={configSeo?.title}
          />
        )}
        <button
          type="button"
          className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
          onClick={onClose}
        >
          <img
            src={require("assets/icons/close.png")}
            className="w-5 h-5"
            alt={configSeo?.title}
          />
        </button>
      </div>
      {isAuth && (
        <>
          <div className="flex flex-col">
            {headerPageMenus?.map((menu: any, index: number) => (
              <div
                className="flex items-center py-2 px-2.5 text-text-secondary dark:text-white/40 active:text-black dark:active:text-white"
                key={index}
                onClick={() => handleNavigate(menu.path)}
              >
                <div
                  className="mr-2.5"
                  dangerouslySetInnerHTML={{ __html: menu.icon }}
                />
                <span className="text-sm font-semibold">{menu.title}</span>
              </div>
            ))}
          </div>
          <div className="w-full h-[1px] bg-borderLightPrimary dark:bg-borderPrimary my-2"></div>
          <div className="flex flex-col">
            {listNav?.map((menu: any, index: number) => (
              <div
                className="flex items-center py-2 px-2.5 text-text-secondary dark:text-white/40 active:text-black dark:active:text-white"
                key={index}
                onClick={() => handleNavigate(menu.path)}
              >
                <div
                  className="mr-2.5"
                  dangerouslySetInnerHTML={{ __html: menu.icon }}
                />
                <span className="text-sm font-semibold">{menu.title}</span>
              </div>
            ))}
          </div>
          <div className="w-full h-[1px] bg-borderLightPrimary dark:bg-borderPrimary my-2"></div>
        </>
      )}
      <div className="flex flex-col">
        {listMenuPublic?.map((menu: any, index: number) => (
          <div
            className="flex items-center py-2 px-2.5 text-text-secondary dark:text-white/40 active:text-black dark:active:text-white"
            key={index}
            onClick={() => {
              if (menu.isNewTab) {
                handleRedirectLink(menu.path);
              } else {
                handleNavigate(menu.path, menu.isPublic);
              }
            }}
          >
            <div
              className="mr-2.5"
              dangerouslySetInnerHTML={{ __html: menu.icon }}
            />
            <span className="text-sm font-semibold">{menu.title}</span>
          </div>
        ))}
        {isAuth && (
          <div
            className="nav-item px-2.5 py-2 flex items-center rounded-lg text-text-secondary dark:text-white/40 active:text-black dark:active:text-white active:bg-gray-200 dark:active:bg-gray-900 cursor-pointer"
            onClick={() => setShowModalConfirmLogout(true)}
          >
            <svg
              className="mr-2"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 4.62219V15.245C20 16.3579 19.2857 17.4708 18.1633 17.8754L13.0612 19.7977C12.7551 19.8988 12.449 20 12.0408 20C11.5306 20 10.9184 19.7977 10.4082 19.4942C10.2041 19.2918 9.89796 19.0895 9.79592 18.8871H5.91837C4.38776 18.8871 3.06122 17.6731 3.06122 16.0544V15.0427C3.06122 14.638 3.36735 14.2333 3.87755 14.2333C4.38776 14.2333 4.69388 14.5368 4.69388 15.0427V16.0544C4.69388 16.7626 5.30612 17.2684 5.91837 17.2684H9.28572V2.69997H5.91837C5.20408 2.69997 4.69388 3.20582 4.69388 3.91401V4.9257C4.69388 5.33038 4.38776 5.73506 3.87755 5.73506C3.36735 5.73506 3.06122 5.33038 3.06122 4.9257V3.91401C3.06122 2.39646 4.28572 1.08125 5.91837 1.08125H9.79592C10 0.878913 10.2041 0.676573 10.4082 0.474233C11.2245 -0.0316156 12.1429 -0.132785 13.0612 0.170724L18.1633 2.09295C19.1837 2.39646 20 3.50933 20 4.62219Z"
                fill="currentColor"
              />
              <path
                d="M2.85714 12.8169C2.65306 12.8169 2.44898 12.7158 2.34694 12.6146L0.306122 10.5912C0.204082 10.49 0.204082 10.3889 0.102041 10.3889C0.102041 10.2877 0 10.1865 0 10.0854C0 9.98419 -9.88342e-08 9.88302 0.102041 9.78185C0.102041 9.68068 0.204082 9.57951 0.306122 9.57951L2.34694 7.55612C2.65306 7.25261 3.16327 7.25261 3.46939 7.55612C3.77551 7.85963 3.77551 8.36548 3.46939 8.66898L2.7551 9.37717H6.93878C7.34694 9.37717 7.7551 9.68068 7.7551 10.1865C7.7551 10.6924 7.34694 10.7936 6.93878 10.7936H2.65306L3.36735 11.5017C3.67347 11.8052 3.67347 12.3111 3.36735 12.6146C3.26531 12.7158 3.06122 12.8169 2.85714 12.8169Z"
                fill="currentColor"
              />
            </svg>

            <span className="text-sm font-semibold">Đăng Xuất</span>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between mt-2">
        <button
          className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/20 outline-none rounded-[10px]"
          type="button"
          onClick={handleToggleMode}
        >
          <img
            src={mode === 1 ? icons.sun : icons.moon}
            className="icon-header text-primary w-5 h-5"
            alt={configSeo?.title}
          />
        </button>
        <div className="flex items-center">
          {listSocial?.map((social: any, index: number) => (
            <div className="w-6 h-6 group ml-4 cursor-pointer" key={index}>
              <img
                src={social.iconHover}
                className={classNames("w-full h-full  block", {})}
                alt={configSeo?.title}
              />
            </div>
          ))}
        </div>
      </div>
      <Modal
        isShow={showModalConfirmLogout}
        onClose={() => setShowModalConfirmLogout(false)}
      >
        <ModalCommon
          title="Xác nhận"
          content="Bạn có thật sự muốn đăng xuất không?"
          handleClose={() => setShowModalConfirmLogout(false)}
          handleSubmit={handleLogout}
        />
      </Modal>
    </div>
  );
};

export default MenuMobile;
