import moment from "moment-timezone";

export const getCurrentDateTime = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
};

export const getStartDateTime = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).startOf("day").format("YYYY-MM-DD HH:mm:ss");
};

export const getEndDateTime = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).endOf("day").format("YYYY-MM-DD HH:mm:ss");
};

export const getCurrentDateTimeFormatBasic = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).format("YYYY-MM-DD");
};
export const getFormatDayMonthYear = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).format("DD-MM-YYYY");
};

export const getMidnightDateTimeFormatBasic = () => {
  const timeZone = "Asia/Ho_Chi_Minh"; // Múi giờ của Việt Nam
  return moment().tz(timeZone).startOf("day").format("YYYY-MM-DD");
};