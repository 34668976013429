import axiosClient from "./axiosInstance";
import { paramsGetListUserInbox } from "../interfaces/api.interface";

const commonApi = {
  getListNotification() {
    const url = "/api/v1/event/promotsys/all";
    return axiosClient.get(url);
  },
  getListGameTypePromo() {
    const url = "/api/v1/event/promotsys/constant/gametype";
    return axiosClient.get(url);
  },
  getListBanner() {
    const url = "api/v1/event/public/promotsys/banners";
    return axiosClient.get(url);
  },
  getListUserInbox(params?: paramsGetListUserInbox) {
    const url = "/api/v1/message/userinbox";
    return axiosClient.get(url, { params });
  },
  viewInbox(inboxid: string) {
    const url = `/api/v1/message/userinbox/view?id=${inboxid}`;
    return axiosClient.put(url);
  },
  viewAllInbox() {
    const url = "/api/v1/message/userinbox/viewall";
    return axiosClient.put(url);
  },
  deleteInbox(inboxid: string) {
    const url = `/api/v1/message/userinbox/${inboxid}`;
    return axiosClient.delete(url);
  },
  deleteAllInbox() {
    const url = `/api/v1/message/userinbox/deleteall`;
    return axiosClient.delete(url);
  },
};

export default commonApi;
