import { useEffect, useRef, useState } from "react";
import "./MultipleComponent.scss";
import classNames from "classnames";
import { formatCurrencyNumberWithDecimal } from "utils/currencyFormat";

interface propI {
  multipleValue: number;
  getMultiple: any;
  statusBet?: boolean;
  isMobile?: boolean;
  methodActive?: any;
  ticketBet?: any;
}

function MultipleComponent(props: propI) {
  const { multipleValue, getMultiple, statusBet, methodActive, ticketBet } =
    props;

  const multipleRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [valueMultiple, setValueMultiple] = useState<string>(
    multipleValue.toString()
  );
  const [isDisableAction, setIsDisableAction] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (inputRef.current.value === "" || inputRef.current.value === "0") {
          setValueMultiple("1");
          getMultiple(1);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsDisableAction(!ticketBet ? true : false);
    if (!ticketBet) {
      setValueMultiple("1");
      getMultiple(1);
    }
  }, [ticketBet]);

  useEffect(() => {
    if (!statusBet || methodActive) {
      setValueMultiple("1");
      getMultiple(1);
    }
  }, [statusBet, methodActive]);

  const handleAction = (type: string) => {
    let value = Number(valueMultiple.replace(/,/g, ""));
    if (type === "decrease") {
      if (value > 1) {
        value--;
        const formatValue: any =
          value.toString().length > 0
            ? formatCurrencyNumberWithDecimal(value.toString())
            : "1";
        setValueMultiple(formatValue);
      }
    } else {
      value++;
      const formatValue: any =
        value.toString().length > 0
          ? formatCurrencyNumberWithDecimal(value.toString())
          : "1";
      setValueMultiple(formatValue);
    }
    getMultiple(Number(value));
  };

  const handleChangeInput = (ev: any) => {
    let newValue = ev.target.value;
    if (newValue.length >= 9) {
      return;
    }
    const formatValue: any =
      newValue.length > 0 ? formatCurrencyNumberWithDecimal(newValue) : "";
    setValueMultiple(formatValue);
    getMultiple(Number(newValue.replace(/,/g, "")));
  };

  const handleKeyDown = (event: any) => {
    // Lấy mã phím từ sự kiện
    const keyCode = event.keyCode || event.which;

    // Kiểm tra xem mã phím có phải là các phím số từ 0 đến 9 hoặc các phím điều hướng (nếu cần)
    const isNumberKey =
      (keyCode >= 48 && keyCode <= 57) || // Phím số từ 0 đến 9
      (keyCode >= 96 && keyCode <= 105) || // Phím số từ bàn phím số
      keyCode === 8 || // Phím Backspace
      keyCode === 9 || // Phím Tab
      keyCode === 37 || // Phím mũi tên sang trái
      keyCode === 39; // Phím mũi tên sang phải
    if (!isNumberKey || ["e", "E", "+", "-", "."].includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className={classNames("multiple flex items-center")} ref={multipleRef}>
      <div className="flex items-center">
        <button
          className={classNames(
            "btn-decrease p-2.5 w-7 h-7 flex items-center justify-center bg-success-500 hover:bg-success-600 text-white text-xl rounded-md",
            { disable: Number(valueMultiple) === 1 || isDisableAction }
          )}
          type="button"
          onClick={() => handleAction("decrease")}
        >
          -
        </button>
        <input
          type="text"
          maxLength={10}
          min={1}
          max={1000000000}
          value={valueMultiple}
          ref={inputRef}
          className={classNames(
            "input-multiple text-center max-w-20 mx-1 h-7 p-2 rounded-md outline-none text-sm border border-borderLightPrimary dark:border-borderPrimary bg-white dark:bg-black text-black dark:text-white focus:border-primary",
            { "pointer-events-none": !statusBet }
          )}
          pattern="[0-9]*"
          onChange={(e) => handleChangeInput(e)}
          onKeyDown={(evt) => handleKeyDown(evt)}
        />
        <button
          className={classNames(
            "btn-decrease p-2.5 w-7 h-7 flex items-center justify-center bg-primary hover:bg-primary/80 text-white text-xl rounded-md",
            { disable: isDisableAction }
          )}
          type="button"
          onClick={() => handleAction("increase")}
        >
          +
        </button>
      </div>
    </div>
  );
}

export default MultipleComponent;
