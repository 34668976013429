import classNames from "classnames";
import FormContact from "components/FormContact/FormContact";
import { listSocial } from "constant";
import React from "react";
import { useSelector } from "react-redux";

const ContactPage = () => {
  const { isMobile } = useSelector((state: any) => state.layout);

  return (
    <div
      className={classNames(
        "w-full h-full p-8 flex items-center justify-center",
        {
          "!p-4": isMobile,
        }
      )}
    >
      <div className="w-full flex flex-col items-center">
        <h1 className="font-semibold text-black dark:text-white text-[30px] mb-7">
          Liên hệ quảng cáo
        </h1>
        <div className="flex items-center mb-7">
          {listSocial.map((social: any, index: number) => (
            <div
              className={classNames("w-10 h-10 group ml-10 cursor-pointer", {
                "!ml-0": index === 0,
              })}
              key={index}
            >
              {!isMobile && (
                <img
                  src={social.iconDefault}
                  className="w-full h-full block group-hover:hidden"
                  alt="Liên hệ quảng cáo"
                />
              )}
              <img
                src={social.iconHover}
                className={classNames(
                  "w-full h-full hidden group-hover:block",
                  { "!block": isMobile }
                )}
                alt="Liên hệ quảng cáo"
              />
            </div>
          ))}
        </div>
        <div className="max-w-[480px] w-full">
          <FormContact />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
