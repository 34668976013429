import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ListNumberStatitic = (props: any) => {
  const {
    getNumber,
    getNumberHover,
    dataSelect,
    lengthTable,
    getListDauDuoiSelected,
  } = props;

  const { isMobile } = useSelector((state: any) => state.layout);

  const [numberSelected, setNumberSelected] = useState<string | null>(null);

  useEffect(() => {
    return () => {
      setNumberSelected(null);
      getNumber(null);
      getNumberHover(null);
    };
  }, []);

  useEffect(() => {
    if (dataSelect?.length < lengthTable) {
      getListDauDuoiSelected(dataSelect);
      setNumberSelected(null);
      getNumber(null);
      getNumberHover(null);
    }
  }, [dataSelect]);

  const handleSelectNumber = (num: string) => {
    const number = !numberSelected || numberSelected !== num ? num : null;
    setNumberSelected(number);
    getNumber(number);
  };

  const handleOnMouseEnter = (num: string) => {
    if (isMobile) {
      return;
    }
    const number = num !== null ? num : null;
    getNumberHover(number);
  };

  const handleOnMouseLeave = () => {
    if (isMobile) {
      return;
    }
    getNumberHover(!numberSelected ? null : numberSelected);
  };

  return (
    <div
      className={classNames("flex items-center", {
        "w-full justify-between": isMobile,
      })}
    >
      {Array.from({ length: 10 }, (_, index: number) => (
        <div
          className={classNames(
            "w-6 h-6 rounded-full bg-surface-tertiary dark:bg-bgSecondary text-sm font-semibold text-text-secondary dark:text-white/40  dark:hover:text-white dark:hover:bg-primary flex items-center justify-center cursor-pointer",
            {
              "hover:text-white hover:bg-primary": !isMobile,
              "ml-3": index > 0 && !isMobile,
              "!text-white !bg-primary": numberSelected === index.toString(),
            }
          )}
          onClick={() => handleSelectNumber(index.toString())}
          onMouseEnter={() => handleOnMouseEnter(index.toString())}
          onMouseLeave={handleOnMouseLeave}
          key={index}
        >
          {index}
        </div>
      ))}
    </div>
  );
};

export default ListNumberStatitic;
