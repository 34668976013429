import commonApi from "apis/common";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalCommon from "components/ModalCommon/ModalCommon";
import ModalPromotion from "components/ModalPromotion/ModalPromotion";
import PaginationComponent from "components/PaginationComponent/PaginationComponent";
import SkeletonLoading from "components/SkeletonLoading/SkeletonLoading";
import {
  addCountInbox,
  addListNotification,
  setLoading,
  setLoadingTopBar,
} from "features/commonSlice";
import useToast from "hooks/useToast";
import { dataPromoI } from "interfaces/data.interface";
import { UserInboxI } from "interfaces/user.interface";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { formatDateBasic } from "utils/formateDate";
import { icons } from "utils/icons";

function NotificationPage() {
  const notiPageRef = useRef<HTMLDivElement>(null);
  const toastify = useToast();
  const dispatch = useDispatch();

  const { isMobile, isTablet } = useSelector((state: any) => state.layout);
  const { listNotification, countInbox, idSelectedMarquee } = useSelector(
    (state: any) => state.common
  );
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false);
  const [tabNotification, setTabNotification] = useState<string>("person");
  const [listUserInbox, setListUserInbox] = useState<UserInboxI[]>([]);
  const [listNoti, setListNoti] = useState<dataPromoI[]>([]);
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sizePage, setSizePage] = useState<number>(0);
  const [showModalPromo, setShowModalPromo] = useState<boolean>(false);
  const [showModalCommonDelete, setShowModalCommonDelete] =
    useState<boolean>(false);
  const [dataPromo, setDataPromo] = useState<any>(null);
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);

  useEffect(() => {
    handleGetListNotification();
    if (tabNotification === "person") {
      handleGetListUserInbox("search", 0);
      if (isMobile) {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (idSelectedMarquee) {
      setTabNotification("system");
    }
    const listNotiConvert = listNotification
      ?.filter((item: dataPromoI) => item.type === 1)
      .map((noti: dataPromoI) => {
        let isSelect = noti.id === idSelectedMarquee;
        return { ...noti, isSelect: isSelect };
      });
    setListNoti(listNotiConvert);
  }, [idSelectedMarquee, listNotification]);

  useEffect(() => {
    if (isMobile) {
      if (
        atBottom &&
        Number(currentPage) < Number(totalPage) &&
        tabNotification === "person"
      ) {
        setDataAvailable(true);
        if (!loadMore)
          handleGetListUserInbox(
            "pagi",
            currentPage === 0 ? currentPage + 1 : currentPage
          );
      }
    }
  }, [atBottom, totalPage]);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const handleGetListUserInbox = async (
    typeCall?: string,
    offset?: number | 0
  ) => {
    if (isMobile) {
      setLoadMore(true);
    } else {
      dispatch(setLoadingTopBar(true));
    }
    if (typeCall === "search") {
      dispatch(setLoading(true));
    }
    try {
      const { data } = await commonApi.getListUserInbox({
        pageSize: isMobile ? 15 : 10,
        offset: offset,
      });
      if (data) {
        const dataRes = data.data.content;
        if (!isMobile || typeCall === "search") {
          setListUserInbox(dataRes);
        } else {
          setListUserInbox((prevList: any) => [...prevList, ...dataRes]);
        }
        setCurrentPage(Number(data.data.number) + 1);
        setTotalPage(data.data.totalPages);
      }
    } catch (error: any) {
      console.log(error);
      // if (error.error_code !== 403 && error.error_code !== 401) {
      //   if (!error.msg) toastify(error.msg, "error");
      // }
    } finally {
      setLoadMore(false);
      dispatch(setLoadingTopBar(false));
      if (typeCall === "search") {
        dispatch(setLoading(false));
      }
    }
  };

  const handleViewAllInbox = async () => {
    if (!listUserInbox.some((ib: UserInboxI) => ib.viewtimes === 0)) {
      return;
    }
    try {
      const { data } = await commonApi.viewAllInbox();
      if (data.error_code === 0) {
        const newListInbox = listUserInbox.map((inbox: UserInboxI) => {
          inbox.viewtimes = 1;
          return inbox;
        });
        setListUserInbox(newListInbox);
        dispatch(addCountInbox(null));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewInboxById = async (inboxid: string) => {
    if (
      !listUserInbox.some(
        (ib: UserInboxI) => inboxid === ib.id && ib.viewtimes === 0
      )
    ) {
      return;
    }
    try {
      const { data } = await commonApi.viewInbox(inboxid);
      if (data.error_code === 0) {
        const newListInbox = listUserInbox.map((inbox: UserInboxI) => {
          if (inbox.id === inboxid) {
            inbox.viewtimes = 1;
          }
          return inbox;
        });
        setListUserInbox(newListInbox);
        dispatch(addCountInbox(countInbox - 1));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteInbox = async (inboxid: string) => {
    try {
      const { data } = await commonApi.deleteInbox(inboxid);
      if (data.error_code === 0) {
        toastify("Đã xóa thông báo thành công", "success");
        const listUserinboxDelete = listUserInbox.filter(
          (item: UserInboxI) => item.id !== inboxid
        );
        if (isMobile) {
          setListUserInbox(listUserinboxDelete);
        } else {
          handleGetListUserInbox("pagi");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowModalCommonDeleteAll = () => {
    setShowModalCommonDelete(true);
  };

  const handleDeleteAllInbox = async () => {
    try {
      const { data } = await commonApi.deleteAllInbox();
      if (data.error_code === 0) {
        toastify("Đã xóa tất cả thông báo thành công", "success");
        setListUserInbox([]);
        setShowModalCommonDelete(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetListNotification = async () => {
    try {
      const { data } = await commonApi.getListNotification();
      if (data) {
        dispatch(addListNotification(data.data));
      }
    } catch (error) {
      return;
    }
  };

  const changeOffset = (newOffset: any) => {
    handleGetListUserInbox("pagi", newOffset);
  };

  const handleCloseModalPromo = () => {
    setShowModalPromo(false);
    setDataPromo(null);
  };

  const handleChangeTab = (tab: string) => {
    setTabNotification(tab);
    if (tab === "person") {
      const listNotiConvert = listNotification
        .filter((item: dataPromoI) => item.type === 1)
        .map((noti: dataPromoI) => ({
          ...noti,
          isSelect: false,
        }));
      setListNoti(listNotiConvert);
    }
  };

  const handleSelectNoti = (id: string) => {
    const listNotiSelected = listNoti.map((item: any) => {
      item.isSelect = item.id === id ? !item.isSelect : false;
      return item;
    });
    setListNoti(listNotiSelected);
  };

  return (
    <div
      className={classNames("w-full h-full", {
        "!max-h-full": isMobile || isTablet,
      })}
      ref={notiPageRef}
    >
      <div
        className={classNames("notification-page flex justify-center w-full", {
          "flex-col !p-4": isMobile,
        })}
      >
        <div
          className={classNames(
            "w-full max-w-4xl bg-white dark:bg-bgPrimary shadow-table rounded-md h-full"
          )}
        >
          <div className="table-history-container w-full rounded p-5">
            <div className="header-popover flex items-center justify-between mb-4">
              <div className="title-table-wrap flex items-center">
                <h1 className="title-table text-xl font-semibold text-black dark:text-white">
                  Thông báo chung
                </h1>
              </div>
              {tabNotification === "person" && (
                <span
                  className="text-sm font-medium text-success-500 hover:text-success-600 underline cursor-pointer"
                  onClick={handleViewAllInbox}
                >
                  Đọc tất cả
                </span>
              )}
            </div>
            <div className="flex items-center justify-between mb-4">
              <div className="wrap-tabs flex items-center">
                <button
                  className={classNames(
                    "text-text-secondary dark:text-white/40 px-4 py-3.5 mr-2 text-sm border-b-2 border-transparent font-semibold cursor-pointer hover:text-black dark:hover:text-white",
                    {
                      "!border-success-400 !text-black dark:!text-white":
                        tabNotification === "person",
                    }
                  )}
                  type="button"
                  onClick={() => handleChangeTab("person")}
                >
                  Hộp thư đến
                </button>
                <button
                  className={classNames(
                    "text-text-secondary dark:text-white/40 px-4 py-3.5 text-sm border-b-2 border-transparent font-semibold cursor-pointer hover:text-black dark:hover:text-white",
                    {
                      "!border-success-400 !text-black dark:!text-white":
                        tabNotification === "system",
                    }
                  )}
                  type="button"
                  onClick={() => handleChangeTab("system")}
                >
                  Thông báo
                </button>
              </div>
              {tabNotification === "person" && listUserInbox.length > 0 && (
                <button
                  className="bg-transparent"
                  type="button"
                  onClick={handleShowModalCommonDeleteAll}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-success-500 hover:text-success-600 w-5 h-5"
                  >
                    <path
                      d="M3 6.38597C3 5.90152 3.34538 5.50879 3.77143 5.50879L6.43567 5.50832C6.96502 5.49306 7.43202 5.11033 7.61214 4.54412C7.61688 4.52923 7.62232 4.51087 7.64185 4.44424L7.75665 4.05256C7.8269 3.81241 7.8881 3.60318 7.97375 3.41617C8.31209 2.67736 8.93808 2.16432 9.66147 2.03297C9.84457 1.99972 10.0385 1.99986 10.2611 2.00002H13.7391C13.9617 1.99986 14.1556 1.99972 14.3387 2.03297C15.0621 2.16432 15.6881 2.67736 16.0264 3.41617C16.1121 3.60318 16.1733 3.81241 16.2435 4.05256L16.3583 4.44424C16.3778 4.51087 16.3833 4.52923 16.388 4.54412C16.5682 5.11033 17.1278 5.49353 17.6571 5.50879H20.2286C20.6546 5.50879 21 5.90152 21 6.38597C21 6.87043 20.6546 7.26316 20.2286 7.26316H3.77143C3.34538 7.26316 3 6.87043 3 6.38597Z"
                      fill="currentColor"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.5956 22.0001H12.4044C15.1871 22.0001 16.5785 22.0001 17.4831 21.1142C18.3878 20.2283 18.4803 18.7751 18.6654 15.8686L18.9321 11.6807C19.0326 10.1037 19.0828 9.31524 18.6289 8.81558C18.1751 8.31592 17.4087 8.31592 15.876 8.31592H8.12404C6.59127 8.31592 5.82488 8.31592 5.37105 8.81558C4.91722 9.31524 4.96744 10.1037 5.06788 11.6807L5.33459 15.8686C5.5197 18.7751 5.61225 20.2283 6.51689 21.1142C7.42153 22.0001 8.81289 22.0001 11.5956 22.0001ZM10.2463 12.1886C10.2051 11.7548 9.83753 11.4382 9.42537 11.4816C9.01321 11.525 8.71251 11.9119 8.75372 12.3457L9.25372 17.6089C9.29494 18.0427 9.66247 18.3593 10.0746 18.3159C10.4868 18.2725 10.7875 17.8856 10.7463 17.4518L10.2463 12.1886ZM14.5746 11.4816C14.9868 11.525 15.2875 11.9119 15.2463 12.3457L14.7463 17.6089C14.7051 18.0427 14.3375 18.3593 13.9254 18.3159C13.5132 18.2725 13.2125 17.8856 13.2537 17.4518L13.7537 12.1886C13.7949 11.7548 14.1625 11.4382 14.5746 11.4816Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className={classNames("content-popover")}>
              {tabNotification === "person" &&
                (!loadingSkeleton ? (
                  listUserInbox?.length > 0 ? (
                    listUserInbox?.map((inbox: UserInboxI, index: number) => (
                      <div
                        className={classNames(
                          "inbox-item flex items-center p-2 justify-between rounded bg-surface-tertiary dark:bg-bgSecondary mt-2",
                          {
                            "dark:!bg-primary/10 cursor-pointer":
                              inbox.viewtimes === 0,
                            "!mt-0": index === 0,
                          }
                        )}
                        key={index}
                        onClick={() => handleViewInboxById(inbox.id)}
                      >
                        <div className="flex flex-col">
                          <span
                            className={classNames(
                              "text-[13px] font-medium text-black dark:text-white",
                              { "mb-1": isMobile }
                            )}
                          >
                            {Number(index) + 1 + (currentPage - 1) * sizePage}.
                            <b className="text-success-500 mx-1">
                              {inbox.sender}:
                            </b>
                            {inbox.content}
                          </span>
                          {isMobile && (
                            <span className="text-xs font-medium text-black dark:text-white mr-2">
                              {formatDateBasic(inbox.inserttime)}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center">
                          {!isMobile && (
                            <span className="text-xs font-medium text-black dark:text-white mr-2">
                              {formatDateBasic(inbox.inserttime)}
                            </span>
                          )}
                          {inbox.viewtimes === 0 ? (
                            <div className="w-5 h-5 flex items-center justify-center">
                              <div
                                className={classNames(
                                  "w-2 h-2 bg-transparent rounded-full",
                                  {
                                    "!bg-primary": inbox.viewtimes === 0,
                                  }
                                )}
                              ></div>
                            </div>
                          ) : (
                            <button
                              className="bg-transparent"
                              type="button"
                              onClick={() => handleDeleteInbox(inbox.id)}
                            >
                              <img
                                src={icons.trash}
                                className="w-5 h-5"
                                alt="Xoá thông báo"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data text-base w-full flex justify-center items-center text-black dark:text-white py-4">
                      Chưa có thông báo nào
                    </div>
                  )
                ) : (
                  !isMobile &&
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index: number) => (
                    <div
                      className={classNames("mt-2", { "!mt-0": index === 0 })}
                      key={index}
                    >
                      <SkeletonLoading width="100%" height={36} />
                    </div>
                  ))
                ))}
              {tabNotification === "system" &&
                (!loadingSkeleton ? (
                  listNoti?.length > 0 ? (
                    listNoti?.map((item: dataPromoI, index: number) => (
                      <div
                        className={classNames(
                          "flex flex-col p-2 rounded bg-surface-tertiary dark:bg-bgSecondary mt-2 overflow-hidden",
                          {
                            "!mt-0": index === 0,
                            "hover:bg-gray-200 dark:hover:!bg-gray-900":
                              !isMobile,
                            "active:bg-gray-200 dark:active:bg-gray-900":
                              isMobile,
                          }
                        )}
                        key={index}
                      >
                        <div className="inbox-item flex items-center justify-between">
                          <div className="flex flex-col">
                            <span
                              className={classNames(
                                "text-[13px] font-medium text-black dark:text-white",
                                { "mb-1": isMobile }
                              )}
                            >
                              {Number(index) + 1 + (currentPage - 1) * sizePage}
                              .{" " + item.title}
                            </span>
                            {isMobile && (
                              <div className="flex items-center">
                                <span className="text-xs font-medium text-black dark:text-white mr-1">
                                  {formatDateBasic(item.inserttime)}
                                </span>
                                {moment().isAfter(moment(item.endtevent)) && (
                                  <span className="text-xs font-medium text-error-500">
                                    (Đã hết hạn)
                                  </span>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="flex items-center">
                            {!isMobile && (
                              <>
                                <span className="text-xs font-medium text-black dark:text-white mr-1">
                                  {formatDateBasic(item.inserttime)}
                                </span>
                                {moment().isAfter(moment(item.endtevent)) && (
                                  <span className="text-xs font-medium text-error-500 mr-1">
                                    (Đã hết hạn)
                                  </span>
                                )}
                              </>
                            )}
                            <svg
                              className={`${
                                item.isSelect ? "transform rotate-180" : ""
                              }
                             w-5 h-5 transition-all text-text-secondary dark:text-white/40 duration-200 ease-linear cursor-pointer`}
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() => handleSelectNoti(item.id)}
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          className={classNames(
                            "wrap-content-inbox w-full max-h-0 transition-all duration-300 ease-linear overflow-auto",
                            {
                              "!max-h-[500px] transition-all duration-300 ease-linear":
                                item.isSelect,
                            }
                          )}
                        >
                          <div
                            className="p-4 w-full text-black dark:text-white text-sm content-noti"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                          ></div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data text-base w-full flex justify-center items-center text-black dark:text-white py-4">
                      Chưa có thông báo nào
                    </div>
                  )
                ) : (
                  !isMobile &&
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index: number) => (
                    <div
                      className={classNames("mt-2", { "!mt-0": index === 0 })}
                      key={index}
                    >
                      <SkeletonLoading width="100%" height={36} />
                    </div>
                  ))
                ))}
            </div>
            {totalPage &&
            !isMobile &&
            tabNotification === "person" &&
            totalPage > 1 ? (
              <div className="mt-4">
                <PaginationComponent
                  totalPage={totalPage}
                  changeOffset={changeOffset}
                ></PaginationComponent>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {tabNotification === "person" &&
          isMobile &&
          (loadMore ? (
            <div className="w-full flex items-center justify-center my-4">
              <PuffLoader color="#36d7b7" size={36} />
            </div>
          ) : (
            listUserInbox.length > 15 &&
            dataAvailable && (
              <div className="w-full flex items-center justify-center my-4">
                <span className="text-sm text-white font-medium">
                  Đã tải hết dữ liệu
                </span>
              </div>
            )
          ))}
      </div>
      <Modal
        isShow={showModalPromo}
        onClose={handleCloseModalPromo}
        noClickOutSide
      >
        <ModalPromotion data={dataPromo} onClose={handleCloseModalPromo} />
      </Modal>
      <Modal
        isShow={showModalCommonDelete}
        onClose={() => setShowModalCommonDelete(false)}
        noClickOutSide
      >
        <ModalCommon
          title="Thông báo"
          content="Bạn có muốn xóa tất cả thông báo không?"
          handleClose={() => setShowModalCommonDelete(false)}
          handleSubmit={handleDeleteAllInbox}
        />
      </Modal>
    </div>
  );
}

export default NotificationPage;
