import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const MainPageAI = ({ changePage }: any) => {
  const { isMobile, isTablet, mode } = useSelector(
    (state: any) => state.layout
  );
  const { configSeo } = useSelector((state: any) => state?.seo);
  
  return (
    <div
      className={classNames(
        "w-full h-full bg-mainAILight dark:bg-mainAI bg-cover bg-no-repeat bg-center p-14",
        {
          "!px-5": isTablet,
          "!px-3 !py-8 overflow-auto": isMobile,
        }
      )}
    >
      <div className="w-full flex items-start justify-center">
        <img
          src={require(`assets/images/logo${mode === 1 ? "" : "-light"}.png`)}
          className="h-10"
          alt={configSeo?.title}
        />
      </div>
      <div className="mt-11 mb-[60px]">
        <h1
          className={classNames(
            "font-semibold text-4xl text-center text-linear-primary",
            { "text-linear-primary-dark": mode === 0 }
          )}
        >
          Khám phá và trải nghiệm Trợ Lý Xổ Số AI đầu tiên tại Việt Nam
        </h1>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex flex-col">
          <div className="flex items-center mb-5">
            <img
              src={require(`assets/icons/btn-bolt${
                mode === 1 ? "" : "-light"
              }.png`)}
              className="mr-5 w-16 h-16"
              alt="Soi kèo, tra cứu tỉ lệ nhanh chóng"
            />
            <h3 className="dark:text-white text-black font-semibold text-base">
              Soi kèo, tra cứu tỉ lệ nhanh chóng
            </h3>
          </div>
          <div className="flex items-center mb-5">
            <img
              src={require(`assets/icons/btn-light${
                mode === 1 ? "" : "-light"
              }.png`)}
              className="mr-5 w-16 h-16"
              alt="Phân tích dữ liệu thông minh"
            />
            <h3 className="dark:text-white text-black font-semibold text-base">
              Phân tích dữ liệu thông minh
            </h3>
          </div>
          <div className="flex items-center">
            <img
              src={require(`assets/icons/btn-electron${
                mode === 1 ? "" : "-light"
              }.png`)}
              className="mr-5 w-16 h-16"
              alt="Công nghệ tân tiến, tích hợp liền mạch"
            />
            <h3 className="dark:text-white text-black font-semibold text-base">
              Công nghệ tân tiến, tích hợp liền mạch
            </h3>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-[60px]">
        <button
          className="bg-bgPrimary text-white rounded-[10px] py-3 px-11 hover:opacity-80 border-light-hover"
          type="button"
          onClick={changePage}
        >
          <h2 className="relative z-[3] w-full h-full">Trò chuyện</h2>
        </button>
      </div>
    </div>
  );
};

export default MainPageAI;
