import React, { useEffect, useState } from "react";

const NumberCountSlide = ({ hours, minutes, seconds, size }: any) => {
  const formatTime = (time: any) => {
    return time < 10 ? `0${time}` : `${time}`;
  };

  const hoursStr = hours ? formatTime(hours).split("") : [0, 0];
  const minutesStr = minutes ? formatTime(minutes).split("") : [0, 0];
  const secondsStr = seconds ? formatTime(seconds).split("") : [0, 0];

  useEffect(() => {
    const animated = () => {
      const elements = document.getElementsByClassName("stepper");

      requestAnimationFrame(() => {
        [...elements].forEach((e: any) => {
          const numToStop = e.dataset.num;
          e.style.top = `-${20 * parseInt(numToStop)}px`;
        });
      });
    };

    animated();
  }, [hours, minutes, seconds]);

  return (
    <div className="odometer overflow-hidden h-5 flex">
      {[...hoursStr, ":", ...minutesStr, ":", ...secondsStr].map(
        (value, index) => (
          <Stepper num={value} key={index} size={size} />
        )
      )}
    </div>
  );
};

const Stepper = ({ num, size }: { num: any; size: string }) => {
  if (num === ":") {
    return (
      <div
        className={`stepper-value h-5 leading-5 ${
          size === "big" ? "mx-1" : ""
        }`}
      >
        :
      </div>
    );
  }

  const arr = Array.from(new Array(10));

  return (
    <div
      data-num={num}
      className="stepper flex flex-col relative top-0 transition-all duration-200 ease-linear"
    >
      {arr.map((_, index) => (
        <span className="stepper-value text-center h-5 leading-5" key={index}>
          {index}
        </span>
      ))}
    </div>
  );
};

export default NumberCountSlide;
