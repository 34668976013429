import classNames from "classnames";
import TagComponent from "components/TagComponent/TagComponent";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { copyToClipboard } from "utils/commonFormat";
import { formatDateBasic } from "utils/formateDate";
import { icons } from "utils/icons";
import { currencyFormat } from "../../utils/currencyFormat";

const ListAgentReg = ({
  getAgentId,
  onClose,
  agentid,
  listTopAgent,
  loading,
}: any) => {
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  useEffect(() => {
    if (agentid) {
      getAgentId(agentid);
    }
  }, [agentid]);

  const handleChoiceAgent = (agentid: number) => {
    getAgentId(agentid);
  };

  return (
    <div
      className={classNames(
        "flex flex-col bg-white dark:bg-black border border-borderPrimary rounded-[20px] w-full h-full overflow-hidden",
        { "!rounded-none !border-none": isMobile, "!h-[60vh]": isTablet }
      )}
    >
      {!isMobile && (
        <div className="header py-4 px-5 bg-cardLottery bg-center flex items-center justify-between">
          <h2 className="uppercase text-base font-semibold text-white">
            Top các đại lý uy tín
          </h2>
          {isTablet && (
            <button
              type="button"
              className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
              onClick={onClose}
            >
              <img
                src={require("assets/icons/close.png")}
                className="w-5 h-5"
                alt="Đóng"
              />
            </button>
          )}
        </div>
      )}
      <div className="w-full h-full py-4 px-5 max-h-[calc(100%-56px)] overflow-auto">
        {!loading ? (
          listTopAgent?.length > 0 ? (
            listTopAgent?.map((agent: any, index: number) => (
              <div
                className={classNames(
                  "bg-secondary/10 rounded-[10px] border border-transparent p-4 cursor-pointer",
                  {
                    "mt-2": index > 0,
                    "!border-primary shadow-[0px_0px_0px_4px_#22C55E33]":
                      agent.userid === Number(agentid),
                  }
                )}
                onClick={() => handleChoiceAgent(agent.userid)}
                key={index}
              >
                <div className="flex items-center justify-between mb-1.5">
                  <span
                    className={classNames(
                      "text-base font-semibold text-black dark:text-white flex items-center",
                      { "flex-col !items-start": isMobile }
                    )}
                  >
                    {agent.loginname}{" "}
                    <div className={`${!isMobile ? "ml-2" : "mt-1"}`}>
                      <TagComponent title={agent.tag} type="warning" />
                    </div>
                  </span>
                  <div
                    className={classNames("flex items-center", {
                      "flex-col !items-start": isMobile,
                    })}
                  >
                    <span className="text-sm text-text-secondary dark:text-white/40 mr-1">
                      Mã đại lý:
                    </span>
                    <span
                      className={classNames(
                        "flex items-center text-sm text-black dark:text-white",
                        { "mt-1": isMobile }
                      )}
                    >
                      {agent.userid}
                      <img
                        src={icons.copy}
                        className="w-6 h-6 ml-1 active transition-all duration-200 scale-100 active:scale-110"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(agent.userid);
                        }}
                        alt="Sao chép"
                      />
                    </span>
                  </div>
                </div>
                <div className="flex items justify-between">
                  <div
                    className={classNames("flex items-center", {
                      "flex-col !items-start": isMobile,
                    })}
                  >
                    <span className="text-sm text-text-secondary dark:text-white/40 mr-1">
                      Ngày tham gia:
                    </span>
                    <span className="text-sm text-black dark:text-white">
                      {formatDateBasic(agent.addtime)}
                    </span>
                  </div>
                  <div
                    className={classNames("flex items-center", {
                      "flex-col !items-start": isMobile,
                    })}
                  >
                    <span className="text-sm text-text-secondary dark:text-white/40 mr-1">
                      Thành viên:
                    </span>
                    <span className="text-sm text-black dark:text-white">
                      {currencyFormat(agent.totaluser)}
                    </span>
                  </div>
                </div>
                <div className="flex items mt-1.5">
                  <p className="text-sm text-black dark:text-white">
                    {agent.bio}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full flex justify-center text-black dark:text-white">
              Chưa có dữ liệu
            </div>
          )
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <ClipLoader color="#22C55E" size={50} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListAgentReg;
