import classNames from "classnames";
import { bottomSheetI } from "interfaces/common.interface";
import { useEffect, useRef, useState } from "react";
import { icons } from "utils/icons";
import "./BottomSheet.scss";

function BottomSheet(props: bottomSheetI) {
  const {
    isShow,
    onClose,
    children,
    title,
    noClickOutSide,
    onScrollToBottom,
    direction,
    height,
  } = props;

  const darkmode = localStorage.getItem("darkMode");
  const bottomSheetRef = useRef<HTMLDivElement>(null);
  const [bottomSheetStyle, setBottomSheetStyle] = useState<any>({});

  useEffect(() => {
    if (bottomSheetRef.current && isShow) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isShow]);

  useEffect(() => {
    setBottomSheetStyle({
      height: height ? height : "70%",
    });
  }, [isShow, direction, height]);

  useEffect(() => {
    if (isShow && bottomSheetRef.current && onScrollToBottom) {
      bottomSheetRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (!isShow && bottomSheetRef.current && onScrollToBottom) {
        bottomSheetRef.current.scrollTop = 0;
        bottomSheetRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isShow, onScrollToBottom]);

  const handleScroll = () => {
    if (bottomSheetRef.current) {
      const contentHeight = bottomSheetRef.current.scrollHeight;
      const scrollPosition = bottomSheetRef.current.scrollTop;
      const bottomSheetHeight = bottomSheetRef.current.clientHeight;
      if (scrollPosition + bottomSheetHeight >= contentHeight) {
        onScrollToBottom(true);
      } else {
        onScrollToBottom(false);
      }
    }
  };

  const handleCloseBottomSheet = () => {
    if (!noClickOutSide) {
      onClose();
    }
  };

  return (
    <>
      <div
        className={classNames("backdrop", {
          "!visible !opacity-100 !z-[40]": isShow,
        })}
        onClick={handleCloseBottomSheet}
      ></div>

      <div
        className={classNames("bottom-sheet flex items-end justify-center", {
          dark: darkmode === "true",
          "!bottom-0": isShow,
        })}
        style={bottomSheetStyle}
      >
        <div
          className={`bottom-sheet-content w-full h-full sm:max-w-sm md:max-w-md lg:max-w-md ${
            height === "100%" ? "" : "rounded-tr-[10px] rounded-tl-[10px]"
          } overflow-hidden`}
        >
          {title && title !== "" && (
            <div
              className={`bottom-sheet-header w-full flex items-center justify-between bg-white dark:bg-bgPrimary border-b border-borderLightPrimary dark:border-borderPrimary py-3 px-4 ${
                height === "100%" ? "" : "rounded-tr-[10px] rounded-tl-[10px]"
              }`}
            >
              <span className="text-primary text-md font-semibold">
                {title}
              </span>
              <button
                className="close-button p-1 rounded-lg bg-secondary/10"
                onClick={() => onClose()}
                type="button"
              >
                <img
                  src={require("assets/icons/close.png")}
                  className="w-5 h-5"
                  alt="Đóng"
                />
              </button>
            </div>
          )}
          <div
            className="bottom-sheet-body w-full h-full bg-white dark:bg-bgPrimary"
            ref={bottomSheetRef}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default BottomSheet;
