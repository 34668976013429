import classNames from "classnames";
import SideBar from "components/SideBar/SideBar";
import { toggleSidebar } from "features/layoutSlice";
import { setConfigSeo } from "features/seoSlice";
import Header from "partials/Header/Header";
import MenuSidebar, { listMenuSidebar } from "partials/MenuSidebar/MenuSidebar";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { set } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { homeRoutes } from "routes/routes";

function HomeLayout() {
  const dispatch = useDispatch();
  const { isMobile, isTablet, showSidebar, isMiniDesktop } = useSelector(
    (state: any) => state.layout
  );
  const { configSeo } = useSelector((state: any) => state?.seo);

  const pathname = useLocation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const parts = pathname.pathname.split("/");
    const strSlice = parts[2] || "";
    const nameLottery = listMenuSidebar?.find(
      (item: any) => String(item?.url) === String(strSlice)
    );
    dispatch(
      setConfigSeo({
        ...configSeo,
        title: `${nameLottery?.name} - Trợ lý xổ số hàng đầu Việt Nam`,
      })
    );

  }, [pathname]);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-layout w-full h-screen dark:bg-bgLayout bg-surface-primary flex">
      {(isMobile || isTablet || isMiniDesktop) && (
        <>
          <SideBar />
          <div
            className={classNames(
              "bg-black/40 opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
              {
                "!z-30 opacity-80 duration-150 ease-linear !visible":
                  showSidebar,
              }
            )}
            onClick={() => dispatch(toggleSidebar(false))}
          ></div>
        </>
      )}
      <div className="content-layout-secondary flex-1 w-full">
        <div
          className={classNames("main-header w-full h-[84px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-10 w-full min-w-[100vw]">
            <Header type="home"></Header>
          </div>
        </div>
        <div
          className={`main-content flex justify-center w-full ${
            isTablet && isMobile ? "h-[calc(100vh-32px)] overflow-y-auto" : ""
          } bg-surface-primary dark:bg-bgLayout ${
            isTablet ? "p-4" : isMobile ? "" : "p-8"
          }`}
        >
          <div className="max-w-[1440px] w-full flex">
            {!isMobile && (
              <div className="w-[256px] mr-4">
                <MenuSidebar></MenuSidebar>
              </div>
            )}
            <Routes>
              {homeRoutes?.map((route, index) => {
                const SubPage = route.component;
                return (
                  <Route key={index} path={route.path} element={<SubPage />} />
                );
              })}
              <Route path="*" element={<Navigate to={"/ket-qua"} replace />} />
            </Routes>
            {isMobile && isVisible && (
              <button
                className="btn-scroll p-2 rounded-full bg-success-500 outline-none fixed bottom-5 right-5"
                type="button"
                onClick={handleScrollTop}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-white"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.46967 14.5303C5.17678 14.2374 5.17678 13.7626 5.46967 13.4697L11.4697 7.46967C11.7626 7.17678 12.2374 7.17678 12.5303 7.46967L18.5303 13.4697C18.8232 13.7626 18.8232 14.2374 18.5303 14.5303C18.2374 14.8232 17.7626 14.8232 17.4697 14.5303L12 9.06066L6.53033 14.5303C6.23744 14.8232 5.76256 14.8232 5.46967 14.5303Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLayout;
