import classNames from "classnames";
import ContactPage from "pages/ContactPage/ContactPage";
import Header from "partials/Header/Header";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { commonRoutes } from "routes/routes";

const CommonLayout = () => {
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  return (
    <div className="main-layout w-full h-full bg-surface-primary dark:bg-bgLayout flex">
      <div className="content-layout-secondary flex-1 w-full min-h-screen">
        <div
          className={classNames("main-header w-full h-[84px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-30 w-full min-w-[100vw]">
            <Header type="landing"></Header>
          </div>
        </div>
        <div
          className={`main-content flex items-center justify-center w-full ${
            isTablet && isMobile
              ? "h-[calc(100vh-56px)] overflow-y-auto"
              : "h-[calc(100vh-84px)]"
          } bg-surface-primary dark:bg-bgLayout`}
        >
          <Routes>
            {commonRoutes?.map((route, index) => {
              const SubPage = route.component;
              return (
                <Route key={index} path={route.path} element={<SubPage />} />
              );
            })}
            <Route path="*" element={<Navigate to={"/ket-qua"} replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
