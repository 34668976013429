import BetTypeMobileComponent from "components/BetTypeComponent/BetTypeMobileComponent";
import { useState } from "react";
import { icons } from "utils/icons";

function ModalBetLottery(props: any) {
  const { listMethod, getType, onClose, methodActivated } = props;

  const [methodActive, setMethodActive] = useState<any>();

  const handleGetType = (method: any) => {
    setMethodActive(method);
  };

  const handleSubmitMethod = () => {
    getType(methodActive);
    onClose();
  };

  return (
    <div className="modal-bet-lottery bg-white dark:bg-surface-second rounded-lg overflow-hidden shadow-[0px_4px_24px_0px_#00000033] h-[400px] max-w-[400px]">
      <div className="header-modal px-5 py-4 flex items-center justify-between border-b border-divider dark:border-divider-dark">
        <span className="w-full text-base text-success-500 font-semibold">
          Chọn kiểu chơi
        </span>
        <img
          src={icons.closePrimary}
          className="w-4 h-4"
          alt="Đóng"
          onClick={onClose}
        />
      </div>
      <div className="content-modal h-[275px] flex-1 overflow-auto">
        <BetTypeMobileComponent
          listMethod={listMethod}
          getType={handleGetType}
          methodActive={methodActivated}
        />
      </div>
      <div className="bottom-modal w-full flex items-center justify-end p-4">
        <button
          type="button"
          className="px-4 py-2 rounded bg-success-500 hover:bg-success-600 text-sm font-semibold text-white ml-3"
          onClick={handleSubmitMethod}
        >
          Xác nhận
        </button>
      </div>
    </div>
  );
}

export default ModalBetLottery;
