import axiosInstance from "./axiosInstance";
import {
  paramsGetReportUserI,
  paramsTransactionUserI,
} from "../interfaces/api.interface";

const userApi = {
  getUserInfo() {
    const url = `/api/v1/user/current`;
    return axiosInstance.get(url);
  },
  getBalanceUser() {
    const url = `/api/v1/user/balance`;
    return axiosInstance.get(url);
  },
  getAllReportUser(params: paramsGetReportUserI) {
    const url = "/api/v1/user/report/allbetwin/day";
    return axiosInstance.get(url, { params });
  },
  getTransactionUser(params: paramsTransactionUserI) {
    const url = `/api/v1/user/transactions/user`;
    return axiosInstance.get(url, { params });
  },
  getTransactionStatus() {
    const url = `/api/v1/transaction/constant/transtype`;
    return axiosInstance.get(url);
  },
  getListMenuTrees() {
    const url = "/api/v1/user/menutrees";
    return axiosInstance.get(url);
  },
  updateUserPassword(params: any) {
    const url = "/api/v1/user/changecurrentpw";
    return axiosInstance.put(url, null, { params });
  },
  getListTopAgent() {
    const url = "/api/v1/user/public/topagent";
    return axiosInstance.get(url);
  },
};

export default userApi;
