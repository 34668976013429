import { useDispatch, useSelector } from "react-redux";
import "./ToastMobileComponent.scss";
import { useEffect, useState } from "react";
import { setShowToast } from "features/commonSlice";
import classNames from "classnames";
import { icons } from "utils/icons";

interface toastI {
  position: "top" | "bottom";
  closeOnClick?: boolean;
  pauseOnFocusLoss?: boolean;
  autoClose?: number;
  hideProgressBar?: boolean;
  draggable?: boolean;
  theme?: "light" | "dark";
  style?: React.CSSProperties;
}

function ToastMobileComponent(props: toastI) {
  const { position, closeOnClick, autoClose, theme, style } = props;
  const dispatch = useDispatch();
  const { toast } = useSelector((state: any) => state.common);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, autoClose);

    return () => {
      clearTimeout(timer);
    };
  }, [autoClose, toast]);

  const handleClose = () => {
    dispatch(
      setShowToast({ show: false, type: toast.type, message: toast.message })
    );
  };

  const handlePauseOnFocusLoss = () => {
    setIsPaused(true);
  };

  const handleResumeOnFocusGain = () => {
    setIsPaused(false);
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData("text/plain", "moving");
  };

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      onFocus={handleResumeOnFocusGain}
      onBlur={handlePauseOnFocusLoss}
      className={classNames(
        `toast ${position ? position : "top"} ${theme ? theme : "light"} ${
          toast.type
        } flex ${
          position === "top" ? "items-end" : "item-start"
        } transition-all duration-300 ease-linear max-h-0 overflow-hidden`,
        {
          "!max-h-[120px] transition-all duration-300 ease-linear": toast.show,
        }
      )}
      style={{
        ...style,
      }}
    >
      <div className="toast-content p-4 w-full flex justify-between items-center z-[9999999999]">
        <span className="toast-message text-base text-white mr-3">
          {toast?.message ? toast?.message : ""}
        </span>
        {closeOnClick && (
          <button className="close-button w-8 h-8" onClick={handleClose}>
            <img src={icons.closeWhite} className="w-full h-full" alt="Đóng" />
          </button>
        )}
      </div>
      {!isPaused && (
        <div
          className="progress-bar"
          style={{ animationDuration: `${autoClose}ms` }}
        />
      )}
    </div>
  );
}

export default ToastMobileComponent;
