import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: null,
    loginname: "",
    balance: null,
  },
  reducers: {
    addUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    addLoginname(state, action) {
      state.loginname = action.payload;
    },
    addBalanceUser(state, action){
      state.balance = action.payload;
    }
  },
});

const { actions, reducer } = userSlice;

export const { addUserInfo, addLoginname, addBalanceUser } = actions;
export default reducer;
