import classNames from "classnames";
import NumberCountSlide from "components/NumberCountSlide/NumberCountSlide";
import { useEffect, useState } from "react";
import { getCurrentDateTime } from "utils/date";

function CountDownComponent(props: any) {
  const { startTime, endTime, check, size } = props;

  const caculateTimeRemaining = () => {
    if (endTime) {
      const currentTime = new Date(getCurrentDateTime()).getTime();
      const difference = endTime - currentTime;
      if (difference <= 0) {
        check(true);
        return { hours: 0, minutes: 0, seconds: 0 };
      } else if (difference > 0) {
        check(false);
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        return { hours, minutes, seconds };
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const [timeRemaining, setTimeRemaining] = useState<any>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(caculateTimeRemaining());
    }, 1000);
    return () => {
      check(false);
      clearInterval(interval);
    };
  }, [startTime, endTime]);

  return (
    <div className="countdown flex items-center">
      <span
        className={classNames(`whitespace-nowrap font-semibold`, {
          "!text-error-500":
            timeRemaining?.minutes < 1 &&
            timeRemaining?.seconds < 10 &&
            timeRemaining?.hours < 1,
        })}
      >
        <NumberCountSlide
          size={size}
          hours={timeRemaining?.hours}
          minutes={timeRemaining?.minutes}
          seconds={timeRemaining?.seconds}
        />
      </span>
    </div>
  );
}

export default CountDownComponent;
