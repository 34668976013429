import classNames from "classnames";
import { useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { formatDateBasic } from "utils/formateDate";
import { getTitleTransStatus } from "utils/lotteryFormat";
import { dataListTransactionI } from "../../interfaces/data.interface";

function TransactionTable(props: any) {
  const { data, loading, showDetail, listStatusTransaction } = props;
  const { isMobile } = useSelector((state: any) => state.layout);

  return (
    <div
      className={classNames("transaction-table mb-4", { "!mb-0": isMobile })}
    >
      <div className="table-history-container w-full roundedd">
        <div className="table-wrap w-full">
          <div className="body-table w-full">
            {data.length > 0 ? (
              data.map((item: dataListTransactionI, index: number) => (
                <div
                  className={classNames(
                    "body-table-item flex flex-col px-4 py-2 rounded-[10px] mt-3 bg-white dark:bg-bgPrimary shadow-table cursor-pointer",
                    {
                      "pointer-events-none": item.referenceid === null,
                      "!mt-0": index === 0,
                    }
                  )}
                  key={index}
                  onClick={(e) => showDetail(item, e)}
                >
                  <div className="flex items-start justify-between text-sm mb-2">
                    <div className="flex flex-col font-medium">
                      <span className="text-black dark:text-white">
                        {item.id}
                      </span>
                    </div>
                    <div>
                      <span className="text-text-secondary dark:text-white/40">
                        {formatDateBasic(item.opttime)}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-start text-sm mb-2">
                    <div className="flex items-center font-medium">
                      <span className="text-black dark:text-white mr-2">
                        Loại biến động:
                      </span>
                      <span
                        className={`text-${
                          item.pay > 0 ? "error" : "success"
                        }-500 text-end font-semibold`}
                      >
                        {getTitleTransStatus(
                          listStatusTransaction,
                          item.transtype
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-start justify-between text-sm">
                    <div className="flex items-center text-sm font-medium">
                      <span className="text-text-secondary dark:text-white/40 mr-2">
                        Số điểm:
                      </span>
                      <span
                        className={classNames(
                          `text-${item.pay > 0 ? "error" : "success"}-500`
                        )}
                      >
                        {item.pay
                          ? "-" + currencyFormat(item.pay)
                          : item.income
                          ? "+" + currencyFormat(item.income)
                          : 0}
                      </span>
                    </div>
                    <div className="flex items-center text-sm font-medium">
                      <span className="text-text-secondary dark:text-white/40 mr-2">
                        Số dư sau:
                      </span>
                      <span className="text-black dark:text-white">
                        {item.afterbalance
                          ? currencyFormat(item.afterbalance)
                          : 0}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data px-2.5 py-2.5 flex items-center justify-center border-borderLightPrimary dark:border-borderPrimary">
                <span className=" text-xs font-semibold text-black dark:text-white">
                  Chưa có biến động nào
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionTable;
