import classNames from "classnames";
import React from "react";

interface PropsI {
  title: string;
  type: "info" | "success" | "error" | "warning";
}

const TagComponent = (props: PropsI) => {
  const { title, type } = props;
  return (
    <div
      className={classNames(
        "py-1 px-2 rounded text-xs font-semibold flex items-center justify-center",
        {
          "bg-warning-500/[15%] text-warning-500": type === "warning",
          "bg-success-500/[15%] text-success-500": type === "success",
          "bg-error-500/[15%] text-error-500": type === "error",
          "bg-gray-500/[15%] text-gray-500": type === "info",
        }
      )}
    >
      {title}
    </div>
  );
};

export default TagComponent;
