import userApi from "apis/userApi";
import classNames from "classnames";
import InputComponent from "components/InputComponent/InputComponent";
import Modal from "components/Modal/Modal";
import ModalCommon from "components/ModalCommon/ModalCommon";
import { setLoading } from "features/commonSlice";
import useToast from "hooks/useToast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const InfoPage = () => {
  const dispatch = useDispatch();
  const toastify = useToast();

  const { userInfo } = useSelector((state: any) => state.user);
  const { isMobile } = useSelector((state: any) => state.layout);

  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    currentpw: null,
    newpw: null,
    newpw2: null,
  });

  const handleSubmitUpdatePassword = async () => {
    let params = { currentpw: formData.currentpw, newpw: formData.newpw };
    try {
      const { data } = await userApi.updateUserPassword(params);
      if (data.error_code === 0) {
        toastify("Đổi mật khẩu thành công!", "success");
      }
    } catch (error: any) {
      console.log(error);
      // if (!error.msg) toastify(error.msg, "error");
    } finally {
      setShowModalConfirm(false);
      setStatusFilter(true);
      setTimeout(() => {
        setStatusFilter(false);
      }, 50);
    }
  };

  const updateFormData = (field: any, value: any, type?: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleChangeIdInput = (value: string, type: string) => {
    const valueInput = value;
    if (valueInput !== null) {
      updateFormData(valueInput, type || null);
    } else {
      updateFormData(null, type);
    }
  };

  const handleShowModalConfirmUpdatePassword = () => {
    if (formData.currentpw === formData.newpw) {
      toastify("Mật khẩu mới không được giống mật khẩu cũ!", "warning");
      return;
    }

    if (!formData.newpw || !formData.newpw2 || !formData.currentpw) {
      toastify("Vui lòng nhập đầy đủ!", "warning");
      return;
    }

    if (formData.newpw !== formData.newpw2) {
      toastify("Mật khẩu xác nhận không trùng với mật khẩu mới!", "warning");
      return;
    }
    setShowModalConfirm(true);
  };

  return (
    <div
      className={classNames("w-full flex justify-center", { "!p-4": isMobile })}
    >
      <div className="flex flex-col w-full max-w-[608px]">
        <div className="header-page mb-4">
          <h1 className="font-semibold text-black dark:text-white text-xl">
            Thông tin cá nhân
          </h1>
        </div>
        <div className="flex items-center justify-between px-4 py-2 bg-white dark:bg-bgPrimary rounded-[10px]">
          <div className="flex flex-col text-xs font-medium">
            <span className="text-text-secondary dark:text-black dark:text-white/40 mb-1">
              ID User
            </span>
            <span className="text-black dark:text-white">{userInfo?.id}</span>
          </div>
          <div className="flex flex-col text-xs font-medium">
            <span className="text-text-secondary dark:text-black dark:text-white/40 mb-1">
              Tên tài khoản
            </span>
            <span className="text-black dark:text-white">
              {userInfo?.loginname}
            </span>
          </div>
          <div></div>
          {/* <div className="flex flex-col text-xs font-medium">
            <span className="text-text-secondary dark:text-black dark:text-white/40 mb-1">
              Tổng thầu
            </span>
            <span className="text-black dark:text-white">
              {userInfo?.masteragent}
            </span>
          </div> */}
        </div>
        <div className="header-page my-4">
          <h3 className="font-semibold text-black dark:text-white text-xl">
            Đổi mật khẩu
          </h3>
        </div>
        <div className="flex flex-col px-4 py-2 bg-white dark:bg-bgPrimary rounded-[10px]">
          <div className="flex items-center">
            <div className="flex-col items-start flex w-full mr-1">
              <InputComponent
                handleChange={handleChangeIdInput}
                label="Mật khẩu cũ"
                title="Mật khẩu cũ*"
                typeInput="password"
                type="currentpw"
                reset={statusFilter}
                width="w-full"
              />
            </div>
            <div className="flex-col items-start flex w-full ml-1">
              <InputComponent
                handleChange={handleChangeIdInput}
                label="Mật khẩu mới"
                title="Mật khẩu mới*"
                typeInput="password"
                type="newpw"
                reset={statusFilter}
                width="w-full"
              />
            </div>
          </div>
          <div className="flex items-end mt-3">
            <div className="flex-col items-start w-full flex mr-1">
              <InputComponent
                handleChange={handleChangeIdInput}
                label="Xác nhận Mật khẩu"
                title="Mật khẩu xác nhận*"
                typeInput="password"
                type="newpw2"
                reset={statusFilter}
                width="w-full"
              />
            </div>
            <div className="w-full ml-1 flex items-end justify-end">
              <button
                type="button"
                onClick={handleShowModalConfirmUpdatePassword}
                className="px-4 py-2 bg-primary hover:bg-primary/80 rounded-[10px] text-sm font-semibold text-white"
              >
                Đổi mật khẩu
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isShow={showModalConfirm}
        onClose={() => setShowModalConfirm(false)}
      >
        <ModalCommon
          title="Xác nhận"
          content="Bạn có chắc chắn muốn đổi mật khẩu mới không?"
          handleClose={() => setShowModalConfirm(false)}
          handleSubmit={handleSubmitUpdatePassword}
        />
      </Modal>
    </div>
  );
};

export default InfoPage;
