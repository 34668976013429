import classNames from "classnames";
import CountDownComponent from "components/CountDownComponent/CountDownComponent";
import { setUpdateResult } from "features/lotterySlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentDateTime } from "utils/date";
import { formatDateBasic } from "utils/formateDate";
interface ILotteryTodayItem {
  name: string;
  endtime: string;
  goGame?: any;
  image: string;
  gameid: number;
  winnumber: string | null; // Thêm dấu ? để chỉ định winnumber có thể là undefined
  getReload?: any; // Thêm dấu ? để chỉ định winnumber có thể là undefined
}

export default function LotteryTodayItem(props: ILotteryTodayItem) {
  const { name, endtime, goGame, winnumber, getReload, image, gameid } = props;

  const dispatch = useDispatch();

  const { gameId } = useSelector((state: any) => state.lottery);

  const [digits, setDigits] = useState<string[]>([]);
  const [statusShowResult, setShowStatusShowResult] = useState<boolean>(false);

  useEffect(() => {
    // Kiểm tra nếu có winnumber thì sử dụng nó, ngược lại sử dụng số ngẫu nhiên
    if (winnumber) {
      setShowStatusShowResult(true);
      setDigits(winnumber.split(""));
      getReload(false);
    } else {
      setShowStatusShowResult(false);
    }
  }, [winnumber]);

  useEffect(() => {
    if (winnumber && gameid === gameId) {
      dispatch(setUpdateResult(true));
    }
  }, [winnumber, gameId]);

  const handleCheckReload = (status: boolean) => {
    if (status === true && !statusShowResult) {
      getReload(true);
    }
  };

  return (
    <div
      className={`w-full rounded-xl bg-cardLottery min-h-[116px] bg-cover relative overflow-hidden cursor-pointer`}
    >
      <img
        src={image}
        className="absolute top-0 left-0 w-full h-full object-fill z-0 max-w-full"
        alt={name}
      />
      <div className="relative z-10">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between py-1.5 px-3">
            <span className="text-white text-sm text-center text-wrap line-clamp-1">
              {name}
            </span>
            <span className="text-white/40 text-xs flex gap-1 items-center justify-center">
              {formatDateBasic(endtime)}
            </span>
          </div>
          <div className="w-full py-1 flex items-center justify-center">
            {statusShowResult ? (
              digits.map((digit, index) => (
                <span
                  key={index}
                  className="rounded-[10px] flex justify-center items-center bg-primary/30 text-white w-9 h-9 mx-0.5"
                >
                  {digit ? digit : "-"}
                </span>
              ))
            ) : (
              <div className="w-fit flex justify-center items-center px-2.5 py-2 bg-primary/30 rounded-lg text-lg leading-7 text-white">
                <div
                  className={classNames("hidden", {
                    "!block":
                      new Date(endtime).getTime() >
                      new Date(getCurrentDateTime()).getTime(),
                  })}
                >
                  <CountDownComponent
                    size="big"
                    endTime={new Date(endtime).getTime()}
                    check={handleCheckReload}
                  />
                </div>

                {new Date(endtime).getTime() <
                  new Date(getCurrentDateTime()).getTime() && (
                  <span className="text-base text-white font-semibold">
                    Đang xổ kết quả
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="bg-bg-gray p-1.5">
          <div className="w-full flex items-center justify-center">
            <button
              type="button"
              className="text-white text-xs px-4 py-1.5 flex items-center justify-center bg-primary hover:bg-primary/80 active:bg-primary/80 rounded-[10px]"
              onClick={goGame}
            >
              Chơi ngay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
