import { useState } from "react";
import { formatDateTime, formatToIOString } from "utils/formateDate";
import DateFilter from "./components/DateFilter/DateFilter";
import { getEndDateTime, getStartDateTime } from "utils/date";

// const listChoiceDate = [
//   {
//     id: 1,
//     desc: "Hôm nay",
//     name: "today",
//   },
//   {
//     id: 2,
//     desc: "Hôm qua",
//     name: "yesterday",
//   },

//   {
//     id: 3,
//     desc: "Trong 3 ngày",
//     name: "last3Days",
//   },
//   {
//     id: 4,
//     desc: "Trong 1 tuần",
//     name: "lastWeek",
//   },
//   {
//     id: 5,
//     desc: "Trong 1 tháng",
//     name: "lastMonth",
//   },
// ];

const getDateRange = (option: string) => {
  let today = new Date();
  let startDate;
  let endDate = new Date(today);

  switch (option) {
    case "today":
      startDate = new Date(today.setHours(0, 0, 0, 0));
      endDate = new Date(today);
      break;
    case "yesterday":
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 1);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
      break;
    case "last3Days":
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 2);
      startDate.setHours(0, 0, 0, 0);
      break;
    case "lastWeek":
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 6);
      startDate.setHours(0, 0, 0, 0);
      break;
    case "lastMonth":
      startDate = new Date(today);
      startDate.setMonth(today.getMonth() - 1);
      startDate.setHours(0, 0, 0, 0);
      break;
    default:
      console.error("Invalid option");
      return;
  }

  return {
    startDate: formatDateTime(startDate.toString()),
    endDate: formatDateTime(endDate.toString()),
  };
};

const DatePickerFilter = (props: any) => {
  const { onChange, width, type, reset } = props;
  // const [typeDateChoice, setTypeDateChoice] = useState<string>("today");
  // const [dateRange, setDateRange] = useState<any>(getDateRange("today"));

  // useEffect(() => {
  //   if (reset) {
  //     setDateRange(getDateRange("today"));
  //   }
  // }, [reset]);

  const handleGetDate = (type: string, date: string) => {
    if (type === "start") {
      onChange("fromdate", formatToIOString(new Date(date)));
    } else {
      onChange("todate", formatToIOString(new Date(date)));
    }
  };

  return (
    <div className="flex">
      <div
        className={`flex ${
          type === "horizontal" ? "items-center mr-3" : "flex-col w-full"
        }`}
      >
        <div
          className={`flex flex-col w-full ${
            type === "horizontal" ? "mr-3" : "mb-3"
          }`}
        >
          <span className="text-text-secondary dark:text-white/40 text-xs font-semibold text-start mb-1">
            Từ
          </span>
          <DateFilter
            onChange={handleGetDate}
            type="start"
            value={getStartDateTime()}
            width={width}
            reset={reset}
          />
        </div>
        <div className="flex flex-col w-full">
          <span className="text-text-secondary dark:text-white/40 text-xs font-semibold text-start mb-1">
            Đến
          </span>
          <DateFilter
            onChange={handleGetDate}
            type="end"
            value={getEndDateTime()}
            width={width}
            reset={reset}
          />
        </div>
      </div>
    </div>
  );
};

export default DatePickerFilter;
