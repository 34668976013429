import classNames from "classnames";
import { betLotteryI } from "interfaces/lottery.interface";
import { useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";
import { formatCodeBreakWord } from "utils/lotteryFormat";

function ModalBetMobile(props: any) {
  const { onClose, clearTicket, handleSubmit, ticket, typeModalBet, issue } =
    props;
  const { listTickets } = useSelector((state: any) => state.lottery);

  return (
    <div className="modal-bet-mobile w-[95%] max-w-[430px]">
      <div className="modal-bet-container rounded-lg overflow-hidden bg-surface-primary dark:bg-bgPrimary shadow-[0px_4px_24px_0px_#00000033]">
        <div className="header-modal flex items-center justify-between px-4 py-2 bg-cardLottery bg-center bg-no-repeat bg-cover">
          <span className="title-modal text-white text-sm flex items-center font-semibold">
            Chi Tiết Vé Chơi{" "}
            {issue && <p className="text-sm ml-1">({issue?.issue})</p>}
          </span>
          <button
            type="button"
            className="btn-close cursor-pointer"
            onClick={onClose}
          >
            <img src={icons.closeWhite} className="w-6 h-6" alt="Đóng" />
          </button>
        </div>
        <div className="content-modal">
          <div className="max-h-72 p-4 overflow-auto">
            <div className="table-wrap">
              {typeModalBet === "betList" ? (
                listTickets?.length > 0 &&
                listTickets?.map((item: betLotteryI, index: number) => (
                  <div
                    className={classNames(
                      "body-table-item flex flex-col pb-2 border-b border-borderLightPrimary dark:border-borderPrimary mt-3",
                      { "!mt-0": index === 0 }
                    )}
                    key={index}
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex justify-start items-center w-6/12">
                        <span className="text-text-secondary text-start text-xs font-semibold">
                          Tên kiểu chơi
                        </span>
                      </div>
                      <div className="flex justify-end items-center w-6/12">
                        <span className=" text-end text-xs font-semibold text-black dark:text-white">
                          {item.methodName}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex justify-start items-center w-5/12">
                        <span className="text-text-secondary text-start text-xs font-semibold">
                          Số chơi
                        </span>
                      </div>
                      <div className="flex justify-end items-center w-7/12 overflow-hidden">
                        <span className=" w-full text-end text-xs font-semibold text-black dark:text-white overflow-auto">
                          {formatCodeBreakWord(
                            item.codes,
                            item.codes?.split(",")?.length > 200 ? 100 : 10
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex justify-start items-center w-6/12">
                        <span className="text-text-secondary text-start text-xs font-semibold">
                          Tổng số chơi
                        </span>
                      </div>
                      <div className="flex justify-end items-center w-6/12">
                        <span className=" text-end text-xs font-semibold text-black dark:text-white">
                          {item.betnumber} số
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex justify-start items-center w-6/12">
                        <span className="text-text-secondary text-start text-xs font-semibold">
                          Cấp số nhân
                        </span>
                      </div>
                      <div className="flex justify-end items-center w-6/12">
                        <span className=" text-end text-xs font-semibold text-black dark:text-white">
                          {item.multiple}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex justify-start items-center w-6/12">
                        <span className="text-text-secondary text-start text-xs font-semibold">
                          Tỉ lệ
                        </span>
                      </div>
                      <div className="flex justify-end items-center w-6/12">
                        <span className="text-error-500 text-end text-xs font-semibold">
                          1 : {item.odd}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex justify-start items-center w-6/12">
                        <span className="text-text-secondary text-start text-xs font-semibold">
                          Điểm chơi
                        </span>
                      </div>
                      <div className="flex justify-end items-center w-6/12">
                        <span className="text-warning-500 text-end text-xs font-semibold">
                          {currencyFormat(item.amount)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={classNames("body-table-item flex flex-col")}>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex justify-start items-center w-6/12">
                      <span className="text-text-secondary text-start text-xs font-semibold">
                        Tên kiểu chơi
                      </span>
                    </div>
                    <div className="flex justify-end items-center w-6/12">
                      <span className=" text-end text-xs font-semibold text-black dark:text-white">
                        {ticket?.methodName}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex justify-start items-center w-5/12">
                      <span className="text-text-secondary text-start text-xs font-semibold">
                        Số chơi
                      </span>
                    </div>
                    <div className="flex justify-end items-center w-7/12 overflow-hidden">
                      <span className=" w-full text-end text-xs font-semibold text-black dark:text-white overflow-auto">
                        {formatCodeBreakWord(
                          ticket?.codes,
                          ticket?.codes.split(",")?.length > 200 ? 100 : 10
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex justify-start items-center w-6/12">
                      <span className="text-text-secondary text-start text-xs font-semibold">
                        Tổng số chơi
                      </span>
                    </div>
                    <div className="flex justify-end items-center w-6/12">
                      <span className=" text-end text-xs font-semibold text-black dark:text-white">
                        {ticket?.betnumber} số
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex justify-start items-center w-6/12">
                      <span className="text-text-secondary text-start text-xs font-semibold">
                        Cấp số nhân
                      </span>
                    </div>
                    <div className="flex justify-end items-center w-6/12">
                      <span className=" text-end text-xs font-semibold text-black dark:text-white">
                        {ticket?.multiple}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex justify-start items-center w-6/12">
                      <span className="text-text-secondary text-start text-xs font-semibold">
                        Tỉ lệ
                      </span>
                    </div>
                    <div className="flex justify-end items-center w-6/12">
                      <span className="text-error-500 text-end text-xs font-semibold">
                        {ticket?.odd}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex justify-start items-center w-6/12">
                      <span className="text-text-secondary text-start text-xs font-semibold">
                        Điểm chơi
                      </span>
                    </div>
                    <div className="flex justify-end items-center w-6/12">
                      <span className="text-warning-500 text-end text-xs font-semibold">
                        {currencyFormat(ticket?.amount)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-modal p-2 flex items-center justify-end border-t border-borderLightPrimary dark:border-borderPrimary">
          <div className="wrap-btn flex items-center">
            <button
              type="button"
              className="bg-secondary/20 flex items-center justify-center text-primary hover:opacity-80 px-3 py-2.5 rounded-[10px] text-sm font-semibold mr-2"
              onClick={() => clearTicket()}
            >
              Huỷ
            </button>
            <button
              type="button"
              className="text-sm font-semibold border border-transparent rounded-[10px] bg-primary text-white hover:bg-primary/80 px-3 py-2"
              onClick={handleSubmit}
            >
              Đặt vé
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalBetMobile;
