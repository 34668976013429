import classNames from "classnames";
import SideBar from "components/SideBar/SideBar";
import { toggleSidebar } from "features/layoutSlice";
import { setConfigSeo } from "features/seoSlice";
import AIChatPage from "pages/AIChatPage/AIChatPage";
import Header from "partials/Header/Header";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ChatAILayout = () => {
  const dispatch = useDispatch();
  const { isMobile, isTablet, showSidebar, isMiniDesktop } = useSelector(
    (state: any) => state.layout
  );
  const { configSeo } = useSelector((state: any) => state?.seo);
  useEffect(() => {
    dispatch(
      setConfigSeo({
        ...configSeo,
        title: `Trò chuyện với AI - Trợ lý xổ số hàng đầu Việt Nam`,
        description:
          "Khám phá Trợ Lý Xổ Số AI hàng đầu Việt Nam - Nơi bạn có thể trò chuyện, soi kèo, và tra cứu tỷ lệ xổ số nhanh chóng. Với công nghệ tân tiến và phân tích dữ liệu thông minh, chúng tôi mang đến trải nghiệm người dùng liền mạch và hiệu quả.",
        keywords:
          "trò chuyện với AI, trợ lý xổ số, soi kèo, tra cứu tỷ lệ xổ số, công nghệ tân tiến, phân tích dữ liệu",
      })
    );
  }, []);

  return (
    <div className="main-layout w-full h-screen bg-surface-tertiary dark:bg-[#131313] flex">
      {(isMobile || isTablet || isMiniDesktop) && (
        <>
          <SideBar />
          <div
            className={classNames(
              "bg-black/40 opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
              {
                "!z-30 opacity-80 duration-150 ease-linear !visible":
                  showSidebar,
              }
            )}
            onClick={() => dispatch(toggleSidebar(false))}
          ></div>
        </>
      )}
      <div className="content-layout-secondary flex-1 w-full min-h-screen">
        <div
          className={classNames("main-header w-full h-[84px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-10 w-full min-w-[100vw]">
            <Header type="home"></Header>
          </div>
        </div>
        <div
          className={`main-content flex justify-center w-full ${
            isMobile ? "h-[calc(100vh-56px)]" : ""
          } bg-surface-tertiary dark:bg-[#131313] ${
            isTablet ? "p-4" : isMobile ? "" : "p-8"
          }`}
        >
          <div className="max-w-[1440px] w-full h-full flex justify-center">
            <AIChatPage />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatAILayout;
