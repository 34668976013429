import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import "./ChoiceMethodBet.scss";

interface numsListI {
  number: string;
  isActive: boolean;
  isDisable: boolean;
}

interface choiceRandomI {
  id: number;
  title: string;
  start: string;
  end: string;
  listNums: numsListI[];
  isActive: boolean;
  isShow: boolean;
  count: number;
  numberTicket: string;
}

interface numberRandomI {
  id: number;
  number: number;
  isShow: boolean;
  isActive: boolean;
}

const listRandomNumber: numberRandomI[] = [
  {
    id: 1,
    number: 10,
    isShow: false,
    isActive: false,
  },
  {
    id: 2,
    number: 20,
    isShow: false,
    isActive: false,
  },
  {
    id: 3,
    number: 30,
    isShow: false,
    isActive: false,
  },
  {
    id: 4,
    number: 40,
    isShow: false,
    isActive: false,
  },
  {
    id: 5,
    number: 50,
    isShow: false,
    isActive: false,
  },
];

const listRandomNumber2: numberRandomI[] = [
  {
    id: 1,
    number: 1,
    isShow: false,
    isActive: false,
  },
];

const listChoiceRandom: choiceRandomI[] = [
  {
    id: 1,
    title: "00 - 99",
    start: "0",
    end: "99",
    listNums: [],
    isActive: true,
    isShow: true,
    count: 0,
    numberTicket: "",
  },
  {
    id: 2,
    title: "100 - 199",
    start: "100",
    end: "199",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 3,
    title: "200 - 299",
    start: "200",
    end: "299",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 4,
    title: "300 - 399",
    start: "300",
    end: "399",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 5,
    title: "400 - 499",
    start: "400",
    end: "499",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 6,
    title: "500 - 599",
    start: "500",
    end: "599",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 7,
    title: "600 - 699",
    start: "600",
    end: "699",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 8,
    title: "700 - 799",
    start: "700",
    end: "799",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 9,
    title: "800 - 899",
    start: "800",
    end: "899",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
  {
    id: 10,
    title: "900 - 999",
    start: "900",
    end: "999",
    listNums: [],
    isActive: false,
    isShow: false,
    count: 0,
    numberTicket: "",
  },
];

function ChoiceMethodBet(props: any) {
  const { methodType, getCodes, statusBet } = props;

  const listChoiceRef = useRef(null);
  const [listRandom, setListRandom] =
    useState<numberRandomI[]>(listRandomNumber); // List random số chọn từ 10 - 50
  const [listNumberChoice, setListNumberChoice] = useState<any>([]); // list number đã chọn
  const [listChoice, setListChoice] =
    useState<choiceRandomI[]>(listChoiceRandom); // list config random number
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [checkMethod, setCheckMethod] = useState<boolean>(false);
  const [checkMethod2, setCheckMethod2] = useState<boolean>(false);
  const [statusRemoveBet, setStatusRemoveBet] = useState<boolean>(true);

  useEffect(() => {
    const isCheckMethod =
      methodType === "LO3SO" ||
      methodType === "3CANGDAU" ||
      methodType === "3CANGDAUMB" ||
      methodType === "3CANGDAUDUOI" ||
      methodType === "3CANGDAUDUOIMB" ||
      methodType === "3CANGDACBIET";
    setCheckMethod(isCheckMethod);
    const isCheckMethod2 =
      methodType === "XIEN2" ||
      methodType === "XIEN3" ||
      methodType === "XIEN4" ||
      methodType === "LOTRUOT4SO" ||
      methodType === "LOTRUOT8SO" ||
      methodType === "LOTRUOT10SO";
    let indexActive = 0;
    const listNumsArrayChoice = listChoice.map((item) => {
      const arrayNumberChoice = Array.from({ length: 100 }, (_, index) => ({
        number:
          Number(item.start) + index < 10
            ? `0${Number(item.start) + index}`
            : `${Number(item.start) + index}`,
        isActive: false,
        isDisable: false,
      }));
      item.listNums = arrayNumberChoice;
      if (isCheckMethod) {
        item.isShow = item.id !== 1;
        item.isActive = item.id === 2;
        indexActive = 1;
      } else {
        item.isActive = item.id === 1;
        item.isShow = item.id === 1;
        indexActive = 0;
      }
      return item;
    });
    if (isCheckMethod2) {
      setListRandom(listRandomNumber2);
      setCheckMethod2(true);
    } else {
      setListRandom(listRandomNumber);
      setCheckMethod2(false);
    }
    setListChoice(listNumsArrayChoice);
    setListNumberChoice(listNumsArrayChoice[indexActive]?.listNums);
  }, [methodType]);

  useEffect(() => {
    if (statusBet === false) {
      setStatusRemoveBet(statusBet);
      if (statusRemoveBet === false) {
        handleRemoveSelected();
      }
    }
  }, [statusBet]);

  useEffect(() => {
    const newData = listChoice.map((item): any => {
      if (item.isActive === true) {
        item.listNums = listNumberChoice;
      }
      const count = handleCountLenghtActiveNumber(item?.listNums);
      return { ...item, count };
    });

    setListChoice(newData);
  }, [listNumberChoice]);

  // Hàm đếm length number actived
  const handleCountLenghtActiveNumber = (array: any) => {
    let arrayCount = array.filter((num: any) => num.isActive === true).length;
    if (checkMethod2) {
      arrayCount = arrayCount > 0 ? 1 : 0;
    }
    return arrayCount;
  };

  // Hàm chọn số ngẫu nhiên theo số lượng vd: 10
  const handleSelectCountNumber = (numId: number, num: number) => {
    const listNewRandom = listRandom.map((item: any) => {
      item.isActive = item.id === numId ? true : false;
      return item;
    });
    setListRandom(listNewRandom);

    const listNumberChoiceRandom = [...listNumberChoice].sort(
      () => Math.random() - 0.5
    );
    let listSelectNumber: any;
    if (checkMethod) {
      listSelectNumber = handleConvertNumberChoiceRandom3(num);
    } else {
      listSelectNumber = handleConvertNumberChoiceRandom(
        listNumberChoiceRandom,
        num
      );
    }

    const updatedArray = listNumberChoice.map((obj: any) => ({
      ...obj,
      isActive: listSelectNumber.some(
        (selectedObj: any) => selectedObj.number === obj.number
      ),
    }));
    setListNumberChoice(handleConvertDisable(updatedArray));
  };

  // Hàm chọn số random theo bộ từ 00 - 99
  const handleConvertNumberChoiceRandom = (array: any, num: number) => {
    let listSelectNumber;
    if (!checkMethod2) {
      listSelectNumber = array.slice(0, num);
    } else {
      if (methodType === "XIEN2") {
        listSelectNumber = array.slice(0, 2);
      } else if (methodType === "XIEN3") {
        listSelectNumber = array.slice(0, 3);
      } else if (methodType === "XIEN4" || methodType === "LOTRUOT4SO") {
        listSelectNumber = array.slice(0, 4);
      } else if (methodType === "LOTRUOT8SO") {
        listSelectNumber = array.slice(0, 8);
      } else if (methodType === "LOTRUOT10SO") {
        listSelectNumber = array.slice(0, 10);
      }
    }
    handleGenerateTicket(listSelectNumber, 1);
    return listSelectNumber;
  };

  // Hàm chọn random toàn bộ số từ 000 - 999
  const handleConvertNumberChoiceRandom3 = (num: number) => {
    const getListNumsAll = listChoice
      .filter((choice: choiceRandomI) => choice.id !== 1)
      .map((item: choiceRandomI) => item.listNums.map((num) => num.number));
    const listNumsAll = getListNumsAll.join(",").split(",");
    const listNumsAllChoiceRandom = [...listNumsAll].sort(
      () => Math.random() - 0.5
    );
    const listMergeChoice = listChoice.map((item: choiceRandomI) => {
      const listNumberActive = item.listNums.map((choice: any) => ({
        ...choice,
        isActive: listNumsAllChoiceRandom
          .slice(0, num)
          .some((nums: any) => choice.number === nums),
      }));
      const listNumsTicket = listNumberActive
        .filter((item: numberRandomI) => item.isActive)
        .map((item: numberRandomI) => item.number);
      item.listNums = listNumberActive;
      item.numberTicket = listNumsTicket.join(",");
      return item;
    });
    const listMergeNumberChoice = listNumberChoice.filter(
      (item: numberRandomI) =>
        listNumsAllChoiceRandom
          .slice(0, num)
          .some((num: any) => item.number === num)
    );
    handleGenerateTicket(listMergeChoice, 2);
    setListChoice(listMergeChoice);
    return listMergeNumberChoice;
  };

  //Hàm xoá tất cả số đã chọn
  const handleRemoveSelected = () => {
    const removeListNumber = listChoice.map((item: any) => {
      const removeListNums = item.listNums.map((item: numsListI) => ({
        ...item,
        isActive: false,
        isDisable: false,
      }));
      return { ...item, listNums: removeListNums, numberTicket: "" };
    });
    const removeListChoiceNumber = listNumberChoice.map((item: numsListI) => {
      item.isActive = false;
      item.isDisable = false;
      return item;
    });
    setListChoice(removeListNumber);
    setListNumberChoice(removeListChoiceNumber);
    getCodes("", 0);
  };

  // Hàm chọn khoảng số vd: 00-99
  const handleChoiceDistanceNumber = (id: number) => {
    const newListChoice = listChoice.map((item: any) => {
      item.isActive = item.id === id ? true : false;
      return item;
    });

    const newListNumberChoice = newListChoice.find(
      (item: any) => item.id === id
    );
    setListChoice(newListChoice);
    setListNumberChoice(newListNumberChoice.listNums);
  };

  // Hàm chọn số đơn
  const handleChoiceNumber = (num: number) => {
    let listNewNumberChoice: any = [];
    listNewNumberChoice = listNumberChoice.map((item: any) => {
      if (item.number === num) {
        item.isActive = !item.isActive;
      }
      return item;
    });

    if (checkMethod2) {
      setListNumberChoice(handleConvertDisable(listNewNumberChoice));
    } else {
      setListNumberChoice(listNewNumberChoice);
    }
    handleGenerateTicket(handleMergeNumberChoice(listNewNumberChoice), 2);
  };

  // Hàm merge list số đã chọn vào list số tổng
  const handleMergeNumberChoice = (array: any) => {
    const listNumberFilter = listChoice.map((item: choiceRandomI) => {
      if (item.isActive === true) {
        const listNumsActive = array.filter((item: numsListI) => item.isActive);
        item.listNums = array;
        item.count = listNumsActive.length;
        item.numberTicket = listNumsActive
          .map((item: numsListI) => item.number)
          .join(",");
      }
      item.numberTicket = item.numberTicket.length > 0 ? item.numberTicket : "";

      return item;
    });
    return listNumberFilter;
  };

  // Hàm convert trạng thái disable theo method cược
  const handleConvertDisable = (array: any) => {
    const arrayActive = array.filter((item: any) => item.isActive);
    let resultArray;
    if (methodType === "XIEN2") {
      resultArray = handleConvertArrayDisable(array, arrayActive?.length, 2);
    } else if (methodType === "XIEN3") {
      resultArray = handleConvertArrayDisable(array, arrayActive?.length, 3);
    } else if (methodType === "XIEN4" || methodType === "LOTRUOT4SO") {
      resultArray = handleConvertArrayDisable(array, arrayActive?.length, 4);
    } else if (methodType === "LOTRUOT8SO") {
      resultArray = handleConvertArrayDisable(array, arrayActive?.length, 8);
    } else if (methodType === "LOTRUOT10SO") {
      resultArray = handleConvertArrayDisable(array, arrayActive?.length, 10);
    } else {
      resultArray = array;
    }
    return resultArray;
  };

  // Hàm convert lại Array khi đủ điều kiện disable
  const handleConvertArrayDisable = (
    array: any,
    length: number,
    numberDisable: number
  ) => {
    const arrayConvert = array.map((item: any) => {
      if (length === numberDisable) {
        item.isDisable = item.isActive === false;
      } else {
        item.isDisable = false;
      }
      return item;
    });
    return arrayConvert;
  };

  // Hàm tạo vé cược
  const handleGenerateTicket = (array: any, type: number) => {
    // type: 1 là cược đơn, 2 là cược nhiều số ngẫu nhiên\
    let listTicketFilter =
      type === 2
        ? array
            .filter((item: choiceRandomI) => item.numberTicket !== "")
            .map((item: choiceRandomI) => item.numberTicket)
        : array.map((item: numberRandomI) => item.number);
    let codesTicket;
    if (checkMethod2) {
      codesTicket = listTicketFilter.join(",").replace(/,/g, "-");
    } else {
      codesTicket = listTicketFilter.join(",");
    }
    const listTicketsFormat = listTicketFilter.join(",").split(",");
    let statusCodeLength = false;
    if (listTicketsFormat.length === 1 && listTicketsFormat[0] === "") {
      statusCodeLength = true;
    } else {
      statusCodeLength = false;
    }
    const lengthCodes = checkMethod2
      ? handleIsCheckGenerateTicket(listTicketFilter)
      : !statusCodeLength
      ? listTicketsFormat.length
      : 0;
    getCodes(codesTicket, lengthCodes);
  };

  // Hàm check generate ticket cho riêng loại lô xiên và lô trượt
  const handleIsCheckGenerateTicket = (array: string[]): number => {
    const lengthArray = array.join(",").split(",").length;
    let result = 0;
    if (methodType === "XIEN2") {
      result = lengthArray === 2 ? 1 : 0;
    } else if (methodType === "XIEN3") {
      result = lengthArray === 3 ? 1 : 0;
    } else if (methodType === "XIEN4" || methodType === "LOTRUOT4SO") {
      result = lengthArray === 4 ? 1 : 0;
    } else if (methodType === "LOTRUOT8SO") {
      result = lengthArray === 8 ? 1 : 0;
    } else if (methodType === "LOTRUOT10SO") {
      result = lengthArray === 10 ? 1 : 0;
    }
    return result;
  };

  const handlePrevSlide = () => {
    const current = currentSlide === 0 ? 0 : currentSlide - 1;
    setCurrentSlide(current);
  };

  const handleNextSlide = () => {
    const current = currentSlide === 10 ? 0 : currentSlide + 1;
    setCurrentSlide(current);
  };

  return (
    <div className="choice-method-bet w-full">
      <div className="list-random-wrap flex flex-col w-full">
        <div className="header-list-random flex items-center w-full">
          <div className="wrap-number-random flex items-center rounded overflow-hidden">
            {listRandom.length > 0 &&
              listRandom.map((num: any, index: number) => (
                <button
                  type="button"
                  className={classNames(
                    "wrap-number text-text-secondary dark:text-white/40 p-2 text-sm font-semibold rounded-sm mr-1 hover:text-black dark:hover:text-white"
                  )}
                  key={index}
                  onClick={() => handleSelectCountNumber(num.id, num.number)}
                >
                  {num.number} số
                </button>
              ))}
          </div>
          <button
            className="btn-remove bg-primary/[6%] hover:bg-primary/10 rounded-[10px] text-sm p-1.5 ml-2"
            type="button"
            onClick={() => handleRemoveSelected()}
          >
            <img
              src={require("assets/icons/trash.png")}
              className="icon-trash w-5 h-5"
              alt="Huỷ chọn số"
            />
          </button>
        </div>
        <div className="list-number-choice mt-2 mb-6 flex items-center w-full">
          {checkMethod && (
            <button className="btn-nav" type="button" onClick={handlePrevSlide}>
              <GrFormPrevious
                fontSize={24}
                className="text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white"
              />
            </button>
          )}

          <div className="wrap-slides-number overflow-hidden w-full">
            <div
              className="tab-list-wrap flex items-center flex-nowrap flex-1 max-w-[300px]"
              style={{
                transform: `translateX(-${currentSlide * 30}%)`,
                transition: `0.3s linear`,
              }}
              ref={listChoiceRef}
            >
              {listChoice.length > 0 &&
                listChoice.map((item) => (
                  <div className="wrap-btn-choice relative" key={item.id}>
                    <button
                      className={classNames(
                        "btn-choice-random px-4 py-2 text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white border-b border-transparent mr-2 text-nowrap",
                        {
                          "!text-primary !border-primary": item.isActive,
                          none: !item.isShow,
                        }
                      )}
                      onClick={() => handleChoiceDistanceNumber(item.id)}
                    >
                      {item.title}
                      {item.count > 0 && (
                        <p className="count-num px-1 py-1 rounded">
                          {item.count}
                        </p>
                      )}
                    </button>
                  </div>
                ))}
            </div>
          </div>
          {checkMethod && (
            <button className="btn-nav" type="button" onClick={handleNextSlide}>
              <GrFormNext
                fontSize={24}
                className="text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white"
              />
            </button>
          )}
        </div>
        <div className="list-number-random flex flex-wrap w-full gap-2.5">
          {listNumberChoice?.length > 0 &&
            listNumberChoice.map((item: any, index: number) => (
              <button
                className={classNames(
                  "btn-number w-11 h-11 py-2 px-2.5 text-text-secondary dark:text-white/40 bg-white dark:bg-bgPrimary text-sm rounded-full hover:bg-ballActive hover:text-white dark:hover:bg-ballActive dark:hover:text-white transition-all duration-150 ease-out",
                  {
                    "!bg-ballActive !text-white": item.isActive,
                    disable: item.isDisable,
                  }
                )}
                type="button"
                key={index}
                onClick={() => handleChoiceNumber(item.number)}
              >
                {item.number}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ChoiceMethodBet;
