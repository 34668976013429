import moment from "moment";
import { getCurrentDateTime } from "./date";
import { dayWeeks } from "constant";

export const formatDate = (value: string) => {
  const date = moment(value);
  const formattedDate = `${date.format("DD/MM/yyyy")} \n ${date.format(
    "HH:mm:ss"
  )}`;
  return formattedDate;
};

export const formatDayInWeeks = (valueDate: number) => {
  return dayWeeks[valueDate];
};

export const formatDateBasic = (value: string) => {
  const date = moment(value).format("DD/MM/yyyy");
  const splitDate = date.split(" ");
  const dateWithNewline = splitDate.join("\n");
  return dateWithNewline;
};

export const formatDateTimeBasic = (value: string) => {
  const date = moment(value).format("DD/MM/yyyy HH:mm:ss");
  return date;
};

export const formatPeriodBet = (value: string) => {
  const period = value.slice(11);
  const result = Number(period) >= 10 ? period : `0${period}`;
  return result;
};

export const formatToIOString = (time: Date) => {
  const year = time.getFullYear();
  const month = (time.getMonth() + 1).toString().padStart(2, "0");
  const day = time.getDate().toString().padStart(2, "0");
  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");

  // Tạo định dạng mới
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  return formattedDate;
};

export const formatDateTime = (time: string) => {
  // Chuyển đổi inputDateTimeString thành một đối tượng Date
  const dateTime = new Date(time);

  // Kiểm tra nếu đối tượng Date không hợp lệ
  if (isNaN(dateTime.getTime())) {
    return "Invalid date";
  }

  // Lấy các thành phần ngày, tháng, năm, giờ, phút, giây
  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  // Tạo chuỗi mới theo định dạng mong muốn
  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
};

export const checkDateChoiceToday = (dateChoice: string): boolean => {
  const givenTimeVN = new Date(dateChoice);
  const currentTimeVN = new Date(getCurrentDateTime());
  const isToday =
    givenTimeVN.getFullYear() === currentTimeVN.getFullYear() &&
    givenTimeVN.getMonth() === currentTimeVN.getMonth() &&
    givenTimeVN.getDate() === currentTimeVN.getDate();
  return isToday;
};

export const checkDateBigAvailabilityToday = (dateChoice: string): boolean => {
  const givenTimeVN = new Date(dateChoice);
  const currentTimeVN = new Date(getCurrentDateTime());

  return givenTimeVN.getTime() > currentTimeVN.getTime();
};

export const compareDate = (date1: any, date2: any, type: string = "equal") => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const onlyDate1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const onlyDate2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
  switch (type) {
    case "greater":
      return onlyDate1 > onlyDate2;
    case "less":
      return onlyDate1 < onlyDate2;
    default:
      return onlyDate1.getTime() === onlyDate2.getTime();
  }
};
