import userApi from "apis/userApi";
import classNames from "classnames";
import DateRangeComponent from "components/DateRangeComponent/DateRangeComponent";
import PaginationComponent from "components/PaginationComponent/PaginationComponent";
import SkeletonLoading from "components/SkeletonLoading/SkeletonLoading";
import moment from "moment";
import { useEffect, useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { PuffLoader } from "react-spinners";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { findDataByType } from "utils/commonFormat";
import { currencyFormat } from "utils/currencyFormat";
import {
  getCurrentDateTimeFormatBasic,
  getMidnightDateTimeFormatBasic,
} from "utils/date";
import { formatGetNameLottery } from "utils/lotteryFormat";
import BottomSheet from "components/BottomSheet/BottomSheet";
import DetailReportBet from "./DetailReportBet";

import { setLoading, setLoadingTopBar } from "features/commonSlice";
import {
  dataBetWinReportI,
  dataItemGameReportI,
  dataReportI,
} from "interfaces/data.interface";
import { icons } from "utils/icons";
import FilterReport from "components/FilterComponent/FilterReport";

interface LotteryTypeTotal {
  totalBet: number;
  totalWin: number;
}

export const listMenuBetting = [
  // {
  //   id: 5,
  //   title: "Xổ số Live",
  //   type: "xslive",
  //   isGame: true,
  // },
  {
    id: 2,
    title: "Xổ số truyền thống",
    type: "xstt",
    isGame: true,
  },
  // {
  //   id: 3,
  //   title: "Xổ Số VIP",
  //   type: "xsvip",
  //   isGame: true,
  // },
  {
    id: 4,
    title: "Xổ Số Nhanh",
    type: "xsn",
    isGame: true,
  },
  // {
  //   id: 6,
  //   title: "Game Dân Gian",
  //   type: "folk",
  //   isGame: true,
  // },
  // {
  //   id: 8,
  //   title: "Thể Thao",
  //   type: "sport",
  //   isGame: true,
  // },
  // {
  //   id: 9,
  //   title: "Live Casino",
  //   type: "casino",
  //   isGame: true,
  // },
  // {
  //   id: 10,
  //   title: "Game Slot",
  //   type: "slot",
  //   isGame: true,
  // },
];

function ReportAllBetPage() {
  const dispatch = useDispatch();

  const { isMobile, mode } = useSelector((state: any) => state.layout);
  const { listGames } = useSelector((state: any) => state.lottery);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [statusFilter, setStatusFilter] = useState<boolean>(false);
  const [totalBet, setTotalBet] = useState<number>(0);
  const [totalWin, setTotalWin] = useState<number>(0);
  const [listReportUser, setListReportUser] = useState<dataReportI[]>([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const [dataTotal, setDataTotal] = useState<any>();
  const [dataDetail, setDataDetail] = useState<any>(null);
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const [showBottomSheetDetail, setShowBottomSheetDetail] =
    useState<boolean>(false);
  const [formDataPagination, setFormDataPagination] = useState<any>();
  const [showFilterBottomSheet, setShowFilterBottomSheet] =
    useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    fromDate: getMidnightDateTimeFormatBasic(),
    toDate: getCurrentDateTimeFormatBasic(),
  });

  useEffect(() => {
    handleGetReportUser(0, "search");
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      if (atBottom && Number(currentPage) < Number(totalPages)) {
        setDataAvailable(true);
        if (!loadMore)
          handleGetReportUser(
            currentPage === 0 ? currentPage + 1 : currentPage,
            "pagi"
          );
      }
    }
  }, [atBottom, totalPages]);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  useEffect(() => {
    const totalsByLotteryType: { [key: string]: LotteryTypeTotal } = {};
    listReportUser.forEach((item: any) => {
      item.betwin.forEach((lottery: any) => {
        const { bet, win, type } = lottery;
        if (!totalsByLotteryType[type]) {
          totalsByLotteryType[type] = { totalBet: 0, totalWin: 0 };
        }
        totalsByLotteryType[type].totalBet += bet;
        totalsByLotteryType[type].totalWin += win;
      });
    });
    setDataTotal(totalsByLotteryType);
  }, [listReportUser]);

  const updateFormData = (field: any, value: any, type?: any) => {
    setFormData({
      ...formData,
      [field]: value !== "" ? value : null,
    });
    setFormDataPagination({
      ...formDataPagination,
      [field]: value !== "" ? value : null,
    });
  };

  const changeOffset = (newOffset: any) => {
    setFormData((prevParams: any) => ({
      ...prevParams,
      offset: newOffset,
    }));
    handleGetReportUser(newOffset, "pagi");
  };

  const handleGetReportUser = async (
    offset?: number | 0,
    typeCall?: string
  ) => {
    if (isMobile) {
      setLoadMore(true);
    }
    if (typeCall === "filter") {
      dispatch(setLoadingTopBar(true));
    }
    if (typeCall === "search") {
      dispatch(setLoading(true));
    }
    let payload =
      typeCall === "pagi"
        ? {
            ...formDataPagination,
            pageSize: isMobile ? 15 : 10,
            offset: offset,
          }
        : {
            ...formData,
            pageSize: isMobile ? 15 : 10,
            offset: typeCall === "filter" ? 0 : offset,
          };
    try {
      const { data } = await userApi.getAllReportUser({
        fromDate: payload.fromdate
          ? moment(new Date(payload.fromdate)).format("YYYY-MM-DD")
          : getMidnightDateTimeFormatBasic(),
        toDate: payload.todate
          ? moment(new Date(payload.todate)).format("YYYY-MM-DD")
          : getCurrentDateTimeFormatBasic(),
        pageSize: isMobile ? 15 : 10,
        offset: offset,
      });
      if (data.error_code === 0) {
        const dataRes = data.data.allBetWinDay;
        if (!isMobile || typeCall === "search" || typeCall === "filter") {
          setListReportUser(dataRes.content);
        } else {
          setListReportUser((prevList: any) => [
            ...prevList,
            ...dataRes.content,
          ]);
        }
        setCurrentPage(Number(dataRes.number) + 1);
        setTotalBet(data.data.totalBet);
        setTotalWin(data.data.totalWin);
        setTotalPages(dataRes.totalPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadMore(false);
      setLoadingSkeleton(false);
      dispatch(setLoading(false));
      if (typeCall === "filter") {
        setShowFilterBottomSheet(false);
        setFormDataPagination(formData);
      }
      dispatch(setLoadingTopBar(false));
    }
  };

  const handleCloseBottomSheetDetail = () => {
    setShowBottomSheetDetail(false);
    setDataDetail(null);
  };

  const handleShowBottomSheetDetail = (data: any) => {
    if (isMobile) {
      setDataDetail(data);
      setShowBottomSheetDetail(true);
    } else {
      return;
    }
  };

  const HtmlTooltip = styled(
    ({ className, onClose, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )(({ theme }) => ({
    [`& .MuiTooltip-arrow`]: {
      color: `#F79009`, // Thay đổi màu sắc tại đây
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: mode === 1 ? `#000` : "#F0FDF4",
      color: mode === 1 ? `#ececec` : "#737373",
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: `1px solid transparent`,
      boxShadow: `#00000033`,
    },
  }));

  const renderTooltipContent = (
    filteredItems: dataBetWinReportI[],
    typeGame: any
  ) => {
    const dataFilter = filteredItems.filter(
      (item: any) => item.type === typeGame
    );
    if (dataFilter.length > 0) {
      return (
        <HtmlTooltip
          arrow={true}
          placement={"left"}
          className={`${mode && "dark"}`}
          title={
            <table className="table-auto w-full my-1 rounded bg-surface-primary dark:bg-bgLayout border border-borderLightPrimary dark:border-borderPrimary">
              <thead className="bg-surface-tertiary dark:bg-black">
                <tr className="grid grid-cols-3 items-center justify-center text-warning-500">
                  <th className="col-span-1 text-center border-r border-borderLightPrimary dark:border-borderPrimary py-1 px-3">
                    Trò chơi
                  </th>
                  <th className="col-span-1 text-center border-r border-borderLightPrimary dark:border-borderPrimary py-1 px-3">
                    Chơi
                  </th>
                  <th className="col-span-1 text-center py-1 px-3">Thắng</th>
                </tr>
              </thead>
              <tbody>
                {dataFilter?.map((filteredItem: dataBetWinReportI) =>
                  filteredItem?.details?.map(
                    (detail: dataItemGameReportI, detailIndex: number) =>
                      detail.bet !== 0 && (
                        <tr
                          key={detailIndex}
                          className={classNames(
                            "grid grid-cols-3 bg-white dark:bg-bgPrimary items-center justify-center border-t border-borderLightPrimary dark:border-borderPrimary text-black dark:text-white"
                          )}
                        >
                          <td className="col-span-1 text-center border-r border-borderLightPrimary dark:border-borderPrimary py-1 px-3 ">
                            {formatGetNameLottery(detail.gameid, listGames)}
                          </td>
                          <td className="col-span-1 flex items-center justify-center h-full text-center border-r border-borderLightPrimary dark:border-borderPrimary py-1 px-3">
                            {detail.bet ? currencyFormat(detail.bet) : 0}
                          </td>
                          <td className="col-span-1 flex items-center justify-center h-full text-center py-1 px-3">
                            {detail.win ? currencyFormat(detail.win) : 0}
                          </td>
                        </tr>
                      )
                  )
                )}
              </tbody>
            </table>
          }
        >
          <span
            className={classNames(
              " text-xs text-center cursor-pointer text-black dark:text-white whitespace-nowrap w-[95%]",
              {
                "pointer-events-none":
                  findDataByType(dataFilter, typeGame) === "0/0",
              }
            )}
            onClick={() => handleShowBottomSheetDetail(dataFilter)}
          >
            {findDataByType(dataFilter, typeGame)}
          </span>
        </HtmlTooltip>
      );
    } else {
      return (
        <span className=" text-xs text-center text-white whitespace-nowrap w-[95%] pointer-events-none">
          {findDataByType(dataFilter, typeGame)}
        </span>
      );
    }
  };

  const handleClearFilter = () => {
    setFormData({
      fromDate: null,
      toDate: null,
    });
    setStatusFilter(true);
    setCurrentPage(0);

    setTimeout(() => {
      setStatusFilter(false);
    }, 500);
  };

  return (
    <div className={classNames("w-full")}>
      <div
        className={classNames(
          "history-page flex justify-center w-full bg-surface-primary dark:bg-bgLayout",
          {
            "flex-col !p-4": isMobile,
          }
        )}
      >
        <div className={classNames("rounded-md flex-1 sm:m-0")}>
          <div className="table-history-container w-full">
            <div className="flex flex-col w-full h-full">
              <div className="title-table-wrap flex items-center justify-between">
                <h1 className="title-table text-xl font-semibold text-black dark:text-white">
                  Thống Kê
                </h1>
                {isMobile && (
                  <button
                    className="bg-secondary/10 active:bg-secondary/20 rounded-[10px] p-2.5"
                    type="button"
                    onClick={() => setShowFilterBottomSheet(true)}
                  >
                    <img src={icons.filter} className="w-5 h-5" alt="Thống Kê" />
                  </button>
                )}
              </div>
            </div>
            {isMobile && (
              <div className="flex flex-col my-4">
                <div className="flex flex-col">
                  <div className="total-bet-wrap flex items-center justify-between bg-secondary/10 py-2 px-3 border text-white border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm mb-3">
                    <span className="text-black dark:text-white mr-2">
                      Tổng điểm chơi:
                    </span>
                    <span className="text-warning-500 font-semibold">
                      {totalBet ? currencyFormat(totalBet) : 0}
                    </span>
                  </div>
                  <div className="total-bet-wrap flex items-center justify-between bg-secondary/10 py-2 px-3 border text-white border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm mb-3">
                    <span className="text-black dark:text-white mr-2">
                      Tổng điểm trúng:
                    </span>
                    <span className="text-warning-500 font-semibold">
                      {totalWin ? currencyFormat(totalWin) : 0}
                    </span>
                  </div>
                  <div className="total-bet-wrap flex items-center justify-between bg-secondary/10 py-2 px-3 border text-white border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm mb-3">
                    <span className="text-black dark:text-white mr-2">
                      Tổng lợi nhuận:
                    </span>
                    <span
                      className={classNames("text-warning-500 font-semibold", {
                        "!text-success-500": totalWin - totalBet > 0,
                        "!text-error-500": totalWin - totalBet < 0,
                      })}
                    >
                      {totalBet > 0 || totalWin > 0
                        ? currencyFormat(totalWin - totalBet)
                        : 0}
                    </span>
                  </div>
                </div>
                <span className="text-xs font-medium text-warning-500 italic">
                  Chú ý: Tổng điểm chơi sẽ không bao gồm các vé huỷ
                </span>
              </div>
            )}
            {!isMobile && (
              <FilterReport
                updateFormData={updateFormData}
                statusFilter={statusFilter}
                handleGetReportUser={handleGetReportUser}
                handleClearFilter={handleClearFilter}
              />
            )}
            <div className={classNames("h-full ", { "mt-3": isMobile })}>
              <div
                className={classNames(
                  "table-wrap mb-0 overflow-x-scroll flex rounded-[10px] shadow-table border border-borderLightPrimary dark:border-borderPrimary",
                  {
                    "no-scrollbar": isMobile || listReportUser.length <= 0,
                  }
                )}
              >
                <div className="flex-none min-w-full ">
                  <div className="heade-table grid grid-cols-12 w-full bg-surface-tertiary dark:bg-black">
                    <div className="py-3 flex justify-center items-center border-r border-borderLightPrimary dark:border-borderPrimary col-span-1">
                      <span className="text-primary text-xs text-center font-semibold">
                        Stt
                      </span>
                    </div>
                    <div className="py-3 flex justify-center items-center border-r border-borderLightPrimary dark:border-borderPrimary col-span-3">
                      <span className="text-primary text-xs text-center font-semibold">
                        Thời Gian
                      </span>
                    </div>
                    <div className="py-3 flex justify-center items-center border-r border-borderLightPrimary dark:border-borderPrimary  col-span-4">
                      <span className="text-primary text-xs text-center font-semibold">
                        Xổ Số Truyền Thống
                      </span>
                    </div>
                    <div className="py-3 flex justify-center items-center border-r border-borderLightPrimary dark:border-borderPrimary  col-span-4">
                      <span className="text-primary text-xs text-center font-semibold">
                        Xổ Số Nhanh
                      </span>
                    </div>
                  </div>
                  <div className="body-table w-full">
                    {listReportUser?.length > 0 ? (
                      listReportUser?.map(
                        (item: dataReportI, index: number) => (
                          <div
                            className={classNames(
                              "w-full grid grid-cols-12 border-t border-borderLightPrimary dark:border-borderPrimary hover:bg-gray-200 dark:hover:bg-gray-800 bg-white dark:bg-bgPrimary",
                              {
                                "bg-surface-tertiary dark:!bg-black":
                                  index % 2 !== 0,
                              }
                            )}
                            key={index}
                          >
                            <div className="py-3 flex justify-center items-center col-span-1 border-r border-borderLightPrimary dark:border-borderPrimary">
                              <span className=" text-xs text-center text-black dark:text-white">
                                {index + 1}
                              </span>
                            </div>
                            <div className="py-3 flex justify-center items-center col-span-3 border-r border-borderLightPrimary dark:border-borderPrimary">
                              <span className=" text-xs text-center text-black dark:text-white">
                                {moment(new Date(item.rtime)).format(
                                  "DD/MM/YYYY"
                                )}
                              </span>
                            </div>
                            <div className="py-3 px-2 flex justify-center items-center col-span-4 border-r border-borderLightPrimary dark:border-borderPrimary">
                              {renderTooltipContent(item.betwin, "xstt")}
                            </div>
                            <div className="py-3 px-2 flex justify-center items-center col-span-4 border-r border-borderLightPrimary dark:border-borderPrimary">
                              {renderTooltipContent(item.betwin, "xsn")}
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="no-data w-full px-2.5 py-5 flex items-center justify-center bg-white dark:bg-bgPrimary border-t border-borderLightPrimary dark:border-borderPrimary">
                        <span className="text-sm font-semibold text-black dark:text-white">
                          Chưa có thống kê
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="bottom-table w-full grid grid-cols-12 bg-surface-tertiary dark:bg-bgPrimary border-t border-borderLightPrimary dark:border-borderPrimary ">
                    <div className="py-3 flex justify-center items-center border-r border-borderLightPrimary dark:border-borderPrimary col-span-4">
                      <span className="text-primary text-xs text-center font-semibold">
                        Tổng
                      </span>
                    </div>
                    {listMenuBetting &&
                      dataTotal &&
                      listMenuBetting.map((item: any, index: number) => {
                        return (
                          <div
                            className="py-3 flex justify-center items-center border-r border-borderLightPrimary dark:border-borderPrimary col-span-4"
                            key={index}
                          >
                            <span className="text-primary text-xs text-center text-wrap break-words font-semibold w-[95%]">
                              {dataTotal[item.type] &&
                              dataTotal[item.type]?.totalBet
                                ? currencyFormat(dataTotal[item.type]?.totalBet)
                                : 0}
                              {"/"}
                              {dataTotal[item.type] &&
                              dataTotal[item.type]?.totalWin
                                ? currencyFormat(dataTotal[item.type]?.totalWin)
                                : 0}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              {!isMobile && listReportUser?.length > 0 && (
                <div className="flex flex-col">
                  <div className="flex items-center flex-wrap mt-4">
                    <div className="flex flex-col bg-surface-tertiary dark:bg-black rounded-[10px] py-2.5 px-2 mb-2 min-w-44">
                      <span className="text-xs font-semibold text-text-secondary dark:text-white/40 mb-1">
                        Tổng điểm chơi:
                      </span>
                      <span className="text-sm text-end font-medium text-success-500">
                        {totalBet ? currencyFormat(totalBet) : 0}
                      </span>
                    </div>
                    <div className="flex flex-col bg-surface-tertiary dark:bg-black rounded-[10px] py-2.5 px-2 mx-2 mb-2 min-w-44">
                      <span className="text-xs font-semibold text-text-secondary dark:text-white/40 mb-1">
                        Tổng điểm trúng:
                      </span>
                      <span className="text-sm text-end font-medium text-success-500">
                        {totalWin ? currencyFormat(totalWin) : 0}
                      </span>
                    </div>
                    <div className="flex flex-col bg-surface-tertiary dark:bg-black rounded-[10px] py-2.5 px-2 mb-2 min-w-44">
                      <span className="text-xs font-semibold text-text-secondary dark:text-white/40 mb-1">
                        Tổng lợi nhuận:
                      </span>
                      <span
                        className={classNames("text-sm text-end font-medium", {
                          "!text-success-500": totalWin - totalBet >= 0,
                          "!text-error-500": totalWin - totalBet < 0,
                        })}
                      >
                        {totalBet > 0 || totalWin > 0
                          ? currencyFormat(totalWin - totalBet)
                          : 0}
                      </span>
                    </div>
                  </div>
                  <span className="text-xs font-medium text-text-secondary dark:text-white/40 italic">
                    Chú ý: Tổng điểm chơi sẽ không bao gồm các vé huỷ
                  </span>
                </div>
              )}
              {totalPages && totalPages > 1 && !isMobile ? (
                <PaginationComponent
                  totalPage={totalPages}
                  changeOffset={changeOffset}
                  currentPage={currentPage}
                ></PaginationComponent>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {isMobile &&
          (loadMore ? (
            <div className="w-full flex items-center justify-center my-4">
              <PuffLoader color="#36d7b7" size={36} />
            </div>
          ) : (
            dataAvailable && (
              <div className="w-full flex items-center justify-center my-4">
                <span className="text-sm text-black dark:text-white font-medium">
                  Đã tải hết dữ liệu
                </span>
              </div>
            )
          ))}
      </div>
      <BottomSheet
        isShow={showFilterBottomSheet}
        onClose={() => setShowFilterBottomSheet(false)}
        title="Bộ lọc"
        height="50%"
      >
        <FilterReport
          updateFormData={updateFormData}
          statusFilter={statusFilter}
          handleGetReportUser={handleGetReportUser}
          handleClearFilter={handleClearFilter}
        />
      </BottomSheet>
      <BottomSheet
        isShow={showBottomSheetDetail}
        onClose={handleCloseBottomSheetDetail}
        height="60%"
        direction="vertical"
        title="Chi tiết thống kê"
      >
        <DetailReportBet data={dataDetail} />
      </BottomSheet>
    </div>
  );
}

export default ReportAllBetPage;
