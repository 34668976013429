function LoadingComponent() {
  return (
    <div className="loading-container fixed top-0 left-0 w-full h-full z-[9999999] flex flex-col items-center justify-center">
      <div className="fixed top-0 left-0 w-full h-full z-[5] bg-black opacity-80 block"></div>
      <img
        src={require("assets/images/icon-logo.png")}
        className="w-10 h-10 absolute z-10"
        alt="Loading"
      />
      <div className="loop-spin relative w-[60px] h-[60px] rounded-full border-[5px] border-white border-b-error-500 border-l-error-500 inline-block align-middle z-10"></div>
    </div>
  );
}

export default LoadingComponent;
