const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const validateLength = (value: string) => {
  return value.length < 5 ? true : false;
};

const validatePhone = (phone: string) => {
  const regex = /^\d{10}$/; // Kiểm tra số điện thoại 10 chữ số
  return regex.test(phone);
};

const validateUsername = (username: string) => {
  // Biểu thức chính quy cho phép chỉ các ký tự chữ cái, chữ số, dấu gạch dưới và dấu chấm
  const regex = /^[a-zA-Z0-9_.]+$/;
  return regex.test(username);
};

export { validateEmail, validateLength, validatePhone, validateUsername };
