import classNames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";
import { formatGetNameLottery, getTitleTransStatus } from "utils/lotteryFormat";

function ModalDetailAdjustment(props: any) {
  const { data, onClose } = props;
  const { listStatusTransaction, listGames } = useSelector(
    (state: any) => state.lottery
  );

  return (
    <div className="modal-detail-bet bg-white dark:bg-surface-second flex flex-col rounded-lg overflow-hidden shadow-[0px_4px_24px_0px_#00000033]">
      <div className="header-modal bg-gradient-primary px-4 py-2 flex justify-between items-center">
        <div className="flex items-center">
          <span className="title flex-1 text-xl text-white">Chi tiết</span>
          <span className="text-base text-white ml-2">
            {" "}
            (Mã biến động: {data?.id})
          </span>
        </div>
        <button
          type="button"
          className="bg-transparent border-none cursor-pointer"
          onClick={onClose}
        >
          <img src={icons.closeWhite} className="w-6 h-6" alt="Đóng" />
        </button>
      </div>
      <div className="content-modal px-4 py-4 overflow-auto max-h-[500px]">
        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              User ID
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.userid}
            </span>
          </div>
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between ml-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Loại biến động
            </span>
            <span
              className={classNames(
                `text-${
                  data?.pay > 0 ? "error" : "success"
                }-500 text-sm text-center`
              )}
            >
              {getTitleTransStatus(listStatusTransaction, data?.transtype)}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Thời gian cập nhật
            </span>
            <span className="value text-sm text-white font-medium">
              {moment(data?.inserttime).format("DD/MM/yyyy - HH:mm:ss")}
            </span>
          </div>
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between ml-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Số điểm
            </span>
            <span
              className={classNames(
                `text-${
                  data?.pay > 0 ? "error" : "success"
                }-500 text-sm text-center`
              )}
            >
              {data?.pay
                ? "-" + currencyFormat(data?.pay)
                : data?.income
                ? "+" + currencyFormat(data?.income)
                : 0}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Số dư trước
            </span>
            <span className="value text-sm text-white font-medium">
              {currencyFormat(data?.beforebalance)}
            </span>
          </div>
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between ml-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Số dư sau
            </span>
            <span className="value text-sm text-white font-medium">
              {currencyFormat(data?.afterbalance)}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-full flex items-center justify-between">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Mô tả
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.remark ? data?.remark : "-"}
            </span>
          </div>
        </div>
      </div>
      <div className="bottom-modal px-4 py-4 flex justify-end items-center border-t border-divider dark:border-divider-dark">
        <button
          className="rounded border border-transparent bg-success-500 text-white hover:bg-success-600 px-4 py-1.5"
          type="button"
          onClick={onClose}
        >
          Đóng
        </button>
      </div>
    </div>
  );
}

export default ModalDetailAdjustment;
