import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    refreshToken: null,
    sessionCookie: null,
    isAuth: false,
  },
  reducers: {
    login(state, action) {
      state.token = action.payload;
    },
    logout(state) {
      state.token = null;
    },
    addRefreshToken(state, action) {
      state.refreshToken = action.payload;
    },
    checkAuth(state, action) {
      state.isAuth = action.payload;
    },
    getSessionCookie(state, action) {
      state.sessionCookie = action.payload;
    },
  },
});

const { actions, reducer } = authSlice;

export const { logout, login, getSessionCookie, checkAuth, addRefreshToken } =
  actions;
export default reducer;
