import classNames from "classnames";
import SideBar from "components/SideBar/SideBar";
import { toggleSidebar } from "features/layoutSlice";
import { useDispatch, useSelector } from "react-redux";

function MainLayout(props: any) {
  const { children } = props;
  const dispatch = useDispatch();
  const { isMobile, isTablet, isMiniDesktop, showSidebar } = useSelector(
    (state: any) => state.layout
  );

  const handleToggleSidebar = (status: boolean) => {
    dispatch(toggleSidebar(status));
  };
  return (
    <div className="main-layout w-full flex">
      {(isMobile || isTablet || isMiniDesktop) && (
        <div className="sidebar z-30">
          <SideBar />
          <div
            className={classNames(
              "bg-black opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
              {
                "!z-30 opacity-80 duration-150 ease-linear !visible":
                  showSidebar,
              }
            )}
            onClick={() => handleToggleSidebar(false)}
          ></div>
        </div>
      )}
      <div className="content-layout-secondary w-full dark:bg-bgLayout bg-surface-primary">
        <div className="main-content flex justify-center w-full">
          {children}
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
