import moment from "moment";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";

function ModalDetailGift(props: any) {
  const { data, onClose } = props;

  return (
    <div className="modal-detail-bet bg-white dark:bg-surface-second flex flex-col rounded-lg overflow-hidden shadow-[0px_4px_24px_0px_#00000033]">
      <div className="header-modal bg-gradient-primary px-4 py-2 flex justify-normal items-center">
        <span className="title flex-1 text-xl text-white">
          Chi tiết quà tặng
        </span>
        <button
          type="button"
          className="bg-transparent border-none cursor-pointer"
          onClick={onClose}
        >
          <img src={icons.closeWhite} className="w-6 h-6" alt="Đóng" />
        </button>
      </div>
      <div className="content-modal px-4 py-4 overflow-auto max-h-[500px]">
        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Mã quà tặng
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.id}
            </span>
          </div>
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between ml-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Phòng live
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.livestreamid}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Dealer ID
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.adminid}
            </span>
          </div>
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between ml-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Người tặng
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.sender}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Thời gian tặng
            </span>
            <span className="value text-sm text-white font-medium">
              {moment(data?.inserttime).format("DD/MM/yyyy - HH:mm:ss")}
            </span>
          </div>
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between ml-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Tổng điểm
            </span>
            <span className="value text-sm text-warning-500 font-medium">
              {data?.amount ? currencyFormat(data?.amount) : 0}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Tên quà tặng
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.gift?.name}
            </span>
          </div>
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between ml-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Số quà
            </span>
            <span className="value text-sm text-white font-medium">
              {data?.multiple}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="item-content px-2 py-2.5 border-b border-divider dark:border-border-primary-dark w-1/2 flex items-center justify-between mr-1">
            <span className="title text-sm text-text-secondary font-medium flex-1">
              Quà tặng
            </span>
            <div className="value">
              <img src={data?.gift?.iconurl} className="w-8 h-8" alt="Quà tặng" />
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-modal px-4 py-4 flex justify-end items-center border-t border-divider dark:border-divider-dark">
        <button
          className="rounded border border-transparent bg-success-500 text-white hover:bg-success-600 px-4 py-1.5"
          type="button"
          onClick={onClose}
        >
          Đóng
        </button>
      </div>
    </div>
  );
}

export default ModalDetailGift;
