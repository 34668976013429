import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import store, { persistor } from "app/store";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/ReactToastify.min.css";
import { PersistGate } from "redux-persist/integration/react";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import App from "./App";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-us"}>
      <PersistGate loading={<div>loading</div>} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </LocalizationProvider>
  </Provider>
);
