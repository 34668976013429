export const icons = {
  pause: require("assets/icons/ic_pause.svg").default,
  play: require("assets/icons/ic_play.svg").default,
  mute: require("assets/icons/mute.svg").default,
  search: require("assets/icons/search.svg").default,
  logout: require("assets/icons/logout.svg").default,
  login: require("assets/icons/login.svg").default,
  user: require("assets/icons/user.svg").default,
  arrowUp: require("assets/icons/arrow-up.svg").default,
  arrowDown: require("assets/icons/arrow-down.svg").default,
  arrowLeft: require("assets/icons/arrow-left.svg").default,
  arrowRight: require("assets/icons/arrow-right.svg").default,
  wallet: require("assets/icons/wallet.svg").default,
  walletPrimary: require("assets/icons/walletPrimary.svg").default,
  history: require("assets/icons/history.svg").default,
  bell: require("assets/icons/bell.svg").default,
  bellOutline: require("assets/icons/bell-outline.svg").default,
  trash: require("assets/icons/trash.svg").default,
  trashWhite: require("assets/icons/trash-white.svg").default,
  close: require("assets/icons/close.svg").default,
  closeWhite: require("assets/icons/close-white.svg").default,
  closePrimary: require("assets/icons/closePrimary.svg").default,
  moon: require("assets/icons/moon.svg").default,
  sun: require("assets/icons/sun.svg").default,
  clipboard: require("assets/icons/clipboard.svg").default,
  gamepad: require("assets/icons/gamepad.svg").default,
  document: require("assets/icons/document.svg").default,
  documentCheck: require("assets/icons/document-check.svg").default,
  documentCheckPrimary: require("assets/icons/document-check-primary.svg")
    .default,
  ranking: require("assets/icons/ranking.svg").default,
  openInNew: require("assets/icons/openInNew.svg").default,
  show: require("assets/icons/show.svg").default,
  hide: require("assets/icons/hide.svg").default,
  toggle: require("assets/icons/toggle.svg").default,
  speaker: require("assets/icons/speaker.svg").default,
  setting: require("assets/icons/setting.svg").default,
  widget: require("assets/icons/widget.svg").default,
  face: require("assets/icons/face.svg").default,
  send: require("assets/icons/send.svg").default,
  add: require("assets/icons/add.svg").default,
  sendPrimary: require("assets/icons/sendPrimary.svg").default,
  icon: require("assets/icons/icon.svg").default,
  iconPrimary: require("assets/icons/iconPrimary.svg").default,
  clipboardDocumentList: require("assets/icons/clipboard-document-list.svg")
    .default,
  home: require("assets/icons/home.svg").default,
  coin: require("assets/icons/coin.svg").default,
  gift: require("assets/icons/gift.svg").default,
  bolt: require("assets/icons/bolt.svg").default,
  hamburger: require("assets/icons/hamburger.svg").default,
  microphone: require("assets/icons/microphone.svg").default,
  dangerCircle: require("assets/icons/danger-circle.svg").default,
  roundArrowLeft: require("assets/icons/round-arrow-left.svg").default,
  schedule: require("assets/icons/schedule.svg").default,
  assignment: require("assets/icons/assignment.svg").default,
  ticket: require("assets/icons/ticket.svg").default,
  pending: require("assets/icons/pending_actions.svg").default,
  edit: require("assets/icons/edit.svg").default,
  filter: require("assets/icons/filter.svg").default,
  calendar: require("assets/icons/calendar.svg").default,
  print: require("assets/icons/print.svg").default,
  copy: require("assets/icons/copy.svg").default,
  check: require("assets/icons/check.svg").default,
  fileDownload: require("assets/icons/file_download.svg").default,
};
