import { Tooltip } from "@mui/material";
import lotteryApi from "apis/lotteryApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalDetailBet from "components/ModalDetailBet/ModalDetailBet";
import PaginationComponent from "components/PaginationComponent/PaginationComponent";
import TagComponent from "components/TagComponent/TagComponent";
import { recordLotteryI } from "interfaces/lottery.interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { formatDateBasic, formatToIOString } from "utils/formateDate";
import {
  formatBetContent,
  formatCodesEasyWatch,
  formatGetTitleStatus,
  handleGetBalanceUser,
} from "utils/lotteryFormat";
import "./TableHistoryBet.scss";

const listHeaderTitle = [
  "Lượt xổ",
  "Kiểu chơi",
  "Thời gian chơi",
  "Số chơi",
  "Tổng số chơi",
  "Điểm chơi",
  "Điểm trúng",
  "Trạng thái",
];

function TableHistoryBet(props: any) {
  // properties
  const { statusBet, gameid, clearStatusBet } = props;

  // Hook thao tác
  // const toastify = useToast();
  // Hook get từ store
  const { isMobile } = useSelector((state: any) => state.layout);
  const { listStatus } = useSelector((state: any) => state.lottery);

  // Hook xử lý state
  const [listGameRecord, setListGameRecord] = useState<any>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [showModalDetailBet, setShowModalDetailBet] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<recordLotteryI | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalBetMoney, setTotalBetMoney] = useState<number>(0);
  const [totalWinMoney, setTotalWinMoney] = useState<number>(0);
  const [reloadHistory, setReloadHistory] = useState<boolean>(false);

  useEffect(() => {
    if (!isMobile) {
      handleGetRecord();
    }
  }, [gameid]);

  useEffect(() => {
    if (statusBet === true) {
      handleGetRecord();
      clearStatusBet();
    }
  }, [statusBet]);

  // Hàm lấy danh sách lịch sử cược
  const handleGetRecord = async (offset?: number) => {
    setLoading(true);
    const now = new Date();
    try {
      const { data } = await lotteryApi.getGameRecordCurrentUser({
        pageSize: 10,
        offset: offset ? offset : 0,
        gameid: gameid,
        fromdate: formatToIOString(
          new Date(now.getFullYear(), now.getMonth(), now.getDate())
        ),
      });
      if (data) {
        const dataRes = data.data.gameRecords.content;
        setListGameRecord(dataRes);
        setTotalBetMoney(data.data.totalbet);
        setTotalWinMoney(data.data.totalwin);
        setTotalPage(data.data.gameRecords.totalPages);
        setCurrentPage(Number(data.data.gameRecords.number) + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Hàm hiện modal chi tiết vé cược
  const handleShowDetailBet = (data: any) => {
    setShowModalDetailBet(true);
    setDataDetail(data);
  };

  // Hàm onChange pagination
  const handleChangePagination = (page: number) => {
    handleGetRecord(page);
  };

  const handleReloadHistory = () => {
    setReloadHistory(true);
    handleGetRecord();
    handleGetBalanceUser();
    setTimeout(() => {
      setReloadHistory(false);
    }, 1000);
  };

  return (
    <div className="table-history-container mt-4 rounded">
      <div className="title-table-wrap flex items-center justify-between">
        <div className="flex items-center">
          <span className="title-table text-xl font-semibold text-black dark:text-white">
            Lịch Sử Chơi Hôm Nay
          </span>
        </div>
        <button
          type="button"
          className="w-6 h-6 text-text-secondary dark:text-white"
          onClick={handleReloadHistory}
        >
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames("w-6 h-6", {
              "rotate-[-360deg] duration-1000 ease-in-out": reloadHistory,
            })}
          >
            <path
              d="M6.96843 3.70193V1.37693C6.96843 1.00193 6.51843 0.818596 6.26009 1.08526L3.09343 4.2436C2.92676 4.41026 2.92676 4.6686 3.09343 4.83526L6.25176 7.9936C6.51843 8.25193 6.96843 8.0686 6.96843 7.6936V5.3686C10.0768 5.3686 12.5351 8.2186 11.8518 11.4436C11.4601 13.3353 9.92676 14.8603 8.04343 15.2519C5.06843 15.8769 2.41843 13.8353 2.01843 11.0769C1.96009 10.6769 1.61009 10.3686 1.20176 10.3686C0.701761 10.3686 0.301761 10.8103 0.368427 11.3103C0.885094 14.9686 4.36843 17.6769 8.31009 16.9103C10.9101 16.4019 13.0018 14.3103 13.5101 11.7103C14.3351 7.43526 11.0851 3.70193 6.96843 3.70193Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
      <div className="table-wrap mt-4 border border-borderLightPrimary dark:border-borderPrimary rounded-[10px] overflow-hidden">
        <div className="header-table bg-surface-tertiary dark:bg-black">
          <div className="heade-table-item flex items-center">
            {listHeaderTitle.map((header: string, index: number) => (
              <div
                className="px-2.5 py-3 flex justify-center items-center w-1/8"
                key={index}
              >
                <span className="text-primary text-xs text-center font-semibold">
                  {header}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="body-table">
          {listGameRecord?.length > 0 ? (
            listGameRecord?.map((item: recordLotteryI, index: number) => (
              <div
                className={`flex items-center py-2.5 border-borderLightPrimary dark:border-borderPrimary border-t ${
                  index % 2 !== 0
                    ? "bg-surface-tertiary dark:bg-black"
                    : "bg-white dark:bg-bgPrimary"
                } cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800`}
                key={index}
                onClick={() => handleShowDetailBet(item)}
              >
                <div className="flex justify-center items-center w-1/8">
                  <span className=" text-xs text-center font-semibold text-black dark:text-white">
                    {item.issue}
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-1/8">
                  <span className=" text-xs text-center font-semibold text-black dark:text-white">
                    {formatBetContent(item.betcontent)}
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-1/8">
                  <span className=" text-xs text-center font-semibold text-black dark:text-white">
                    {formatDateBasic(item.bettime)}
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-1/8">
                  <Tooltip
                    title={formatCodesEasyWatch(item.codes)}
                    placement="right"
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -8],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <span className=" w-4/5 text-xs text-center text-nowrap truncate font-semibold text-black dark:text-white">
                      {formatCodesEasyWatch(item.codes)}
                    </span>
                  </Tooltip>
                </div>
                <div className="px-2.5 flex justify-center items-center w-1/8">
                  <span className=" text-xs text-center font-semibold text-black dark:text-white">
                    {item.betnumber ? currencyFormat(item.betnumber) : 0}
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-1/8">
                  <span className=" text-xs text-center font-semibold text-black dark:text-white">
                    {item.amount ? currencyFormat(item.amount) : 0}
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-1/8">
                  <span className=" text-xs text-center font-semibold text-black dark:text-white">
                    {item.bonus ? currencyFormat(item.bonus) : 0}
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-1/8">
                  <span
                    className={classNames(
                      "w-fit text-center text-wrap font-medium break-words truncate text-xs"
                    )}
                  >
                    <TagComponent
                      title={formatGetTitleStatus(
                        item.status,
                        listStatus,
                        "title"
                      )}
                      type={
                        [3, 5, 6, 7].includes(item.status)
                          ? "error"
                          : item.status === 4
                          ? "success"
                          : "warning"
                      }
                    />
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="no-data px-2.5 py-2.5 flex items-center justify-center border-t border-borderLightPrimary dark:border-borderPrimary bg-white dark:bg-bgPrimary">
              <span className=" text-xs font-semibold text-black dark:text-white">
                Chưa tìm thấy lịch sử chơi nào
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="wrap-pagination flex justify-between mt-3">
        <div className="w-full flex items-center">
          <div className="total-bet-wrap bg-secondary/10 py-1.5 px-3 border border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm mr-4">
            <span className="text-text-secondary dark:text-white mr-2">
              Tổng chơi:
            </span>
            <span className="text-warning-500 font-semibold">
              {totalBetMoney ? currencyFormat(totalBetMoney) : 0}
            </span>
          </div>
          <div className="total-bet-wrap bg-secondary/10 py-1.5 px-3 border border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm mr-4">
            <span className="text-text-secondary dark:text-white mr-2">
              Tổng thắng:
            </span>
            <span className="text-success-500 font-semibold">
              {totalWinMoney ? currencyFormat(totalWinMoney) : 0}
            </span>
          </div>
          <div className="total-bet-wrap bg-secondary/10 py-1.5 px-3 border border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm">
            <span className="text-text-secondary dark:text-white mr-2">
              Tổng lợi nhuận:
            </span>
            <span
              className={classNames("text-sm font-semibold", {
                "!text-success-500": totalWinMoney - totalBetMoney >= 0,
                "!text-error-500": totalWinMoney - totalBetMoney < 0,
              })}
            >
              {totalBetMoney > 0 || totalWinMoney > 0
                ? currencyFormat(totalWinMoney - totalBetMoney)
                : 0}
            </span>
          </div>
        </div>
        {totalPage > 0 && (
          <div className="w-full flex justify-end items-center">
            <PaginationComponent
              totalPage={totalPage}
              changeOffset={handleChangePagination}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>

      <Modal isShow={showModalDetailBet} noClickOutSide>
        {showModalDetailBet && (
          <ModalDetailBet
            onClose={() => setShowModalDetailBet(false)}
            data={dataDetail}
            getListRecord={handleGetRecord}
          />
        )}
      </Modal>
    </div>
  );
}

export default TableHistoryBet;
