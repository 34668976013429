import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalCommon from "components/ModalCommon/ModalCommon";
import TableDauDuoi from "components/TableDauDuoi/TableDauDuoi";
import TableFormatMB from "components/TableResult/TableFormatMB";
import TableFormatMTMN from "components/TableResult/TableFormatMTMN";
import TagComponent from "components/TagComponent/TagComponent";
import { addBalanceUser } from "features/userSlice";
import useToast from "hooks/useToast";
import { toPng } from "html-to-image";
import moment from "moment";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";
import {
  formatBetContent,
  formatCodesEasyWatch,
  formatGetNameLottery,
  formatGetTitleStatus,
  formatStringWinnumberToResultData,
  handleGetBalanceUser,
} from "utils/lotteryFormat";

function ModalDetailBet(props: any) {
  // properties
  const { data, onClose, getListRecord, loading } = props;

  const dispatch = useDispatch();
  const toastify = useToast();
  // Hook get data từ store redux
  const { listStatus, listGames } = useSelector((state: any) => state.lottery);
  const { userInfo } = useSelector((state: any) => state.user);

  const [showModalConfirmCancel, setShowModalConfirmCancel] =
    useState<boolean>(false);

  const ticketRef = useRef<HTMLDivElement | null>(null);

  // Hàm hủy vé cược
  const handleCancelBet = async (idTicket: number) => {
    try {
      const { data } = await lotteryApi.cancelBetLottery({
        id: idTicket,
      });
      if (data) {
        onClose();
        getListRecord();
        toastify("Hủy vé thành công!", "success");
        handleGetBalanceUser();
      }
    } catch (error: any) {
      console.log(error);
      // if (!error.msg) toastify(error.msg, "error");
    } finally {
      setShowModalConfirmCancel(false);
    }
  };

  const handlePrintTicketBet = async () => {
    if (ticketRef?.current) {
      await toPng(ticketRef?.current, { quality: 1 }).then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "anh_ve_cuoc.png";
        link.href = dataUrl;
        link.click();
      });
    }
  };

  return (
    <>
      <div className="modal-detail-bet bg-surface-primary dark:bg-bgLayout flex flex-col rounded-lg overflow-hidden shadow-[0px_4px_24px_0px_#00000033] w-[800px]">
        <div className="header-modal bg-cardLottery bg-center bg-no-repeat bg-cover px-5 py-4 flex justify-between items-center">
          <span className="title flex-1 text-xl text-primary dark:text-white">
            Chi Tiết Vé Chơi
          </span>
          <div className="flex items-center">
            <button
              type="button"
              className="mr-4 p-1 rounded-lg text-primary hover:opacity-80 cursor-pointer flex items-center"
              onClick={handlePrintTicketBet}
            >
              <img
                src={icons.print}
                className="w-5 h-5 mr-1"
                alt="In hóa đơn"
              />
              <span className="text-xs font-semibold">In hóa đơn</span>
            </button>
            <button
              type="button"
              className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
              onClick={onClose}
            >
              <img
                src={require("assets/icons/close.png")}
                className="w-5 h-5"
                alt="Đóng"
              />
            </button>
          </div>
        </div>
        <div className="content-modal overflow-auto max-h-[500px]">
          {!loading ? (
            <>
              <div
                className="p-4 bg-surface-primary dark:bg-bgLayout"
                ref={ticketRef}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between mr-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Mã vé chơi
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {data?.id}
                    </span>
                  </div>
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between ml-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Tên người chơi
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {userInfo?.loginname}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between mr-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Tên đài chơi
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {formatGetNameLottery(data?.gameid, listGames)}
                    </span>
                  </div>
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between ml-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Kiểu chơi
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {formatBetContent(data?.betcontent)}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between mr-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Lượt xổ
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {data?.issue}
                    </span>
                  </div>

                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between ml-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Tỉ lệ
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      <span className="text-error-500">1 :</span>
                      <span className="text-error-500 ml-1">{data?.odd}</span>
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between mr-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Thời gian chơi
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {moment(data?.bettime).format("DD/MM/yyyy - HH:mm:ss")}
                    </span>
                  </div>
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between ml-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Thời gian kết thúc
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {data?.finishtime
                        ? moment(data?.finishtime).format(
                            "DD/MM/yyyy - HH:mm:ss"
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between mr-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Tổng số chơi
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {data?.betnumber} số
                    </span>
                  </div>
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between ml-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Cấp số nhân
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {data?.multiple} lần
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-6 border-b border-borderLightPrimary dark:border-borderPrimary w-full flex items-center justify-between">
                    <span className="title mr-4 text-sm text-text-secondary dark:text-white/40font-medium">
                      Nội dung chi tiết
                    </span>
                    <div className="max-w-[80%] flex items-start justify-end">
                      <span className="value w-full text-sm text-black dark:text-white font-medium text-wrap whitespace-normal break-words text-start">
                        {formatCodesEasyWatch(data?.codes)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between mr-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Số lần trúng
                    </span>
                    <span className="value text-sm text-black dark:text-white font-medium">
                      {data?.wintimes} lần
                    </span>
                  </div>
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between ml-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Trạng thái
                    </span>
                    <span
                      className={classNames(
                        "w-fit text-center text-wrap font-medium break-words truncate text-xs"
                      )}
                    >
                      <TagComponent
                        title={formatGetTitleStatus(
                          data?.status,
                          listStatus,
                          "title"
                        )}
                        type={
                          [3, 5, 6, 7].includes(data?.status)
                            ? "error"
                            : data?.status === 4
                            ? "success"
                            : "warning"
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between mr-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Điểm chơi
                    </span>
                    <span className="value text-sm text-warning-500 font-medium">
                      {data?.amount > 0 ? currencyFormat(data?.amount) : 0}
                    </span>
                  </div>
                  <div className="item-content px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary w-1/2 flex items-center justify-between ml-1">
                    <span className="title text-sm text-text-secondary dark:text-white/40font-medium flex-1">
                      Điểm trúng
                    </span>
                    <span
                      className={classNames(
                        "value text-sm text-black dark:text-white font-medium",
                        {
                          "!text-success-500": data?.bonus > 0,
                          "!text-error-500": data?.bonus < 0,
                        }
                      )}
                    >
                      {data?.bonus > 0 ? currencyFormat(data?.bonus) : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="px-4 pb-4">
                {data?.winnumber !== null && data?.winnumber !== "" ? (
                  <div className="table-result-wrap mt-3 flex justify-between">
                    <div className="flex flex-col bg-white dark:bg-black border border-borderLightPrimary dark:border-borderPrimary rounded-[10px] overflow-hidden w-8/12">
                      <div className="title-table-wrap w-full flex items-center justify-center bg-green-200 dark:bg-bgPrimary py-2">
                        <span className="title-table text-md font-semibold text-primary">
                          Kết Quả
                        </span>
                      </div>
                      <div
                        className={classNames("table-result", {
                          "!w-full":
                            data?.winnumber !== null || data?.winnumber !== "",
                        })}
                      >
                        {[210, 200, 201].includes(data?.gameid) ? (
                          <TableFormatMB
                            gameid={data?.gameid}
                            method={data?.checkflag}
                            data={formatStringWinnumberToResultData(
                              data?.gameid,
                              data?.winnumber
                            )}
                          />
                        ) : (
                          <TableFormatMTMN
                            gameid={data?.gameid}
                            method={data?.checkflag}
                            data={formatStringWinnumberToResultData(
                              data?.gameid,
                              data?.winnumber
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      className={classNames("table-dauduoi block w-4/12 ml-2", {
                        "!hidden": !data?.winnumber,
                      })}
                    >
                      <TableDauDuoi
                        winnumber={data?.winnumber}
                        method={data?.checkflag}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center w-full h-[500px]">
              <ClipLoader color="#22c55e" size={40} />
            </div>
          )}
        </div>
        <div className="bottom-modal px-4 py-4 flex justify-end items-center border-t border-borderLightPrimary dark:border-borderPrimary">
          {data?.status === 0 && (
            <button
              className="bg-secondary/20 flex items-center justify-center text-primary hover:opacity-80 px-3 py-2.5 rounded-[10px] text-sm font-semibold mr-2"
              type="button"
              onClick={() => setShowModalConfirmCancel(true)}
            >
              Hủy vé
            </button>
          )}
          <button
            className="text-sm font-semibold rounded-[10px] bg-primary text-white hover:bg-primary/80 px-3 py-2.5"
            type="button"
            onClick={onClose}
          >
            Đóng
          </button>
        </div>
      </div>
      <Modal
        isShow={showModalConfirmCancel}
        onClose={() => setShowModalConfirmCancel(false)}
      >
        <ModalCommon
          title="Xác nhận"
          content="Bạn có muốn huỷ vé cược này không?"
          handleClose={() => setShowModalConfirmCancel(false)}
          handleSubmit={() => handleCancelBet(data?.id)}
        />
      </Modal>
    </>
  );
}

export default ModalDetailBet;
