import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import {
  handleFormatDauDuoi,
  handleFormatDuoiDau,
  handleGroupDauDuoi,
} from "utils/lotteryFormat";

function TableDauDuoi(props: any) {
  const { winnumber, method, statusUpdate, isShow = true, type } = props;

  const { isMobile } = useSelector((state: any) => state.layout);
  const [listDauduoi, setListDauduoi] = useState<any[]>([]);

  useEffect(() => {
    setListDauduoi(
      handleGroupDauDuoi(
        handleFormatDuoiDau(winnumber),
        handleFormatDauDuoi(winnumber, "LO2SO")
      )
    );
  }, [winnumber, method]);

  return (
    <div
      className={classNames(
        "table-dau-duoi h-fit rounded-[10px] bg-white dark:bg-black border border-borderLightPrimary dark:border-borderPrimary overflow-hidden",
        { "!rounded-none": type === "modal" }
      )}
    >
      <div
        className={classNames(
          "wrap-prize px-4 py-3 bg-green-200 dark:bg-bgPrimary text-primary text-sm font-semibold  flex items-center",
          { "!text-xs !px-1": isMobile }
        )}
      >
        <div className="title-prize flex items-center justify-center w-2/5">
          <span className="text-center">Chục</span>
        </div>
        <div className="title-prize flex items-center justify-center w-1/5">
          <span className="text-center">Số</span>
        </div>
        <div className="number-prize flex items-center justify-center w-2/5">
          <span className="text-center">Đơn vị</span>
        </div>
      </div>
      <div
        className={classNames("transition-all duration-500 max-h-0 h-full", {
          "!max-h-[9999px]": isShow,
        })}
      >
        {winnumber?.giaidb !== "" ? (
          listDauduoi?.map((item: any, index: number) => (
            <div
              className={classNames(
                "wrap-prize grid border-t border-borderLightPrimary dark:border-borderPrimary grid-cols-5"
              )}
              key={index}
            >
              <div className="number-prize px-1 py-2 flex items-center justify-center min-h-10 col-span-2">
                {statusUpdate ? (
                  <BeatLoader size={6} color="#12B76A" />
                ) : (
                  <span
                    className={`${
                      isMobile ? "text-xs" : "text-sm"
                    }  text-black dark:text-white font-semibold text-center text-wrap break-words w-[95%]`}
                  >
                    {item.dau}
                  </span>
                )}
              </div>
              <div className="title-prize flex items-center min-h-10 h-full border-x border-borderLightPrimary dark:border-borderPrimary justify-center col-span-1">
                <span
                  className={`${
                    isMobile ? "text-sm" : "text-base"
                  } text-black dark:text-white font-semibold text-center`}
                >
                  {item.key}
                </span>
              </div>
              <div className="number-prize px-1 py-2 flex items-center justify-center min-h-10 col-span-2">
                {statusUpdate ? (
                  <BeatLoader size={6} color="#12B76A" />
                ) : (
                  <span
                    className={`${
                      isMobile ? "text-xs" : "text-sm"
                    }  text-black dark:text-white font-semibold text-center text-wrap break-words w-[95%]`}
                  >
                    {item.duoi}
                  </span>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="w-full h-full p-4 flex justify-center items-center text-sm text-black dark:text-white bg-white dark:bg-black border-t border-borderLightPrimary dark:border-borderPrimary">
            Chưa có kết quả
          </div>
        )}
      </div>
    </div>
  );
}

export default TableDauDuoi;
