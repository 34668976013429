import classNames from "classnames";
import useToast from "hooks/useToast";
import { useEffect, useRef, useState } from "react";
import { icons } from "utils/icons";
import "./EnterMethodBet.scss";

interface numberRandomI {
  id: number;
  number: number;
  isShow: boolean;
  isActive: boolean;
}

const listRandomNumber: numberRandomI[] = [
  {
    id: 1,
    number: 10,
    isShow: false,
    isActive: false,
  },
  {
    id: 2,
    number: 20,
    isShow: false,
    isActive: false,
  },
  {
    id: 3,
    number: 30,
    isShow: false,
    isActive: false,
  },
  {
    id: 4,
    number: 40,
    isShow: false,
    isActive: false,
  },
  {
    id: 5,
    number: 50,
    isShow: false,
    isActive: false,
  },
];

const listRandomNumber2: numberRandomI[] = [
  {
    id: 1,
    number: 1,
    isShow: false,
    isActive: false,
  },
  {
    id: 2,
    number: 2,
    isShow: false,
    isActive: false,
  },
  {
    id: 3,
    number: 3,
    isShow: false,
    isActive: false,
  },
  {
    id: 4,
    number: 5,
    isShow: false,
    isActive: false,
  },
  {
    id: 5,
    number: 10,
    isShow: false,
    isActive: false,
  },
];

function EnterMethodBet(props: any) {
  const { methodType, getCodes, statusBet } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const toastify = useToast();
  const [listRandom, setListRandom] =
    useState<numberRandomI[]>(listRandomNumber);
  const [valueTextarea, setValueTextarea] = useState<string>("");
  const [placeholderTextarea, setPlaceholderTexarea] = useState<string>("");
  const [typeGroupRandomNumber, setTypeGroupRandomNumber] = useState<number>(1);
  const [unitGroupRandomNumber, setUnitGroupRandomNumber] = useState<number>(2);
  const [dataResult, setDataResult] = useState<any>([]);
  const [checkMethod, setCheckMethod] = useState<boolean>(false);
  const [listNumberChoiceFormated, setListNumberChoiceFormated] = useState<
    string[]
  >([]);

  useEffect(() => {
    let plchol = "";
    if (methodType === "LO4SO" || methodType === "4CANGDACBIET") {
      plchol = "2384;8210;1393 hoặc 2384 8210 1393";
      setTypeGroupRandomNumber(1);
      setUnitGroupRandomNumber(4);
    } else if (
      methodType === "LO3SO" ||
      methodType === "3CANGDACBIET" ||
      methodType === "3CANGDAU" ||
      methodType === "3CANGDAUDUOI" ||
      methodType === "3CANGDAUMB" ||
      methodType === "3CANGDAUDUOIMB"
    ) {
      plchol = "238;821;393 hoặc 238 821 393";
      setTypeGroupRandomNumber(1);
      setUnitGroupRandomNumber(3);
    } else if (methodType === "XIEN2") {
      plchol = "23-43;87-25;39-54 hoặc 23-43 87-25 39-54";
      setTypeGroupRandomNumber(2);
      setUnitGroupRandomNumber(2);
    } else if (methodType === "XIEN3") {
      setTypeGroupRandomNumber(3);
      setUnitGroupRandomNumber(2);
      plchol = "23-43-66;87-25-43;39-54-11 hoặc 23-43-66 87-25-43 39-54-11";
    } else if (methodType === "XIEN4" || methodType === "LOTRUOT4SO") {
      setTypeGroupRandomNumber(4);
      setUnitGroupRandomNumber(2);
      plchol =
        "23-43-66-55;87-25-43-09;39-54-11-69 hoặc 23-43-66-55 87-25-43-09 39-54-11-69";
    } else if (methodType === "LOTRUOT8SO") {
      setTypeGroupRandomNumber(8);
      setUnitGroupRandomNumber(2);
      plchol =
        "23-43-66-55-65-13-09-15;87-25-42-09-70-77-61-99 hoặc 23-43-66-55-65-13-09-15 87-25-43-09-70-77-61-99";
    } else if (methodType === "LOTRUOT10SO") {
      setTypeGroupRandomNumber(10);
      setUnitGroupRandomNumber(2);
      plchol =
        "23-43-66-55-65-13-09-15-00-10;87-25-42-09-70-77-61-99-90-30 hoặc 23-43-66-55-65-13-09-15-00-10 87-25-42-09-70-77-61-99-90-30";
    } else {
      setTypeGroupRandomNumber(1);
      setUnitGroupRandomNumber(2);
      plchol = "23;90;83 hoặc 23 90 83";
    }

    if (
      methodType === "XIEN2" ||
      methodType === "XIEN3" ||
      methodType === "XIEN4" ||
      methodType === "LOTRUOT4SO" ||
      methodType === "LOTRUOT8SO" ||
      methodType === "LOTRUOT10SO"
    ) {
      setListRandom(listRandomNumber2);
      setCheckMethod(true);
      setPlaceholderTexarea(
        `Cách chơi \nGiữa mỗi cặp số được phân cách bởi dấu ; hoặc khoảng trắng \nVí dụ: ${plchol}`
      );
    } else {
      setListRandom(listRandomNumber);
      setCheckMethod(false);
      setPlaceholderTexarea(
        `Cách chơi \nGiữa mỗi cặp số được phân cách bởi dấu ; hoặc dấu , hoặc khoảng trắng \nVí dụ: ${plchol}`
      );
    }
  }, [methodType]);

  useEffect(() => {
    if (!statusBet) {
      handleRemoveSelected();
    }
  }, [statusBet]);

  useEffect(() => {
    countNum();
  }, [dataResult]);

  // Hàm chọn số ngẫu nhiên theo số lượng vd: 10
  const handleSelectCountNumber = (numId: number, num: number) => {
    const listNewRandom = listRandom.map((item: any) => {
      item.isActive = item.id === numId ? true : false;
      return item;
    });
    handleGenerateRandom(num, typeGroupRandomNumber, unitGroupRandomNumber);
    setListRandom(listNewRandom);
  };

  //Hàm generate random number
  const generateRandom = (min: number, max: number, pad: number) => {
    let random;
    random = Math.floor(Math.random() * (max - min + 1)) + min;
    const formattedRandomNumber = String(random).padStart(pad, "0");

    return formattedRandomNumber;
  };

  // Hàm generate group random number
  const generateGroupRandomNumber = (typeAmount: number, unit: number) => {
    const listRandomGroup: any[] = [];
    let max = unit === 4 ? 9999 : unit === 3 ? 999 : 99;

    while (listRandomGroup.length < typeAmount) {
      let randomNums = generateRandom(0, max, unit);

      if (!listRandomGroup.includes(randomNums)) {
        listRandomGroup.push(randomNums);
      }
    }
    return listRandomGroup.sort((a: number, b: number) => a - b).join("-");
  };

  // Hàm generate codes
  const handleGenerateRandom = (amount: number, type: number, unit: number) => {
    const listGroupRandomNumber: any[] = [];
    while (listGroupRandomNumber.length < amount) {
      let randomNumber = generateGroupRandomNumber(type, unit);
      if (!listGroupRandomNumber.includes(randomNumber)) {
        listGroupRandomNumber.push(randomNumber);
      }
    }
    const codes = listGroupRandomNumber
      .sort((a: number, b: number) => a - b)
      .join(";");
    const codeLength = codes.split(/;/g).length;
    setListNumberChoiceFormated(handleFormatCodesEaseWatch(codes));
    if (
      methodType === "XIEN2" ||
      methodType === "XIEN3" ||
      methodType === "XIEN4" ||
      methodType === "LOTRUOT4SO" ||
      methodType === "LOTRUOT8SO" ||
      methodType === "LOTRUOT10SO"
    ) {
      getCodes(codes, codeLength);
    } else {
      getCodes(codes.replace(/;/g, ","), codeLength);
    }
    if (textareaRef.current) {
      textareaRef.current.value = codes;
    }
    setValueTextarea(codes);
  };

  const handleFormatCodesEaseWatch = (codes: string) => {
    const codesArrayFormat: any[] = codes.split(";").map((code: string) => {
      return {
        listCodes: code.split("-"),
        strCode: code.replaceAll(/&/g, "-"),
      };
    });
    return codesArrayFormat;
  };

  // Hàm change textarea
  const handleChangeTextArea = (ev: any) => {
    const text = ev.target.value;
    const checkStrLx = /[^0-9-;,\s]/g.test(text);
    if (!checkStrLx) {
      const overText = formatWhiteSpace(text).replace(/[,\s]/g, ";");
      const sepaVal = overText.split(";");
      const arrTemp = [];
      for (let i = 0, len = sepaVal.length; i < len; i++) {
        if (sepaVal[i] !== "") {
          arrTemp.push(sepaVal[i]);
        }
      }
      setListNumberChoiceFormated(
        handleFormatCodesEaseWatch(arrTemp.join(";"))
      );
      setDataResult(arrTemp);
    }
  };

  const handleKeyPress = (event: any) => {
    // Lấy ký tự được nhập vào từ sự kiện
    const charCode = event.which || event.keyCode;
    const charTyped = String.fromCharCode(charCode);

    if (!/[\d\s,;-]/.test(charTyped)) {
      event.preventDefault();
    }
  };

  // Hàm check số bị duplicate
  const checkNumDuplicateLX = (arr: any) => {
    let flagCheck = false;
    let count = 0;
    let valTemp = arr[count];
    const flag = true;
    const len = arr.length;
    let i = 1;
    while (flag) {
      if (valTemp === arr[i] && i !== count) {
        flagCheck = true;
        break;
      }
      if (i === len) {
        count += 1;
        i = count;
        valTemp = arr[count];
      }
      i += 1;
      if (!flag || i > len) {
        break;
      }
    }
    return flagCheck;
  };

  // Hàm check loại số
  const checkNumType = (
    digits: number,
    symbolAnd: boolean,
    isOne?: boolean
  ) => {
    const dataLx = dataResult;
    let dataSelTemp = [];
    let checkWrongNum = false;
    for (let i = 0, len = dataLx.length; i < len; i++) {
      const item = dataLx[i];
      let getNumItem;
      let flag = false;

      if (symbolAnd) {
        if (item.search("-") > -1) {
          getNumItem = item.split("-");
          if (checkNumDuplicateLX(getNumItem)) {
            checkWrongNum = true;
            break;
          }
          const idxNull = getNumItem.indexOf("");
          if (
            idxNull > -1 &&
            (idxNull === 0 || idxNull + 1 === getNumItem.length)
          ) {
            checkWrongNum = true;
          }

          if (getNumItem.length === digits && !checkWrongNum) {
            for (let j = 0, lenJ = getNumItem.length; j < lenJ; j++) {
              if (!checkNumLen(2, getNumItem[j])) {
                checkWrongNum = true;
                flag = true;
              }
              if (j + 1 === digits && !flag && lenJ === digits) {
                dataSelTemp.push(item.split("-").sort(sortNumber).join("-"));
              }
            }
          } else {
            checkWrongNum = true;
          }
        } else {
          checkWrongNum = true;
        }
      } else {
        if (!checkNumLen(digits, item)) {
          checkWrongNum = true;
          flag = true;
        }
        if (!flag) {
          dataSelTemp.push(item);
        }
      }
    }

    if (checkWrongNum) {
      dataSelTemp = [];
    }

    if (dataSelTemp.length > 0) {
      const checkDupli = removeItemDupliArr(dataSelTemp);
      if (checkDupli?.flag) {
        dataSelTemp = checkDupli.result;
        setValueTextarea(dataSelTemp.join(";"));
        toastify("Vé bị trùng. Vui lòng nhập lại!", "warning");
      }
    }

    if (dataSelTemp.length > 1 && isOne) {
      dataSelTemp = [dataSelTemp[0]];
      setValueTextarea(dataSelTemp[0]);
      toastify("Mỗi vé chỉ được chọn 1 số duy nhất!", "warning");
    }
    return dataSelTemp;
  };

  // Hàm đếm số vé
  const countNum = () => {
    const mathFn: any = {
      LO2SO: () => lo2so(),
      LO2SO1K: () => lo2so(),
      LO2SODAU: () => lo2so(),
      LO3SO: () => lo3so(),
      LO4SO: () => lo4so(),
      DEDAU: () => dedau(),
      DEDACBIET: () => dedacbiet(),
      DEDACBIETNEW: () => dedacbiet(),
      DEDACBIETDAU: () => dedaudacbiet(),
      DEGIAIBAY: () => degiai7(),
      DEGIAINHAT: () => degiainhat(),
      DEDAUDUOI: () => dedauduoi(),
      "3CANGDAU": () => bacangdau(),
      "3CANGDAUDUOI": () => bacangdauduoi(),
      "3CANGDAUMB": () => bacangdau(),
      "3CANGDAUDUOIMB": () => bacangdauduoi(),
      "3CANGDACBIET": () => bacangdacbiet(),
      "4CANGDACBIET": () => boncangdacbiet(),
      XIEN2: () => lx2(),
      XIEN3: () => lx3(),
      XIEN4: () => lx4(),
      LOTRUOT4SO: () => lt4(),
      LOTRUOT8SO: () => lt8(),
      LOTRUOT10SO: () => lt10(),
    };
    if (methodType) {
      const getNo = mathFn[methodType]();
      getCodes(getNo.join(checkMethod ? ";" : ","), getNo.length);
    } else {
      return;
    }
  };

  const lo2so = () => {
    return checkNumType(2, false);
  };

  const lo3so = () => {
    return checkNumType(3, false);
  };

  const lo4so = () => {
    return checkNumType(4, false);
  };

  const dedau = () => {
    return checkNumType(2, false);
  };

  const dedauduoi = () => {
    return checkNumType(2, false);
  };

  const dedacbiet = (isOne?: boolean) => {
    return checkNumType(2, false, isOne);
  };

  const dedaudacbiet = () => {
    return checkNumType(2, false);
  };

  const degiai7 = () => {
    return checkNumType(2, false);
  };

  const degiainhat = () => {
    return checkNumType(2, false);
  };

  const bacangdau = () => {
    return checkNumType(3, false);
  };

  const bacangdauduoi = () => {
    return checkNumType(3, false);
  };

  const bacangdacbiet = (isOne?: boolean) => {
    return checkNumType(3, false, isOne);
  };

  const boncangdacbiet = (isOne?: boolean) => {
    return checkNumType(4, false, isOne);
  };

  const lx2 = () => {
    return checkNumType(2, true);
  };

  const lx3 = () => {
    return checkNumType(3, true);
  };

  const lx4 = () => {
    return checkNumType(4, true);
  };

  const lt4 = () => {
    return checkNumType(4, true);
  };

  const lt8 = () => {
    return checkNumType(8, true);
  };

  const lt10 = () => {
    return checkNumType(10, true);
  };

  // Hàm xoá số bị duplicate
  const removeItemDupliArr = (arr: any) => {
    if (arr && arr.length === 0) {
      return;
    }
    let i;
    const len = arr.length;
    const result = [];
    const obj: any = {};
    let flag = false;

    // mẹo
    for (i = 0; i < len; i++) {
      if (obj[arr[i]] === undefined) {
        obj[arr[i]] = 0;
      } else {
        flag = true;
      }
    }

    // tslint:disable-next-line:forin
    for (i in obj) {
      result.push(i);
    }

    return { flag, result };
  };

  // Hàm sort số
  const sortNumber = (a: any, b: any): number => {
    a = parseInt(a, 10);
    b = parseInt(b, 10);
    if (isNaN(a) || isNaN(b)) {
      return 0;
    }
    return a - b;
  };

  // Hàm check độ dài của số
  const checkNumLen = (manyNum: number, num: any) => {
    let regexr: RegExp | undefined;

    switch (manyNum) {
      case 2:
        regexr = /^[0-9]{2}$/;
        break;
      case 3:
        regexr = /^[0-9]{3}$/;
        break;
      case 4:
        regexr = /^[0-9]{4}$/;
        break;
      default:
        break;
    }
    if (regexr) {
      return regexr.test(num);
    }
  };

  // Hàm format lại khoảng trắng
  const formatWhiteSpace = (val: string) => {
    let reformatStr = val.replace(/\s+/g, " ").trim();
    let arrTemp = [];
    if (
      methodType === "XIEN2" ||
      methodType === "XIEN3" ||
      methodType === "XIEN4" ||
      methodType === "LOTRUOT4SO" ||
      methodType === "LOTRUOT8SO" ||
      methodType === "LOTRUOT10SO"
    ) {
      let symbolTemp = ";";
      if (reformatStr.search(",") > 0) {
        symbolTemp = ",";
      }
      if (reformatStr.search(",") > 0 || reformatStr.search(";") > 0) {
        arrTemp = reformatStr.split(symbolTemp);
        for (let i = 0, len = arrTemp.length; i < len; i++) {
          arrTemp[i] = arrTemp[i].trim();
          arrTemp[i] = arrTemp[i].replace(/\s+/g, "-");
        }
        reformatStr = arrTemp.join(symbolTemp);
      } else if (reformatStr !== "") {
        reformatStr = reformatStr.replace(/\s+/g, "-");
      }
    }
    return reformatStr;
  };

  //Hàm xoá tất cả số đã chọn
  const handleRemoveSelected = () => {
    const removeListRandom = listRandom.map((item: any) => {
      item.isActive = false;
      return item;
    });
    setListRandom(removeListRandom);
    setListNumberChoiceFormated([]);
    if (textareaRef.current) {
      textareaRef.current.value = "";
    }
    setValueTextarea("");
    getCodes("");
  };

  return (
    <div className="enter-method-bet">
      <div className="header-list-random flex items-center">
        <div className="wrap-number-random flex items-center rounded overflow-hidden">
          {listRandom.length > 0 &&
            listRandom.map((num: any, index: number) => (
              <button
                type="button"
                className={classNames(
                  "wrap-number text-text-secondary dark:text-white/40 p-2 text-sm font-semibold rounded-sm mr-1 hover:text-black dark:hover:text-white"
                )}
                key={index}
                onClick={() => handleSelectCountNumber(num.id, num.number)}
              >
                {num.number} số
              </button>
            ))}
        </div>
        <button
          className="btn-remove bg-primary/[6%] hover:bg-primary/10 rounded-[10px] text-sm p-1.5 ml-2"
          type="button"
          onClick={() => handleRemoveSelected()}
        >
          <img
            src={require("assets/icons/trash.png")}
            className="icon-trash w-5 h-5"
            alt="Huỷ chọn số"
          />
        </button>
      </div>
      <div className="wrap-form-choice mt-5">
        <div className="wrap-textarea">
          <textarea
            className="textarea w-full bg-white dark:bg-bgPrimary border-borderLightPrimary dark:border-borderPrimary focus:border-primary p-3 text-text-secondary dark:text-white rounded outline-none text-sm placeholder:text-text-secondary dark:placeholder:text-white/40 border"
            rows={6}
            placeholder={placeholderTextarea}
            ref={textareaRef}
            defaultValue={valueTextarea}
            onKeyUp={handleChangeTextArea}
            onKeyPress={handleKeyPress}
          ></textarea>
        </div>
      </div>
      {listNumberChoiceFormated.length > 0 && (
        <div className="wrap-number-choice flex flex-wrap w-[calc(100%+16px)] -m-1">
          {listNumberChoiceFormated.map(
            (code: any, index: number) =>
              code.strCode !== "" && (
                <div
                  className={classNames(
                    "text-xs text-wrap min-w-[30px] text-center text-black dark:text-white break-words p-1.5 rounded border border-success-500 bg-success-100 dark:bg-success-1000 mx-1 mt-2"
                  )}
                  key={index}
                >
                  {code.strCode}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
}

export default EnterMethodBet;
