import classNames from "classnames";
import React, { useEffect, useState } from "react";

const TabSegmentComponent = (props: any) => {
  const { listTabs, handleChange, regionLotteryActive } = props;

  const [activeTab, setActiveTab] = useState<string>("mb");

  useEffect(() => {
    const checkTab = listTabs?.filter(
      (item: any) => item?.name === regionLotteryActive
    );
    if (checkTab?.length > 0) setActiveTab(checkTab[0]?.name);
  }, [regionLotteryActive, listTabs]);


  const handleChoiceTab = (name: string, data: any) => {
    setActiveTab(name);
    handleChange(data);
  };

  return (
    <div className="list-bet-method w-fit rounded-lg flex items-center flex-wrap p-1 border border-borderLightPrimary dark:border-borderPrimary bg-white dark:bg-bgPrimary mt-4">
      {listTabs?.length > 0 &&
        listTabs.map((item: any, index: number) => (
          <div
            className={classNames(
              "method-item px-2.5 py-1.5 rounded-md text-text-secondary dark:text-white/40 ml-1 cursor-pointer",
              {
                "!ml-0": index === 0,
                "!text-black dark:!text-white bg-surface-tertiary dark:bg-bgSecondary":
                  item?.name === activeTab,
              }
            )}
            onClick={() => handleChoiceTab(item?.name, item)}
            key={index}
          >
            {item.desc}
          </div>
        ))}
    </div>
  );
};

export default TabSegmentComponent;
