import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import classNames from "classnames";
import BottomSheet from "components/BottomSheet/BottomSheet";
import ChoiceMethodBetMobile from "components/ChoiceMethodBet/ChoiceMethodBetMobile";
import DigitalMethodBetMobile from "components/DigitalMethodBet/DigitalMethodBetMobile";
import EnterMethodBetMobile from "components/EnterMethodBet/EnterMethodBetMobile";
import Modal from "components/Modal/Modal";
import ModalBetMobile from "components/ModalBet/ModalBetMobile";
import MultipleComponent from "components/MultipleComponent/MultipleComponent";
import TableHistoryBetMobile from "components/TableHistoryBet/TableHistoryBetMobile";
import TableResultMobile from "components/TableResultMobile/TableResultMobile";
import TableTicketBetMobile from "components/TableTicketBet/TableTicketBetMobile";
import ToggleMode from "components/ToggleMode/ToggleMode";
import { setLoading } from "features/commonSlice";
import { addListTickets } from "features/lotterySlice";
import { addBalanceUser } from "features/userSlice";
import useToast from "hooks/useToast";
import { betLotteryI, modelLotteryI } from "interfaces/lottery.interface";
import { useEffect, useRef, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";
import { handleGetBalanceUser } from "utils/lotteryFormat";

const listMethodBet = [
  {
    id: 1,
    name: "Chọn số",
    type: "digital",
    typeid: "digital",
    isShow: true,
    isActive: true,
  },
  {
    id: 2,
    name: "Chọn số nhanh",
    type: "f-digital",
    typeid: "choice",
    isShow: true,
    isActive: false,
  },
  {
    id: 3,
    name: "Nhập số",
    type: "f-digital",
    typeid: "enter",
    isShow: true,
    isActive: false,
  },
];

function BetLotteryComponentMoblie(props: any) {
  const {
    listMethod,
    gameid,
    getCheck,
    previousIssue,
    statusUpdate,
    clearStatusUpdate,
    listIssue,
    getMethodTypeActive,
    getHadTicket,
    getListIssue,
  } = props;
  const dispatch = useDispatch();
  const toastify = useToast();

  const listMethodRef = useRef<any>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const wrapPopoverRef = useRef<HTMLDivElement | null>(null);
  const { configSeo } = useSelector((state: any) => state?.seo);

  const { listTickets, currentIssue } = useSelector(
    (state: any) => state.lottery
  );
  const { balance } = useSelector((state: any) => state.user);

  //bet method and bet type
  const [methodBetList, setMethodBetList] = useState<any>(listMethodBet);
  const [typePick, setTypePick] = useState<string>("digital");
  const [typeBet, setTypeBet] = useState<string>("digital");
  const [typeMethodBet, setTypeMethodBet] = useState<string>(
    listMethod[0]?.checkflag
  );
  const [methodActivated, setMethodActivated] = useState<any>();

  // bet content
  const [countNumberBet, setCountNumberBet] = useState<number>(1);
  const [codes, setCodes] = useState<string>("");
  const [amountBet, setAmountBet] = useState<number>(0);
  const [ticketBet, setTicketBet] = useState<betLotteryI | null>(null);
  const [multipleBet, setMultipleBet] = useState<number>(1);
  const [statusBet, setStatusBet] = useState<boolean>(false);
  const [showModalBet, setShowModalBet] = useState<boolean>(false);
  const [betContent, setBetContent] = useState<string>("");
  const [showBottomSheet, setShowBottomSheet] = useState<any>({
    isShow: false,
    type: "",
  });
  const [statusScrollBottom, setStatusScrollBottom] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [paramsStressTest, setParamsStress] = useState<any>();
  const [showDropdownMethod, setShowDropdownMethod] = useState<boolean>(false);
  const [toggleMethod1K, setToggleMethod1K] = useState<boolean>(false);
  const [showDescMethod, setShowDescMethod] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        listMethodRef.current &&
        !listMethodRef.current.contains(event.target)
      ) {
        setShowDropdownMethod(false);
      }
    };

    const handleOnScroll = () => {
      if (showDropdownMethod) setShowDropdownMethod(false);
      if (showDescMethod) setShowDescMethod(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleOnScroll);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleOnScroll);
    };
  }, []);

  useEffect(() => {
    if (popoverRef.current) {
      const handleClickOutside = (event: any) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target) &&
          wrapPopoverRef.current &&
          !wrapPopoverRef.current.contains(event.target)
        ) {
          setShowDescMethod(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef]);

  useEffect(() => {
    let method = !methodActivated
      ? listMethod?.find((method: any) =>
          gameid !== 201
            ? method.checkflag === "LO2SO"
            : method.checkflag === "DEDACBIETNEW"
        )
      : listMethod?.find(
          (item: any) => item.checkflag === listMethod[0]?.checkflag
        );
    setMethodActivated(method);
    getMethodTypeActive(method);
    if (!methodActivated) {
      handleBetType(method?.checkflag);
    }
    handleClearTicket();
    setToggleMethod1K(false);
  }, [listMethod, gameid]);

  useEffect(() => {
    const listMethodBetConverted = methodBetList.map((item: any) => {
      const isActive = item.id === 1 ? true : false;
      return { isActive: isActive, ...item };
    });
    setMethodBetList(listMethodBetConverted);
    handleClearTicket();
  }, [typePick, typeMethodBet]);

  useEffect(() => {
    const amount =
      Number(multipleBet) *
      Number(methodActivated?.payprizenum) *
      Number(countNumberBet);
    setAmountBet(amount);
    if (codes.length > 0 && countNumberBet !== 0) {
      const ticket: betLotteryI = {
        gameid: Number(gameid),
        methodName: `${methodActivated?.groupbet} - ${methodActivated?.methodname}`,
        odd: methodActivated?.level1,
        methodid: Number(methodActivated?.methodid),
        curtimes: Date.now().toString(),
        codes: codes,
        bettype: typeBet,
        amount: Number(amount),
        betnumber: countNumberBet,
        multiple: multipleBet,
        betcontent: betContent,
        issue: Number(gameid) === 50 ? "0" : currentIssue?.issue,
      };
      setTicketBet(ticket);
      getHadTicket(true);
    } else {
      handleClearTicket();
      getHadTicket(false);
    }
  }, [
    multipleBet,
    countNumberBet,
    currentIssue,
    methodActivated,
    codes,
    gameid,
    betContent,
    typeBet,
  ]);

  // Hàm check method cược
  const handleMethodBet = (id: number, type: string, typeBet: string) => {
    const newListMethodBet = methodBetList.map((item: any) => {
      if (item.id === id) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setMethodBetList(newListMethodBet);
    setTypePick(type);
    setTypeBet(typeBet);
  };

  // Hàm check type cược
  const handleBetType = (type: string) => {
    const newlistMethodBet = methodBetList.map((item: any) => {
      if (
        [
          "XIEN2",
          "XIEN3",
          "XIEN4",
          "LOTRUOT4SO",
          "LOTRUOT8SO",
          "LOTRUOT10SO",
        ].includes(type)
      ) {
        item.isShow = item.typeid !== "digital";
        item.isActive = item.typeid === "choice";
        setTypePick("choice");
      } else {
        if (["DAU", "DUOI"].includes(typeMethodBet)) {
          item.isShow = item.typeid !== "choice" && item.typeid !== "enter";
        } else if (["4CANGDACBIET", "LO4SO"].includes(typeMethodBet)) {
          item.isShow = item.typeid !== "choice";
        } else {
          item.isShow = true;
        }
        item.isActive = item.typeid === "digital";
        setTypePick("digital");
      }
      return item;
    });
    setTypeMethodBet(type);
    setMethodBetList(newlistMethodBet);
  };

  // Hàm lấy số cược
  const handleGetCodesNumber = (code: string, codeLength: number) => {
    if (code !== "" && codeLength !== 0) {
      const amount =
        Number(multipleBet) *
        (Number(codeLength) * Number(methodActivated?.payprizenum));
      const codeSort = code
        .split(",")
        .sort((a: any, b: any) => a - b)
        .join(",");
      setStatusBet(true);
      setCountNumberBet(codeLength);
      setCodes(codeSort);
      setAmountBet(amount);
      const betContentActive = `[${methodActivated?.groupbet} | ${methodActivated?.methodname}] - ${code}`;
      setBetContent(betContentActive);
    } else {
      handleClearTicket();
    }
  };

  // Hàm lấy cấp số nhân
  const handleGetMultiple = (mul: number) => {
    setMultipleBet(mul);
  };

  // Hàm thêm vé cược
  const handleAddTicketBet = () => {
    if (!ticketBet) {
      return;
    } else {
      const tickets = [...listTickets, ticketBet];
      dispatch(addListTickets(tickets));
      handleClearTicket();
      toastify("Đã thêm vé chờ!", "success");
      setStatusBet(false);
    }
  };

  const handleSubmitBet = async (e: any) => {
    e.preventDefault();

    let totalAmountBet = ticketBet?.amount;
    if (Number(totalAmountBet) > balance) {
      setShowModalBet(false);
      toastify("Số dư không đủ!", "warning");
      return;
    }
    if (isSubmitting) {
      return;
    }
    if (ticketBet) {
      setIsSubmitting(true);
      dispatch(setLoading(true));
      try {
        const { data } = await lotteryApi.submitBetLottery(
          [ticketBet],
          paramsStressTest
        );
        if (data.error_code === 0) {
          getCheck(true);
          toastify("Đặt vé thành công!", "success");
          dispatch(addListTickets([]));
          handleGetBalanceUser();
          handleCloseModalBet();
          handleClearTicket();
          return;
        }
      } catch (error: any) {
        // if (!error.msg) toastify(error.msg, "error");
        console.log(error);
      } finally {
        setIsSubmitting(false);
        dispatch(setLoading(false));
        setShowModalBet(false);
      }
    }
  };

  const handleCancelTicket = () => {
    handleClearTicket();
    setShowModalBet(false);
    setStatusBet(false);
  };

  const handleCloseModalBet = () => {
    setShowModalBet(false);
    setStatusBet(false);
  };

  // Hàm clear vé cược
  const handleClearTicket = () => {
    setCountNumberBet(0);
    setAmountBet(0);
    setTicketBet(null);
    setMultipleBet(1);
  };

  const handleGetMethodBetActivated = (method: any) => {
    setMethodActivated(method);
    handleBetType(method?.checkflag);
    getMethodTypeActive(method);
    setShowDropdownMethod(false);
  };

  const handleToggle1k = (check: boolean) => {
    setToggleMethod1K(check);
    let method: any;
    if (check) {
      const checkFlagWith1K = methodActivated?.checkflag + "1K";
      method = listMethod.find(
        (method: modelLotteryI) => method.checkflag === checkFlagWith1K
      );
    } else {
      const originalCheckFlag = methodActivated?.checkflag.replace("1K", "");
      method = listMethod.find(
        (method: modelLotteryI) => method.checkflag === originalCheckFlag
      );
    }
    setMethodActivated(method);
    getMethodTypeActive(method);
  };

  const checkFor1KMethod = () => {
    const checkFlagWith1K = methodActivated?.checkflag.includes("1K")
      ? methodActivated?.checkflag
      : methodActivated?.checkflag + "1K";
    const originalCheckflag = methodActivated?.checkflag.includes("1K")
      ? methodActivated?.checkflag.replace("1K", "")
      : methodActivated?.checkflag;
    const exists = listMethod.some(
      (method: modelLotteryI) =>
        method.checkflag === checkFlagWith1K &&
        originalCheckflag === checkFlagWith1K.replace("1K", "")
    );
    return exists;
  };

  return (
    <>
      <div
        className={classNames(
          "bet-lottery bg-white dark:bg-bgPrimary w-full h-full"
        )}
      >
        <div className="wrap-tabs w-full py-2.5 px-4 flex items-center justify-between">
          {/* select option method bet */}
          <div
            className="flex items-center relative"
            onClick={() => setShowDropdownMethod(!showDropdownMethod)}
          >
            <div className="py-1.5 px-2.5 rounded-[10px] bg-transparent border border-borderLightPrimary dark:border-borderPrimary flex items-center min-w-32">
              <span className="method-name w-full text-sm text-black dark:text-white font-medium mr-1">
                {methodActivated?.methodname.replace(/1K$/, "")}
              </span>
              <RiArrowDownSLine
                className="text-text-secondary dark:text-white/40"
                size={24}
              />
            </div>
            <div
              className={classNames(
                "absolute top-10 left-0 -z-50 opacity-0 bg-white dark:bg-bgPrimary max-h-0 rounded-[10px] w-[200px] overflow-auto border border-borderLightPrimary dark:border-borderPrimary shadow-2xl transition-all duration-200 ease-in-out",
                { "!max-h-80 !opacity-100 !z-50": showDropdownMethod }
              )}
              ref={listMethodRef}
            >
              {listMethod?.map((method: any, index: number) => (
                <div
                  className={classNames(
                    "w-full px-3 py-2.5 text-sm font-semibold text-text-secondary dark:text-white/40 active:text-black dark:active:text-white",
                    { "!hidden": method.checkflag.includes("1K") }
                  )}
                  onClick={() => handleGetMethodBetActivated(method)}
                  key={index}
                >
                  {method.methodname}
                </div>
              ))}
            </div>
          </div>
          {/* list button */}
          <div className="flex items-center">
            <button
              type="button"
              className="flex flex-col items-center mr-2 p-2.5 rounded-[10px] bg-secondary/10"
              onClick={() =>
                setShowBottomSheet({ isShow: true, type: "history" })
              }
            >
              <img src={icons.pending} className="w-5 h-5" alt="Lịch sử chơi" />
            </button>
            <button
              type="button"
              className="flex flex-col items-center mr-2 p-2.5 rounded-[10px] bg-secondary/10"
              onClick={() =>
                setShowBottomSheet({ isShow: true, type: "result" })
              }
            >
              <img src={icons.assignment} className="w-5 h-5" alt="Kết quả" />
            </button>
            <div className="relative">
              <button
                type="button"
                className="flex flex-col items-center p-2.5 rounded-[10px] bg-secondary/10"
                onClick={() =>
                  setShowBottomSheet({ isShow: true, type: "ticket" })
                }
              >
                <img src={icons.ticket} className="w-5 h-5" alt="Vé chờ" />
              </button>
              {listTickets?.length > 0 && (
                <span className="h-4 min-w-4 flex items-center justify-center rounded-full text-xs text-white bg-base-error absolute -top-1 -right-1">
                  {listTickets?.length}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="bet-lottery-container w-full bg-inherit p-4 pt-2">
          <div className="bet-info-wrap rounded-lg">
            <div className="flex items-center justify-between mb-4">
              {checkFor1KMethod() && (
                <div className="flex items-center justify-start">
                  <>
                    <ToggleMode
                      size="medium"
                      onToggle={handleToggle1k}
                      checked={toggleMethod1K}
                    />
                    <span className="text-sm text-black dark:text-white ml-2">
                      Đặt vé <b>1k</b>
                    </span>
                  </>
                </div>
              )}
              <div
                className="flex items-center cursor-pointer relative text-text-secondary dark:text-white/40"
                onClick={() => setShowDescMethod(!showDescMethod)}
                ref={wrapPopoverRef}
              >
                <img
                  src={icons.dangerCircle}
                  className="w-5 h-5 mr-1"
                  alt="Cách chơi"
                />
                <span className="text-sm">Cách chơi</span>
                <div
                  className={`absolute bg-surface-primary dark:bg-bgSecondary shadow-sm top-7 ${
                    checkFor1KMethod() ? "right-0" : "left-0"
                  } w-[320px] transition-all duration-500 ${
                    showDescMethod ? "opacity-100 z-40" : "opacity-0 : -z-50"
                  } p-4 rounded-[10px]`}
                  ref={popoverRef}
                >
                  <span className="text-black dark:text-white text-sm">
                    {methodActivated?.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="bet-method-type w-fit flex mb-3 border border-borderLightPrimary dark:border-borderPrimary p-1 rounded-md">
              {methodBetList.length > 0 &&
                methodBetList.map((item: any) => (
                  <div
                    className={classNames(
                      "method-bet-item text-text-secondary dark:text-white/40 text-nowrap rounded-md px-2.5 py-1 text-sm font-semibold cursor-pointer",
                      {
                        "!text-black dark:!text-white bg-surface-tertiary dark:bg-bgSecondary":
                          item.isActive === true,
                        none: item.isShow === false,
                      }
                    )}
                    key={item.id}
                    onClick={() =>
                      handleMethodBet(item.id, item.typeid, item.type)
                    }
                  >
                    {item.name}
                  </div>
                ))}
            </div>

            <div className="bet-method-wrap">
              {typePick === "digital" &&
                ![
                  "XIEN2",
                  "XIEN3",
                  "XIEN4",
                  "LOTRUOT4SO",
                  "LOTRUOT8SO",
                  "LOTRUOT10SO",
                ].includes(typeMethodBet) && (
                  <DigitalMethodBetMobile
                    typeBet={typeMethodBet}
                    getCodes={handleGetCodesNumber}
                    statusBet={statusBet}
                  />
                )}{" "}
              {typePick === "choice" && (
                <ChoiceMethodBetMobile
                  methodType={typeMethodBet}
                  getCodes={handleGetCodesNumber}
                  statusBet={statusBet}
                />
              )}{" "}
              {typePick === "enter" && (
                <EnterMethodBetMobile
                  methodType={typeMethodBet}
                  getCodes={handleGetCodesNumber}
                  statusBet={statusBet}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-[430px] btn-action-wrap flex flex-col fixed bottom-0 z-[25] p-4 pb-5 bg-white dark:bg-bgPrimary shadow-[0px_-3px_6px_0px_#00000026]">
        {ticketBet && (
          <div className="bet-action-wrap flex flex-col items-center">
            <div className="w-full flex items-center justify-between mb-2">
              <span className="choiced text-sm text-text-secondary dark:text-white/40">
                Đã chọn{" "}
                <b className="text-black dark:text-white">
                  {countNumberBet} số
                </b>
              </span>
              <div className="flex items-center bg-primary/10 border rounded-[10px] border-white/10 py-2 px-3">
                <span className="text-sm text-black dark:text-white font-medium">
                  Số điểm{" "}
                  <b className="strong !text-warning-500 text-sm ml-1">
                    {amountBet > 0 ? currencyFormat(amountBet) : 0}
                  </b>
                </span>
              </div>
            </div>
            <div className="w-full flex items-center justify-between mb-4">
              <div className="text-sm text-text-secondary dark:text-white/40 font-medium flex flex-col">
                Tỉ lệ
                <span className="text-error-500 px-3 py-1 rounded-[10px] border border-borderLightPrimary dark:border-borderPrimary mt-1">
                  1 ăn {methodActivated?.level1}
                </span>
              </div>
              <div className="text-sm text-text-secondary dark:text-white/40 font-medium flex flex-col">
                Cấp số nhân
                <div className="mt-1">
                  <MultipleComponent
                    multipleValue={multipleBet}
                    getMultiple={handleGetMultiple}
                    statusBet={statusBet}
                    methodActive={methodActivated}
                    ticketBet={ticketBet}
                    isMobile
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-between">
          <button
            className={classNames(
              "btn-add border border-transparent bg-primary/10 active:bg-primary/20 flex items-center justify-center w-full text-primary px-3 py-2.5 rounded-[10px] text-sm font-semibold mr-[3px]",
              { disable: !ticketBet }
            )}
            type="button"
            onClick={() => handleAddTicketBet()}
          >
            <img src={icons.add} className="w-5 h-5 mr-1" alt="hêm vé chơi" />
            Thêm vé chơi
          </button>
          <button
            className={classNames(
              "btn-bet w-full flex items-center justify-center bg-primary active:opacity-90 border border-transparent text-white px-3 py-2.5 rounded-[10px] text-sm font-semibold ml-[3px]",
              { disable: !ticketBet }
            )}
            type="button"
            onClick={() => setShowModalBet(true)}
          >
            <img src={icons.bolt} className="w-5 h-5 mr-1" alt="Chơi ngay" />
            Chơi ngay
          </button>
        </div>
      </div>

      <Modal
        isShow={showModalBet}
        onClose={() => setShowModalBet(false)}
        width="300"
      >
        <ModalBetMobile
          onClose={handleCloseModalBet}
          clearTicket={handleCancelTicket}
          ticket={ticketBet}
          typeModalBet="bet"
          issue={currentIssue}
          handleSubmit={handleSubmitBet}
          getStressTest={(stressTest: any) => setParamsStress(stressTest)}
        />
      </Modal>
      <BottomSheet
        isShow={showBottomSheet.isShow}
        onClose={() => setShowBottomSheet({ isShow: false, type: "" })}
        onScrollToBottom={(status: boolean) => {
          if (showBottomSheet.type === "history") setStatusScrollBottom(status);
        }}
        direction="vertical"
        height={showBottomSheet.type === "history" ? "100%" : "90%"}
        title={
          showBottomSheet.type === "ticket"
            ? `Vé Chờ (${listTickets?.length})`
            : ""
        }
        noClickOutSide
      >
        {showBottomSheet.isShow &&
          (showBottomSheet.type === "history" ? (
            <TableHistoryBetMobile
              statusScroll={statusScrollBottom}
              isShow={showBottomSheet.isShow}
              gameid={gameid}
              statusBet={statusBet}
              onClose={() => setShowBottomSheet({ isShow: false, type: "" })}
            />
          ) : showBottomSheet.type === "result" ? (
            <TableResultMobile
              type="all"
              method={methodActivated}
              gameid={gameid}
              currentIssue={currentIssue}
              previousIssue={previousIssue}
              statusUpdate={statusUpdate}
              clearStatusUpdate={clearStatusUpdate}
              typeComponent="bottomsheet"
              getListIssue={getListIssue}
              listIssue={listIssue}
              onClose={() => setShowBottomSheet({ isShow: false, type: "" })}
            />
          ) : (
            <TableTicketBetMobile currentIssue={currentIssue} />
          ))}
      </BottomSheet>
    </>
  );
}

export default BetLotteryComponentMoblie;
