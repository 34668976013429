import React from "react";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import SectionContact from "./components/SectionContact";
import { listSocial } from "constant";
import classNames from "classnames";
import { useSelector } from "react-redux";

const LandingPage = () => {
  const { isMobile } = useSelector((state: any) => state.layout);
  const { configSeo } = useSelector((state: any) => state?.seo);

  return (
    <div className="w-full h-full bg-surface-primary dark:bg-[#0E1013] flex items-center justify-center relative">
      <div
        className={classNames(
          "absolute top-0 left-0 w-full bg-[#0E1013] h-[80vh] z-10"
        )}
      >
        <img
          src={require("assets/images/landingpage/bg-section.png")}
          className="bg-bgSection bg-cover object-cover max-w-full"
          alt={configSeo?.title}
        />
      </div>

      <div className="h-full w-full relative z-20">
        <Section1 />
        <div className="bg-surface-primary dark:bg-transparent flex justify-center items-center">
          <div className="max-w-[1280px] w-full">
            <Section2 />
          </div>
        </div>
        <div className="bg-white dark:bg-transparent flex justify-center items-center">
          <div className="max-w-[1280px] w-full">
            <Section3 />
          </div>
        </div>
        <div className="bg-surface-primary dark:bg-transparent flex justify-center items-center">
          <div className="max-w-[1280px] w-full">
            <Section4 />
          </div>
        </div>
        <div className="bg-white dark:bg-transparent flex justify-center items-center">
          <div className="max-w-[1280px] w-full">
            <SectionContact />
          </div>
        </div>
        <div className="bg-surface-primary dark:bg-transparent flex justify-center items-center">
          <div className="max-w-[1280px] w-full">
            <div
              className={classNames(
                "w-full flex items-center justify-between py-5 px-4",
                {
                  "flex-col !items-start": isMobile,
                }
              )}
            >
              <div className={classNames("bottom-left", { "mb-4": isMobile })}>
                <p className="flex items-center text-xs font-medium text-text-secondary dark:text-white/40">
                  <span className="mr-2">2024</span>
                  <span className="mr-2">©TroLyXoSo</span>
                  <span>Bản quyền đã được bảo lưu</span>
                </p>
              </div>
              <div className="bottom-right flex items-center">
                {listSocial.map((social: any, index: number) => (
                  <div
                    className={classNames("w-8 h-8 group cursor-pointer", {
                      "ml-10": index > 0,
                    })}
                    key={index}
                  >
                    <img
                      src={social.iconDefault}
                      className="w-full h-full block group-hover:hidden"
                      alt={configSeo?.title}
                    />
                    <img
                      src={social.iconHover}
                      className="w-full h-full hidden group-hover:block"
                      alt={configSeo?.title}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
