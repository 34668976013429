import React, { useEffect, useState } from "react";
import { getRandomNumbers } from "utils/commonFormat";

const RandomNumberCount = ({ length }: { length: number }) => {
  const [randomNumbers, setRandomNumbers] = useState<string>("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      let result = "";
      for (let i = 0; i < length; i++) {
        result += getRandomNumbers();
      }
      setRandomNumbers(result);
    }, 100); // Cứ mỗi 100ms số sẽ thay đổi

    // Dọn dẹp interval khi component unmount
    return () => clearInterval(intervalId);
  }, [length]);

  return (
    <span className="text-sm font-semibold text-black dark:text-white text-center h-4">
      {randomNumbers}
    </span>
  );
};

export default RandomNumberCount;
