import classNames from "classnames";
import useToast from "hooks/useToast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const listContent1 = [
  { id: 1, title: "Soi kèo, tra cứu tỉ lệ nhanh chóng", iconurl: "bolt" },
  { id: 2, title: "Phân tích dữ liệu thông minh", iconurl: "light" },
  {
    id: 3,
    title: "Công nghệ tân tiến, tích hợp liền mạch",
    iconurl: "electron",
  },
];

const listContent2 = [
  { id: 1, title: "Truy vấn bất cứ điều gì", iconurl: "bolt" },
  { id: 2, title: "Cải thiện hằng ngày", iconurl: "light" },
  {
    id: 3,
    title: "Phản hồi nhanh",
    iconurl: "electron",
  },
];

const Section4 = () => {
  const navigate = useNavigate();
  const toastify = useToast();
  const { isMobile, isTablet, mode } = useSelector(
    (state: any) => state.layout
  );
  const { isAuth } = useSelector((state: any) => state.auth);
  const handleNavigate = (path: string) => {
    if (!isAuth) {
      toastify("Vui lòng đăng nhập!", "warning");
      return;
    }
    navigate(path);
  };

  return (
    <section
      className={classNames(
        "w-full flex flex-col items-center justify-center px-4 py-[120px]",
        { "!py-[70px]": isMobile }
      )}
    >
      <div className="relative flex flex-col items-center justify-center z-20">
        <img
          src={require("assets/images/landingpage/circle.png")}
          className="w-full h-auto absolute -top-20 mx-auto z-10"
          alt="Trí tuệ nhân tạo AI được ứng dụng vào xổ số"
        />
        <div className="mb-10 relative z-20">
          <h2
            className={classNames(
              "text-linear-primary font-semibold text-[48px] text-center",
              { "!text-4xl": isMobile, "text-linear-primary-dark": mode === 0 }
            )}
          >
            Lần đầu tiên tại Việt Nam
          </h2>
          <h2
            className={classNames(
              "text-linear-primary font-semibold text-[48px] text-center",
              { "!text-4xl": isMobile, "text-linear-primary-dark": mode === 0 }
            )}
          >
            Trí tuệ nhân tạo AI được ứng dụng vào xổ số
          </h2>
        </div>
        <div
          className={classNames("w-3/5 relative z-20", { "!w-full": isMobile })}
        >
          <p className="text-base font-medium text-text-secondary text-center">
            Không chỉ mang đến cho người dùng tin tức về xổ số cũng như tham gia
            đặt vé trực tuyến mà chúng tôi TroLyXoSo.Com còn tích hợp Công nghệ
            ChatAI giúp người chơi truy vấn kết quả, soi kèo, cũng như là thống
            kê xu hướng, đưa ra các con số có tỉ lệ trúng cao. Chúng tôi luôn
            phát triển và mong muốn mang đến cho người chơi những trải nghiệm
            tốt nhất.{" "}
          </p>
          <p className="text-base font-medium text-text-secondary text-center">
            TroLyXoSo không chỉ là trang xổ số uy tín hàng đầu mà còn là người
            bạn đồng hành mang đến những cảm xúc vô tận.
          </p>
        </div>
      </div>
      <div
        className={classNames("w-full flex flex-col mt-[120px]", {
          "!flex-row": isTablet,
          "!mt-20": isMobile,
        })}
      >
        <div
          className={classNames("w-full grid grid-cols-3 mb-10", {
            "!grid-cols-1 !w-1/2 !mb-0": isTablet,
            "!grid-cols-1 !mb-0": isMobile,
          })}
        >
          {listContent1.map((item: any, index: number) => (
            <div
              className={classNames("flex items-center col-span-1", {
                "mt-7": (isTablet || isMobile) && index > 0,
              })}
              key={index}
            >
              <img
                src={require(`assets/icons/btn-${item.iconurl}${
                  mode === 1 ? "" : "-light"
                }.png`)}
                className="w-16 h-10 mr-4"
                alt={item.title}
              />
              <h4 className="text-black dark:text-white font-semibold">
                {item.title}
              </h4>
            </div>
          ))}
        </div>
        <div
          className={classNames("w-full grid grid-cols-3", {
            "!grid-cols-1 !w-1/2": isTablet,
            "!grid-cols-1": isMobile,
          })}
        >
          {listContent2.map((item: any, index: number) => (
            <div
              className={classNames("flex items-center col-span-1", {
                "mt-7": isMobile,
                "!mt-7": isTablet && index > 0,
              })}
              key={index}
            >
              <img
                src={require(`assets/icons/btn-${item.iconurl}${
                  mode === 1 ? "" : "-light"
                }.png`)}
                className="w-16 h-10 mr-4"
                alt={item.title}
              />
              <h4 className="text-black dark:text-white font-semibold">
                {item.title}
              </h4>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames("w-full flex items-center mt-[180px]", {
          "flex-col !mt-20": isMobile,
        })}
      >
        <div
          className={classNames("flex flex-col justify-center mr-5", {
            "!mr-0 !mb-20": isMobile,
          })}
        >
          <h2
            className={classNames(
              "text-linear-primary font-semibold w-fit text-4xl",
              { "text-linear-primary-dark": mode === 0 }
            )}
          >
            Mở khoá sức mạnh
          </h2>
          <h2
            className={classNames(
              "text-linear-primary font-semibold w-fit text-4xl mb-10",
              { "text-linear-primary-dark": mode === 0 }
            )}
          >
            Nâng tầm đẳng cấp
          </h2>
          <p className="text-sm text-text-secondary dark:text-white/40 font-medium mb-10 text-wrap break-words">
            Với tính năng tự động hoá thông minh, TroLyXoSo luôn là người trợ lý
            đồng hành cùng bạn.
          </p>
          <button
            className="bg-bgPrimary w-fit text-white rounded-[10px] py-3 px-11 hover:opacity-80 border-light-hover"
            type="button"
            onClick={() => handleNavigate("/tro-ly-xo-so")}
          >
            <h3 className="relative z-[3] w-full h-full">Chat ngay</h3>
          </button>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={require("assets/images/landingpage/chat-ai-desktop.png")}
            className="w-full h-full"
            alt="Mở khoá sức mạnh nâng tầm đẳng cấp"
          />
        </div>
      </div>
    </section>
  );
};

export default Section4;
