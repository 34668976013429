import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import classNames from "classnames";
import BetTypeComponent from "components/BetTypeComponent/BetTypeComponent";
import ChoiceMethodBet from "components/ChoiceMethodBet/ChoiceMethodBet";
import DigitalMethodBet from "components/DigitalMethodBet/DigitalMethodBet";
import EnterMethodBet from "components/EnterMethodBet/EnterMethodBet";
import Modal from "components/Modal/Modal";
import ModalBet from "components/ModalBet/ModalBet";
import MultipleComponent from "components/MultipleComponent/MultipleComponent";
import ToggleMode from "components/ToggleMode/ToggleMode";
import { setLoading } from "features/commonSlice";
import { addListTickets } from "features/lotterySlice";
import { addBalanceUser } from "features/userSlice";
import useToast from "hooks/useToast";
import { betLotteryI, modelLotteryI } from "interfaces/lottery.interface";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";
import "./BetLotteryComponent.scss";
import { handleGetBalanceUser } from "utils/lotteryFormat";

const listTypeBet = [
  {
    id: 1,
    name: "Chọn số",
    type: "digital",
    typeid: "digital",
    isShow: true,
    isActive: true,
  },
  {
    id: 2,
    name: "Chọn số nhanh",
    type: "f-digital",
    typeid: "choice",
    isShow: true,
    isActive: false,
  },
  {
    id: 3,
    name: "Nhập số",
    type: "f-digital",
    typeid: "enter",
    isShow: true,
    isActive: false,
  },
];

function BetLotteryComponent(props: any) {
  const { listMethod, gameid, getCheck, getMethodTypeActive } = props;
  const dispatch = useDispatch();
  const toastify = useToast();

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const wrapPopoverRef = useRef<HTMLDivElement | null>(null);
  const { configSeo } = useSelector((state: any) => state?.seo);
  const { listTickets, currentIssue, listGames } = useSelector(
    (state: any) => state.lottery
  );
  const { balance } = useSelector((state: any) => state.user);

  //bet method and bet type
  const [typeBetList, setTypeBetList] = useState<any>(listTypeBet);
  const [typePick, setTypePick] = useState<string>("digital");
  const [typeBet, setTypeBet] = useState<string>("digital");
  const [typeMethodBet, setTypeMethodBet] = useState<string>(
    listMethod[0]?.checkflag
  );
  const [methodActive, setMethodActive] = useState<modelLotteryI>();

  // bet content
  const [countNumberBet, setCountNumberBet] = useState<number>(1);
  const [codes, setCodes] = useState<string>("");
  const [amountBet, setAmountBet] = useState<number>(0);
  const [ticketBet, setTicketBet] = useState<betLotteryI | null>(null);
  const [multipleBet, setMultipleBet] = useState<number>(1);
  const [statusBet, setStatusBet] = useState<boolean>(false);
  const [showModalBet, setShowModalBet] = useState<boolean>(false);
  const [gameId, setgameId] = useState<number>(0);
  const [betContent, setBetContent] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDescMethod, setShowDescMethod] = useState<boolean>(false);
  const [toggleMethod1K, setToggleMethod1K] = useState<boolean>(false);
  const [imageThumbnail, setImageThumbnail] = useState<string>("");

  useEffect(() => {
    if (gameid) {
      setgameId(gameid);
      setStatusBet(false);
      const imageByGame = listGames?.find(
        (game: any) => game.id === gameid
      )?.imageurl;
      setImageThumbnail(imageByGame);
    }
  }, [gameid, listGames]);

  useEffect(() => {
    let method: any;
    method = listMethod?.find((item: any) => item.checkflag === typeMethodBet);
    setMethodActive(method);
    getMethodTypeActive(method);
    handleClearTicket();
    setToggleMethod1K(false);
  }, [listMethod, gameid, typeMethodBet]);

  useEffect(() => {
    const listMethodBetConverted = typeBetList.map((item: any) => {
      const isActive = item.id === 1 ? true : false;
      return { isActive: isActive, ...item };
    });
    const typeBetFind = listMethodBetConverted.find(
      (method: any) => method.isActive === true
    );
    setTypeBetList(listMethodBetConverted);
    setTypeBet(typeBetFind.type);

    handleClearTicket();
  }, [typePick, typeMethodBet]);

  useEffect(() => {
    const amount =
      Number(multipleBet) *
      Number(methodActive?.payprizenum) *
      Number(countNumberBet);
    setAmountBet(amount);
    if (codes.length > 0 && countNumberBet !== 0) {
      const ticket: betLotteryI = {
        gameid: Number(gameId),
        methodName: `${methodActive?.groupbet} - ${methodActive?.methodname}`,
        odd: methodActive?.level1,
        methodid: Number(methodActive?.methodid),
        curtimes: Date.now().toString(),
        codes: codes,
        bettype: typeBet,
        amount: Number(amount),
        betnumber: countNumberBet,
        multiple: multipleBet,
        betcontent: betContent,
        issue: Number(gameId) === 50 ? "0" : currentIssue?.issue,
      };
      setTicketBet(ticket);
    } else {
      handleClearTicket();
    }
  }, [multipleBet, countNumberBet, currentIssue, methodActive, codes, gameId]);

  useEffect(() => {
    if (popoverRef.current) {
      const handleClickOutside = (event: any) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target) &&
          wrapPopoverRef.current &&
          !wrapPopoverRef.current.contains(event.target)
        ) {
          setShowDescMethod(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef]);

  const handleMethodBet = (id: number, type: string, typeBet: string) => {
    const newListMethodBet = typeBetList.map((item: any) => {
      if (item.id === id) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setTypeBetList(newListMethodBet);
    setTypePick(type);
    setTypeBet(typeBet);
  };

  const handleBetType = (type: string) => {
    const newlistMethodBet = typeBetList.map((item: any) => {
      if (
        type === "XIEN2" ||
        type === "XIEN3" ||
        type === "XIEN4" ||
        type === "LOTRUOT4SO" ||
        type === "LOTRUOT8SO" ||
        type === "LOTRUOT10SO"
      ) {
        item.isShow = item.typeid !== "digital";
        item.isActive = item.typeid === "choice";
        setTypePick("choice");
      } else {
        if (type === "DAU" || type === "DUOI") {
          item.isShow = item.typeid !== "choice" && item.typeid !== "enter";
        } else if (type === "4CANGDACBIET" || type === "LO4SO") {
          item.isShow = item.typeid !== "choice";
        } else {
          item.isShow = true;
        }
        item.isActive = item.typeid === "digital";
        setTypePick("digital");
      }
      return item;
    });
    setTypeMethodBet(type);
    setTypeBetList(newlistMethodBet);
  };

  const handleGetCodesNumber = (code: string, codeLength: number) => {
    if (code !== "" && codeLength !== 0) {
      const amount =
        Number(multipleBet) *
        (Number(codeLength) * Number(methodActive?.payprizenum));
      const codeSort = code
        .split(",")
        .sort((a: any, b: any) => a - b)
        .join(",");
      setStatusBet(true);
      setCountNumberBet(codeLength);
      setCodes(codeSort);
      setAmountBet(amount);
      const betContentActive = `[${methodActive?.groupbet} | ${methodActive?.methodname}] - ${code}`;
      setBetContent(betContentActive);
    } else {
      handleClearTicket();
    }
  };

  const handleGetMultiple = (mul: number) => {
    setMultipleBet(mul);
  };

  const handleAddTicketBet = () => {
    if (!ticketBet) {
      return;
    } else {
      const tickets = [...listTickets, ticketBet];
      dispatch(addListTickets(tickets));
      handleClearTicket();
      setStatusBet(false);
    }
  };

  const handleClearTicket = () => {
    setCountNumberBet(0);
    setAmountBet(0);
    setTicketBet(null);
    setMultipleBet(1);
  };

  const handleCancelTicket = () => {
    handleClearTicket();
    setShowModalBet(false);
    setStatusBet(false);
  };

  const handleCloseModalBet = () => {
    setShowModalBet(false);
    setStatusBet(false);
  };

  const handleSubmitBet = async (e: any) => {
    let totalAmountBet = ticketBet?.amount;
    if (Number(totalAmountBet) > balance) {
      setShowModalBet(false);
      toastify("Số dư không đủ!", "warning");
      return;
    }
    if (isSubmitting) {
      return;
    }
    if (ticketBet) {
      setIsSubmitting(true);
      dispatch(setLoading(true));
      try {
        const { data } = await lotteryApi.submitBetLottery([ticketBet]);
        if (data.error_code === 0) {
          getCheck(true);
          toastify("Đặt vé thành công!", "success");
          setTimeout(() => {
            handleGetBalanceUser();
          }, 2000);
          return;
        }
      } catch (error: any) {
        console.log(error);
        // if (error.msg) toastify(error.msg, "error");
        setShowModalBet(false);
      } finally {
        dispatch(setLoading(false));
        handleCloseModalBet();
        handleClearTicket();
        setIsSubmitting(false);
      }
    }
  };

  const handleToggle1k = (check: boolean) => {
    setToggleMethod1K(check);
    let method: any;
    if (check) {
      const checkFlagWith1K = methodActive?.checkflag + "1K";
      method = listMethod.find(
        (method: modelLotteryI) => method.checkflag === checkFlagWith1K
      );
    } else {
      const originalCheckFlag = methodActive?.checkflag.replace("1K", "");
      method = listMethod.find(
        (method: modelLotteryI) => method.checkflag === originalCheckFlag
      );
    }
    setMethodActive(method);
  };

  const checkFor1KMethod = () => {
    const checkFlagWith1K = methodActive?.checkflag.includes("1K")
      ? methodActive?.checkflag
      : methodActive?.checkflag + "1K";
    const originalCheckflag = methodActive?.checkflag.includes("1K")
      ? methodActive?.checkflag.replace("1K", "")
      : methodActive?.checkflag;
    const exists = listMethod.some(
      (method: modelLotteryI) =>
        method.checkflag === checkFlagWith1K &&
        originalCheckflag === checkFlagWith1K.replace("1K", "")
    );
    return exists;
  };

  return (
    <>
      <div className="bet-lottery w-full">
        <div className="title-bet-container flex items-center mb-4">
          <h3 className="text-2xl font-semibold text-black dark:text-white">
            Đặt Vé Chơi
          </h3>
        </div>
        <div className="bet-lottery-container w-full h-full bg-white dark:bg-bgPrimary rounded-[10px] border border-borderLightPrimary dark:border-borderPrimary">
          <BetTypeComponent
            listMethod={listMethod}
            getType={handleBetType}
            image={imageThumbnail}
          />
          <div className="bet-info-wrap mt-4 rounded-[10px] bg-surface-tertiary dark:bg-black mx-5 p-4">
            <div className="flex items-center justify-between mb-5">
              <div className="bet-method-type flex">
                {typeBetList.length > 0 &&
                  typeBetList.map((item: any, index: number) => (
                    <div
                      className={classNames(
                        "method-bet-item relative text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white transition-colors rounded-3xl px-2 pb-1.5 mr-2 text-sm font-semibold cursor-pointer",
                        {
                          "!mr-0": index === typeBetList.length,
                          "!text-black dark:!text-white after:absolute after:bg-primary after:w-3 after:h-0.5 after:rounded-[2px] after:bottom-0 after:left-[45%]":
                            item.isActive === true,
                          none: item.isShow === false,
                        }
                      )}
                      key={index}
                      onClick={() =>
                        handleMethodBet(item.id, item.typeid, item.type)
                      }
                    >
                      {item.name}
                    </div>
                  ))}
              </div>
              <div
                className="flex items-center cursor-pointer relative transition-colors hover:text-black dark:hover:text-white text-text-secondary dark:text-white/40"
                onClick={(e) => {
                  e.preventDefault();
                  setShowDescMethod(!showDescMethod);
                }}
                ref={wrapPopoverRef}
              >
                <img
                  src={icons.dangerCircle}
                  className="w-5 h-5 mr-1"
                  alt={configSeo?.title}
                />
                <span className="text-sm">Cách chơi</span>
                <div
                  className={`absolute bg-white dark:bg-bgSecondary shadow-sm bottom-5 right-20 w-[460px] transition-all duration-300
                ${showDescMethod ? "opacity-100 z-40" : "opacity-0 : -z-50"}
                p-4 rounded-[10px]`}
                  ref={popoverRef}
                >
                  <span className="text-black dark:text-white text-sm">
                    {methodActive?.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="bet-method-wrap w-full">
              {typePick === "digital" &&
                typeMethodBet !== "XIEN2" &&
                typeMethodBet !== "XIEN3" &&
                typeMethodBet !== "XIEN4" &&
                typeMethodBet !== "LOTRUOT4SO" &&
                typeMethodBet !== "LOTRUOT8SO" &&
                typeMethodBet !== "LOTRUOT10SO" && (
                  <DigitalMethodBet
                    typeBet={typeMethodBet}
                    getCodes={handleGetCodesNumber}
                    statusBet={statusBet}
                  />
                )}{" "}
              {typePick === "choice" && (
                <ChoiceMethodBet
                  methodType={typeMethodBet}
                  getCodes={handleGetCodesNumber}
                  statusBet={statusBet}
                />
              )}{" "}
              {typePick === "enter" && (
                <EnterMethodBet
                  methodType={typeMethodBet}
                  getCodes={handleGetCodesNumber}
                  statusBet={statusBet}
                />
              )}
            </div>
          </div>
          <div className="flex items-center justify-between mt-4 px-5 pb-5">
            <div className="flex items-center">
              {checkFor1KMethod() && (
                <>
                  <ToggleMode
                    size="medium"
                    onToggle={handleToggle1k}
                    checked={toggleMethod1K}
                  />
                  <span className="text-sm text-black dark:text-white ml-2">
                    Đặt vé <b>1k</b>
                  </span>
                </>
              )}
            </div>
            <div className="flex items-center">
              <div className="flex items-center w-full">
                <span
                  className={classNames(
                    "title-multiple mr-2 text-sm font-medium text-text-secondary dark:text-white/40"
                  )}
                >
                  Cấp số nhân:{" "}
                </span>
                <MultipleComponent
                  multipleValue={multipleBet}
                  getMultiple={handleGetMultiple}
                  statusBet={statusBet}
                  methodActive={methodActive}
                  ticketBet={ticketBet}
                />
              </div>
              <div className="flex items-center ml-4">
                <span
                  className={classNames(
                    "title-multiple mr-2 text-sm text-nowrap font-medium text-text-secondary dark:text-white/40"
                  )}
                >
                  Tỉ lệ:{" "}
                </span>
                <span className="text-sm text-error-500 border border-borderLightPrimary dark:border-borderPrimary px-3 py-2 rounded-[10px] whitespace-nowrap">
                  1 ăn {methodActive?.level1}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bet-action-wrap flex justify-between mt-3 w-full">
          <div className="wrap-bet-ticket flex items-center justify-between">
            <span className="choiced text-sm text-text-secondary dark:text-white px-1.5 mr-1 border-r border-black/20">
              Đã chọn <b className="!text-warning-500">{countNumberBet}</b> số
            </span>
            <div className="bg-secondary/10 py-1.5 px-3 border text-text-secondary dark:text-white border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm">
              Số điểm:{" "}
              <b className="strong !text-warning-500">
                {amountBet > 0 ? currencyFormat(amountBet) : 0} point
              </b>{" "}
            </div>
          </div>
          <div className="flex items-center">
            <div className="btn-action-wrap flex items-center">
              <button
                className={classNames(
                  "btn-add bg-secondary/20 flex items-center justify-center text-primary hover:opacity-80 px-3 py-2.5 rounded-[10px] text-sm font-semibold mr-3",
                  { disable: !ticketBet }
                )}
                type="button"
                onClick={() => handleAddTicketBet()}
              >
                <img
                  src={icons.add}
                  className="w-5 h-5 mr-1"
                  alt={configSeo?.title}
                />
                Thêm vé chơi
              </button>
              <button
                className={classNames(
                  "btn-bet bg-primary flex items-center justify-center text-white hover:bg-primary/80 px-3 py-2.5 rounded-[10px] text-sm font-semibold",
                  { disable: !ticketBet }
                )}
                type="button"
                onClick={() => setShowModalBet(true)}
              >
                <img
                  src={icons.bolt}
                  className="w-5 h-5 mr-1"
                  alt={configSeo?.title}
                />
                Chơi ngay
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal isShow={showModalBet} onClose={() => setShowModalBet(false)}>
        {showModalBet && (
          <ModalBet
            onClose={handleCloseModalBet}
            clearTicket={handleCancelTicket}
            gameid={gameId}
            issue={currentIssue}
            typeModalBet="bet"
            ticket={ticketBet}
            handleSubmit={handleSubmitBet}
          />
        )}
      </Modal>
    </>
  );
}

export default BetLotteryComponent;
