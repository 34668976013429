import lotteryApi from "apis/lotteryApi";
import classNames from "classnames";
import DetailBetMobile from "components/DetailBetMobile/DetailBetMobile";
import TagComponent from "components/TagComponent/TagComponent";
import { recordLotteryI } from "interfaces/lottery.interface";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ClipLoader, PuffLoader } from "react-spinners";
import { currencyFormat } from "utils/currencyFormat";
import { formatDateTimeBasic } from "utils/formateDate";
import { icons } from "utils/icons";
import { formatGetTitleStatus } from "utils/lotteryFormat";
import { formatGetNameLottery } from "../../utils/lotteryFormat";
import "./TableHistoryBet.scss";

function TableHistoryBetMobile(props: any) {
  // properties
  const { gameid, onClose, isShow } = props;

  const contentRef = useRef<HTMLDivElement>(null);

  // Hook get từ store
  const { isMobile } = useSelector((state: any) => state.layout);
  const { listStatus, listGames } = useSelector((state: any) => state.lottery);

  // Hook xử lý state
  const [listGameRecord, setListGameRecord] = useState<recordLotteryI[]>([]);
  const [showBottomSheetDetailBet, setShowBottomSheetDetailBet] =
    useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<recordLotteryI | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalBet, setTotalBet] = useState<number>(0);
  const [totalWin, setTotalWin] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>();
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const [idItemSelect, setIdItemSelect] = useState<number>();

  useEffect(() => {
    if (isMobile) {
      if (isShow === true) {
        handleGetRecord(0, "search");
      }
      if (contentRef.current) {
        contentRef.current.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (contentRef.current) {
          contentRef.current.scrollTop = 0;
          contentRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [isShow, isMobile]);

  const handleScroll = () => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const scrollPosition = contentRef.current.scrollTop;
      const bottomSheetHeight = contentRef.current.clientHeight;
      if (scrollPosition + bottomSheetHeight >= contentHeight - 100) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    }
  };

  useEffect(() => {
    if (atBottom && Number(currentPage) < Number(totalPages)) {
      setDataAvailable(true);
      if (!loading)
        handleGetRecord(
          currentPage === 0 ? currentPage + 1 : currentPage,
          "pagi"
        );
    }
  }, [atBottom, totalPages]);

  // Hàm lấy danh sách lịch sử cược
  const handleGetRecord = async (offset?: number | 0, typeCall?: string) => {
    setLoadMore(true);
    if (typeCall === "search") {
      setLoading(true);
    }
    try {
      const { data } = await lotteryApi.getGameRecordCurrentUser({
        pageSize: 10,
        gameid: gameid,
        offset: offset,
      });
      if (data) {
        const dataRes = data.data.gameRecords.content;
        if (typeCall === "search") {
          setListGameRecord(dataRes);
        } else {
          setListGameRecord((list: any) => [...list, ...dataRes]);
        }
        setTotalBet(data.data.totalbet);
        setTotalWin(data.data.totalwin);
        setCurrentPage(Number(data.data.gameRecords.number) + 1);
        setTotalPages(data.data.gameRecords.totalPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadMore(false);
      if (typeCall === "search") {
        setLoading(false);
      }
    }
  };

  const handleUpdateListRecord = (id: any, typeUpdate: string) => {
    const listRecordUpdate = listGameRecord.map((item: recordLotteryI) => {
      item.status = typeUpdate === "cancel" && item.id === id ? 6 : item.status;
      return item;
    });
    setListGameRecord(listRecordUpdate);
  };

  // Hàm hiện modal chi tiết vé cược
  const handleShowDetailBet = (data: any) => {
    setShowBottomSheetDetailBet(true);
    setDataDetail(data);
  };

  const handleToggleShow = (index: any) => {
    setIdItemSelect(index === idItemSelect ? null : index);
  };

  return (
    <div className="relative w-full h-full bg-white dark:bg-bgPrimary">
      <div className="flex items-center justify-between bg-white dark:bg-bgPrimary border-b border-borderLightPrimary dark:border-borderPrimary px-4">
        <span
          className={classNames(
            "text-text-secondary dark:text-white/40 text-base font-semibold border-b border-transparent p-2.5"
          )}
        >
          Lịch sử chơi
        </span>
        <button
          className="close-button p-1 rounded-lg bg-secondary/10"
          onClick={onClose}
        >
          <img
            src={require("assets/icons/close.png")}
            className="w-5 h-5"
            alt="Lịch sử chơi"
          />
        </button>
      </div>
      <div className="content-bottom-sheet h-full">
        {loading ? (
          <div className="w-full h-1/2 flex items-center justify-center">
            <ClipLoader color="#36d7b7" size={36} />
          </div>
        ) : (
          <div
            className="table-history-container w-full px-4 py-4 rounded bg-white dark:bg-bgPrimary overflow-auto max-h-[calc(100%-48px)]"
            ref={contentRef}
          >
            <div className="flex flex-col">
              <div className="total-bet-wrap flex items-center justify-between px-3 py-2 text-sm font-medium rounded-[10px] border border-borderLightPrimary dark:border-white/10 bg-secondary/10 mb-2">
                <span className="text-black dark:text-white mr-1">
                  Tổng điểm chơi:
                </span>
                <span className="text-warning-500">
                  {totalBet ? currencyFormat(totalBet) : 0}
                </span>
              </div>
              <div className="total-bet-wrap flex items-center justify-between px-3 py-2 text-sm font-medium rounded-[10px] border border-borderLightPrimary dark:border-white/10 bg-secondary/10 mb-2">
                <span className="text-black dark:text-white mr-1">
                  Tổng điểm trúng:
                </span>
                <span className="text-warning-500">
                  {totalWin ? currencyFormat(totalWin) : 0}
                </span>
              </div>
              <div className="total-bet-wrap flex items-center justify-between px-3 py-2 text-sm font-medium rounded-[10px] border border-borderLightPrimary dark:border-white/10 bg-secondary/10 mb-2">
                <span className="text-black dark:text-white mr-1">
                  Tổng lợi nhuận:
                </span>
                <span
                  className={classNames(
                    "text-sm text-end font-medium text-warning-500",
                    {
                      "!text-success-500": totalWin - totalBet > 0,
                      "!text-error-500": totalWin - totalBet < 0,
                    }
                  )}
                >
                  {totalBet > 0 || totalWin > 0
                    ? currencyFormat(totalWin - totalBet)
                    : 0}
                </span>
              </div>
            </div>
            <div className="table-wrap w-full mt-2">
              <div className="body-table w-full">
                {listGameRecord.length > 0 ? (
                  listGameRecord.map((item: recordLotteryI, index: number) => (
                    <div
                      className={classNames(
                        "body-table-item flex flex-col bg-surface-primary dark:bg-bgLayout rounded-[10px] overflow-hidden cursor-pointer",
                        { "mt-3": index > 0 }
                      )}
                      key={index}
                    >
                      <div className="flex justify-between px-4 py-2">
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Đài
                          </span>
                          <span className="text-black dark:text-white">
                            {formatGetNameLottery(item.gameid, listGames)}
                          </span>
                        </div>
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Lượt xổ
                          </span>
                          <span className="text-black dark:text-white">
                            {item.issue}
                          </span>
                        </div>
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Kiểu chơi
                          </span>
                          <span className="text-black dark:text-white">
                            {item.methodname}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`${
                          idItemSelect === index
                            ? "max-h-60"
                            : "max-h-0 overflow-hidden"
                        } transition-all duration-500`}
                      >
                        <div className="px-4 pb-2 flex flex-col">
                          <div className="flex justify-between mb-2">
                            <div className="w-1/3 flex flex-col text-xs font-medium">
                              <span className="text-text-secondary dark:text-white/40">
                                Tỉ lệ
                              </span>
                              <span className="text-error-500">
                                1:{item.odd}
                              </span>
                            </div>
                            <div className="w-1/3 flex flex-col text-xs font-medium">
                              <span className="text-text-secondary dark:text-white/40">
                                Số chơi
                              </span>
                              <span className="text-black dark:text-white w-4/5 text-nowrap truncate">
                                {item.codes}
                              </span>
                            </div>
                            <div className="w-1/3 flex flex-col text-xs font-medium">
                              <span className="text-text-secondary dark:text-white/40 mb-1">
                                Thời gian chơi
                              </span>
                              <span className="text-black dark:text-white">
                                {formatDateTimeBasic(item.bettime)}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div className="w-1/3 flex flex-col text-xs font-medium">
                              <span className="text-text-secondary dark:text-white/40 mb-1">
                                Cấp số nhân
                              </span>
                              <span className="text-black dark:text-white">
                                {currencyFormat(item.multiple)}
                              </span>
                            </div>
                            <div className="w-1/3 flex flex-col text-xs font-medium">
                              <span className="text-text-secondary dark:text-white/40 mb-1">
                                Chi tiết
                              </span>
                              <span
                                className={`text-primary underline`}
                                onClick={() => handleShowDetailBet(item)}
                              >
                                Xem chi tiết
                              </span>
                            </div>
                            <div className="w-1/3 flex flex-col text-xs font-medium">
                              <span className="text-text-secondary dark:text-white/40 mb-1">
                                Mã vé chơi
                              </span>
                              <span className="text-black dark:text-white text-wrap break-words">
                                {item.id}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="flex justify-between px-4 py-2 bg-surface-tertiary dark:bg-black"
                        onClick={() => handleToggleShow(index)}
                      >
                        <div className="w-3/12 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Điểm chơi
                          </span>
                          <span className="text-black dark:text-white">
                            {" "}
                            {currencyFormat(item.amount)}
                          </span>
                        </div>
                        <div className="w-3/12 flex flex-col text-xs font-medium mx-1">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Điểm thắng
                          </span>
                          <span
                            className={classNames(
                              "text-black dark:text-white",
                              {
                                "!text-success-500": item.bonus > 0,
                                "!text-error-500": item.bonus < 0,
                              }
                            )}
                          >
                            {item.bonus > 0 ? currencyFormat(item.bonus) : 0}
                          </span>
                        </div>
                        <div className="w-5/12 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Trạng thái
                          </span>
                          <span
                            className={classNames(
                              "w-fit text-center text-wrap font-medium break-words truncate text-xs"
                            )}
                          >
                            <TagComponent
                              title={formatGetTitleStatus(
                                item.status,
                                listStatus,
                                "title"
                              )}
                              type={
                                [3, 5, 6, 7].includes(item.status)
                                  ? "error"
                                  : item.status === 4
                                  ? "success"
                                  : "warning"
                              }
                            />
                          </span>
                        </div>
                        <div className="w-1/12 flex items-center ml-1">
                          <button
                            className="bg-secondary/10 p-1 rounded-lg w-7 h-7 flex items-center justify-center"
                            type="button"
                          >
                            <img
                              src={icons.arrowLeft}
                              className={`${
                                idItemSelect !== index
                                  ? "-rotate-90"
                                  : "rotate-90"
                              } transition-transform duration-500 h-3`}
                              alt="Lịch sử chơi"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-data px-2.5 py-2.5 flex items-center justify-center border-borderLightPrimary dark:border-borderPrimary">
                    <span className=" text-xs font-semibold text-black dark:text-white">
                      Chưa tìm thấy lịch sử chơi nào
                    </span>
                  </div>
                )}
                {loadMore ? (
                  <div className="w-full flex items-center justify-center my-4">
                    <PuffLoader color="#36d7b7" size={36} />
                  </div>
                ) : (
                  dataAvailable && (
                    <div className="w-full flex items-center justify-center my-4">
                      <span className="text-sm text-black dark:text-white font-medium">
                        Đã tải hết dữ liệu
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* detail bet */}
      <div
        className={classNames(
          "w-full h-full bg-white dark:bg-bgPrimary absolute top-0 -right-full transition-all duration-300 ease-in-out",
          {
            "!right-0": showBottomSheetDetailBet,
          }
        )}
      >
        <DetailBetMobile
          data={dataDetail}
          typeComponent="component"
          updateListRecord={handleUpdateListRecord}
          onClose={() => setShowBottomSheetDetailBet(false)}
        />
      </div>
    </div>
  );
}

export default TableHistoryBetMobile;
