import { Tooltip } from "@mui/material";
import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import classNames from "classnames";
import BottomSheet from "components/BottomSheet/BottomSheet";
import DetailBetMobile from "components/DetailBetMobile/DetailBetMobile";
import FilterTransaction from "components/FilterComponent/FilterTransaction";
import Modal from "components/Modal/Modal";
import ModalDetailAdjustment from "components/ModalDetailBet/ModalDetailAdjustment";
import ModalDetailBet from "components/ModalDetailBet/ModalDetailBet";
import ModalDetailGift from "components/ModalDetailBet/ModalDetailGift";
import PaginationComponent from "components/PaginationComponent/PaginationComponent";
import { setLoading, setLoadingTopBar } from "features/commonSlice";
import useToast from "hooks/useToast";
import { listTypeI } from "interfaces/common.interface";
import { dataListTransactionI } from "interfaces/data.interface";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { currencyFormat } from "utils/currencyFormat";
import { getEndDateTime, getStartDateTime } from "utils/date";
import { formatDate, formatToIOString } from "utils/formateDate";
import { icons } from "utils/icons";
import { formatGetNameLottery, getTitleTransStatus } from "utils/lotteryFormat";
import TransactionTable from "./TransactionTable";
import BottomSheetDetailAdjustment from "./components/BottomSheetDetailAdjustment";

const listHeaderTitle = [
  "Mã biến động",
  "Thời gian",
  "Loại biến động",
  "Số Điểm",
  "Tên trò chơi",
  "Mô tả",
  "Số dư trước",
  "Số dư sau",
  "",
];

const listColSpan = [
  "w-1/8",
  "w-1/8",
  "w-1/8",
  "w-1/8",
  "w-1/8",
  "w-1/8",
  "w-1/8",
  "w-1/8",
  "w-10",
];

function TransactionPage() {
  const { listStatusTransaction, listGames } = useSelector(
    (state: any) => state.lottery
  );
  const { isMobile } = useSelector((state: any) => state.layout);

  const dispatch = useDispatch();

  const [listTransaction, setListTransaction] = useState<
    dataListTransactionI[]
  >([]);
  const [listTransactionType, setListTransactionType] = useState<listTypeI[]>(
    listStatusTransaction
  );
  const [totalPage, setTotalPage] = useState();
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [totalPay, setTotalPay] = useState<number>(0);
  const [atBottom, setAtBottom] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [statusFilter, setStatusFilter] = useState<boolean>(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false);
  const [dataDetailTransaction, setDataDetailTransaction] = useState<any>();
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [showBottomSheetDetailBet, setShowBottomSheetDetailBet] =
    useState<boolean>(false);
  const [showBottomSheetDetailAdjustment, setShowBottomSheetDetailAdjustment] =
    useState<boolean>(false);
  const [typeModalDetail, setTypeModalDetail] = useState<string>("");
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const [formDataPagination, setFormDataPagination] = useState<any>();
  const [showFilterBottomSheet, setShowFilterBottomSheet] =
    useState<boolean>(false);
  const [formData, setFormData] = useState({
    pageSize: 10,
    offset: 0,
    fromdate: formatToIOString(new Date(getStartDateTime())),
    todate: formatToIOString(new Date(getEndDateTime())),
    transtype: null,
    id: null,
  });

  useEffect(() => {
    handleGetTransaction(0, "search");
    setListTransactionType(
      listStatusTransaction.filter(
        (item: any) => ![6, 7, 8, 9].includes(item.id)
      )
    );
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      if (atBottom && Number(currentPage) < Number(totalPage)) {
        setDataAvailable(true);
        if (!loadingSkeleton) {
          handleGetTransaction(
            currentPage === 0 ? currentPage + 1 : currentPage,
            "pagi"
          );
        }
      }
    }
  }, [atBottom, totalPage]);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const updateFormData = (field: any, value: any, type?: any) => {
    setFormData((prevData) => ({
      ...formData,
      [field]: value !== "" ? value : null,
    }));
    setFormDataPagination({
      ...formDataPagination,
      [field]: value !== "" ? value : null,
    });
  };

  const changeOffset = (newOffset: any) => {
    setFormDataPagination((prevParams: any) => ({
      ...prevParams,
      offset: newOffset,
    }));
    handleGetTransaction(newOffset, "pagi");
  };

  const handleGetTransaction = async (
    offset?: number | 0,
    typeCall?: string
  ) => {
    if (isMobile) {
      setLoadingSkeleton(true);
    }
    if (typeCall === "filter") {
      dispatch(setLoadingTopBar(true));
    }
    if (typeCall === "search") {
      dispatch(setLoading(true));
    }
    let payload =
      typeCall === "pagi"
        ? {
            ...formDataPagination,
            pageSize: isMobile ? 15 : 10,
            offset: offset,
          }
        : {
            ...formData,
            pageSize: isMobile ? 15 : 10,
            offset: typeCall === "filter" ? 0 : offset,
          };
    try {
      const { data } = await userApi.getTransactionUser(payload);
      if (data) {
        const dataRes = data.data.transactions;
        if (!isMobile || typeCall === "search" || typeCall === "filter") {
          setListTransaction(dataRes.content);
        } else {
          setListTransaction((prevList: any) => [
            ...prevList,
            ...dataRes.content,
          ]);
        }
        setCurrentPage(Number(dataRes.number) + 1);
        setTotalPage(dataRes.totalPages);
        setTotalIncome(data.data.totalincome);
        setTotalPay(data.data.totalpay);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSkeleton(false);
      if (typeCall === "search") {
        dispatch(setLoading(false));
      }
      if (typeCall === "filter") {
        setFormDataPagination(formData);
        setShowFilterBottomSheet(false);
      }
      dispatch(setLoadingTopBar(false));
    }
  };

  const handleShowModalDetail = (game: any, event?: any) => {
    event.preventDefault();
    if ([7, 12, 13, 14, 15, 16].includes(game.transtype) === true) {
      setTypeModalDetail("adjustment");
      setDataDetailTransaction(game);
      if (!isMobile) {
        setShowModalDetail(true);
      } else {
        setShowBottomSheetDetailAdjustment(true);
      }
    } else {
      setTypeModalDetail("bet");
      handleGetDetailBet(game.referenceid);
    }
  };

  const handleGetDetailBet = async (gameid: number) => {
    setLoadingSkeleton(true);
    try {
      const { data } = await lotteryApi.getGameRecordCurrentUser({
        id: gameid,
      });
      if (data) {
        const dataRes = data.data.gameRecords.content[0];
        setDataDetailTransaction(dataRes);
        if (!isMobile) {
          setShowModalDetail(true);
        } else {
          setShowBottomSheetDetailBet(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSkeleton(false);
    }
  };

  const handleClearFilter = () => {
    setFormData({
      pageSize: 13,
      offset: 0,
      fromdate: formatToIOString(new Date(getStartDateTime())),
      todate: formatToIOString(new Date(getEndDateTime())),
      transtype: null,
      id: null,
    });
    setStatusFilter(true);
    setTimeout(() => {
      setStatusFilter(false);
    }, 500);
  };

  const handleCloseModal = () => {
    setDataDetailTransaction(null);
    setTypeModalDetail("");
    setShowBottomSheetDetailAdjustment(false);
    setShowBottomSheetDetailBet(false);
    setShowModalDetail(false);
  };

  return (
    <div className={classNames("w-full")}>
      <div
        className={classNames(
          "transaction-page w-full flex bg-surface-primary dark:bg-bgLayout",
          {
            "flex-col !p-4": isMobile,
          }
        )}
      >
        <div
          className={classNames("w-full", {
            "!mb-4": isMobile,
          })}
        >
          <div className="table-container w-full">
            <div className="title-table-wrap flex items-center justify-between">
              <h1 className="title-table text-xl font-semibold text-black dark:text-white">
                Biến Động Số Dư
              </h1>
              {isMobile && (
                <button
                  className="bg-secondary/10 active:bg-secondary/20 rounded-[10px] p-2.5"
                  type="button"
                  onClick={() => setShowFilterBottomSheet(true)}
                >
                  <img src={icons.filter} className="w-5 h-5" alt="Biến Động Số Dư" />
                </button>
              )}
            </div>
            {isMobile && (
              <div className="flex flex-col mt-3">
                <div className="flex flex-col">
                  <div className="total-bet-wrap flex items-center justify-between bg-secondary/10 py-2 px-3 border border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm mb-3">
                    <span className="text-black dark:text-white mr-2">
                      Tổng thu:
                    </span>
                    <span className="text-success-500 font-semibold">
                      {totalIncome ? currencyFormat(totalIncome) : 0}
                    </span>
                  </div>
                  <div className="total-bet-wrap flex items-center justify-between bg-secondary/10 py-2 px-3 border border-borderLightPrimary dark:border-white/10 rounded-[10px] text-sm">
                    <span className="text-black dark:text-white mr-2">
                      Tổng chi:
                    </span>
                    <span className="text-error-500 font-semibold">
                      {totalPay ? currencyFormat(totalPay) : 0}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {!isMobile && (
              <>
                <FilterTransaction
                  statusFilter={statusFilter}
                  handleGetTransaction={handleGetTransaction}
                  handleClearFilter={handleClearFilter}
                  updateFormData={updateFormData}
                />
                <div
                  className={classNames(
                    "table-wrap mb-4 border border-borderLightPrimary dark:border-borderPrimary shadow-table rounded-[10px] overflow-hidden",
                    {
                      "!mb-0": isMobile,
                    }
                  )}
                >
                  <div className="header-table py-3 flex items-center justify-between bg-surface-tertiary dark:bg-black">
                    {listHeaderTitle?.map((header: string, index: number) => (
                      <div
                        className={`flex justify-center items-center ${listColSpan[index]}`}
                        key={index}
                      >
                        <span className="text-primary text-xs text-center text-wrap font-semibold">
                          {header}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="body-table">
                    {listTransaction.length > 0 ? (
                      listTransaction.map(
                        (item: dataListTransactionI, index: number) => (
                          <div
                            className={`body-table-item hover:bg-gray-200 dark:hover:bg-gray-800 flex items-center justify-between py-3 border-t border-borderLightPrimary dark:border-borderPrimary ${
                              index % 2 === 0
                                ? "bg-white dark:bg-bgPrimary"
                                : "bg-surface-tertiary dark:bg-black"
                            }`}
                            key={index}
                          >
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <span className=" w-4/5 text-xs text-wrap break-words text-center text-black dark:text-white">
                                {item.id}
                              </span>
                            </div>
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <span className="text-xs px-1.5 whitespace-pre-line text-center text-black dark:text-white">
                                {formatDate(item.opttime)}
                              </span>
                            </div>
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <span className=" text-xs text-center text-black dark:text-white">
                                {getTitleTransStatus(
                                  listStatusTransaction,
                                  item.transtype
                                )}
                              </span>
                            </div>
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <span
                                className={classNames(
                                  `text-${
                                    item.pay > 0 ? "error" : "success"
                                  }-500 text-xs text-center`
                                )}
                              >
                                {item.pay
                                  ? "-" + currencyFormat(item.pay)
                                  : item.income
                                  ? "+" + currencyFormat(item.income)
                                  : 0}
                              </span>
                            </div>
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <span className=" text-xs text-center text-black dark:text-white">
                                {item.gameid
                                  ? formatGetNameLottery(item.gameid, listGames)
                                  : "-"}
                              </span>
                            </div>
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <Tooltip
                                title={item.remark}
                                placement="right"
                                arrow
                                slotProps={{
                                  popper: {
                                    modifiers: [
                                      {
                                        name: "offset",
                                        options: {
                                          offset: [0, -8],
                                        },
                                      },
                                    ],
                                  },
                                }}
                              >
                                <span className=" text-xs text-center truncate w-4/5 text-black dark:text-white">
                                  {item.remark ? item.remark : "-"}
                                </span>
                              </Tooltip>
                            </div>
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <span className=" w-4/5 text-xs text-center text-wrap break-words text-black dark:text-white">
                                {item.beforebalance
                                  ? currencyFormat(item.beforebalance)
                                  : 0}
                              </span>
                            </div>
                            <div className="px-2.5 flex justify-center items-center w-1/8">
                              <span className=" text-xs text-center text-wrap break-words text-black dark:text-white">
                                {item.afterbalance > 0
                                  ? currencyFormat(item.afterbalance)
                                  : 0}
                              </span>
                            </div>
                            <div className="flex justify-center items-center w-10">
                              {item.referenceid ? (
                                <img
                                  onClick={(e: any) =>
                                    handleShowModalDetail(item, e)
                                  }
                                  src={icons.documentCheckPrimary}
                                  className="w-5 h-5 cursor-pointer"
                                  alt="Biến Động Số Dư"
                                />
                              ) : (
                                <span className=" text-xs text-center text-black dark:text-white">
                                  -
                                </span>
                              )}
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="no-data px-2.5 py-2.5 flex items-center justify-center bg-white dark:bg-bgPrimary border-t border-borderLightPrimary dark:border-borderPrimary">
                        <span className="text-xs text-black dark:text-white">
                          Chưa có biến động nào
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {listTransaction?.length > 0 && (
                  <div className="flex flex-col mb-3">
                    <div className="flex items-center flex-wrap">
                      <div className="flex flex-col bg-surface-tertiary dark:bg-black rounded-[10px] py-2.5 px-2 min-w-44">
                        <span className="text-xs font-semibold text-text-secondary dark:text-white/40 mb-1">
                          Tổng thu:
                        </span>
                        <span className="text-sm text-end font-medium text-success-500">
                          {totalIncome ? currencyFormat(totalIncome) : 0}
                        </span>
                      </div>
                      <div className="flex flex-col bg-surface-tertiary dark:bg-black rounded-[10px] py-2.5 px-2 mx-2 min-w-44">
                        <span className="text-xs font-semibold text-text-secondary dark:text-white/40 mb-1">
                          Tổng chi:
                        </span>
                        <span className="text-sm text-end font-medium text-error-500">
                          {totalPay ? currencyFormat(totalPay) : 0}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {totalPage && totalPage > 1 ? (
                  <PaginationComponent
                    totalPage={totalPage}
                    changeOffset={changeOffset}
                    currentPage={currentPage}
                  ></PaginationComponent>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>

        {isMobile && (
          <TransactionTable
            data={listTransaction}
            loading={loadingSkeleton}
            showDetail={handleShowModalDetail}
            listStatusTransaction={listStatusTransaction}
          />
        )}
        {isMobile &&
          (loadingSkeleton ? (
            <div className="w-full flex items-center justify-center my-4">
              <PuffLoader color="#36d7b7" size={36} />
            </div>
          ) : (
            dataAvailable && (
              <div className="w-full flex items-center justify-center my-4">
                <span className="text-sm text-white font-medium">
                  Đã tải hết dữ liệu
                </span>
              </div>
            )
          ))}
      </div>
      <Modal isShow={showModalDetail} noClickOutSide>
        {showModalDetail && typeModalDetail === "bet" && (
          <ModalDetailBet
            loading={loadingSkeleton}
            onClose={handleCloseModal}
            data={dataDetailTransaction}
          />
        )}
        {showModalDetail && typeModalDetail === "adjustment" && (
          <ModalDetailAdjustment
            onClose={handleCloseModal}
            data={dataDetailTransaction}
          />
        )}
        {showModalDetail && typeModalDetail === "gift" && (
          <ModalDetailGift
            onClose={handleCloseModal}
            data={dataDetailTransaction}
          />
        )}
      </Modal>
      <BottomSheet
        noClickOutSide
        isShow={showBottomSheetDetailBet}
        direction="vertical"
        height="90%"
        onClose={() => setShowBottomSheetDetailBet(false)}
      >
        <DetailBetMobile
          data={dataDetailTransaction}
          loading={loadingSkeleton}
          onClose={() => setShowBottomSheetDetailBet(false)}
          typeComponent="bottomsheet"
        />
      </BottomSheet>
      <BottomSheet
        noClickOutSide
        isShow={showBottomSheetDetailAdjustment}
        title="Chi tiết"
        direction="vertical"
        height="60%"
        onClose={() => setShowBottomSheetDetailAdjustment(false)}
      >
        <BottomSheetDetailAdjustment
          data={dataDetailTransaction}
          loading={loadingSkeleton}
        />
      </BottomSheet>
      <BottomSheet
        isShow={showFilterBottomSheet}
        onClose={() => setShowFilterBottomSheet(false)}
        title="Bộ lọc"
      >
        <FilterTransaction
          statusFilter={statusFilter}
          handleGetTransaction={handleGetTransaction}
          handleClearFilter={handleClearFilter}
          updateFormData={updateFormData}
        />
      </BottomSheet>
    </div>
  );
}

export default TransactionPage;
