import TableDauDuoi from "components/TableDauDuoi/TableDauDuoi";

import TableFormatMBMobile from "components/TableResultMobile/TableFormatMBMobile";
import TableFormatMTMNMobile from "components/TableResultMobile/TableFormatMTMNMobile";

function ModalResultMobile(props: any) {
  const { onClose, gameid, method, issueCurrent, statusUpdate, dataResult } =
    props;

  return (
    <div className="rounded-lg w-full min-w-60 overflow-hidden border border-borderPrimary bg-white dark:bg-black shadow-[0px_4px_24px_0px_#00000033]">
      <div className="header-modal flex items-center justify-center px-4 py-2 bg-bgLayout">
        <span className="title-modal text-white text-sm font-semibold">
          Kết Quả Lượt Xổ: {issueCurrent ? issueCurrent : ""}
        </span>
      </div>
      <div className="content-modal flex-1">
        <div className="w-full flex">
          <div className="w-full">
            {/* <div className="wrap-prize px-4 py-3 bg-green-200 dark:bg-bgPrimary text-primary text-sm font-semibold flex items-center justify-center">
              <span className="text-center">Kết quả</span>
            </div> */}
            {[210, 200, 201].includes(Number(gameid)) ? (
              <TableFormatMBMobile
                method={method?.checkflag}
                data={dataResult}
                currentIssue={issueCurrent}
                statusUpdate={statusUpdate}
                type="modal"
              />
            ) : (
              <TableFormatMTMNMobile
                method={method?.checkflag}
                data={dataResult}
                currentIssue={issueCurrent}
                statusUpdate={statusUpdate}
                type="modal"
              />
            )}
          </div>
          <div className="w-2/5">
            <TableDauDuoi
              method={method?.checkflag}
              winnumber={dataResult}
              statusUpdate={statusUpdate}
              type="modal"
            />
          </div>
        </div>
      </div>
      <div className="bottom-modal flex items-center justify-between border-t border-borderPrimary">
        <button
          type="button"
          className="btn-submit px-3 py-2.5 w-full text-base font-semibold bg-bgLayout text-white active:opacity-80"
          onClick={onClose}
        >
          Đóng
        </button>
      </div>
    </div>
  );
}

export default ModalResultMobile;
