import classNames from "classnames";
import ReactDOM from "react-dom";
import { useTransition, animated } from "react-spring";
import "./Modal.scss";
import { modalI } from "interfaces/common.interface";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Modal = (props: modalI) => {
  const { isShow, onClose, children, noClickOutSide, overlay } = props;

  const { mode } = useSelector((state: any) => state.layout);

  const transition = useTransition(isShow, {
    from: { scale: 0.7 },
    enter: { scale: 1 },
    leave: { scale: 0.7 },
    config: { duration: 200 },
  });

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isShow]);

  const handleClickBackDrop = () => {
    if (!noClickOutSide) {
      onClose();
    }
  };

  return isShow
    ? ReactDOM.createPortal(
        <div className={classNames("modal-container", { dark: mode === 1 })}>
          <div className={"modal-wrapper"}>
            {transition(
              (style, item) =>
                item && (
                  <animated.div style={style} className={classNames(`modal`)}>
                    {/* body */}
                    <div className={"modal-body"}>{children}</div>
                  </animated.div>
                )
            )}

            <div
              className={overlay ? overlay : "modal-overlay"}
              onClick={handleClickBackDrop}
            />
          </div>
        </div>,
        document.body // add modal into body
      )
    : null;
};
export default Modal;
