import axiosInstance from "./axiosInstance";
import { sendChatI, sendGiftI } from "../interfaces/chat.interface";

const chatApi = {
  sendChat(payload: sendChatI) {
    const url = "api/v1/message/chat";
    return axiosInstance.post(url, payload);
  },
  getListConversation(params: { gameid: number }) {
    const url = `api/v1/message/public/chat/${params.gameid}`;
    return axiosInstance.get(url);
  },
  getListGifts() {
    const url = "api/v1/livestream/gifts";
    return axiosInstance.get(url);
  },
  sendGift(payload: sendGiftI) {
    const url = "api/v1/livestream/gift/send";
    return axiosInstance.post(url, payload);
  },
  getDetailGiftUser(params: any) {
    const url = "api/v1/livestream/gifts/user";
    return axiosInstance.get(url, { params });
  },
};

export default chatApi;
