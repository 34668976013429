import { useEffect, useRef, useState } from "react";
import { formatDateSmall } from "utils/commonFormat";

interface SelectPropsI {
  parentRef: any;
  isShow: boolean;
  options: any[];
  onChange: any;
  value: any;
  onClose: any;
}

const CustomSelect = (props: SelectPropsI) => {
  const { options, onChange, value, isShow, parentRef, onClose } = props;
  const ulRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropUp, setDropUp] = useState(false);

  useEffect(() => {
    setIsOpen(isShow);
  }, [isShow]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ulRef]);

  useEffect(() => {
    if (isOpen) {
      const rect = parentRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const shouldDropUp = rect.bottom + 200 > windowHeight; // 200 là chiều cao tối đa của dropdown
      setDropUp(shouldDropUp);
    }
  }, [isOpen]);

  const handleOptionClick = (option: any) => {
    onChange(option);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          ref={ulRef}
          className={`absolute left-0 right-0 text-sm p-1 border border-borderLightPrimary dark:border-borderPrimary bg-white dark:bg-bgPrimary rounded-md shadow-lg z-10 max-h-60 max-w-60 min-w-24 overflow-y-auto ${
            dropUp ? "bottom-full mb-1" : "top-full mt-1"
          }`}
        >
          {options.map((option: any, index: number) => (
            <div
              key={index}
              className={`p-2 rounded-lg text-black dark:text-white text-center cursor-pointer hover:bg-gray-200 dark:hover:bg-bgSecondary ${
                formatDateSmall(option) === value
                  ? "bg-gray-200 dark:bg-bgSecondary"
                  : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {typeof option === "number" ? formatDateSmall(option) : option}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CustomSelect;
