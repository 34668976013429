import classNames from "classnames";
import TableDauDuoi from "components/TableDauDuoi/TableDauDuoi";
import ToggleMode from "components/ToggleMode/ToggleMode";
import { useEffect, useState } from "react";
import { formatDateBasic } from "utils/formateDate";
import {
  formatGetNameLottery,
  formatStringWinnumberToResultData,
} from "../../utils/lotteryFormat";
import TableFormatMB from "./TableFormatMB";
import TableFormatMTMN from "./TableFormatMTMN";
import ButtonAction from "components/ButtonAction/ButtonAction";
import { useSelector } from "react-redux";
import { listIdLotteryByRegion } from "constant";

function TableResult(props: any) {
  const {
    gameid,
    method,
    clearStatusUpdate,
    statusUpdate,
    listIssue,
    result,
    getListIssue,
    loading,
  } = props;

  const { listGames } = useSelector((state: any) => state.lottery);

  const [dataResult, setDataResult] = useState<any>(null);
  const [issueCurrent, setIssueCurrent] = useState<string>();
  const [isFastLottery, setIsFastLottery] = useState<boolean>(false);
  const [isShowTableResult, setIsShowTableResult] = useState<boolean>(false);

  useEffect(() => {
    setIsFastLottery([50, 100, 101, 102, 103, 104].includes(gameid));
  }, [gameid]);

  useEffect(() => {
    setIssueCurrent(
      listIssue[0]?.winnumber ? listIssue[0]?.issue : listIssue[1]?.issue
    );
  }, [gameid, listIssue]);

  useEffect(() => {
    let timeout;
    if (result) {
      setDataResult(result);
      timeout = setTimeout(
        () => {
          clearStatusUpdate();
        },
        isFastLottery ? 2500 : 1500
      );
    }
    return clearTimeout(timeout);
  }, [result]);

  const handleChangeIssue = (ev: any) => {
    const issueChange = ev.target.value;
    setIssueCurrent(issueChange);
    const issueDataCurrent: any = listIssue.find(
      (iss: any) => (isFastLottery ? iss.time : iss.issue) === issueChange
    );
    setDataResult(
      formatStringWinnumberToResultData(gameid, issueDataCurrent.winnumber)
    );
  };

  const findRegionByGameId = (gameid: number) => {
    const region = listIdLotteryByRegion?.find((region) =>
      region?.listIdLotterys?.includes(gameid)
    );
    return region ? region?.name : null;
  };

  return (
    <div className="flex flex-col w-full">
      <div
        className={classNames(
          "table-result rounded overflow-hidden w-full h-full flex"
        )}
      >
        <div className="content-table-result shadow-table rounded-[10px] border border-borderLightPrimary dark:border-borderPrimary overflow-hidden w-full h-fit">
          <div className="wrap-issue-result w-full h-12 flex justify-between items-center border border-transparent bg-green-200 dark:bg-bgPrimary py-2 px-4">
            <div className="flex items-center">
              <div className="flex flex-col mr-3">
                <span className="text-primary text-base font-semibold">
                  Kết quả xổ số
                </span>
              </div>
              <div className="flex items-center">
                <select
                  className="select-period px-2 py-1 text-black dark:text-white bg-surface-tertiary dark:bg-black text-sm outline-none border border-borderLightPrimary dark:border-borderPrimary rounded-lg cursor-pointer"
                  onChange={(e) => handleChangeIssue(e)}
                  value={issueCurrent}
                >
                  {listIssue?.length > 0 &&
                    listIssue?.map((iss: any, index: number) => (
                      <option
                        className="text-option"
                        value={isFastLottery ? iss.time : iss.issue}
                        key={index}
                      >
                        {iss.issue}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="flex items-center">
              {/* {dataResult?.giaidb === "" && !isFastLottery && (
                <ButtonAction
                  title="Cập nhật kết quả"
                  type="secondary"
                  onSubmit={getListIssue}
                  loading={loading}
                  className="mr-4 min-w-[135px]"
                />
              )} */}
              <span className="text-text-secondary dark:text-white text-base font-semibold mr-2">
                {isShowTableResult ? "Ẩn" : "Hiện"} bảng KQ
              </span>
              <ToggleMode
                size="medium"
                onToggle={(toggle: boolean) => setIsShowTableResult(toggle)}
              />
            </div>
          </div>
          <div
            className={classNames(
              "transition-all duration-500 max-h-0 h-full",
              {
                "!max-h-[9999px]": isShowTableResult,
              }
            )}
          >
            {dataResult?.giaidb !== "" ? (
              <div className="w-full h-full bg-white dark:bg-black">
                {[210, 200, 201].includes(Number(gameid)) ? (
                  <TableFormatMB
                    gameid={gameid}
                    method={method?.checkflag}
                    data={dataResult}
                    currentIssue={issueCurrent}
                    issue={listIssue[0]?.issue}
                    statusUpdate={statusUpdate}
                  />
                ) : (
                  <TableFormatMTMN
                    gameid={gameid}
                    method={method?.checkflag}
                    data={dataResult}
                    currentIssue={issueCurrent}
                    issue={listIssue[0]?.issue}
                    statusUpdate={statusUpdate}
                  />
                )}
              </div>
            ) : (
              <div className="w-full h-full p-4 flex justify-center items-center text-sm text-black dark:text-white bg-white dark:bg-black border-t border-borderLightPrimary dark:border-borderPrimary">
                Chưa có kết quả
              </div>
            )}
          </div>
        </div>

        <div className="w-1/3 ml-4">
          <TableDauDuoi
            method={method?.checkflag}
            winnumber={dataResult}
            statusUpdate={statusUpdate}
            isShow={isShowTableResult}
          />
        </div>
      </div>
      {dataResult?.giaidb === "" && !isFastLottery && (
        <div className="mt-3 w-full flex items-center">
          <p className="text-error-500 text-sm italic font-medium">
            Kết quả xổ số {formatGetNameLottery(gameid, listGames)} hôm nay sẽ
            có vào lúc{" "}
            {findRegionByGameId(gameid) === "mb"
              ? "18h35"
              : findRegionByGameId(gameid) === "mt"
              ? "17h35"
              : "16h35"}
            . Nếu như đã đến giờ bảng kết quả chưa hiển thị vui lòng reload lại
            trang.
          </p>
        </div>
      )}
    </div>
  );
}

export default TableResult;
