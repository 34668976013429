import classNames from "classnames";
import RandomNumberCount from "components/RandomNumberCount/RandomNumberCount";
import { prizesInitMTMN } from "constant";
import {
  formatGeneratePrizeLottery,
  formatPrizeNumberByMethod,
} from "utils/lotteryFormat";

function TableFormatMTMN(props: any) {
  const { method, data, currentIssue, issue, statusUpdate } = props;

  const checkInvalidValue = (value: any) => {
    if ((!value || value === "" || value === null) && currentIssue === issue) {
      return false;
    } else {
      return true;
    }
  };

  // console.log(currentIssue, issue);

  return (
    <div className="table-result">
      {prizesInitMTMN.map((prize: any, index: number) => (
        <div
          className="wrap-prize px-4 border-t border-borderLightPrimary dark:border-borderPrimary grid grid-cols-12"
          key={index}
        >
          <div className="title-prize flex items-center justify-center col-span-2 border-r border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm text-black dark:text-white font-semibold text-center">
              {prize.title}
            </span>
          </div>
          <div className="w-full col-span-10 flex items-center justify-center flex-wrap -mb-1 py-1.5">
            {Array.from({ length: prize.subPrizes || 1 }).map((_, subIndex) => (
              <div
                className={`${
                  index === 3
                    ? "w-1/2"
                    : index === 6 || (index === 4 && subIndex <= 2)
                    ? "w-1/3"
                    : index === 4 && subIndex > 2
                    ? "w-1/4"
                    : "w-full"
                } flex items-center justify-center mb-1`}
                key={subIndex}
              >
                <div
                  className={classNames(
                    "number-prize w-fit py-1.5 px-3 rounded-[10px] text-black dark:text-white flex items-center justify-center",
                    {
                      hidden:
                        formatPrizeNumberByMethod(
                          method,
                          index,
                          subIndex,
                          formatGeneratePrizeLottery(
                            data?.[prize.dataKey],
                            subIndex + 1
                          )
                        ) === "",
                    }
                  )}
                >
                  {checkInvalidValue(
                    formatGeneratePrizeLottery(
                      data?.[prize.dataKey],
                      subIndex + 1
                    )
                  ) ? (
                    statusUpdate ? (
                      <RandomNumberCount length={prize.length} />
                    ) : (
                      <span
                        className={classNames(
                          "text-base font-semibold text-center",
                          {
                            "!text-xl": index === 0,
                          }
                        )}
                        dangerouslySetInnerHTML={{
                          __html: formatPrizeNumberByMethod(
                            method,
                            index,
                            subIndex,
                            formatGeneratePrizeLottery(
                              data?.[prize.dataKey],
                              subIndex + 1
                            )
                          ),
                        }}
                      ></span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TableFormatMTMN;
