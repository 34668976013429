import { useEffect, useState } from "react";
import "./DigitalMethodBet.scss";
import classNames from "classnames";

interface methodI {
  id: number;
  title: string;
  type: string;
  isActive: boolean;
}

interface digitalI {
  id: number;
  unit: string;
  title: string;
  listNums: Array<any>;
  listTypeBet: Array<methodI>;
  isShow: boolean;
}

const methodChoiceList: methodI[] = [
  {
    id: 1,
    title: "Tất cả",
    type: "all",
    isActive: false,
  },
  {
    id: 2,
    title: "Tài",
    type: "big",
    isActive: false,
  },
  {
    id: 3,
    title: "Xỉu",
    type: "small",
    isActive: false,
  },
  {
    id: 4,
    title: "Chẵn",
    type: "even",
    isActive: false,
  },
  {
    id: 5,
    title: "Lẻ",
    type: "odd",
    isActive: false,
  },
];

const listNumberDigital: digitalI[] = [
  {
    id: 4,
    unit: "thousand",
    title: "Nghìn",
    listNums: [],
    listTypeBet: [],
    isShow: false,
  },
  {
    id: 3,
    unit: "hundred",
    title: "Trăm",
    listNums: [],
    listTypeBet: [],
    isShow: false,
  },
  {
    id: 2,
    unit: "dozen",
    title: "Chục",
    listNums: [],
    listTypeBet: [],
    isShow: false,
  },
  {
    id: 1,
    unit: "unit",
    title: "Đơn vị",
    listNums: [],
    listTypeBet: [],
    isShow: true,
  },
];

function DigitalMethodBetMobile(props: any) {
  const { typeBet, getCodes, statusBet } = props;
  const [listArrayDigital, setListArrayDigital] =
    useState<digitalI[]>(listNumberDigital);

  useEffect(() => {
    if (typeBet) {
      const listNumber = Array.from({ length: 10 }, (_, index) => ({
        number: index,
        isActive: false,
      }));
      const listArrayDigitalConvert = listArrayDigital.map((item: digitalI) => {
        item.listNums = listNumber;
        item.listTypeBet = methodChoiceList;
        if (typeBet === "DAU") {
          item.isShow = item.id === 2;
        } else if (typeBet === "DUOI") {
          item.isShow = item.id === 1;
        } else if (
          typeBet === "3CANGDAU" ||
          typeBet === "3CANGDAUDUOI" ||
          typeBet === "3CANGDACBIET" ||
          typeBet === "LO3SO" ||
          typeBet === "3CANGDAUDUOIMB" ||
          typeBet === "3CANGDAUMB"
        ) {
          item.isShow = item.id !== 4;
        } else if (typeBet === "LO4SO" || typeBet === "4CANGDACBIET") {
          item.isShow = true;
        } else {
          item.isShow = item.id === 1 || item.id === 2;
        }
        return item;
      });
      setListArrayDigital(listArrayDigitalConvert);
    }
  }, [typeBet]);

  useEffect(() => {
    const newListArrayTicket = listArrayDigital.map((item: digitalI) => {
      const listArrNumberSelect = item?.listNums.filter(
        (num: any) => num.isActive
      );
      return convertNumberSelect(listArrNumberSelect);
    });
    convertTicket(newListArrayTicket.filter((item: any) => item !== undefined));
  }, [listArrayDigital, typeBet]);

  useEffect(() => {
    if (!statusBet) {
      handleClearDigital();
    }
  }, [statusBet]);

  const convertTicket = (array: any) => {
    let ticket;
    if (typeBet === "LO4SO" || typeBet === "4CANGDACBIET") {
      ticket = array.length === 4 ? array.join("|") : "";
    } else if (
      typeBet === "LO3SO" ||
      typeBet === "3CANGDAU" ||
      typeBet === "3CANGDACBIET" ||
      typeBet === "3CANGDAUDUOI" ||
      typeBet === "3CANGDAUDUOIMB" ||
      typeBet === "3CANGDAUMB"
    ) {
      ticket = array.length === 3 ? array.slice(0, 3).join("|") : "";
    } else if (typeBet === "DAU" || typeBet === "DUOI") {
      ticket = array.length === 1 ? array.slice(0, 1).join("|") : "";
    } else {
      ticket = array.length === 2 ? array.slice(0, 2).join("|") : "";
    }
    const checkTicketEmpty = ticket
      .split("|")
      .some((tic: string) => tic === "");
    const listArray = ticket.split("|").map((num: string) => {
      const numSplit = num?.length > 1 ? sumNumbers(num) : num?.length;
      return numSplit;
    });
    let lengthTicket: number = 1;
    listArray.forEach((item: number) => {
      lengthTicket *= item;
    });
    if (!checkTicketEmpty) {
      getCodes(ticket, lengthTicket);
    } else {
      getCodes("", 0);
    }
  };

  const handleClearDigital = () => {
    const clearArrayList = listArrayDigital.map((item: digitalI) => {
      item.listNums = item.listNums.map((num: methodI) => {
        num.isActive = false;
        return num;
      });
      item.listTypeBet = item.listTypeBet.map((num: any) => {
        num.isActive = false;
        return num;
      });
      return item;
    });
    setListArrayDigital(clearArrayList);
  };

  const convertNumberSelect = (listChoice: any) => {
    if (listChoice?.length > 0) {
      const newListChoice = listChoice.map((item: any) => item.number);
      const ticket = newListChoice.join(",");
      return ticket;
    }
  };

  // Hàm chọn số đơn
  const handleChoiceNumber = (unit: string, num: number) => {
    const newListArrayDigital = listArrayDigital.map((item: any) =>
      item.unit === unit
        ? {
            ...item,
            listNums: item.listNums.map((child: any) =>
              child.number === num
                ? { ...child, isActive: !child.isActive }
                : child
            ),
          }
        : item
    );
    setListArrayDigital(newListArrayDigital);
  };

  // Hàm chọn số theo loại
  const handlePickChoiceNumber = (type: string, unit: string) => {
    const newArrayActiveNumber = listArrayDigital.map((parent: digitalI) => {
      if (parent.unit === unit) {
        const newListMethod = parent.listTypeBet.map((item: methodI) => {
          return {
            ...item,
            isActive: type === item.type ? !item.isActive : false,
          };
        });
        const checkTypeChoice: any = newListMethod.find(
          (item: any) => item.type === type
        );
        const newListNums = parent.listNums.map((child: any) => {
          const checkBig = child.number >= 5 && child.number <= 9;
          const checkSmall = child.number >= 0 && child.number <= 4;
          const checkEven = child.number % 2 === 0;
          const checkOdd = child.number % 2 !== 0;
          return {
            ...child,
            isActive:
              type === "all"
                ? checkTypeChoice.isActive
                : type === "big"
                ? checkBig && checkTypeChoice.isActive
                : type === "small"
                ? checkSmall && checkTypeChoice.isActive
                : type === "even"
                ? checkEven && checkTypeChoice.isActive
                : type === "odd"
                ? checkOdd && checkTypeChoice.isActive
                : false,
          };
        });
        parent.listTypeBet = newListMethod;
        parent.listNums = newListNums;
      }
      return parent;
    });
    setListArrayDigital(newArrayActiveNumber);
  };

  // Hàm tính tổng của các số từ chuỗi, bỏ qua dấu &
  const sumNumbers = (input: string) => {
    const numbersArray = input.split(",");
    return numbersArray.length;
  };

  return (
    <div className="digital-bet">
      {listArrayDigital.length > 0 &&
        listArrayDigital.map(
          (digit, index) =>
            digit?.isShow === true && (
              <div
                className={classNames(
                  "list-number-wrap flex flex-col rounded-2xl mt-3 bg-surface-tertiary dark:bg-black shadow-table overflow-hidden",
                  { "!mt-0": index === 0 }
                )}
                key={index}
              >
                <div className="list-bet-choice flex items-center justify-between p-1.5">
                  <div
                    className={classNames(
                      "title-type text-center flex items-center bg-white dark:bg-bgPrimary text-primary p-1 text-sm font-semibold text-nowrap whitespace-nowrap rounded-[10px]"
                    )}
                  >
                    <div className="flex items-center mr-1 p-1.5 rounded-lg bg-primary/[6%]">
                      {Array.from({
                        length:
                          digit.unit === "unit"
                            ? 1
                            : digit.unit === "dozen"
                            ? 2
                            : digit.unit === "hundred"
                            ? 3
                            : 4,
                      }).map((_: any, index: number) => (
                        <span
                          key={index}
                          className={`${
                            index === 0
                              ? "text-primary"
                              : "text-primary/40 ml-[2px]"
                          }`}
                        >
                          <svg
                            width="7"
                            height="14"
                            viewBox="0 0 7 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.55664 13.125C2.54036 13.125 1.75423 12.8402 1.19824 12.2705C0.64681 11.7008 0.371094 10.8805 0.371094 9.80957V4.38184C0.371094 3.25163 0.633138 2.38574 1.15723 1.78418C1.68587 1.17806 2.48568 0.875 3.55664 0.875C4.63216 0.875 5.43197 1.17806 5.95605 1.78418C6.48014 2.38574 6.74219 3.25163 6.74219 4.38184V9.80957C6.74219 10.8805 6.46647 11.7008 5.91504 12.2705C5.36361 12.8402 4.57747 13.125 3.55664 13.125ZM3.55664 10.9102C3.74349 10.9102 3.88932 10.8258 3.99414 10.6572C4.09896 10.4886 4.15137 10.2995 4.15137 10.0898V4.14941C4.15137 3.86686 4.11947 3.62077 4.05566 3.41113C3.99642 3.19694 3.83008 3.08984 3.55664 3.08984C3.2832 3.08984 3.11458 3.19694 3.05078 3.41113C2.99154 3.62077 2.96191 3.86686 2.96191 4.14941V10.0898C2.96191 10.2995 3.01432 10.4886 3.11914 10.6572C3.22852 10.8258 3.37435 10.9102 3.55664 10.9102Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      ))}
                    </div>
                    {digit?.title}
                  </div>
                  <div className="flex items-center">
                    {digit?.listTypeBet.length > 0 &&
                      digit?.listTypeBet.map((item: methodI, index: number) => (
                        <div
                          className="flex items-center justify-center my-2"
                          key={index}
                        >
                          <button
                            className={classNames(
                              "btn-choice flex flex-col text-text-secondary dark:text-white/40 items-center justify-center text-xs font-medium cursor-pointer hover:text-black dark:hover:text-white ml-3",
                              {
                                "!text-black dark:!text-white": item.isActive,
                                "!ml-0": index === 0,
                              }
                            )}
                            type="button"
                            key={index}
                            onClick={() =>
                              handlePickChoiceNumber(item.type, digit.unit)
                            }
                          >
                            {item.title}
                            <div
                              className={`w-5 h-[3px] rounded ${
                                item.isActive ? "bg-primary" : "bg-transparent"
                              }`}
                            ></div>
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="list-number flex flex-wrap justify-between">
                  {digit?.listNums.length > 0 &&
                    digit?.listNums.map((num: any, index: number) => (
                      <div
                        className="w-1/5 flex items-center justify-center my-2"
                        key={index}
                      >
                        <button
                          type="button"
                          className={classNames(
                            "border-none w-10 h-10 flex items-center outline-none justify-center px-2 py-2 rounded-full bg-white dark:bg-bgPrimary text-text-secondary dark:text-white/40 cursor-pointer",
                            {
                              "!bg-primary !text-white !opacity-100":
                                num?.isActive,
                            }
                          )}
                          key={index}
                          onClick={() =>
                            handleChoiceNumber(digit?.unit, num?.number)
                          }
                        >
                          <span className="font-semibold text-sm">
                            {num?.number}
                          </span>
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            )
        )}
    </div>
  );
}

export default DigitalMethodBetMobile;
