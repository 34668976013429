import classNames from "classnames";
import { toggleSidebar } from "features/layoutSlice";
import { addGameId, addListTickets } from "features/lotterySlice";
import useToast from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { compareVietnameseStrings } from "utils/commonFormat";
import { convertNameLotteryToParam } from "utils/lotteryFormat";

const MenuLottery = ({
  className,
  onClose,
}: {
  className: string;
  onClose?: any;
}) => {
  const { idLottery } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastify = useToast();

  const { listGames } = useSelector((state: any) => state.lottery);
  const { isAuth } = useSelector((state: any) => state.auth);
  const { listMenuTrees } = useSelector((state: any) => state.common);
  const { isMobile } = useSelector((state: any) => state.layout);

  const [listMenuConvert, setListMenuConvert] = useState<any[]>([]);

  useEffect(() => {
    if (listGames && listMenuTrees) {
      const menuConverted = handleGroupMenuGames(listGames, listMenuTrees).map(
        (menu: any) => {
          const updateListMenuItem = menu.listMenuItem.map((subMenu: any) => {
            return {
              ...subMenu,
              isActive: subMenu.id === Number(idLottery),
            };
          });
          return {
            ...menu,
            listMenuItem: updateListMenuItem,
            isSelected: updateListMenuItem.some(
              (item: any) => item.id === Number(idLottery)
            ),
          };
        }
      );
      setListMenuConvert(menuConverted);
    }
  }, [listGames, listMenuTrees, idLottery]);

  const handleGroupMenuGames = (listGames: any, listMenus: any) => {
    const mergeArray: any = [];
    const menuMap: any = {};
    listMenus?.forEach((menu: any) => {
      menu?.listMenu?.forEach((item: any) => {
        const gameid = Number(item.gameid);
        menuMap[gameid] = item;
      });
    });
    listGames.forEach((game: any) => {
      if (menuMap[game.id]) {
        const mergeItem = {
          ...game,
          type: game.type,
          name: menuMap[game.id].name,
          url: menuMap[game.id].url,
          fathername:
            game.type === 3
              ? "Xổ Số Miền Bắc"
              : game.type === 2
              ? "Xổ Số Miền Trung"
              : game.type === 1
              ? "Xổ Số Miền Nam"
              : game.type === 0
              ? "Xổ Số Nhanh"
              : game.group,
        };
        mergeArray.push(mergeItem);
      }
    });
    return groupMenuByFathername(mergeArray);
  };

  const groupMenuByFathername = (menuArr: any) => {
    const groupMenu = menuArr.reduce((acc: any, item: any) => {
      if (!acc.has(item.fathername)) {
        acc.set(item.fathername, []);
      }
      acc.get(item.fathername).push(item);
      return acc;
    }, new Map());
    const result = [...groupMenu.entries()].map(([fathername, items]: any) => ({
      fathername,
      iconurl: items[0].iconurl,
      listMenuItem: items
        .map(({ id, name, desc, group, iconurl, type, url }: any) => ({
          id,
          name,
          desc,
          group,
          iconurl,
          type,
          url,
        }))
        .sort((a: any, b: any) => {
          return compareVietnameseStrings(a.desc, b.desc);
        }),
    }));
    result.sort((a: any, b: any) => {
      const order: any = {
        "Xổ Số Nhanh": 0,
        "Xổ Số Miền Bắc": 1,
        "Xổ Số Miền Trung": 2,
        "Xổ Số Miền Nam": 3,
      };
      return order[a.fathername] - order[b.fathername];
    });
    return result;
  };

  const toggleDropdown = (group: string) => {
    const newListGameConverted = listMenuConvert.map((item: any) => {
      if (item.fathername === group && item.isSelected === false) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      return item;
    });
    setListMenuConvert(newListGameConverted);
  };

  const handleNavigateLottery = (data: any) => {
    if (!isAuth) {
      toastify("Vui lòng đăng nhập!", "warning");
      return;
    }
    const parts = data.url.split("/");
    const gameid = parts.slice(1).join("/");
    navigate(`/mua-so/${convertNameLotteryToParam(data?.desc)}`);
    dispatch(addListTickets([]));
    dispatch(addGameId(gameid));
    dispatch(toggleSidebar(false));
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className={classNames(
        `w-full ${className} h-full overflow-auto bg-white dark:bg-bgPrimary p-1 rounded-[10px]`,
        { "no-scrollbar": isMobile }
      )}
    >
      {isAuth ? (
        listMenuConvert?.length > 0 &&
        listMenuConvert?.map((game: any, index: number) => (
          <div
            key={index}
            className={`${index > 0 ? "mt-2" : ""} rounded-md overflow-hidden`}
          >
            <div
              className="flex items-center justify-between bg-cardLotteryLight dark:bg-cardLottery bg-cover bg-center px-2.5 text-white/30 hover:text-white cursor-pointer"
              onClick={() => toggleDropdown(game.fathername)}
            >
              <div
                className={classNames(
                  "flex items-center w-full py-2 text-primary dark:text-white font-semibold",
                  {
                    "text-primary": game.isSelected,
                    "!text-black dark:!text-white": isMobile,
                  }
                )}
              >
                <img src={game.iconurl} className="w-6 h-6 mr-2" alt={game.fathername} />
                <span className={classNames("text-sm font-semibold italic")}>
                  {game.fathername}
                </span>
              </div>

              <svg
                className={`${
                  game.isSelected
                    ? "transform rotate-180 text-black dark:text-white"
                    : "text-text-secondary dark:text-white/40"
                } w-6 h-6 transition-all duration-300 ease-linear`}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z"
                  fill="currentColor"
                />
              </svg>
            </div>

            <div
              className={classNames(
                "max-h-0 transition-all duration-700 overflow-hidden",
                {
                  "!max-h-[9999px] transition-all duration-700":
                    game.isSelected === true,
                }
              )}
            >
              {game.listMenuItem?.map((item: any, index: number) => (
                <div
                  className={classNames(
                    "game-item p-2 my-2 rounded flex items-center text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white cursor-pointer",
                    {
                      "!text-black dark:!text-white": item.isActive,
                    }
                  )}
                  key={index}
                  onClick={() => handleNavigateLottery(item)}
                >
                  <span
                    className={classNames(
                      "text-sm font-semibold ml-8 w-full truncate"
                    )}
                  >
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center p-4 text-base text-black dark:text-white font-semibold">
          Vui lòng đăng nhập
        </div>
      )}
    </div>
  );
};

export default MenuLottery;
