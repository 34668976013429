import classNames from "classnames";
import { dataListTransactionI } from "../../../interfaces/data.interface";
import moment from "moment";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { currencyFormat } from "utils/currencyFormat";
import { getTitleTransStatus } from "utils/lotteryFormat";

interface propsI {
  data: dataListTransactionI;
  loading: boolean;
}

function BottomSheetDetailAdjustment(props: propsI) {
  const { data, loading } = props;
  const { listStatusTransaction } = useSelector((state: any) => state.lottery);
  return (
    <>
      {loading ? (
        <div className="w-full h-1/2 flex items-center justify-center">
          <ClipLoader color="#36d7b7" size={36} />
        </div>
      ) : (
        <div className="detail-gift flex flex-col px-4 py-2">
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">Mã số</span>
            <span className="text-sm font-medium text-white">{data?.id}</span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">
              Loại biến động
            </span>
            <span
              className={classNames(
                `text-${
                  data?.pay > 0 ? "error" : "success"
                }-500 text-sm text-center`
              )}
            >
              {getTitleTransStatus(listStatusTransaction, data?.transtype)}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">User ID</span>
            <span className="text-sm font-medium text-white">
              {data?.userid}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">
              Admin ID thao tác
            </span>
            <span className="text-sm font-medium text-white">
              {data?.adminid}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">
              Thời gian cập nhật
            </span>
            <span className="text-sm font-medium text-white">
              {moment(data?.inserttime).format("DD/MM/yyyy - HH:mm:ss")}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">Số điểm</span>
            <span
              className={classNames(
                `text-${
                  data?.pay > 0 ? "error" : "success"
                }-500 text-sm text-center`
              )}
            >
              {data?.pay
                ? "-" + currencyFormat(data?.pay)
                : data?.income
                ? "+" + currencyFormat(data?.income)
                : 0}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">
              Số dư trước
            </span>
            <span className="text-sm text-end font-medium  text-wrap break-words text-white">
              {data?.beforebalance ? currencyFormat(data?.beforebalance) : 0}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">Số dư sau</span>
            <span className="text-sm text-end font-medium  text-wrap break-words text-white">
              {data?.afterbalance ? currencyFormat(data?.afterbalance) : 0}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-divider dark:border-divider-dark">
            <span className="text-sm font-medium text-primary">Mô tả</span>
            <span className="text-sm text-end font-medium  text-wrap break-words text-white">
              {data?.remark}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default BottomSheetDetailAdjustment;
