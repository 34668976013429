import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    layoutType: 0, //0: default; 1: all
    mode: 0, //0: light; 1: dark
    isMobile: false,
    isTablet: false,
    isMiniDesktop: false,
    isBottomScreen: false,
    showSidebar: false,
    resizeSidebar: false,
    showSplashScreen: true,
  },
  reducers: {
    addLayoutType(state, action) {
      state.layoutType = action.payload;
    },
    addThemeMode(state, action) {
      state.mode = action.payload;
    },
    addIsMobile(state, action) {
      state.isMobile = action.payload;
    },
    addIsTablet(state, action) {
      state.isTablet = action.payload;
    },
    addIsMiniDesktop(state, action) {
      state.isMiniDesktop = action.payload;
    },
    addIsBottomSreen(state, action) {
      state.isBottomScreen = action.payload;
    },
    toggleResizeSidebar(state, action) {
      state.resizeSidebar = action.payload;
    },
    toggleSidebar(state, action) {
      state.showSidebar = action.payload;
    },
    addShowSplashScreen(state, action) {
      state.showSplashScreen = action.payload;
    },
  },
});

const { actions, reducer } = layoutSlice;

export const {
  addLayoutType,
  addThemeMode,
  addIsMobile,
  addIsTablet,
  addIsBottomSreen,
  toggleSidebar,
  addShowSplashScreen,
  toggleResizeSidebar,
  addIsMiniDesktop,
} = actions;
export default reducer;
