import authApi from "apis/authApi";
import classNames from "classnames";
import ListAgentReg from "components/ListAgentReg/ListAgentReg";
import { addRefreshToken, checkAuth, login } from "features/authSlice";
import { addLoginname } from "features/userSlice";
import useToast from "hooks/useToast";
import { signinI } from "interfaces/auth.interface";
import Header from "partials/Header/Header";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import RegisterForm from "./components/RegisterForm";
import userApi from "apis/userApi";
import { setConfigSeo } from "features/seoSlice";

function AuthPage(props: any) {
  const pathname = useLocation();
  const { isMobile, isTablet } = useSelector((state: any) => state.layout);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastify = useToast();
  const [agentId, setAgentId] = useState<string>("");
  const [listTopAgent, setListTopAgent] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { configSeo } = useSelector((state: any) => state?.seo);

  useEffect(() => {
    if (pathname) {
      const ref = pathname.search.slice(pathname.search.indexOf("=") + 1);
      setAgentId(ref);
    }
  }, [pathname]);

  useEffect(() => {
    handleGetListTopAgent();

    dispatch(
      setConfigSeo({
        ...configSeo,
        title: "Đăng ký - Trợ lý xổ số hàng đầu Việt Nam",
      })
    );
  }, []);

  const handleSubmitLogin = async (payload: signinI, type: string) => {
    try {
      const { data } = await authApi.signin(payload);
      if (data.error_code === 0) {
        if (type !== "register") toastify("Đăng nhập thành công!", "success");
        dispatch(addLoginname(payload.loginname));
        dispatch(addRefreshToken(data.data.refeshtoken));
        dispatch(checkAuth(true));
        dispatch(login(data.data.token));
        navigate("/ket-qua");
        return;
      }
    } catch (error: any) {
      // if (error.msg === "Invalid username or password") {
      //   toastify("Tài khoản hoặc mật khẩu sai!", "error");
      //   return;
      // } else if (error.msg === "You already loggin") {
      //   toastify("Bạn đã đăng nhập rồi!", "error");
      //   return;
      // } else if (error.msg) {
      //   toastify(error.msg, "error");
      // }
      console.log(error);
    }
  };

  const handleGetListTopAgent = async () => {
    setLoading(true);
    try {
      const { data } = await userApi.getListTopAgent();
      if (data) {
        const dataRes = data.data.sort(
          (a: any, b: any) => a.orderNum - b.orderNum
        );

        setListTopAgent(dataRes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitRegister = async (payload: any) => {
    if (!agentId) {
      toastify("Thông tin đại lý không được trống", "warning");
      return;
    }
    try {
      const { data } = await authApi.register({
        ...payload,
        agentid: Number(agentId),
      });
      if (data.error_code === 0) {
        toastify("Đăng ký tài khoản mới thành công!", "success");
        handleSubmitLogin(
          {
            loginname: payload.loginname,
            password: payload.password,
          },
          "register"
        );
      } else {
        toastify(data.msg || "Đăng ký thất bại", "error");
      }
    } catch (error: any) {
      // if (error.msg) {
      //   toastify(error.msg || "Đăng ký thất bại", "error");
      // }
      console.log(error);
    }
  };

  const handleGetAgentId = (agentId: number) => {
    setAgentId(agentId.toString());
  };

  return (
    <div
      className={classNames(
        "w-full h-full min-h-screen bg-white dark:bg-bgPrimary flex flex-col items-center shadow-[10px_18px_40px_0px_rgba(19,126,43,0.3)] justify-center transition-opacity duration-300 ease-in-out"
      )}
    >
      <div className={classNames("main-header w-full h-[56px]")}>
        <div className="fixed z-10 w-full min-w-[100vw]">
          <Header type="home"></Header>
        </div>
      </div>

      {!isMobile && (
        <div className="w-full h-full absolute top-0 left-0">
          <LazyLoadImage
            width="100%"
            height="100%"
            className="max-w-full object-fit"
            src={require("assets/images/banner/splash-screen.png")}
          />
        </div>
      )}
      <div
        className={classNames(
          "flex items-center justify-center w-full h-full flex-1 z-[5] max-w-[1096px]"
        )}
      >
        {!isMobile && !isTablet && (
          <div className="w-full mr-5 h-[60vh]">
            <ListAgentReg
              getAgentId={handleGetAgentId}
              agentid={agentId}
              listTopAgent={listTopAgent}
              loading={loading}
            />
          </div>
        )}
        <div className="login-container h-full mx-auto shadow-lg min-w-[360px] flex items-center rounded-[20px]">
          <RegisterForm
            submitRegister={handleSubmitRegister}
            agentId={agentId}
            submitLogin={handleSubmitLogin}
            getAgentId={handleGetAgentId}
            listTopAgent={listTopAgent}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
