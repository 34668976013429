import classNames from "classnames";
import BottomSheet from "components/BottomSheet/BottomSheet";
import DetailBetMobile from "components/DetailBetMobile/DetailBetMobile";
import TagComponent from "components/TagComponent/TagComponent";
import { recordLotteryI } from "interfaces/lottery.interface";
import { useState } from "react";
import { currencyFormat } from "utils/currencyFormat";
import { formatDateTimeBasic } from "utils/formateDate";
import { icons } from "utils/icons";
import {
  formatGetNameLottery,
  formatGetTitleStatus,
} from "utils/lotteryFormat";

function HistoryTableBet(props: any) {
  const { data, listStatus, listGames, updateListRecord } = props;

  const [dataDetail, setDataDetail] = useState<any>();
  const [showBottomSheetDetail, setShowBottomSheetDetail] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [idItemSelect, setIdItemSelect] = useState<number>();

  const handleShowDetailBet = (data: any) => {
    setLoading(true);
    setDataDetail(data);
    setShowBottomSheetDetail(true);
    setTimeout(() => {
      setLoading(false);
    });
  };

  const handleToggleShow = (index: any) => {
    setIdItemSelect(index === idItemSelect ? null : index);
  };

  return (
    <>
      <div className="table-history-container w-full rounded p-4">
        <div className="table-wrap w-full">
          <div className="body-table w-full">
            {data.length > 0 ? (
              data.map((item: recordLotteryI, index: number) => (
                <div
                  className={classNames(
                    "body-table-item flex flex-col bg-white dark:bg-bgPrimary rounded-[10px] overflow-hidden cursor-pointer",
                    { "mt-3": index > 0 }
                  )}
                  key={index}
                >
                  <div className="flex justify-between px-4 py-2">
                    <div className="w-1/3 flex flex-col text-xs font-medium">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Đài
                      </span>
                      <span className="text-black dark:text-white">
                        {formatGetNameLottery(item.gameid, listGames)}
                      </span>
                    </div>
                    <div className="w-1/3 flex flex-col text-xs font-medium">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Lượt xổ
                      </span>
                      <span className="text-black dark:text-white">
                        {item.issue}
                      </span>
                    </div>
                    <div className="w-1/3 flex flex-col text-xs font-medium">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Kiểu chơi
                      </span>
                      <span className="text-black dark:text-white">
                        {item.methodname}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${
                      idItemSelect === index
                        ? "max-h-60"
                        : "max-h-0 overflow-hidden"
                    } transition-all duration-500`}
                  >
                    <div className="px-4 pb-2 flex flex-col">
                      <div className="flex justify-between mb-2">
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40">
                            Tỉ lệ
                          </span>
                          <span className="text-error-500">1:{item.odd}</span>
                        </div>
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40">
                            Số chơi
                          </span>
                          <span className="text-black dark:text-white w-4/5 text-nowrap truncate">
                            {item.codes}
                          </span>
                        </div>
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Thời gian chơi
                          </span>
                          <span className="text-black dark:text-white">
                            {formatDateTimeBasic(item.bettime)}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Cấp số nhân
                          </span>
                          <span className="text-black dark:text-white">
                            {currencyFormat(item.multiple)}
                          </span>
                        </div>
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Chi tiết
                          </span>
                          <span
                            className={`text-primary underline`}
                            onClick={() => handleShowDetailBet(item)}
                          >
                            Xem chi tiết
                          </span>
                        </div>
                        <div className="w-1/3 flex flex-col text-xs font-medium">
                          <span className="text-text-secondary dark:text-white/40 mb-1">
                            Mã vé chơi
                          </span>
                          <span className="text-black dark:text-white text-wrap break-words">
                            {item.id}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex justify-between px-4 py-2 bg-surface-tertiary dark:bg-black"
                    onClick={() => handleToggleShow(index)}
                  >
                    <div className="w-3/12 flex flex-col text-xs font-medium">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Điểm chơi
                      </span>
                      <span className="text-black dark:text-white">
                        {" "}
                        {currencyFormat(item.amount)}
                      </span>
                    </div>
                    <div className="w-3/12 flex flex-col text-xs font-medium">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Điểm thắng
                      </span>
                      <span
                        className={classNames("text-black dark:text-white", {
                          "!text-success-500": item.bonus > 0,
                          "!text-error-500": item.bonus < 0,
                        })}
                      >
                        {item.bonus > 0 ? currencyFormat(item.bonus) : 0}
                      </span>
                    </div>
                    <div className="w-5/12 flex flex-col text-xs font-medium">
                      <span className="text-text-secondary dark:text-white/40 mb-1">
                        Trạng thái
                      </span>
                      <span
                        className={classNames(
                          "w-fit text-center text-wrap font-medium break-words truncate text-xs"
                        )}
                      >
                        <TagComponent
                          title={formatGetTitleStatus(
                            item.status,
                            listStatus,
                            "title"
                          )}
                          type={
                            [3, 5, 6, 7].includes(item.status)
                              ? "error"
                              : item.status === 4
                              ? "success"
                              : "warning"
                          }
                        />
                      </span>
                    </div>
                    <div className="w-1/12 flex items-center ml-1">
                      <button
                        className="bg-secondary/10 p-1 rounded-lg w-7 h-7 flex items-center justify-center"
                        type="button"
                      >
                        <img
                          src={icons.arrowLeft}
                          className={`${
                            idItemSelect !== index ? "-rotate-90" : "rotate-90"
                          } transition-transform duration-500 h-3`}
                          alt="Lịch Sử Chơi"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data px-2.5 py-2.5 flex items-center justify-center border-borderLightPrimary dark:border-borderPrimary">
                <span className=" text-xs font-semibold text-black dark:text-white">
                  Chưa tìm thấy lịch sử chơi nào
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomSheet
        noClickOutSide
        isShow={showBottomSheetDetail}
        direction="vertical"
        height="90%"
      >
        <DetailBetMobile
          data={dataDetail}
          loading={loading}
          updateListRecord={updateListRecord}
          onClose={() => setShowBottomSheetDetail(false)}
          typeComponent="bottomsheet"
        />
      </BottomSheet>
    </>
  );
}

export default HistoryTableBet;
