import React, { useState } from "react";

interface PropsI {
  type: string;
  onSubmit: any;
  loading?: boolean;
  title: string;
  className?: string;
}

const ButtonAction = (props: PropsI) => {
  const { type, onSubmit, loading, title, className } = props;

  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoadingAction(true);

      setTimeout(() => {
        setLoadingAction(false);
      }, 2000);
    } else {
      setLoadingAction(loading);
    }
    onSubmit();
  };

  return (
    <button
      className={`px-2.5 py-2 max-h-10 rounded-[10px] text-sm flex items-center justify-center font-semibold ${className} ${
        type === "primary"
          ? "bg-primary hover:bg-primary/80 active:bg-primary/80 text-white"
          : "text-primary bg-secondary/20 hover:opacity-80 active:opacity-80"
      }`}
      type="button"
      onClick={handleSubmit}
    >
      {!loadingAction ? (
        title
      ) : (
        <div className="loop-spin relative w-[20px] h-[20px] rounded-full border-[2px] border-opacity-80 border-transparent border-l-white border-r-white inline-block align-middle z-10"></div>
      )}
    </button>
  );
};

export default ButtonAction;
