import classNames from "classnames";
import { prizesInitMB, prizesInitMTMN } from "constant";
import { useSelector } from "react-redux";
import { formatGeneratePrizeLottery } from "utils/lotteryFormat";

const ResultHistoryTable = ({
  data,
  gameid,
  dataHover,
  dataSelect,
  isHovered,
}: {
  data: any;
  gameid: number;
  dataHover: any;
  dataSelect: any;
  isHovered: boolean;
}) => {
  const { isMobile } = useSelector((state: any) => state.layout);

  const handleCheckDauDuoiHighlight = (
    value: string,
    datahover: any,
    typehover: string,
    dataselect: any,
    typeselect: string
  ) => {
    let strHTML: string = "";
    const styleHightlight =
      "background-color: #22C55E; margin-left: 1px; transition: transform 0.5s ease-linear; transform: scale(1); padding: 1px 3px; display: flex; align-items: center;justify-content: center;border-radius: 8px;color: #ffffff;";
    const firstPart: string = value?.slice(0, -2);
    const lastPart: string = value?.slice(-2);
    const arrValueDauHover = datahover?.dau?.split(/,/g).map((str: string) => {
      return str + datahover?.key;
    });
    const arrValueDuoiHover = datahover?.duoi
      ?.split(/,/g)
      .map((str: string) => {
        return datahover?.key + str;
      });

    const arrValueDauSelect = dataselect?.dau
      ?.split(/,/g)
      .map((str: string) => {
        return str + dataselect?.key;
      });

    const arrValueDuoiSelect = dataselect?.duoi
      ?.split(/,/g)
      .map((str: string) => {
        return dataselect?.key + str;
      });
    const shouldHighlightHover = (part: string) => {
      switch (typehover) {
        case "dau":
          return arrValueDauHover.includes(part);
        case "duoi":
          return arrValueDuoiHover.includes(part);
        case "both":
          return (
            arrValueDauHover.includes(part) || arrValueDuoiHover.includes(part)
          );
        default:
          return false;
      }
    };

    const shouldHighlightSelect = (part: string) => {
      switch (typeselect) {
        case "dau":
          return arrValueDauSelect.includes(part);
        case "duoi":
          return arrValueDuoiSelect.includes(part);
        case "both":
          return (
            arrValueDauSelect.includes(part) ||
            arrValueDuoiSelect.includes(part)
          );
        default:
          return false;
      }
    };

    const isHighlighted =
      shouldHighlightHover(lastPart) || shouldHighlightSelect(lastPart);

    strHTML = isHighlighted
      ? firstPart + `<span style="${styleHightlight}">${lastPart}</span>`
      : value;

    return strHTML && isHovered ? strHTML : value;
  };

  return (
    <div className="w-full min-h-full flex flex-col">
      {[200, 201, 210].includes(gameid)
        ? prizesInitMB.map((prize: any, index: number) => (
            <div
              className={classNames(
                "wrap-prize h-full flex-1 border-t dark:border-borderPrimary border-borderLightPrimary flex",
                {
                  "!border-none": index === 0,
                }
              )}
              key={index}
            >
              <div className="title-prize flex items-center justify-center w-2/12">
                <span
                  className={classNames(
                    "text-sm text-black dark:text-white font-semibold text-center",
                    { "!text-xs": isMobile }
                  )}
                >
                  {prize.title}
                </span>
              </div>
              <div className="w-full p-2 flex justify-center flex-1 border-l border-borderLightPrimary dark:border-borderPrimary">
                <div className="flex w-full items-center justify-center flex-wrap">
                  {Array.from({ length: prize.subPrizes || 1 }).map(
                    (_, subIndex) => (
                      <div
                        className={`${
                          index === 2
                            ? "w-1/2"
                            : index === 3 || index === 6 || index === 5
                            ? "w-1/3"
                            : index === 4 || index === 7
                            ? "w-1/4"
                            : "w-full"
                        } flex items-center justify-center`}
                        key={subIndex}
                      >
                        <div
                          className={classNames(
                            "number-prize w-full flex items-center justify-center"
                          )}
                        >
                          <p
                            className={classNames(
                              "text-sm font-semibold flex items-center justify-center text-black dark:text-white text-center",
                              {
                                "!text-base !text-warning-500":
                                  index === 0 || index === 7,
                                "!text-sm":
                                  isMobile && (index === 0 || index === 7),
                                "!text-[13px]": isMobile,
                              }
                            )}
                            dangerouslySetInnerHTML={{
                              __html: handleCheckDauDuoiHighlight(
                                formatGeneratePrizeLottery(
                                  data?.[prize.dataKey],
                                  subIndex + 1
                                ),
                                dataHover?.data,
                                dataHover?.type,
                                dataSelect?.data,
                                dataSelect?.type
                              ),
                            }}
                          ></p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ))
        : prizesInitMTMN.map((prize: any, index: number) => (
            <div
              className={classNames(
                "wrap-prize h-full flex-1 border-t border-borderLightPrimary dark:border-borderPrimary flex",
                {
                  "!border-none": index === 0,
                }
              )}
              key={index}
            >
              <div className="title-prize flex items-center justify-center w-2/12">
                <span
                  className={classNames(
                    "text-sm text-black dark:text-white text-center font-semibold flex items-cente",
                    { "!text-xs": isMobile }
                  )}
                >
                  {prize.title}
                </span>
              </div>
              <div className="flex-1 flex items-center justify-center flex-wrap border-l border-borderLightPrimary dark:border-borderPrimary">
                {Array.from({ length: prize.subPrizes || 1 }).map(
                  (_, subIndex) => (
                    <div
                      className={`${
                        index === 3
                          ? "w-1/2"
                          : index === 6 || (index === 4 && subIndex > 3)
                          ? "w-1/3"
                          : index === 4 && subIndex < 4
                          ? "w-1/4"
                          : "w-full"
                      } flex items-center justify-center`}
                      key={subIndex}
                    >
                      <div
                        className={classNames(
                          "number-prize w-fit flex items-center justify-center"
                        )}
                      >
                        <p
                          className={classNames(
                            "text-sm font-semibold flex items-center justify-center text-black dark:text-white text-center",
                            {
                              "!text-base !text-warning-500":
                                index === 8 || index === 0,
                              "!text-sm":
                                isMobile && (index === 0 || index === 8),
                              "!text-[13px]": isMobile,
                            }
                          )}
                          dangerouslySetInnerHTML={{
                            __html: handleCheckDauDuoiHighlight(
                              formatGeneratePrizeLottery(
                                data?.[prize.dataKey],
                                subIndex + 1
                              ),
                              dataHover?.data,
                              dataHover?.type,
                              dataSelect?.data,
                              dataSelect?.type
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
    </div>
  );
};

export default ResultHistoryTable;
