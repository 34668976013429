import authApi from "apis/authApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalCommon from "components/ModalCommon/ModalCommon";
import { headerPageMenus, listNav, listSocial } from "constant";
import { addRefreshToken, checkAuth, logout } from "features/authSlice";
import { addThemeMode } from "features/layoutSlice";
import { addBalanceUser, addUserInfo } from "features/userSlice";
import useToast from "hooks/useToast";
import { navI } from "interfaces/common.interface";
import moment from "moment";
import "moment/locale/vi";
import LoginForm from "pages/AuthPage/components/LoginForm";
import MenuLottery from "partials/MenuLottery/MenuLottery";
import MenuMobile from "partials/MenuMobile/MenuMobile";
import MenuResult from "partials/MenuResult/MenuResult";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { handleRedirectLink } from "utils/commonFormat";
import { currencyFormat } from "utils/currencyFormat";
import { icons } from "utils/icons";
import "./Header.scss";

moment.locale("vi");

function Header({ type }: { type: string }) {
  const pathname = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastify = useToast();
  const popopverUserRef = useRef<any>();
  const menuMobileRef = useRef<any>();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const { isAuth } = useSelector((state: any) => state.auth);
  const { countInbox } = useSelector((state: any) => state.common);
  const { userInfo, balance } = useSelector((state: any) => state.user);
  const { isTablet, isMobile, mode } = useSelector(
    (state: any) => state.layout
  );
  const { configSeo } = useSelector((state: any) => state?.seo);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [showModalLogin, setShowModalLogin] = useState<boolean>(false);
  const [showMenuMobile, setShowMenuMobile] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [showModalConfirmLogout, setShowModalConfirmLogout] =
    useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (menuMobileRef.current && !menuMobileRef.current.contains(e.target)) {
        setShowMenuMobile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (showMenuMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMenuMobile]);

  const handleNavigate = (path: any, isPublic?: boolean) => {
    if (!isAuth) {
      if (isPublic === true) {
        navigate(path);
      } else {
        toastify("Vui lòng đăng nhập!", "warning");
        return;
      }
    } else {
      navigate(path);
    }
    handleClosePopover();
    setShowMenuMobile(false);
  };

  const handleLogout = async () => {
    setLoadingAction(true);
    try {
      const { data } = await authApi.logout();
      if (data) {
        setShowMenuMobile(false);
        dispatch(logout());
        dispatch(checkAuth(false));
        dispatch(addUserInfo(null));
        dispatch(addBalanceUser(0));
        dispatch(addRefreshToken(null));
        toastify("Đăng xuất thành công!", "success");
        setShowPopover(false);
        navigate("/ket-qua");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAction(false);
      setShowModalConfirmLogout(false);
    }
  };

  const handleToggleMode = (e: any) => {
    if (mode === 0) {
      dispatch(addThemeMode(1));
      localStorage.setItem("darkMode", "true");
    } else {
      dispatch(addThemeMode(0));
      localStorage.setItem("darkMode", "false");
    }
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  const handleClickUserIcon = (path: string) => {
    if (isTablet) {
      setShowPopover(true);
    } else {
      handleNavigate(path);
    }
  };

  return (
    <div
      className="w-full flex items-center justify-center shadow-sm bg-black"
      ref={menuRef}
    >
      {showPopover && (
        <div
          className="w-full h-full fixed top-0 left-0 z-10"
          onClick={handleClosePopover}
        ></div>
      )}
      <header
        className={`header w-full flex justify-center items-center max-w-[1440px] ${
          !isMobile ? "px-3" : ""
        }`}
      >
        {!isMobile ? (
          <div className="flex flex-col justify-center items-center w-full h-[84px] py-2">
            {/* {type !== "landing" && ( */}
            <div className="flex items-center justify-between w-full mb-2">
              <div className="flex items-center text-xs text-white/40 font-semibold">
                <span
                  className="py-0.5 px-2 hover:text-white cursor-pointer"
                  onClick={() => handleNavigate("/ve-chung-toi", true)}
                >
                  Về chúng tôi
                </span>
                <span
                  className="py-0.5 px-2 hover:text-white cursor-pointer"
                  onClick={() =>
                    handleRedirectLink("https://forms.office.com/r/xmKmtrUCZ4")
                  }
                >
                  Liên hệ quảng cáo
                </span>
              </div>
              <div className="flex items-center">
                {listSocial.map((social: any, index: number) => (
                  <div
                    className="w-4 h-4 group ml-2 cursor-pointer"
                    key={index}
                  >
                    <img
                      src={social.iconDefault}
                      className="w-full h-full block group-hover:hidden"
                      alt={configSeo?.title}
                    />
                    <img
                      src={social.iconHover}
                      className="w-full h-full hidden group-hover:block"
                      alt={configSeo?.title}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* )} */}

            <div className="header-container w-full sm:hidden block">
              <div className="topbar flex justify-between items-center w-full">
                <div className="header-left flex items-center">
                  <img
                    src={require("assets/images/logo.png")}
                    className={`${
                      isTablet ? "h-9" : "h-7"
                    } w-auto cursor-pointer`}
                    onClick={() => navigate("/")}
                    alt={configSeo?.title}
                  />
                </div>
                <ul className="header-center flex items-center">
                  {headerPageMenus.map((menu: any, index: number) => (
                    <li
                      key={index}
                      className={classNames("relative", {
                        "ml-4": index > 0,
                        group: index !== 2,
                        hidden: !isAuth && index === 1,
                      })}
                    >
                      <span
                        className="hover-line flex items-center text-white/40 hover:text-white transition-colors text-sm font-semibold cursor-pointer"
                        onClick={() => {
                          if (index === 2)
                            handleNavigate(menu.path, menu.isPublic);
                        }}
                      >
                        {menu.title}
                        {index !== 2 && (
                          <svg
                            className={`${"transform group-hover:rotate-180"} w-5 h-5 transition-all duration-300 ease-linear ml-1`}
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z"
                              fill="currentColor"
                            />
                          </svg>
                        )}
                      </span>
                      <div
                        className={`${
                          index === 3
                            ? `p-4 w-full fixed top-[84px]`
                            : "p-1 min-w-[240px] absolute top-9"
                        } group-hover:!opacity-100 group-hover:!visible opacity-0 invisible -z-50 group-hover:z-50 transition-all bg-white dark:bg-bgPrimary rounded-[10px] shadow-table left-0 ${
                          showPopover && (isMobile || isTablet)
                            ? "opacity-100 visible z-50"
                            : "opacity-0 invisible -z-50"
                        }`}
                      >
                        {index === 0 && (
                          <MenuResult className="max-h-[60vh] h-full" />
                        )}
                        {index === 1 && (
                          <MenuLottery className="h-full max-h-[60vh]" />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="header-right flex items-center">
                  {isAuth ? (
                    <>
                      <div className="wrap-balance-user flex items-center justify-between bg-bgLayout px-3 py-2 rounded-4xl">
                        <div className="balance-user flex items-center">
                          <span className="balance text-sm text-white font-semibold flex items-center">
                            {Number(balance) > 0
                              ? currencyFormat(Number(balance))
                              : Number(userInfo?.balance) > 0
                              ? currencyFormat(userInfo?.balance)
                              : 0}{" "}
                            {!isTablet && "point"}
                          </span>
                        </div>
                      </div>
                      <button
                        className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/10 outline-none rounded-[10px] ml-2"
                        type="button"
                        onClick={handleToggleMode}
                      >
                        <img
                          src={mode === 1 ? icons.sun : icons.moon}
                          className="icon-header text-primary w-5 h-5"
                          alt={configSeo?.title}
                        />
                      </button>
                      {!isTablet && (
                        <div className="relative ml-2">
                          <button
                            className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/10 rounded-[10px]"
                            type="button"
                            onClick={() =>
                              handleNavigate("/tai-khoan/thong-bao")
                            }
                          >
                            <img
                              src={require("assets/icons/bell.png")}
                              className="icon-header w-5 h-5"
                              alt={configSeo?.title}
                            />
                          </button>
                          {countInbox > 0 && (
                            <div className="absolute -top-1 -right-1 w-5 h-5 rounded-full bg-error-500 text-white text-xs flex items-center justify-center">
                              {countInbox}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="relative group ml-2">
                        <div
                          className="wrap-user-info flex items-center justify-center bg-primary/10 rounded-[10px] cursor-pointer w-9 h-9"
                          onClick={() =>
                            handleClickUserIcon("/tai-khoan/thong-tin-ca-nhan")
                          }
                          ref={popopverUserRef}
                        >
                          <img
                            src={require("assets/icons/user.png")}
                            className="avatar w-5 h-5"
                            alt={configSeo?.title}
                          />
                        </div>{" "}
                        <div
                          className={`popover-menu-user transition-all p-1 group-hover:!opacity-100 group-hover:!visible opacity-0 invisible duration-300 ease-in-out w-[240px] bg-white dark:bg-bgPrimary rounded-[10px] overflow-hidden shadow-custom2 absolute top-12 right-0 z-50 ${
                            showPopover && (isMobile || isTablet)
                              ? "opacity-100 visible"
                              : "opacity-0 invisible"
                          } transition-all duration-300 ease-in-out`}
                        >
                          {listNav?.map((nav: navI, index: number) => (
                            <div
                              className="nav-item px-3 py-2 flex items-center rounded-lg cursor-pointer text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-900"
                              key={index}
                              onClick={() => handleNavigate(nav.path)}
                            >
                              <div
                                className="mr-2.5"
                                dangerouslySetInnerHTML={{ __html: nav.icon }}
                              />

                              <span className="text-sm font-semibold">
                                {nav.title}
                              </span>
                            </div>
                          ))}
                          <div
                            className="nav-item px-3 py-2 flex items-center rounded-lg text-text-secondary dark:text-white/40 hover:text-black dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-900 cursor-pointer"
                            onClick={() => setShowModalConfirmLogout(true)}
                          >
                            <svg
                              className="mr-2"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 4.62219V15.245C20 16.3579 19.2857 17.4708 18.1633 17.8754L13.0612 19.7977C12.7551 19.8988 12.449 20 12.0408 20C11.5306 20 10.9184 19.7977 10.4082 19.4942C10.2041 19.2918 9.89796 19.0895 9.79592 18.8871H5.91837C4.38776 18.8871 3.06122 17.6731 3.06122 16.0544V15.0427C3.06122 14.638 3.36735 14.2333 3.87755 14.2333C4.38776 14.2333 4.69388 14.5368 4.69388 15.0427V16.0544C4.69388 16.7626 5.30612 17.2684 5.91837 17.2684H9.28572V2.69997H5.91837C5.20408 2.69997 4.69388 3.20582 4.69388 3.91401V4.9257C4.69388 5.33038 4.38776 5.73506 3.87755 5.73506C3.36735 5.73506 3.06122 5.33038 3.06122 4.9257V3.91401C3.06122 2.39646 4.28572 1.08125 5.91837 1.08125H9.79592C10 0.878913 10.2041 0.676573 10.4082 0.474233C11.2245 -0.0316156 12.1429 -0.132785 13.0612 0.170724L18.1633 2.09295C19.1837 2.39646 20 3.50933 20 4.62219Z"
                                fill="currentColor"
                              />
                              <path
                                d="M2.85714 12.8169C2.65306 12.8169 2.44898 12.7158 2.34694 12.6146L0.306122 10.5912C0.204082 10.49 0.204082 10.3889 0.102041 10.3889C0.102041 10.2877 0 10.1865 0 10.0854C0 9.98419 -9.88342e-08 9.88302 0.102041 9.78185C0.102041 9.68068 0.204082 9.57951 0.306122 9.57951L2.34694 7.55612C2.65306 7.25261 3.16327 7.25261 3.46939 7.55612C3.77551 7.85963 3.77551 8.36548 3.46939 8.66898L2.7551 9.37717H6.93878C7.34694 9.37717 7.7551 9.68068 7.7551 10.1865C7.7551 10.6924 7.34694 10.7936 6.93878 10.7936H2.65306L3.36735 11.5017C3.67347 11.8052 3.67347 12.3111 3.36735 12.6146C3.26531 12.7158 3.06122 12.8169 2.85714 12.8169Z"
                                fill="currentColor"
                              />
                            </svg>

                            <span className="text-sm font-semibold">
                              Đăng Xuất
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => setShowModalLogin(true)}
                        className="py-2.5 px-3 rounded-[10px] border border-primary text-white bg-primary text-xs font-semibold hover:bg-primary/80 hover:border-primary/80"
                      >
                        Đăng nhập
                      </button>
                      <button
                        type="button"
                        onClick={() => navigate("/dang-ky")}
                        className="py-2.5 px-3 rounded-[10px] border border-primary text-primary text-xs font-semibold hover:text-primary/80 hover:border-primary/80 ml-2"
                      >
                        Đăng ký
                      </button>
                      <button
                        className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/10 outline-none rounded-[10px] ml-2"
                        type="button"
                        onClick={handleToggleMode}
                      >
                        <img
                          src={mode === 1 ? icons.sun : icons.moon}
                          className="icon-header text-primary w-5 h-5"
                          alt={configSeo?.title}
                        />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="header-mobile h-[56px] flex items-center w-full px-4 py-2 min-h-14 bg-bgLayout">
            <div className="w-full flex items-center justify-between">
              <div className="main-left flex items-center">
                {!pathname.pathname.includes("/ket-qua") && (
                  <div
                    className="wrap-user-info flex items-center justify-center bg-primary/10 rounded-[10px] cursor-pointer w-9 h-9 mr-2"
                    onClick={() => handleNavigate("/ket-qua", true)}
                  >
                    <img
                      src={icons.arrowLeft}
                      className="avatar w-4 h-4"
                      alt={configSeo?.title}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="w-10 h-10 flex items-center justify-center text-primary"
                  onClick={() => handleNavigate("/ket-qua", true)}
                >
                  <img
                    src={require("assets/images/icon-logo.png")}
                    className="h-10"
                    alt={configSeo?.title}
                  />
                </button>
              </div>
              <div className="main-right flex items-center">
                {isAuth ? (
                  <>
                    <div className="wrap-balance-user flex items-center justify-between bg-black px-4 py-2.5 rounded-4xl">
                      <div className="balance-user flex items-center">
                        <span className="balance text-xs text-white font-semibold flex items-center">
                          {balance
                            ? currencyFormat(balance)
                            : userInfo?.balance
                            ? currencyFormat(userInfo?.balance)
                            : 0}{" "}
                          point
                        </span>
                      </div>
                    </div>

                    <div className="relative mx-2">
                      <button
                        className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/10 rounded-[10px]"
                        type="button"
                        onClick={() => handleNavigate("/tai-khoan/thong-bao")}
                      >
                        <img
                          src={require("assets/icons/bell.png")}
                          className="icon-header w-5 h-5"
                          alt={configSeo?.title}
                        />
                      </button>
                      {countInbox > 0 && (
                        <div className="absolute -top-1 -right-1 w-5 h-5 rounded-full bg-error-500 text-white text-xs flex items-center justify-center">
                          {countInbox}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => setShowModalLogin(true)}
                      className="py-[9px] px-3 rounded-[10px] border border-primary text-white bg-primary text-xs font-semibold hover:bg-primary/80 hover:border-primary/80"
                    >
                      Đăng nhập
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate("/dang-ky")}
                      className="py-[9px] px-3 rounded-[10px] border border-primary text-primary text-xs font-semibold hover:text-primary/80 hover:border-primary/80 mx-2"
                    >
                      Đăng ký
                    </button>
                  </>
                )}
                <div
                  className="wrap-user-info flex items-center justify-center bg-primary/10 rounded-[10px] cursor-pointer w-9 h-9"
                  onClick={() => setShowMenuMobile(true)}
                  ref={popopverUserRef}
                >
                  <img
                    src={icons.hamburger}
                    className="avatar w-5 h-5"
                    alt={configSeo?.title}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      <div
        className={classNames(
          "bg-black/40 opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
          {
            "!z-30 opacity-80 duration-150 ease-linear !visible":
              showMenuMobile,
          }
        )}
        onClick={() => setShowModalLogin(false)}
      ></div>
      <div className="z-[50]" ref={menuMobileRef}>
        <MenuMobile
          onClose={() => setShowMenuMobile(false)}
          isShow={showMenuMobile}
        />
      </div>
      <Modal isShow={showModalLogin} onClose={() => setShowModalLogin(false)}>
        <LoginForm onClose={() => setShowModalLogin(false)} />
      </Modal>
      <Modal
        isShow={showModalConfirmLogout}
        onClose={() => setShowModalConfirmLogout(false)}
      >
        <ModalCommon
          title="Xác nhận"
          loading={loadingAction}
          content="Bạn có thật sự muốn đăng xuất không?"
          handleClose={() => setShowModalConfirmLogout(false)}
          handleSubmit={handleLogout}
        />
      </Modal>
    </div>
  );
}

export default Header;
