import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";

interface skeletonI {
  height?: number | string;
  circle?: any;
  width?: number | string;
  count?: number;
}

export default function SkeletonLoading(props: skeletonI) {
  const { height, circle, width, count } = props;
  const { mode } = useSelector((state: any) => state.layout);
  return (
    <Skeleton
      height={height}
      circle={circle}
      width={width}
      count={count || 1}
      baseColor={mode === 1 ? "#333" : "#ebebeb"}
      highlightColor={mode === 1 ? "#444" : "#f5f5f5"}
    />
  );
}
