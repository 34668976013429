import classNames from "classnames";
import RandomNumberCount from "components/RandomNumberCount/RandomNumberCount";
import { prizesInitMB } from "constant";
import { useSelector } from "react-redux";
import {
  formatGeneratePrizeLottery,
  formatPrizeNumberByMethod,
} from "utils/lotteryFormat";

function TableFormatMBMobile(props: any) {
  const { method, data, currentIssue, issue, statusUpdate } = props;

  const { isMobile } = useSelector((state: any) => state.layout);

  const checkInvalidValue = (value: any) => {
    if ((!value || value === "" || value === null) && currentIssue === issue) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="table-format bg-white dark:bg-black shadow-table rounded-[10px] overflow-hidden border border-borderLightPrimary dark:border-borderPrimary">
      {prizesInitMB.map((prize: any, index: number) => (
        <div
          className={classNames("wrap-prize p-2 flex items-center", {
            "border-t border-borderLightPrimary dark:border-borderPrimary":
              index > 0,
          })}
          key={index}
        >
          <div className="title-prize flex items-center justify-center w-3/12">
            <span
              className={classNames(
                "text-sm text-black dark:text-white font-semibold text-center",
                { "!text-xs": isMobile }
              )}
            >
              {prize.title}
            </span>
          </div>
          <div className="flex-1 flex items-center justify-center flex-wrap">
            {Array.from({ length: prize.subPrizes || 1 }).map((_, subIndex) => (
              <div
                className={`${
                  ["giaiba", "giainam"].includes(prize.dataKey) && subIndex > 2
                    ? "mt-2.5"
                    : ""
                } ${
                  ["giaitu", "giaibay"].includes(prize.dataKey)
                    ? "w-1/4"
                    : ["giaiba", "giainam", "giaisau"].includes(prize.dataKey)
                    ? "w-1/3"
                    : prize.dataKey === "giainhi"
                    ? "w-1/2"
                    : "w-full"
                } flex items-center justify-center`}
                key={subIndex}
              >
                <div
                  className={classNames(
                    "number-prize w-[96%] h-9 py-1.5 px-3 rounded-[10px] bg-secondary/20 text-black dark:text-white flex items-center justify-center",
                    { "!w-full": !prize.subPrizes }
                  )}
                >
                  {checkInvalidValue(
                    formatGeneratePrizeLottery(
                      data?.[prize.dataKey],
                      subIndex + 1
                    )
                  ) ? (
                    statusUpdate ? (
                      <RandomNumberCount length={prize.length} />
                    ) : (
                      <span
                        className="text-sm font-semibold text-center"
                        dangerouslySetInnerHTML={{
                          __html: formatPrizeNumberByMethod(
                            method,
                            index,
                            subIndex,
                            formatGeneratePrizeLottery(
                              data?.[prize.dataKey],
                              subIndex + 1
                            )
                          ),
                        }}
                      ></span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TableFormatMBMobile;
