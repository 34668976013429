import { createSlice } from "@reduxjs/toolkit";

export const livestreamSlice = createSlice({
  name: "livestream",
  initialState: {
    linklive: "",
    linkplayer: "",
    listLiveOnlines: [],
  },
  reducers: {
    addLinkLive(state, action) {
      state.linklive = action.payload;
    },
    addLinkPlayer(state, action) {
      state.linkplayer = action.payload;
    },
    addListLiveOnlines(state, action) {
      state.listLiveOnlines = action.payload;
    },
  },
});

const { actions, reducer } = livestreamSlice;

export const { addLinkLive, addLinkPlayer, addListLiveOnlines } = actions;
export default reducer;
