import chatApi from "apis/chatApi";
import classNames from "classnames";
import BottomSheet from "components/BottomSheet/BottomSheet";
import ChatComponent from "components/ChatComponent/ChatComponent";
import LotteryTodaySection from "components/LotteryTodaySection/LotteryTodaySection";
import SideBar from "components/SideBar/SideBar";
import { listLogoPage } from "constant";
import { toggleSidebar } from "features/layoutSlice";
import Header from "partials/Header/Header";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { resultRoutes } from "routes/routes";
import { handleRedirectLink } from "utils/commonFormat";

const ResultLayout = () => {
  const { isMobile, isTablet, isMiniDesktop, showSidebar } = useSelector(
    (state: any) => state.layout
  );
  const dispatch = useDispatch();

  const chatRef = useRef<any>();
  const listGamesRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [offsetListGame, setOffsetListGame] = useState<number>(300);
  const [showBoxChat, setShowBoxChat] = useState<boolean>(false);
  const [messages, setMessages] = useState<any>([]);
  const [loadingChat, setLoadingChat] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        chatRef.current &&
        !chatRef.current.contains(event.target) &&
        !isMobile
      ) {
        setShowBoxChat(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    handleGetListConversation();
    const eventSourceChat = new EventSource(
      `${process.env.REACT_APP_BASE_API}/api/v1/message/public/chat/stream/200`
    );
    eventSourceChat.onerror = (error) => {
      console.log(error);
    };
    eventSourceChat.onmessage = (event) => {
      if (event.data && event.data !== "keep alive") {
        const result = JSON.parse(event.data);
        setMessages((mes: any) => [...mes, result]);
      }
    };

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      eventSourceChat.close();
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = showBoxChat ? "hidden" : "auto";
  }, [showBoxChat]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const newOffset = containerRef.current?.getBoundingClientRect().top;
        if (newOffset >= 62) {
          setOffsetListGame(newOffset);
        }
      }
    };
    window.document.addEventListener("scroll", handleScroll);
    return () => {
      window.document.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  const handleGetListConversation = async () => {
    setLoadingChat(true);
    try {
      const { data } = await chatApi.getListConversation({
        gameid: Number(200),
      });
      if (data) {
        const dataRes = data.data;
        setMessages(dataRes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingChat(false);
    }
  };

  const handleToggleSidebar = (status: boolean) => {
    dispatch(toggleSidebar(status));
  };

  return (
    <div
      className={classNames(
        "main-layout w-full flex dark:bg-bgLayout bg-surface-primary",
        {
          "!bg-surface-primary dark:!bg-bgPrimary": isMobile,
        }
      )}
    >
      {(isMobile || isTablet || isMiniDesktop) && (
        <div className="sidebar z-30">
          <SideBar />
          <div
            className={classNames(
              "bg-black/40 opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
              {
                "!z-30 opacity-80 duration-150 ease-linear !visible":
                  showSidebar,
              }
            )}
            onClick={() => handleToggleSidebar(false)}
          ></div>
        </div>
      )}
      <div className="content-page w-full flex flex-col items-center min-h-screen">
        <div
          className={classNames("main-header w-full h-[84px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-20 w-full min-w-[100vw]">
            <Header type="game"></Header>
          </div>
        </div>

        <div
          className={classNames(
            "content-home w-full h-full flex flex-col items-center flex-1"
          )}
        >
          <div className="w-full flex justify-center p-3 bg-bgPrimary relative z-10">
            <LotteryTodaySection />
          </div>
          <div
            className={`flex justify-center w-full ${
              isTablet ? "p-4 h-full" : isMobile ? "" : "py-8 px-3 h-full"
            } max-w-[1440px] ${
              isMobile ? "bg-surface-primary dark:bg-bgPrimary" : ""
            }`}
          >
            <div
              className={classNames("h-full min-w-[256px] mr-4", {
                hidden: isTablet || isMobile,
              })}
            >
              <div
                className={`fixed delay-100 transition-all duration-500`}
                style={{ top: offsetListGame }}
                ref={listGamesRef}
              >
                <div className="flex flex-col min-w-[256px] max-w-[256px]">
                  {listLogoPage.map((logo: any, index: number) => (
                    <div
                      className={classNames(
                        "rounded-lg overflow-hidden mt-4 cursor-pointer",
                        {
                          "!mt-0": index === 0,
                        }
                      )}
                      key={index}
                      onClick={() => handleRedirectLink(logo.url)}
                    >
                      <img
                        src={logo.img}
                        className="w-full h-full"
                        alt={logo.key}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full max-w-[1280px]" ref={containerRef}>
              <div
                className={classNames(
                  "flex w-full items-center justify-between mb-4",
                  {
                    hidden: !isTablet && !isMobile,
                    "mt-4 px-2": isMobile,
                  }
                )}
              >
                {listLogoPage.map((logo: any, index: number) => (
                  <div
                    className="rounded-lg overflow-hidden"
                    key={index}
                    onClick={() => handleRedirectLink(logo.url)}
                  >
                    <img
                      src={logo.img}
                      className="w-full h-full"
                      alt={logo.key}
                    />
                  </div>
                ))}
              </div>
              <Routes>
                {resultRoutes?.map((route, index) => {
                  const SubPage = route.component;
                  if (route.paths) {
                    return route.paths.map((path) => (
                      <Route key={path} path={path} element={<SubPage />} />
                    ));
                  } else {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={<SubPage />}
                      />
                    );
                  }
                })}
                <Route path="*" element={<Navigate to={"/"} replace />} />
              </Routes>
            </div>
            {!isMobile && !isTablet && !isMiniDesktop ? (
              <div className="chat-wrap ml-4 min-w-72 max-w-72">
                <div
                  className={`fixed delay-100 transition-all duration-500 z-0`}
                  style={{ top: offsetListGame }}
                >
                  <div className="w-72">
                    <ChatComponent listChats={messages} loading={loadingChat} />
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`fixed bottom-5 right-5 ${
                  isMobile ? "!z-[50]" : "!z-[30]"
                }`}
              >
                <button
                  className={classNames(
                    "p-2.5 rounded-[10px] bg-primary active:bg-primary/80"
                  )}
                  onClick={() => setShowBoxChat(true)}
                >
                  <img
                    className="w-5 h-5"
                    src={require("assets/icons/message.png")}
                    alt="Gửi tin nhắn"
                  />
                </button>
                <div
                  className={classNames(
                    "fixed bottom-14 right-14 min-w-0 max-w-96 h-0 transition-all duration-300",
                    {
                      "!min-w-80 !h-[70vh]": showBoxChat && !isMobile,
                    }
                  )}
                  ref={chatRef}
                >
                  <ChatComponent
                    listChats={messages}
                    loading={loadingChat}
                    onClose={() => setShowBoxChat(false)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <BottomSheet isShow={showBoxChat && isMobile} height="100%">
          <ChatComponent
            listChats={messages}
            loading={loadingChat}
            onClose={() => setShowBoxChat(false)}
          />
        </BottomSheet>
      </div>
    </div>
  );
};

export default ResultLayout;
