import classNames from "classnames";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { icons } from "utils/icons";

interface inputProp {
  handleChange: any;
  label: string;
  title: string;
  type: string;
  reset?: boolean;
  typeInput: string;
  disabled?: boolean;
  initValue?: any;
  isMobile?: boolean;
  width?: string;
}

function InputComponent(props: inputProp) {
  const {
    handleChange,
    label,
    type,
    reset,
    isMobile,
    title,
    initValue,
    typeInput,
    disabled,
    width,
  } = props;

  const inputRef = useRef<any>(null);
  const [valueInput, setValueInput] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showClearInput, setShowClearInput] = useState<boolean>(false);

  useEffect(() => {
    setValueInput("");
    handleChange(type, null);
  }, [reset]);

  useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current.value !== "") {
        handleChange(type, inputRef.current.value);
      } else {
        handleChange(type, null);
      }
    }
  }, [inputRef]);

  useEffect(() => {
    if (initValue) {
      setValueInput(initValue);
    }
  }, [initValue]);

  const handleChangeInput = (e: any) => {
    const value = e.target.value;
    setValueInput(value);
    if (value !== "" || valueInput !== "") {
      handleChange(type, value);
      setShowClearInput(true);
    } else {
      setShowClearInput(false);
      handleChange(type, null);
    }
  };

  const handleClearInput = (type: string) => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setValueInput("");
      setShowClearInput(false);
    }
  };

  return (
    <div className=" w-full flex flex-col">
      <span className="text-xs text-text-secondary font-semibold text-start mb-1">
        {title}
      </span>
      {typeInput === "textarea" ? (
        <div
          className={classNames(
            "inline-flex items-center text-sm w-fit px-2.5 py-2 rounded-[10px] focus-within::border-primary outline-0 text-text-secondary dark:text-white border border-borderLightPrimary dark:border-borderPrimary leading-none bg-white dark:bg-bgPrimary",
            { "!w-full": isMobile || width === "w-full" }
          )}
        >
          <textarea
            ref={inputRef}
            disabled={disabled}
            placeholder={label}
            rows={5}
            value={valueInput}
            onChange={(e) => handleChangeInput(e)}
            className={classNames(
              "inline-flex w-full items-center !text-black dark:!text-white text-sm outline-none border-none bg-transparent"
            )}
          />
        </div>
      ) : (
        <div
          className={classNames(
            "inline-flex items-center text-sm h-9 w-fit px-2.5 py-2 rounded-[10px] focus-within::border-primary outline-0 text-text-secondary dark:text-white border border-borderLightPrimary dark:border-borderPrimary leading-none bg-white dark:bg-bgPrimary",
            { "!w-full": isMobile || width === "w-full" }
          )}
        >
          <input
            ref={inputRef}
            disabled={disabled}
            type={typeInput && !showPassword ? typeInput : "text"}
            placeholder={label}
            value={valueInput}
            onChange={(e) => handleChangeInput(e)}
            className={classNames(
              "inline-flex w-full items-center !text-black dark:!text-white text-sm outline-none border-none bg-transparent"
            )}
          />
          {showClearInput && (
            <div
              className="flex items-center justify-center mr-1 cursor-pointer"
              onClick={() => handleClearInput(type)}
            >
              <img className="w-5 h-5" src={icons.close} alt="Đóng" />
            </div>
          )}
          {typeInput === "password" && (
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              <img
                className="w-5 h-5"
                src={showPassword ? icons.hide : icons.show}
                alt="Hiển thị"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default InputComponent;
