import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const listContent = [
  {
    id: 1,
    title: "Truy cập dễ dàng",
  },
  {
    id: 2,
    title: "Truy vấn kết quả",
  },
  {
    id: 3,
    title: "Soi kèo nhanh chóng",
  },
];

const Section3 = () => {
  const { isMobile, isTablet } = useSelector(
    (state: any) => state.layout
  );
  return (
    <section
      className={classNames(
        "w-full flex flex-col items-center justify-center px-4 py-[120px]",
        { "!py-[60px]": isTablet, "!py-[78px]": isMobile }
      )}
    >
      <div className={classNames("w-full bg-bgContentSection3 bg-cover overflow-hidden py-20 px-14 rounded-[20px] relative", { "!px-4 !py-9": isMobile })}>
        <div
          className={classNames("mb-[50px] max-w-[400px]", {
            "!max-w-full": isMobile || isTablet,
          })}
        >
          <h3 className="text-linear-primary text-[32px] font-semibold text-wrap break-words">
            Cập nhập đầy đủ và chính xác kết quả xổ số với hơn 50 tỉnh thành
          </h3>
        </div>
        <div
          className={classNames("flex items-center", {
            "flex-col !items-start": isMobile || isTablet,
          })}
        >
          {listContent.map((item: any, index: number) => (
            <div
              className={classNames(
                "border border-borderPrimary rounded-2xl p-8 bg-[#131313] flex flex-col w-[275px]",
                {
                  "mx-6": index === 1,
                  "!m-0 !w-full": isMobile || isTablet,
                  "!mt-4": (isMobile || isTablet) && index > 0,
                }
              )}
              key={index}
            >
              <h4 className="mb-10 text-white">{item.title}</h4>
              <img
                src={require(`assets/images/landingpage/icon${item.id}-section-3.png`)}
                className="w-11 h-11"
                alt={item.title}
              />
            </div>
          ))}
        </div>
        <img
          src={require("assets/images/landingpage/phone.png")}
          className={classNames("absolute -bottom-14 -right-16 max-w-[425px] h-auto", {
            "!-bottom-20 max-w-[50%] h-auto": isTablet, "!max-w-[325px] !-bottom-10 !-right-20": isMobile
          })}
          alt='Số điện thoại'
        />
      </div>
    </section>
  );
};

export default Section3;
