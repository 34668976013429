import classNames from "classnames";
import { addThemeMode, toggleSidebar } from "features/layoutSlice";
import MenuLottery from "partials/MenuLottery/MenuLottery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "utils/currencyFormat";
import "./SideBar.scss";
import { listSocial } from "constant";
import { icons } from "utils/icons";

function SideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, balance } = useSelector((state: any) => state.user);
  const { showSidebar, isMobile, mode } = useSelector(
    (state: any) => state.layout
  );

  const handleNavigate = (path: string) => {
    navigate(path);
    if (showSidebar) {
      dispatch(toggleSidebar(false));
    }
  };

  const handleToggleMode = (e: any) => {
    if (mode === 0) {
      dispatch(addThemeMode(1));
      localStorage.setItem("darkMode", "true");
    } else {
      dispatch(addThemeMode(0));
      localStorage.setItem("darkMode", "false");
    }
  };

  return (
    <div
      className={classNames(
        "sidebar-wrapper h-full max-h-screen fixed -left-full top-0 flex flex-col bg-white dark:bg-bgPrimary border-borderLightPrimary dark:border-borderPrimary border-r transition-all duration-500 w-[300px] z-50 overflow-hidden",
        {
          "left-0": showSidebar,
          "no-scrollbar": isMobile,
        }
      )}
    >
      <div
        className={classNames(
          "header-sidebar flex items-center justify-center py-4 px-6 border-b border-borderLightPrimary dark:border-borderPrimary"
        )}
      >
        <img
          src={require(`assets/images/logo${mode === 1 ? "" : "-light"}.png`)}
          className="h-6"
          alt="Hành động"
          onClick={() => handleNavigate("/")}
        />
      </div>
      <div className="px-3 mt-2">
        <div className="wrap-balance-user flex items-center justify-between px-3 py-2.5 rounded-4xl bg-surface-tertiary dark:bg-black">
          <div className="wrap-title flex items-center">
            <p className="title-balance text-sm text-black dark:text-white ml-2">
              Số dư:
            </p>
          </div>
          <div className="balance-user flex items-center ml-4">
            <span className="balance text-sm text-black dark:text-white font-semibold flex items-center">
              {balance
                ? currencyFormat(balance)
                : userInfo?.balance
                ? currencyFormat(userInfo?.balance)
                : 0}{" "}
              point
            </span>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "content-sidebar overflow-auto flex-1 my-2 no-scrollbar"
        )}
      >
        <MenuLottery className="h-full" />
      </div>
      <div className="flex flex-col px-4 py-2">
        <div className="flex items-center justify-end">
          <button
            className="btn-signup flex items-center justify-center w-9 h-9 bg-primary/10 outline-none rounded-[10px]"
            type="button"
            onClick={handleToggleMode}
          >
            <img
              src={mode === 1 ? icons.sun : icons.moon}
              className="icon-header text-primary w-5 h-5"
              alt="Hành động"
            />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between w-full border-t border-borderLightPrimary dark:border-borderPrimary py-3 px-4">
        <div className="flex flex-col text-xs text-text-secondary dark:text-white/40 font-semibold">
          <span
            className="active:text-black dark:active:text-white cursor-pointer mb-3"
            onClick={() => handleNavigate("/landing")}
          >
            Về chúng tôi
          </span>
          <span
            className="active:text-black dark:active:text-white cursor-pointer"
            onClick={() => handleNavigate("/contact")}
          >
            Liên hệ quảng cáo
          </span>
        </div>
        <div className="flex items-center justify-end">
          {listSocial?.map((social: any, index: number) => (
            <div className="w-6 h-6 group ml-2 cursor-pointer" key={index}>
              {!isMobile && (
                <img
                  src={social.iconDefault}
                  className="w-full h-full block group-hover:hidden"
                  alt="Hành động"
                />
              )}
              <img
                src={social.iconHover}
                className={classNames(
                  "w-full h-full hidden group-hover:block",
                  { "!block": isMobile }
                )}
                alt="Hành động"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SideBar;
