import lotteryApi from "apis/lotteryApi";
import userApi from "apis/userApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import ModalCommon from "components/ModalCommon/ModalCommon";
import TableResultMobile from "components/TableResultMobile/TableResultMobile";
import TagComponent from "components/TagComponent/TagComponent";
import { addBalanceUser } from "features/userSlice";
import useToast from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { currencyFormat } from "utils/currencyFormat";
import { formatDateBasic } from "utils/formateDate";
import { icons } from "utils/icons";
import {
  formatBetContent,
  formatGetNameLottery,
  formatGetTitleStatus,
  handleGetBalanceUser,
} from "utils/lotteryFormat";

function DetailBetMobile(props: any) {
  const { data, onClose, typeComponent, updateListRecord } = props;

  const toastify = useToast();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: any) => state.user);
  const { listStatus, listGames } = useSelector((state: any) => state.lottery);

  const [loading, setLoading] = useState<boolean>(false);
  const [showBottomSheetResult, setShowBottomSheetResult] =
    useState<boolean>(false);
  const [showModalConfirmCancel, setShowModalConfirmCancel] =
    useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  }, []);

  // Hàm hủy vé cược
  const handleCancelBet = async (idTicket: number) => {
    try {
      const { data } = await lotteryApi.cancelBetLottery({
        id: idTicket,
      });
      if (data) {
        onClose();
        updateListRecord(idTicket, "cancel");
        toastify("Hủy vé thành công!", "success");
        handleGetBalanceUser();
      }
    } catch (error: any) {
      // if (!error.msg) toastify(error.msg, "error");
      console.log(error);
      console.log(error);
    } finally {
      setShowModalConfirmCancel(false);
    }
  };

  return (
    <div className="relative w-full h-full">
      <div
        className={classNames(
          "w-full flex items-center bg-white dark:bg-bgPrimary border-b border-borderLightPrimary dark:border-borderPrimary px-4",
          { "justify-between": typeComponent === "bottomsheet" }
        )}
      >
        {typeComponent === "component" && (
          <button
            className="p-1 w-7 h-7 rounded-lg bg-secondary/10 flex items-center justify-center mr-2.5"
            type="button"
            onClick={onClose}
          >
            <img src={icons.arrowLeft} className="h-3" alt="Chi Tiết Vé Chơi" />
          </button>
        )}
        <span className="text-primary text-base font-semibold border-b border-transparent p-2.5">
          Chi Tiết Vé Chơi
        </span>
        {typeComponent === "bottomsheet" && (
          <button
            className="close-button p-1 rounded-lg bg-secondary/10"
            onClick={onClose}
          >
            <img
              src={require("assets/icons/close.png")}
              className="w-5 h-5"
              alt="Đóng"
            />
          </button>
        )}
      </div>
      {loading ? (
        <div className="w-full h-1/2 flex items-center justify-center">
          <ClipLoader color="#36d7b7" size={36} />
        </div>
      ) : (
        <div className="detail-bet w-full h-full px-4 py-4 rounded bg-white dark:bg-bgPrimary overflow-auto max-h-[calc(100%-46px)]">
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Mã vé chơi
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {data?.id}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Tên người chơi
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {userInfo?.loginname}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Tên đài chơi
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {formatGetNameLottery(data?.gameid, listGames)}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Kiểu chơi
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {formatBetContent(data?.betcontent)}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Lượt xổ
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {data?.gameid === 50
                ? formatDateBasic(data?.bettime)
                : data?.issue}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Tỉ lệ
            </span>
            <span className="text-sm text-error-500 font-medium">
              1 : {data?.odd}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Thời gian chơi
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {data?.bettime ? formatDateBasic(data?.bettime) : "-"}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Thời gian kết thúc
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {data?.finishtime ? formatDateBasic(data?.finishtime) : "-"}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Nội dung chi tiết
            </span>
            <span className="text-sm text-end font-medium w-4/5 text-wrap break-words text-black dark:text-white">
              {data?.codes}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Tổng số chơi
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {data?.betnumber} số
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Kết quả
            </span>
            {data?.winnumber?.length > 0 ? (
              <button
                type="button"
                className="bg-none border-none flex items-center justify-end"
                onClick={() => setShowBottomSheetResult(true)}
              >
                <img src={icons.assignment} className="w-6 h-6" alt="Kết quả" />
              </button>
            ) : (
              <span className="text-sm font-mediumtext-black dark:text-white">
                Chưa có kết quả
              </span>
            )}
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Cấp số nhân
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {data?.multiple} lần
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Số lần trúng
            </span>
            <span className="text-sm font-mediumtext-black dark:text-white">
              {data?.wintimes} lần
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Điểm chơi
            </span>
            <span className="text-sm font-medium text-black dark:text-white">
              {data?.amount > 0 ? currencyFormat(data?.amount) : 0}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="text-sm font-medium text-text-secondary dark:text-white/40">
              Điểm thắng
            </span>
            <span
              className={classNames(
                "text-sm font-medium text-black dark:text-white",
                {
                  "!text-success-500": data?.bonus > 0,
                  "!text-error-500": data?.bonus < 0,
                }
              )}
            >
              {data?.bonus > 0 ? currencyFormat(data?.bonus) : 0}
            </span>
          </div>
          <div className="flex items-center justify-between px-2 py-2.5 border-b border-borderLightPrimary dark:border-borderPrimary">
            <span className="title text-sm text-text-secondary dark:text-white/40 font-medium flex-1">
              Trạng thái
            </span>
            <span
              className={classNames(
                "w-fit text-center text-wrap font-medium break-words truncate text-xs"
              )}
            >
              <TagComponent
                title={formatGetTitleStatus(data?.status, listStatus, "title")}
                type={
                  [3, 5, 6, 7].includes(data?.status)
                    ? "error"
                    : data?.status === 4
                    ? "success"
                    : "warning"
                }
              />
            </span>
          </div>
          <div className="w-full mt-4">
            {data?.status === 0 && (
              <button
                className="w-full bg-primary flex items-center justify-center text-white hover:bg-primary/80 px-3 py-2.5 rounded-[10px] text-sm font-semibold"
                type="button"
                onClick={() => setShowModalConfirmCancel(true)}
              >
                Hủy vé
              </button>
            )}
          </div>
        </div>
      )}
      {/* result bet */}
      <Modal
        isShow={showModalConfirmCancel}
        onClose={() => setShowModalConfirmCancel(false)}
      >
        <ModalCommon
          title="Xác nhận"
          content="Bạn có muốn huỷ vé cược này không?"
          handleClose={() => setShowModalConfirmCancel(false)}
          handleSubmit={() => handleCancelBet(data?.id)}
        />
      </Modal>
      <div
        className={classNames(
          "w-full h-full bg-white dark:bg-bgPrimary absolute top-0 -right-full transition-all duration-300 ease-in-out",
          {
            "!right-0": showBottomSheetResult,
          }
        )}
      >
        <TableResultMobile
          dataDetail={data}
          type="history"
          gameid={data?.gameid}
          onClose={() => setShowBottomSheetResult(false)}
          typeComponent="component"
        />
      </div>
    </div>
  );
}

export default DetailBetMobile;
