import classNames from "classnames";
import TableDauDuoi from "components/TableDauDuoi/TableDauDuoi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { formatDateBasic } from "utils/formateDate";
import { icons } from "utils/icons";
import {
  findRegionByGameId,
  formatGetNameLottery,
  formatStringWinnumberToResultData,
} from "utils/lotteryFormat";
import TableFormatMBMobile from "./TableFormatMBMobile";
import TableFormatMTMNMobile from "./TableFormatMTMNMobile";
import ButtonAction from "components/ButtonAction/ButtonAction";

function TableResultMobile(props: any) {
  const {
    gameid,
    method,
    listIssue,
    statusUpdate,
    previousIssue,
    dataDetail,
    type,
    onClose,
    typeComponent,
    getListIssue,
  } = props;

  const { isMobile, isTablet } = useSelector((state: any) => state.layout);
  const { listGames } = useSelector((state: any) => state.lottery);
  const [issue, setIssue] = useState<string>();
  const [dataResult, setDataResult] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFastLottery, setIsFastLottery] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState<string>("result");

  useEffect(() => {
    if (gameid && (isMobile || isTablet)) {
      setIsFastLottery([50, 100, 101, 102, 103, 104].includes(gameid));

      if (type !== "all") {
        setDataResult(
          formatStringWinnumberToResultData(gameid, dataDetail.winnumber)
        );
      } else {
        setDataResult(
          formatStringWinnumberToResultData(gameid, listIssue[0]?.winnumber)
        );
      }
    }
  }, [gameid, type, dataDetail]);

  const handleChangeIssue = (ev: any) => {
    const issueChange = ev.target.value;
    setIssue(issueChange);
    const issueDataCurrent: any = listIssue.find(
      (iss: any) => (isFastLottery ? iss.time : iss.issue) === issueChange
    );
    setDataResult(
      formatStringWinnumberToResultData(gameid, issueDataCurrent.winnumber)
    );
  };

  return (
    <>
      <div
        className={classNames(
          "w-full flex items-center bg-white dark:bg-bgPrimary border-b border-borderLightPrimary dark:border-borderPrimary px-4",
          { "justify-between": typeComponent === "bottomsheet" }
        )}
      >
        {typeComponent === "component" && (
          <button
            className="p-1 w-7 h-7 rounded-lg bg-secondary/10 flex items-center justify-center mr-2.5"
            type="button"
            onClick={onClose}
          >
            <img src={icons.arrowLeft} className="h-3" alt="Kết Quả" />
          </button>
        )}
        <div className="flex items-center">
          <h2
            className={classNames(
              "text-text-secondary dark:text-white/40 text-base font-semibold border-b border-transparent p-2.5",
              {
                "!text-black dark:!text-white !border-primary bg-[linear-gradient(180deg,rgba(0,0,0,0)_34.34%,rgba(34,197,94,0.1)_82.45%)]":
                  tabActive === "result",
              }
            )}
            onClick={() => setTabActive("result")}
          >
            Kết Quả
          </h2>
          <span
            className={classNames(
              "text-text-secondary dark:text-white/40 text-base font-semibold border-b border-transparent p-2.5",
              {
                "!text-black dark:!text-white !border-primary bg-[linear-gradient(180deg,rgba(0,0,0,0)_34.34%,rgba(34,197,94,0.1)_82.45%)]":
                  tabActive === "dauduoi",
              }
            )}
            onClick={() => setTabActive("dauduoi")}
          >
            Đầu Đuôi
          </span>
        </div>
        {typeComponent === "bottomsheet" && (
          <button
            className="close-button p-1 rounded-lg bg-secondary/10"
            onClick={onClose}
          >
            <img
              src={require("assets/icons/close.png")}
              className="w-5 h-5"
              alt="Đầu Đuôi"
            />
          </button>
        )}
      </div>
      {loading ? (
        <div className="w-full h-1/2 flex items-center justify-center">
          <ClipLoader color="#36d7b7" size={36} />
        </div>
      ) : (
        <div className="table-result w-full px-4 py-4 overflow-auto bg-white dark:bg-bgPrimary max-h-[calc(100%-48px)]">
          <div className="wrap-info-result mb-3">
            <div className="flex items-center justify-between">
              <span className="text-base text-primary font-medium">
                Kết quả xổ số
              </span>
              {type === "all" ? (
                <select
                  className="select-period px-2 py-1 text-black dark:text-white bg-surface-tertiary dark:bg-black text-sm outline-none border border-borderLightPrimary dark:border-borderPrimary rounded-lg"
                  onChange={(e) => handleChangeIssue(e)}
                >
                  {listIssue?.length > 0 &&
                    listIssue.map((iss: any, index: number) => (
                      <option
                        className="text-option"
                        value={isFastLottery ? iss.time : iss.issue}
                        key={index}
                      >
                        {isFastLottery ? formatDateBasic(iss.time) : iss.issue}
                      </option>
                    ))}
                </select>
              ) : (
                <span className="text-sm text-white dark:text-white font-medium">
                  {isFastLottery
                    ? formatDateBasic(dataDetail?.bettime)
                    : dataDetail?.issue}
                </span>
              )}
            </div>
            {/* {dataResult?.giaidb === "" && (
              <ButtonAction
                title="Cập nhật kết quả"
                onSubmit={getListIssue}
                type="secondary"
                className="!w-full my-4"
              />
            )} */}
          </div>
          {tabActive === "result" &&
            (dataResult ? (
              [210, 200, 201].includes(Number(gameid)) ? (
                <TableFormatMBMobile
                  gameid={type === "all" ? gameid : dataDetail?.gameid}
                  method={
                    type === "all" ? method?.checkflag : dataDetail?.checkflag
                  }
                  data={dataResult}
                  currentIssue={issue}
                  issue={previousIssue?.issue}
                  statusUpdate={statusUpdate}
                />
              ) : (
                <TableFormatMTMNMobile
                  gameid={type === "all" ? gameid : dataDetail?.gameid}
                  method={
                    type === "all" ? method?.checkflag : dataDetail?.checkflag
                  }
                  data={dataResult}
                  currentIssue={issue}
                  issue={previousIssue?.issue}
                  statusUpdate={statusUpdate}
                />
              )
            ) : (
              <div className="w-full p-4 flex justify-center items-center text-sm text-black dark:text-white">
                Chưa có kết quả
              </div>
            ))}
          {dataResult && tabActive === "dauduoi" && (
            <div className="wrap-tbl-dauduoi mt-2">
              <TableDauDuoi
                winnumber={dataResult}
                method={
                  type === "all" ? method?.checkflag : dataDetail?.checkflag
                }
                statusUpdate={statusUpdate}
              />
            </div>
          )}
          {dataResult?.giaidb === "" && (
            <div className="mt-3 w-full flex items-center">
              <p className="text-error-500 text-sm italic font-medium">
                Kết quả xổ số {formatGetNameLottery(gameid, listGames)} hôm nay
                sẽ có vào lúc{" "}
                {findRegionByGameId(gameid) === "mb"
                  ? "18h35"
                  : findRegionByGameId(gameid) === "mt"
                  ? "17h35"
                  : "16h35"}
                . Nếu như đã đến giờ bảng kết quả chưa hiển thị vui lòng reload
                lại trang.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default TableResultMobile;
