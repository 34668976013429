import { IField } from "./lottery.interface";

export const ltOpenDay = [
  "202,207,203,200,126,139,131,123,124,125,201",
  "202,207,203,200,126,127,105,106,107,201",
  "202,207,203,200,128,129,109,110,201", // 108
  "202,207,203,200,130,131,111,112,113,201",
  "202,207,203,200,132,133,134,114,115,116,201",
  "202,207,203,200,135,136,117,118,119,201",
  "202,207,203,200,137,138,130,105,121,122,201", // 120
];

export const listLiveLottery = [
  {
    id: 210,
    nameLottery: "Miền Bắc Live",
    group: "lottery",
  },
  {
    id: 211,
    nameLottery: "Miền Trung Live",
    group: "lottery",
  },
  {
    id: 212,
    nameLottery: "Miền Nam Live",
    group: "lottery",
  },
  {
    id: 213,
    nameLottery: "Xóc đĩa Live",
    group: "tradition",
  },
  {
    id: 214,
    nameLottery: "Tài xỉu Live",
    group: "tradition",
  },
  {
    id: 215,
    nameLottery: "Bầu cua Live",
    group: "tradition",
  },
];

export const listDayToWeek = [
  {
    id: 1,
    desc: "Thứ hai",
    name: "Monday",
  },
  {
    id: 2,
    desc: "Thứ ba",
    name: "Tuesday",
  },
  {
    id: 3,
    desc: "Thứ tư",
    name: "Wednesday",
  },
  {
    id: 4,
    desc: "Thứ năm",
    name: "Thursday",
  },
  {
    id: 5,
    desc: "Thứ Sáu",
    name: "Friday",
  },
  {
    id: 6,
    desc: "Thứ Bảy",
    name: "Sartuday",
  },
  {
    id: 7,
    desc: "Chủ nhật",
    name: "Sunday",
  },
];

export const listSelectLot = [
  {
    id: 1,
    title: "All",
    type: "all",
    isSelect: true,
  },
  {
    id: 2,
    title: "Xổ Số Miền Bắc",
    type: "mb",
    isSelect: false,
  },
  {
    id: 3,
    title: "Xổ Số Miền Trung",
    type: "mt",
    isSelect: false,
  },
  {
    id: 4,
    title: "Xổ Số Miền Nam",
    type: "mn",
    isSelect: false,
  },
];

export const dataMBFields: IField[] = [
  {
    name: "Giải Đặc Biệt",
    key: "giaidb",
    nums: 1,
    input: 0,
    length: 5,
  },
  {
    name: "Giải Nhất",
    key: "giainhat",
    nums: 1,
    input: 1,
    length: 5,
  },
  {
    name: "Giải Nhì",
    key: "giainhi",
    nums: 2,
    input: 2,
    length: 5,
  },
  {
    name: "Giải Ba",
    key: "giaiba",
    nums: 6,
    input: 4,
    length: 5,
  },
  {
    name: "Giải Tư",
    key: "giaitu",
    nums: 4,
    input: 10,
    length: 4,
  },
  {
    name: "Giải Năm",
    key: "giainam",
    nums: 6,
    input: 14,
    length: 4,
  },
  {
    name: "Giải Sáu",
    key: "giaisau",
    nums: 3,
    input: 20,
    length: 3,
  },
  {
    name: "Giải Bảy",
    key: "giaibay",
    nums: 4,
    input: 23,
    length: 2,
  },
];

export const dataMNMTFields: IField[] = [
  {
    name: "Giải Đặc Biệt",
    key: "giaidb",
    nums: 1,
    input: 1,
    length: 6,
  },
  {
    name: "Giải Nhất",
    key: "giainhat",
    nums: 1,
    input: 1,
    length: 5,
  },
  {
    name: "Giải Nhì",
    key: "giainhi",
    nums: 1,
    input: 1,
    length: 5,
  },
  {
    name: "Giải Ba",
    key: "giaiba",
    nums: 2,
    input: 2,
    length: 5,
  },
  {
    name: "Giải Tư",
    key: "giaitu",
    nums: 7,
    input: 7,
    length: 5,
  },
  {
    name: "Giải Năm",
    key: "giainam",
    nums: 1,
    input: 1,
    length: 4,
  },
  {
    name: "Giải Sáu",
    key: "giaisau",
    nums: 3,
    input: 3,
    length: 4,
  },
  {
    name: "Giải Bảy",
    key: "giaibay",
    nums: 1,
    input: 1,
    length: 3,
  },
  {
    name: "Giải Tám",
    key: "giaitam",
    nums: 1,
    input: 1,
    length: 2,
  },
];

export const listTypeDate = [
  {
    id: "bettime",
    name: "bettime",
    desc: "Thời gian chơi",
  },
  {
    id: "finishtime",
    name: "finishtime",
    desc: "Thời gian kết thúc",
  },
];
