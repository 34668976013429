import { useEffect, useState } from 'react';
const getRandomNumber = () => {
    return Math.floor(Math.random() * 10);
  };
  
const RandomNumber = ({ length }: any) => {
    const [randomNumbers, setRandomNumbers] = useState<string>("");
    useEffect(() => {
      let timeoutId: any;
  
      const updateRandomNumbers = () => {
        let result = "";
        for (let i = 0; i < length; i++) {
          result += getRandomNumber();
        }
        setRandomNumbers(result);
        timeoutId = setTimeout(updateRandomNumbers, 550); // Thay đổi thời gian để điều chỉnh tốc độ quay
      };
      updateRandomNumbers();
      return () => {
        clearTimeout(timeoutId);
      };
    }, [length]);
  
    useEffect(() => {
      const animateNumbers = () => {
        const elements = document.getElementsByClassName("stepper-result");
  
        requestAnimationFrame(() => {
          [...elements].forEach((e: any) => {
            const numToStop = e.dataset.num;
            const translateY = -parseInt(numToStop) * 20;
            e.style.transform = `translateY(${translateY}px)`;
          });
        });
      };
  
      animateNumbers();
    }, [randomNumbers]);
  
    return (
      <div className="odometer overflow-hidden h-5 flex">
        {randomNumbers.split("").map((value, index) => (
          <Stepper num={value} key={index}  />
        ))}
      </div>
    );
  };
  
  const Stepper = ({ num }: { num: any; }) => {
    const arr = Array.from(new Array(10));
    return (
      <div
        data-num={num}
        className="stepper-result flex flex-col relative top-0 transition-all duration-300 ease-linear"
      >
        {arr.map((_, index) => (
          <span
            className="stepper-result-value text-center h-5 leading-5"
            key={index}
          >
            {index}
          </span>
        ))}
      </div>
    );
  };
  
  export default RandomNumber;
  
