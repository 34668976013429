import "./BetTypeComponent.scss";
import { useEffect, useState } from "react";
import classNames from "classnames";

function BetTypeComponent(props: any) {
  const { listMethod, getType, image } = props;
  const [betTypeList, setBetTypeList] = useState<any>([]);
  const [betMethodList, setBetMethodList] = useState<any>([]);

  useEffect(() => {
    if (listMethod.length > 0) {
      const groupData = listMethod.reduce((group: any, item: any) => {
        const { groupbet, ...rest } = item;

        if (!group[groupbet]) {
          group[groupbet] = {
            nameType: groupbet,
            methodList: [],
            isActive:
              item.gameid === 201
                ? groupbet.toLowerCase() === "đánh đề"
                : groupbet.toLowerCase() === "bao lô"
                ? true
                : false,
          };
        }
        group[groupbet].methodList.push(rest);
        return group;
      }, {});

      const groupArray = Object.values(groupData);
      setBetTypeList(groupArray);
      const listMethodFilter: any = groupArray?.find(
        (item: any) => item.isActive === true
      );
      const methodsList = listMethodFilter?.methodList.map(
        (item: any, index: number) => {
          const isActive = index === 0 ? true : false;
          return { isActive: isActive, ...item };
        }
      );
      setBetMethodList(methodsList);
      if (methodsList) {
        getType(methodsList[0]?.checkflag);
      }
    }
  }, [listMethod]);

  // Hàm chọn loại cược
  const handleSelectBettype = (indexType: number) => {
    const newListBettype = betTypeList.map((item: any, index: number) => {
      if (index === indexType) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setBetTypeList(newListBettype);
    const listMethodFilter = betTypeList.find(
      (item: any, index: number) => index === indexType
    );
    const methodsList = listMethodFilter?.methodList.map(
      (item: any, index: number) => {
        const isActive = index === 0 ? true : false;
        return { isActive: isActive, ...item };
      }
    );
    setBetMethodList(methodsList);
    getType(methodsList[0]?.checkflag);
  };

  // Hàm chọn method cược
  const handleSelectMethod = (indexMethod: number, typeid: string) => {
    const newlistMethod = betMethodList.map((item: any, index: number) => {
      if (index === indexMethod) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setBetMethodList(newlistMethod);
    getType(typeid);
  };

  return (
    <div className="bet-type w-full">
      <div className="list-bet-type w-full flex items-center border-b border-borderLightPrimary dark:border-borderPrimary relative px-5 pt-4 pb-2">
        <img
          src={
            image ? image : require("assets/images/lottery/fast-lottery.png")
          }
          className="absolute top-0 left-0 w-full h-full max-w-full object-cover z-0 rounded-tl-[10px] rounded-tr-[10px]"
          alt="Các kiểu cược"
        />
        <div className="z-0 flex items-center">
          {betTypeList?.length > 0 &&
            betTypeList.map((type: any, index: number) => (
              <div
                className={classNames(
                  "type-item text-white/40 hover:text-white transition-colors p-2.5 text-bast border-b border-transparent font-semibold cursor-pointer",
                  {
                    "!border-primary !text-white bg-[linear-gradient(180deg,rgba(0,0,0,0)_34.34%,rgba(34,197,94,0.1)_82.45%)]":
                      type.isActive,
                  }
                )}
                onClick={() => handleSelectBettype(index)}
                key={index}
              >
                {type.nameType}
              </div>
            ))}
        </div>
      </div>
      <div className="list-bet-method w-fit rounded-lg flex items-center flex-wrap p-1 border border-borderLightPrimary dark:border-borderPrimary mt-4 mx-5">
        {betMethodList?.length > 0 &&
          betMethodList.map((item: any, index: number) => (
            <div
              className={classNames(
                "method-item px-2.5 py-1.5 rounded-md text-text-secondary dark:text-white/40 ml-1 cursor-pointer hover:text-black dark:hover:text-white transition-colors",
                {
                  "!ml-0": index === 0,
                  "!text-black dark:!text-white bg-surface-tertiary dark:!bg-bgSecondary":
                    item.isActive,
                  "!hidden": item.checkflag.includes("1K"),
                }
              )}
              onClick={() => handleSelectMethod(index, item.checkflag)}
              key={index}
            >
              {item.methodname}
            </div>
          ))}
      </div>
    </div>
  );
}

export default BetTypeComponent;
