import React, { useEffect, useState } from "react";

const LoadingTopBar = ({ loading }: any) => {
  const [width, setWidth] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<any>(null);

  useEffect(() => {
    if (loading) {
      setWidth(0);
      const id = setInterval(() => {
        setWidth((prev: number) => (prev < 90 ? prev + 1 : prev));
      }, 30);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setWidth(100);
      setTimeout(() => setWidth(0), 500);
    }
    return () => clearInterval(intervalId);
  }, [loading]);

  const progressStyle = {
    width: `${width}%`,
    height: "4px",
    backgroundColor: "#22C55E",
    transition: "width 0.2s ease-in-out",
  };

  return (
    <div style={{ width: "100%", height: "5px", position: "fixed", top: 0, left: 0, zIndex: 99999 }}>
      <div style={progressStyle}></div>
    </div>
  );
};

export default LoadingTopBar;
