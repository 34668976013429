import classNames from "classnames";
import BounceLoadingComponent from "components/BounceLoadingComponent/BounceLoadingComponent";
import { checkWatingLottery } from "constant";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFormatDayMonthYear } from "utils/date";

function WaitingForLotteryComponent(props: any) {
  const { name } = props;
  const { isMobile } = useSelector((state: any) => state.layout);
  const [timer, setTimer] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [nameLottery, setNameLottery] = useState<string>("");

  useEffect(() => {
    const currentTimeVN = getFormatDayMonthYear();
    setTimer(currentTimeVN);
  }, []);
  useEffect(() => {
    const nameLottery = checkWatingLottery?.filter(
      (item: any) => String(item?.type) === String(name)
    );
    if (nameLottery?.length > 0) {
      setMessage(nameLottery[0]?.msg);
      setNameLottery(nameLottery[0]?.name);
    } else {
      setMessage("Đang đợi quay...");
    }
  }, [name]);

  return (
    <div
      className={classNames(
        "w-[80%] mx-auto my-3 flex items-center justify-center flex-col gap-1",
        { "!w-full": isMobile }
      )}
    >
      <h2 className="text-center md:text-lg text-xl font-bold text-black dark:text-white uppercase">
        {nameLottery} {timer}
      </h2>
      <p className="text-center text-text-secondary text-base">{message}</p>
      <div className="flex">
        <BounceLoadingComponent length={8} size={3} color={"#525252"} />
      </div>
    </div>
  );
}

export default WaitingForLotteryComponent;
