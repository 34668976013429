import classNames from "classnames";
import useToast from "hooks/useToast";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

export const listMenuSidebar = [
  {
    id: 1,
    name: "Thông tin cá nhân",
    url: "thong-tin-ca-nhan",
  },
  {
    id: 2,
    name: "Lịch sử chơi",
    url: "lich-su",
  },
  {
    id: 3,
    name: "Biến động số dư",
    url: "bien-dong-so-du",
  },
  {
    id: 4,
    name: "Thống kê",
    url: "thong-ke",
  },
  {
    id: 5,
    name: "Thông báo",
    url: "thong-bao",
  },
];

const MenuSidebar = () => {
  const pathname = useLocation();
  const navigate = useNavigate();
  const toastify = useToast();

  const { isAuth } = useSelector((state: any) => state.auth);

  const handleNavigate = (path: string) => {
    if (!isAuth) {
      toastify("Vui lòng đăng nhập", "warning");
    }
    navigate(path);
  };

  const handleSliceUrl = (url: string) => {
    const parts = url.split("/");
    const result = parts.slice(2).join("/");
    return result;
  };

  return (
    <div className="flex flex-col bg-surface-tertiary dark:bg-bgSecondary rounded-[10px] p-2">
      {listMenuSidebar.map((menu: any, index: number) => (
        <div
          key={index}
          onClick={() => handleNavigate(`/tai-khoan/${menu.url}`)}
          className={classNames(
            "text-sm font-semibold text-text-secondary dark:text-white/40 p-2.5 rounded-[10px] bg-transparent hover:text-black dark:hover:text-white cursor-pointer",
            {
              "mt-2": index > 0,
              "bg-white dark:!bg-bgPrimary !text-black dark:!text-white":
                handleSliceUrl(pathname.pathname) === menu.url,
            }
          )}
        >
          {menu.name}
        </div>
      ))}
    </div>
  );
};

export default MenuSidebar;
