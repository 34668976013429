import ButtonAction from "components/ButtonAction/ButtonAction";

interface modalCommonProp {
  title: string;
  content: string;
  handleSubmit?: any;
  handleClose: any;
  id?: any;
  loading?: boolean;
}

const ModalCommon = (props: modalCommonProp) => {
  const { title, content, handleSubmit, handleClose, loading } = props;

  const submitModal = () => {
    handleSubmit();
  };

  return (
    <div className="w-[300px] rounded-lg overflow-hidden bg-surface-primary dark:bg-bgLayout mx-auto shadow-[0px_4px_24px_0px_#00000033]">
      <div className="header-dialog flex items-center justify-center px-4 py-3 bg-cardLottery bg-center">
        <span className="flex-1 w-full text-white text-center text-lg font-semibold">
          {title}
        </span>
      </div>
      <div className="content-dialog max-h-[240px] p-4 overflow-auto">
        <p
          className="text-sm text-textSecondary font-medium text-center text-wrap break-words text-black dark:text-white"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></p>
      </div>
      <div className="bottom-dialog flex items-center justify-end px-4 py-2 border-t border-borderLightPrimary dark:border-borderPrimary">
        {handleSubmit && (
          <ButtonAction
            title="Đồng ý"
            loading={loading}
            onSubmit={submitModal}
            type="primary"
            className="!px-4"
          />
        )}
        <button
          type="button"
          onClick={handleClose}
          className="inline-flex text-sm items-center w-fit px-4 py-2 ml-3 bg-primary/10 hover:bg-primary/20 hover:text-success-600 rounded-[10px] text-primary font-semibold"
        >
          {handleSubmit ? "Huỷ" : "Đóng"}
        </button>
      </div>
    </div>
  );
};
export default ModalCommon;
