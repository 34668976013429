import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import chatApi from "apis/chatApi";
import classNames from "classnames";
import Modal from "components/Modal/Modal";
import EmojiPicker from "emoji-picker-react";
import useToast from "hooks/useToast";
import LoginForm from "pages/AuthPage/components/LoginForm";
import { useEffect, useRef, useState } from "react";
import Emoji from "react-emoji-render";
import { useSelector } from "react-redux";
import { containsEmoji, formatMessageGift } from "utils/commonFormat";
import { icons } from "utils/icons";

type Theme = "dark" | "light" | undefined;

function ChatComponent({
  onClose,
  listChats,
  loading,
}: {
  onClose?: any;
  listChats: any;
  loading: boolean;
}) {
  const { userInfo } = useSelector((state: any) => state.user);
  const { isAuth } = useSelector((state: any) => state.auth);
  const { isTablet, isMobile, isMiniDesktop } = useSelector(
    (state: any) => state.layout
  );

  const toastify = useToast();

  const theme: Theme | undefined = "dark";

  const chatListRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const pickerRef = useRef<any>(null);

  const [message, setMessage] = useState<string>("");
  // const [showPopoverGift, setShowPopoverGift] = useState<boolean>(false);
  // const [listGifts, setListGifts] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPicker, setShowPicker] = useState(false);
  const [showModalLogin, setShowModalLogin] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
        // setShowPopoverGift(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [listChats]);

  const onEmojiClick = (emojiObject: any) => {
    setMessage((prevI) => prevI + emojiObject.emoji + " ");
  };

  const handleSubmitSendMessage = async (
    typeMessage: number,
    message: string
  ) => {
    const payload = {
      sender: userInfo?.loginname,
      content: message,
      gameroom: "200",
      typemessage: typeMessage,
    };
    if (message === "") {
      return;
    }

    setIsSubmitting(true);
    try {
      const { data } = await chatApi.sendChat(payload);
      if (data.error_code === 0) {
        if (inputRef.current) {
          inputRef.current.value = "";
          inputRef.current.focus();
          setMessage("");
        } else {
          toastify(data.msg, "warning");
        }
      }
    } catch (error: any) {
      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current.focus();
      }
      setMessage("");
      // if (!error.msg) toastify(error.msg, "error");
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const sendMessage = async (type: number, message: string, event?: any) => {
    event.preventDefault();
    if (isSubmitting) {
      return;
    }
    setShowPicker(false);
    handleSubmitSendMessage(type, message);
  };

  const handleKeyPressEnter = (e: any) => {
    if (e.key === "Enter" || e.key === "Return") {
      sendMessage(0, message, e);
      return;
    }
  };

  const handleChangeMsg = (ev: any) => {
    setMessage(ev.target.value);
  };

  return (
    <div
      className={classNames(
        "chat-wrap bg-white dark:bg-black shadow-table rounded-xl overflow-hidden h-[calc(60vh)] flex flex-col min-w-full",
        {
          "!mr-0 !w-full !max-w-full !h-full":
            isTablet || isMobile || isMiniDesktop,
          "!rounded-none": isMobile,
        }
      )}
    >
      <div
        className={classNames(
          "header-chat w-full px-3 bg-cardLotteryLight bg-no-repeat bg-cover dark:bg-cardLottery py-2.5 flex items-center justify-between"
        )}
      >
        <span className="user-name w-1/2 flex items-center font-semibold text-sm text-primary">
          Trò chuyện
        </span>
        {onClose && (
          <button
            type="button"
            className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
            onClick={onClose}
          >
            <img
              src={require("assets/icons/close.png")}
              className="w-5 h-5"
              alt="Đóng trò chuyện"
            />
          </button>
        )}
      </div>
      <div
        className={classNames(
          "content-chat max-h-[calc(100%-92px)] w-full h-full overflow-auto p-2 border-y border-borderLightPrimary dark:border-borderPrimary flex-1 relative scroll-smooth"
        )}
        ref={chatListRef}
      >
        {!loading ? (
          listChats?.length > 0 ? (
            listChats?.map((chat: any, index: number) => (
              <div className="chat-item px-2 py-2 flex" key={index}>
                <Tooltip
                  title={chat.sender}
                  placement="right"
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -8],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <span
                    className={classNames(
                      "name-user text-sm text-text-secondary dark:text-white/40 font-semibold text-nowrap truncate mr-1 w-3/12 cursor-default"
                    )}
                  >
                    {chat.sender}:
                  </span>
                </Tooltip>
                {chat.typemessage === 1 ? (
                  <div
                    className="flex flex-1"
                    dangerouslySetInnerHTML={{
                      __html: formatMessageGift(chat.content),
                    }}
                  ></div>
                ) : (
                  <p className="content-chat text-sm text-black dark:text-white font-medium flex-1">
                    {containsEmoji(chat.content) ? (
                      <Emoji text={chat.content}></Emoji>
                    ) : (
                      chat.content
                    )}
                  </p>
                )}
              </div>
            ))
          ) : (
            <div className="no-chat w-full h-full flex items-center justify-center text-sm text-black dark:text-white font-semibold">
              Chưa có nội dung chat
            </div>
          )
        ) : (
          <div className="absolute top-1/2 left-[45%] mx-auto">
            <CircularProgress size={24} />
          </div>
        )}
      </div>
      <div
        className={classNames(
          "bottom-chat w-full bg-white dark:bg-black py-3.5 px-3 relative"
        )}
      >
        {isAuth ? (
          <div className="flex items-center">
            <div className="wrap-input-chat w-full flex items-center rounded">
              <img
                src={icons.face}
                className="icon-emoji w-6 h-6 cursor-pointer mr-2"
                alt="Thêm icon"
                onClick={() => setShowPicker((val) => !val)}
              />
              {showPicker && (
                <div
                  className="absolute bottom-14 left-3 w-[92%]"
                  ref={pickerRef}
                >
                  <EmojiPicker
                    width="100%"
                    height={400}
                    searchDisabled={true}
                    theme={theme as any}
                    onEmojiClick={onEmojiClick}
                    allowExpandReactions
                  />
                </div>
              )}
              <input
                type="text"
                className="input-chat w-full flex-1 bg-transparent mr-2 outline-none text-sm text-black dark:text-white"
                placeholder="Nhập nội dung chat..."
                value={message}
                onChange={(e) => handleChangeMsg(e)}
                onKeyDown={(e) => handleKeyPressEnter(e)}
                ref={inputRef}
              />
              <button
                className="border-none bg-none"
                onClick={(e: any) => sendMessage(0, message, e)}
                type="button"
              >
                <img
                  src={icons.send}
                  className="w-6 h-6 cursor-pointer"
                  alt="Gửi tin nhắn"
                />
              </button>
            </div>
          </div>
        ) : (
          <div
            className="flex items-center justify-center w-full h-full text-primary hover:text-primary/80 text-sm font-semibold cursor-pointer"
            onClick={() => setShowModalLogin(true)}
          >
            Vui lòng đăng nhập
          </div>
        )}
      </div>
      <Modal isShow={showModalLogin} onClose={() => setShowModalLogin(false)}>
        <LoginForm onClose={() => setShowModalLogin(false)} />
      </Modal>
    </div>
  );
}

export default ChatComponent;
