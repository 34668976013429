import classNames from "classnames";
import SideBar from "components/SideBar/SideBar";
import { toggleSidebar } from "features/layoutSlice";
import { setConfigSeo } from "features/seoSlice";
import LandingPage from "pages/LandingPage/LandingPage";
import Header from "partials/Header/Header";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const LandingPageLayout = () => {
  const dispatch = useDispatch();
  const { isMobile, isTablet, showSidebar, isMiniDesktop } = useSelector(
    (state: any) => state.layout
  );
  const { configSeo } = useSelector((state: any) => state?.seo);

  useEffect(() => {
    dispatch(
      setConfigSeo({
        ...configSeo,
        title: "Về chúng tôi - Trợ lý xổ số hàng đầu Việt Nam",
      })
    );
  }, []);

  return (
    <div className="main-layout w-full h-full bg-surface-primary dark:bg-[#0E1013] flex">
      {(isMobile || isTablet || isMiniDesktop) && (
        <>
          <SideBar />
          <div
            className={classNames(
              "bg-black/40 opacity-0 transition-all duration-150 invisible ease-out fixed top-0 left-0 w-full h-full -z-[10]",
              {
                "!z-40 opacity-80 duration-150 ease-linear !visible":
                  showSidebar,
              }
            )}
            onClick={() => dispatch(toggleSidebar(false))}
          ></div>
        </>
      )}
      <div className="content-layout-secondary flex-1 w-full min-h-screen">
        <div
          className={classNames("main-header w-full h-[56px]", {
            "!h-[56px]": isMobile,
          })}
        >
          <div className="fixed z-30 w-full min-w-[100vw]">
            <Header type="landing"></Header>
          </div>
        </div>
        <div
          className={`main-content flex justify-center w-full ${
            isTablet && isMobile ? "h-[calc(100vh-32px)] overflow-y-auto" : ""
          } bg-surface-primary dark:bg-[#0E1013]`}
        >
          <LandingPage />
        </div>
      </div>
    </div>
  );
};

export default LandingPageLayout;
