import React, { useEffect } from "react";
import './BounceLoadingComponent.scss'
function BounceLoadingComponent({ length, size, color }:any) {
  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const sizeClass = `w-${size}`;
    const sizeRule = `.${sizeClass} { width: ${size * 4}px; height: ${size * 4}px; }`;
    const colorClass = `bg-${color.replace('#', '')}`;
    const colorRule = `.${colorClass} { background: ${color}; }`;

    styleSheet.insertRule(sizeRule, styleSheet.cssRules.length);
    styleSheet.insertRule(colorRule, styleSheet.cssRules.length);
  }, [size, color]);

  return (
    <div className="bounce-loading">
      {Array(length)
        ?.fill("")
        .map((_, index) => (
          <div key={index} className={`w-${size} h-${size} bg-${color.replace('#', '')}`}></div>
        ))}
    </div>
  );
}

export default BounceLoadingComponent;
