import { Tooltip } from "@mui/material";
import classNames from "classnames";
import ButtonAction from "components/ButtonAction/ButtonAction";
import { betLotteryI } from "interfaces/lottery.interface";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencyFormat } from "utils/currencyFormat";
import { getCurrentDateTime } from "utils/date";
import { formatCodesEasyWatch } from "utils/lotteryFormat";

function ModalBet(props: any) {
  const { onClose, clearTicket, handleSubmit, ticket, typeModalBet } = props;

  const { listTickets } = useSelector((state: any) => state.lottery);

  const [totalAmountBet, setTotalAmountBet] = useState<number>(0);

  useEffect(() => {
    if (typeModalBet === "betList") {
      let totalBet = 0;
      listTickets?.forEach((item: any) => {
        totalBet += item.amount;
      });
      setTotalAmountBet(totalBet);
    } else {
      setTotalAmountBet(ticket?.amount);
    }
  }, []);

  return (
    <div className="modal-bet rounded-lg w-full min-w-60 h-[500px] overflow-hidden bg-surface-primary dark:bg-bgLayout shadow-[0px_4px_24px_0px_#00000033]">
      <div className="header-modal bg-cardLottery bg-center bg-no-repeat bg-cover px-5 py-4 flex justify-normal items-center">
        <span className="title flex-1 text-xl text-primary">
          Chi Tiết Vé Chơi
        </span>
        <button
          type="button"
          className="bg-primary/10 hover:bg-primary/30 p-1 rounded-lg border-none cursor-pointer"
          onClick={onClose}
        >
          <img
            src={require("assets/icons/close.png")}
            className="w-5 h-5"
            alt="Đóng"
          />
        </button>
      </div>
      <div className="content-modal px-4 py-4 h-[calc(100%-133px)]">
        <div className="issue-wrap flex items-center justify-between">
          <div className="bet-time flex flex-col">
            <span className="text-primary text-base font-semiboldy">
              Thời gian đặt vé
            </span>
            <span className="mt-2 text-sm font-medium text-black dark:text-white text-text-secondar">
              {moment(getCurrentDateTime()).format("DD/MM/yyyy HH:mm:ss")}
            </span>
          </div>
          <div className="issue-time flex">
            <div className="bet-time flex flex-col mr-4">
              <span className="text-primary text-base font-semibold">
                Tổng số điểm chơi
              </span>
              <span className="mt-2 text-sm  font-medium text-black dark:text-white">
                {currencyFormat(totalAmountBet)}
              </span>
            </div>
          </div>
        </div>
        <div className="max-h-[calc(100%-66px)] h-full overflow-auto">
          <div className="table-wrap mt-4 rounded-[10px] border border-borderLightPrimary dark:border-borderPrimary overflow-hidden">
            <div className="header-table bg-surface-tertiary dark:bg-black">
              <div className="heade-table-item flex items-center">
                <div className="px-2.5 py-3 flex justify-center items-center w-2/12">
                  <span className="text-primary text-center text-xs font-semibold">
                    Tên kiểu chơi
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-2/12">
                  <span className="text-primary text-center text-xs font-semibold">
                    Số chơi
                  </span>
                </div>

                <div className="px-2.5 flex justify-center items-center w-2/12">
                  <span className="text-primary text-center text-xs font-semibold">
                    Tổng số chơi
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-2/12">
                  <span className="text-primary text-center text-xs font-semibold">
                    Cấp số nhân
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-2/12">
                  <span className="text-primary text-center text-xs font-semibold">
                    Tỉ lệ
                  </span>
                </div>
                <div className="px-2.5 flex justify-center items-center w-2/12">
                  <span className="text-primary text-center text-xs font-semibold">
                    Điểm chơi (point)
                  </span>
                </div>
              </div>
            </div>
            <div className="body-table">
              {typeModalBet === "betList" ? (
                listTickets.length > 0 &&
                listTickets.map((item: betLotteryI, index: number) => (
                  <div
                    className={classNames(
                      "body-table-item flex items-center py-1.5 border-t border-borderLightPrimary dark:border-borderPrimary bg-surface-tertiary dark:bg-black",
                      { "!bg-white dark:!bg-bgPrimary": index % 2 === 0 }
                    )}
                    key={index}
                  >
                    <div className="px-2.5 py-3 flex justify-center items-center w-2/12">
                      <span className=" text-center text-xs font-semibold text-black dark:text-white">
                        {item.methodName}
                      </span>
                    </div>
                    <div className="px-2.5 mx-1.5 flex justify-center items-center w-2/12">
                      <Tooltip
                        title={formatCodesEasyWatch(item.codes)}
                        placement="right"
                        arrow
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -8],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <span className=" w-full text-center truncate text-xs font-semibold text-black dark:text-white">
                          {formatCodesEasyWatch(item.codes)}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="px-2.5 flex justify-center items-center w-2/12">
                      <span className=" text-center text-xs font-semibold text-black dark:text-white">
                        {item.betnumber} số
                      </span>
                    </div>
                    <div className="px-2.5 flex justify-center items-center w-2/12">
                      <span className=" text-center text-xs font-semibold text-black dark:text-white">
                        {item.multiple} lần
                      </span>
                    </div>
                    <div className="px-2.5 flex justify-center items-center w-2/12">
                      <span className=" text-center text-xs font-semibold text-black dark:text-white">
                        {item.odd}
                      </span>
                    </div>
                    <div className="px-2.5 flex justify-center items-center w-2/12">
                      <span className="text-error-500 text-center text-xs font-semibold">
                        {currencyFormat(item.amount)}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="body-table-item flex items-center py-1.5 bg-white dark:bg-bgPrimary border-t border-borderLightPrimary dark:border-borderPrimary">
                  <div className="px-2.5 py-3 flex justify-center items-center w-2/12">
                    <span className=" text-center text-xs font-semibold text-black dark:text-white">
                      {ticket?.methodName}
                    </span>
                  </div>
                  <div className="px-2.5 mx-1.5 flex justify-center items-center w-2/12">
                    <Tooltip
                      title={formatCodesEasyWatch(ticket?.codes)}
                      placement="right"
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -8],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <span className=" w-full text-center truncate text-xs font-semibold text-black dark:text-white">
                        {formatCodesEasyWatch(ticket?.codes)}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="px-2.5 flex justify-center items-center w-2/12">
                    <span className=" text-center text-xs font-semibold text-black dark:text-white">
                      {ticket?.betnumber} số
                    </span>
                  </div>
                  <div className="px-2.5 flex justify-center items-center w-2/12">
                    <span className=" text-center text-xs font-semibold text-black dark:text-white">
                      {ticket?.multiple} lần
                    </span>
                  </div>
                  <div className="px-2.5 flex justify-center items-center w-2/12">
                    <span className=" text-center text-xs font-semibold text-black dark:text-white">
                      {ticket?.odd}
                    </span>
                  </div>
                  <div className="px-2.5 flex justify-center items-center w-2/12">
                    <span className="text-error-500 text-center text-xs font-semibold">
                      {currencyFormat(ticket?.amount)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-modal px-4 py-4 flex items-center justify-end border-t border-borderLightPrimary dark:border-borderPrimary">
        <div className="wrap-btn flex items-center">
          <button
            type="button"
            className="bg-secondary/20 flex items-center justify-center text-primary hover:opacity-80 px-3 py-2.5 rounded-[10px] text-sm font-semibold mr-3"
            onClick={() => clearTicket()}
          >
            Huỷ
          </button>
          <ButtonAction
            title="Đặt vé"
            onSubmit={handleSubmit}
            type="primary"
            className="py-2.5 min-w-16"
          />
        </div>
      </div>
    </div>
  );
}

export default ModalBet;
